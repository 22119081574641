import clsx from 'clsx'
import {doc, setDoc} from 'firebase/firestore'
import {useEffect, useState} from 'react'
import {Link, Navigate, useNavigate} from 'react-router-dom'
import {useCollection} from '../../../../../app/hooks/useCollection'
import {useAuthContext} from '../../../../../app/modules/auth'
import {db} from '../../../../../firebase/config'
import {KTSVG, toAbsoluteUrl} from '../../../../helpers'
import {Avatar} from '../../../../partials'

const SelectClient = () => {
  const navigate = useNavigate()

  const [client, setClient] = useState(null)
  const [search, setSearch] = useState('')
  const {user, userData} = useAuthContext()
  const {documents: clients} = useCollection('clients')
  const {documents: projects} = useCollection('projects')
  const [filteredProjects, setFilteredProjects] = useState<any | null>(projects)

  useEffect(() => {
    if (userData) {
      userData.activeClient && setClient(userData.activeClient)
    }
  }, [userData])

  useEffect(() => {
    if (projects) {
      if (userData.model.isManager) {
        setFilteredProjects(projects)
      } else if (userData.model.isTeammate) {
        setFilteredProjects(
          projects
            .filter((p: any) => p.isActive === true)
            .filter(
              (p: any) =>
                p?.works?.filter(
                  (w: any) => w?.authorities?.filter((a: any) => a.uid === user.uid).length > 0
                ).length > 0 || p.authorities.filter((a: any) => a.uid === user.uid).length > 0
            )
        )
      }
    }
  }, [projects])

  function changeClient(id) {
    const docRef = doc(db, 'users', user.uid)
    setSearch('')
    setDoc(
      docRef,
      {
        activeClient: id,
      },
      {merge: true}
    )
      .then(() => {
        setClient(id)
        navigate('client/' + id)
      })
      .catch((error) => {
        console.log(error.message)
      })
  }

  return clients.filter(
    (client: any) =>
      // client.authorities?.filter((u: any) => user.uid === u.uid).length > 0 &&
      client?.brandName.toLowerCase().indexOf(search.toLowerCase()) !== -1
  ).length > 0 || search !== '' ? (
    <>
      <div className='app-navbar-item ms-1 ms-lg-3'>
        <div
          className={clsx('cursor-pointer')}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-dismiss='click'
          data-kt-menu-placement='bottom-end'
        >
          {client && clients.filter((c: any) => c.id === client).length > 0 ? (
            clients
              .filter((c: any) => c.id === client)
              .map((selectedClient: any, i) => (
                <div key={i}>
                  <Avatar
                    className={'symbol symbol-35px symbol-md-40px'}
                    name={selectedClient.brandName}
                    avatarURL={selectedClient?.logo}
                  />
                </div>
              ))
          ) : (
            <>
              <Avatar className={' symbol symbol-35px symbol-md-40px'} name={'?'} />{' '}
            </>
          )}
        </div>

        <div
          className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold fs-6 w-275px'
          data-kt-menu='true'
        >
          <div className='menu-item px-3 rounded-top '>
            {client &&
              clients
                .filter((c: any) => c.id === client)
                .map((selectedClient: any, i) => (
                  <Link to={`/client/${selectedClient.id}/`} key={i} data-kt-menu-dismiss='true'>
                    <div className='menu-content bg-hover-light-primary rounded-2 mt-2 d-flex align-items-center px-3'>
                      <Avatar
                        className={'symbol symbol-40px me-3'}
                        name={selectedClient.brandName}
                        avatarURL={selectedClient?.logo}
                      />

                      <div className='d-flex flex-column flex-fill'>
                        <div className='fw-bolder d-flex align-items-center fs-7'>
                          {selectedClient.brandName}
                        </div>
                        <span className='fw-semibold text-muted fs-7'>
                          {selectedClient.companyName.substring(0, 25)}
                          {selectedClient.companyName.length > 25 && '...'}
                        </span>
                      </div>
                    </div>
                  </Link>
                ))}
            <div className='separator separator-dashed mt-2 mx-2'></div>
            <input
              autoFocus
              type='text'
              className='form-control  bg-transparent border-0 fs-6  fw-semibold'
              placeholder='Search brand.'
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            {/* <KTSVG path='/media/icons/search.svg' className='svg-icon-2' /> */}
            <div className='separator separator-dashed mx-2'></div>
          </div>

          <div className='scroll-y mh-325px py-2'>
            {clients

              .filter((c: any) => c.isActive === true)
              .filter(
                (c: any) => filteredProjects?.filter((p: any) => p?.clientUID === c?.id).length > 0
              )
              .map(
                (client: any, i) =>
                  // client.authorities?.filter((u: any) => user.uid === u.uid).length > 0 &&
                  client?.brandName.toLowerCase().indexOf(search.toLowerCase()) !== -1 && (
                    <div className='menu-item px-4 pe-3 py-1' key={i}>
                      <a
                        onClick={() => changeClient(client.id)}
                        className='menu-link bg-hover-light-primary p-2'
                      >
                        <div className='d-flex align-items-center'>
                          <Avatar
                            className={'symbol symbol-40px  me-5'}
                            name={client.brandName}
                            avatarURL={client?.logo}
                          />
                          <div className='d-flex flex-column'>
                            <div className='fw-bold d-flex align-items-center fs-7'>
                              {client?.brandName}
                            </div>
                            <span className='fw-bold text-muted fs-8'>
                              {client?.companyName?.substring(0, 30)}
                              {client?.companyName?.length > 25 && '...'}
                            </span>
                          </div>
                        </div>
                      </a>
                    </div>
                  )
              )}
          </div>
        </div>
      </div>
    </>
  ) : (
    <></>
  )
}

export {SelectClient}
