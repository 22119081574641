import {FC} from 'react'

import moment from 'moment'
import {Avatar} from '../../../../content/user/Avatar'

type Props = {
  id: string
  chatTitle: string
  logo: string
  notification?: boolean
  displayName?: boolean
  message?: boolean
  createdAt?: any
}

export const RoomCard: FC<Props> = ({
  id,
  chatTitle,
  logo,
  notification,
  createdAt,
  displayName,
  message,
}) => {
  return (
    <div className='d-flex align-items-sm-center mb-2 p-2 rounded-2 bg-hover-light'>
      <Avatar className='symbol symbol-50px rounded-2 me-4' name={chatTitle} avatarURL={logo} />
      <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
        <div className='flex-grow-1 me-2'>
          <span className='text-primary fs-6 fw-bold '>{chatTitle}</span>
          <span className='text-muted fw-bold d-block fs-8 '>
            {displayName && displayName} {!displayName && 'Send first message.'}
          </span>
          <span className='text-muted fw-normal  fs-8'>
            {message && notification
              ? message?.toString().substring(0, 30)
              : message?.toString().substring(0, 35)}
            {message && message?.toString().length > 30 && '...'}
            {!message && 'You have been added to a room'}
          </span>
        </div>

        <span className='text-muted fs-9 me-4'>
          {createdAt && moment.utc(new Date(createdAt.toDate())).local().startOf('s').fromNow(true)}
        </span>
        {notification && <span className='w-15px h-15px bg-warning me-4 rounded-4'></span>}
      </div>
    </div>
  )
}
