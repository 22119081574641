import {ILayout} from './_Models'

export const DefaultConfig: ILayout = {
  layoutType: 'light-sidebar',
  main: {
    componentName: 'main',
    type: 'default',
    pageBgWhite: false,
  },
  app: {
    general: {
      componentName: 'general',
      evolution: true,
      layoutType: 'default',
      mode: 'light',
      rtl: false,
      primaryColor: '#50CD89',
      pageBgWhite: false,
      pageWidth: 'default',
    },
    header: {
      componentName: 'header',
      display: true,
      default: {
        container: 'fluid',
        containerClass: 'd-flex align-items-stretch justify-content-between',
        fixed: {
          desktop: true,
          mobile: true,
        },
        content: 'menu',
        menu: {
          display: true,
          iconType: 'svg',
        },
      },
    },
		rightSidebar: {
			componentName: 'rightSidebar',
			display: true, // Set true|false to add or remove Sidebar
			toggle: true, // Set true|false to enable Sidebar show and hide toggle
			shown: true, // Set true|false to by default show or hide Sidebar
			content: "works", // Set general|user|shop content for Sidebar
			bgColor: "bg-white", // Set background class for Sidebar,
			displayFooter: false, // Set true|false to display Sidebar Footer
			displayFooterButton: false, // Set true|false to display Sidebar Footer Button
		},
    toolbar: {
      componentName: 'toolbar',
      display: false,
      layout: 'classic',
      class: 'py-3 py-lg-6',
      container: 'fluid',
      containerClass: 'd-flex flex-stack',
      fixed: {
        desktop: false,
        mobile: false,
      },
      // custom settings,
      filterButton: true,
      daterangepickerButton: false,
      primaryButton: true,
      primaryButtonLabel: 'Create',
      primaryButtonModal: 'create-app',
    },
    pageTitle: {
      componentName: 'page-title',
      display: true,
      breadCrumb: true,
      description: false,
      direction: 'column',
    },
    content: {
      componentName: 'content',
      container: 'fluid',
    },
    footer: {
      componentName: 'footer',
      display: true,
      container: 'fluid',
      containerClass: 'd-flex flex-column flex-md-row flex-center flex-md-stack py-3',
      fixed: {
        desktop: false,
        mobile: false,
      },
    },
    pageLoader: {
      componentName: 'page-loader',
      type: 'none',
      logoImage: 'default.svg',
      logoClass: 'mh-75px',
    },
  },
  illustrations: {
    componentName: 'illustrations',
    set: 'sketchy-1',
  },
  scrolltop: {
    componentName: 'scrolltop',
    display: true,
  },
  engage: {
    componentName: 'engage',
    demos: {
      enabled: true,
    },
    purchase: {
      enabled: false,
    },
  },
}
