/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import {DropdownSocialMediaContentSettings} from '../dropdown/DropdownSocialMediaContentSettings'
import clsx from 'clsx'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import moment from 'moment'
import {Timestamp} from 'firebase/firestore'
import {DropdownSocialMediaChannelType} from '../dropdown/DropdownSocialMediaChannelType'
import {
  SocialMediaChannelModels,
  SocialMediaContentStatusModels,
} from '../../components/models/SocialMediaModels'
import {DropdownSocialMediaContentStatus} from '../dropdown/DropdownSocialMediaContentStatus'
import {Avatar} from '../../../../../../../_metronic/partials'
import {useCollection} from '../../../../../../hooks/useCollection'
import {TagsInput} from 'react-tag-input-component'
import {Contents} from './tabs/Contents'
const btnClass = 'btn btn-sm btn-icon btn-light-primary h-31px w-31px'

const channels = [
  {name: 'Facebook', type: 'Post'},
  {name: 'Instagram', type: 'Reels'},
  {name: 'Youtube', type: 'Shorts'},
  {name: 'Linkedin', type: 'Post'},
]

type Props = {
  contentData?: any
  socialMediaSettings?: any
  media: string
  isStory?: boolean
  badgeColor: string
  publishDate?: any
  publishTime?: string
  status: string
  statusColor: string
  content: string
  logsCount?: string
  date: string
  budget: string
  progress: number
}

const CardSocialMediaContent: FC<Props> = ({
  media,
  socialMediaSettings,
  contentData,
  badgeColor,
  isStory,
  publishDate,
  publishTime,
  status,
  statusColor,
  logsCount,
  content,
  date,
  budget,
  progress,
}) => {
  const [channels, setChannels] = useState<any | null>(null)
  const [settings, setSettings] = useState(false)
  const [tags, setTags] = useState<any | null>(contentData?.contentTags)

  const {id} = useParams()
  const clientUID = id ? id : ''
  console.log(contentData)
  const {documents: logsCollections} = useCollection(
    'clients/' + clientUID + '/contents/' + contentData.id + '/logs'
  )

  useEffect(() => {
    if (contentData?.channels) {
      setChannels(
        contentData?.channels.reduce((group, channel) => {
          const {name} = channel
          group[name] = group[name] ?? []
          group[name].push(channel)
          return group
        }, {})
      )
    }
  }, [contentData])

  return (
    <div className='card card-custom card-flush'>
      <div className='card-header border-0 p-5'>
        <div className='card-title m-0 fs-8 fw-bold text-primary'>
          <span
            className={`badge badge-circle badge-${
              SocialMediaContentStatusModels.find((s) => s.name === contentData?.status)?.class
            } p-1 w-15px h-15px me-2`}
          ></span>
          {moment(contentData.publishDate.toDate()).format('dddd / DD MMMM ')}
        </div>

        <div className='card-toolbar m-0'>
          <div className='m-0'>
            {/* <a
              href='#'
              className={`badge badge-light-${
                SocialMediaContentStatusModels.find((s) => s.name === contentData?.status)?.class
              } fw-bolder px-4 py-3 me-2`}
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
            >
              {SocialMediaContentStatusModels.find((s) => s.name === contentData?.status)?.name}
            </a> */}
            <DropdownSocialMediaContentStatus contentData={contentData} />
          </div>
          {/* <div className='me-2'>
            <a href='#' className={clsx('position-relative', btnClass)}>
              <KTSVG path='/media/icons/search.svg' className={'svg-icon-2'} />
            </a>
          </div> */}
          {/* <div className='m-0'>
            <a
              href='#'
              className={clsx('position-relative', btnClass)}
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
            >
              <KTSVG path='/media/icons/three-dots.svg' className={'svg-icon-2'} />
            </a>
            <DropdownSocialMediaContentSettings contentData={contentData} />
          </div> */}
        </div>
      </div>

      <div className='card-body p-5 py-0'>
        {!settings && (
          <>
            {!contentData?.media && (
              <img
                src={toAbsoluteUrl('/media/content/blankContent.png')}
                alt='card2'
                className='rounded-1 w-100'
                object-fit='cover'
                aspect-ratio='1 / 1'
              />
            )}
            {contentData?.media && (
              <div className='w-100 h-auto'>
                {!isStory && (
                  <img
                    src={toAbsoluteUrl(media)}
                    alt='card2'
                    className='rounded-1 w-100'
                    object-fit='cover'
                    aspect-ratio='1 / 1'
                  />
                )}
                {isStory && (
                  <img
                    src={toAbsoluteUrl(media)}
                    alt='card2'
                    className='rounded-1 w-100'
                    object-fit='cover'
                    aspect-ratio='1 / 1'
                  />
                )}
              </div>
            )}
            {contentData?.contentText && (
              <p className='text-primary fs-6 my-2 '>{contentData?.contentText}</p>
            )}
            <p className='text-primary fs-6 my-2 '>
              {contentData?.contentTags?.map((tag: any) => '#' + tag + ' ')}
            </p>
          </>
        )}
        {settings && (
          <>
            <div className='ratio ratio-1x1'>
              <div className='h-100'>
                <ul className='nav  mb-5 fs-7'>
                  <li>
                    <a
                      className={clsx('position-relative w-30px h-30px me-2 active', btnClass)}
                      data-bs-toggle='tab'
                      href='#content'
                    >
                      <i className='bi bi-type fs-4'></i>
                    </a>
                  </li>
                  <li>
                    <a
                      className={clsx('position-relative w-30px h-30px me-2', btnClass)}
                      data-bs-toggle='tab'
                      href='#medias'
                    >
                      <i className='bi bi-images fs-4'></i>
                    </a>
                  </li>
                  <li>
                    <a
                      className={clsx('position-relative w-30px h-30px me-2', btnClass)}
                      data-bs-toggle='tab'
                      href='#medias'
                    >
                      <i className='bi bi-lightbulb fs-4'></i>
                    </a>
                  </li>
                  <li>
                    <a
                      className={clsx('position-relative w-30px h-30px me-2', btnClass)}
                      data-bs-toggle='tab'
                      href='#medias'
                    >
                      <i className='bi bi-currency-dollar fs-4'></i>
                    </a>
                  </li>
                  <li>
                    <a
                      className={clsx('position-relative w-30px h-30px me-2', btnClass)}
                      data-bs-toggle='tab'
                      href='#logs'
                    >
                      <i className='bi bi-chat-right-text fs-4'></i>
                    </a>
                  </li>
                  <li>
                    <a
                      className={clsx('position-relative w-30px h-30px me-2', btnClass)}
                      data-bs-toggle='tab'
                      href='#medias'
                    >
                      <i className='bi bi-trash fs-4'></i>
                    </a>
                  </li>
                  <li className='ms-auto'>
                    <a
                      className={clsx('position-relative w-30px h-30px', btnClass)}
                      data-bs-toggle='tab'
                      onClick={() => setSettings(!settings)}
                    >
                      <i className='bi bi-arrow-left fs-4'></i>
                    </a>
                  </li>
                </ul>
                <div className='tab-content' id='myTabContent'>
                  <div className='tab-pane fade show active' id='content' role='tabpanel'>
                    <Contents contentData={contentData} />
                  </div>
                  <div className='tab-pane fade' id='kt_tab_pane_5' role='tabpanel'>
                    Nulla est ullamco ut irure incididunt nulla Lorem Lorem minim irure officia enim
                    reprehenderit. Magna duis labore cillum sint adipisicing exercitation ipsum.
                    Nostrud ut anim non exercitation velit laboris fugiat cupidatat. Commodo esse
                    dolore fugiat sint velit ullamco magna consequat voluptate minim amet aliquip
                    ipsum aute laboris nisi. Labore labore veniam irure irure ipsum pariatur mollit
                    magna in cupidatat dolore magna irure esse tempor ad mollit. Dolore commodo
                    nulla minim amet ipsum officia consectetur amet ullamco voluptate nisi commodo
                    ea sit eu.
                  </div>
                  <div className='tab-pane fade' id='logs' role='tabpanel'>
                    {/* <div className='card-scroll scroll-y rounded-2 h-100'>
                      {logsCollections
                        ?.sort((a: any, b: any) => (a.createdAt > b.createdAt ? 1 : -1))
                        .reverse()
                        .map((log: any) => (
                          <>
                            <div className='d-flex me-2'>
                              <Avatar
                                className={'symbol symbol-35px me-2'}
                                name={log.displayName}
                                avatarURL={log.photoURL}
                                uid={log.uid}
                              />
                              <div className='d-flex flex-row-fluid flex-column'>
                                <div className='d-flex flex-row-fluid flex-wrap'>
                                  <a href='#' className=' flex-fill  fs-7 fw-bold'>
                                    {log.displayName}
                                  </a>
                                  <span className='text-muted fw-semibold  fs-8'>
                                    {log?.createdAt &&
                                      moment
                                        .utc(new Date(log?.createdAt.toDate()))
                                        .local()
                                        .startOf('s')
                                        .fromNow(true)}
                                  </span>
                                </div>
                                <span className='text-muted fw-semibold d-block fs-7'>
                                  {log.action === 'ContentChangedStatus' && (
                                    <>
                                      İçeriği <strong>{log.oldValue}</strong> durumundan{' '}
                                      <strong>{log.newValue}</strong> olarak güncelledim.
                                    </>
                                  )}
                                  {log.action === 'ContentCreated' && (
                                    <span className='block'>İçeriği oluşturdum</span>
                                  )}
                                </span>
                              </div>
                            </div>

                            <div className='separator separator-dashed my-2'></div>
                          </>
                        ))}
                    </div> */}
                    <div className='ratio ratio-1x1'>
                      <div className='h-100'>
                        <div className='card-scroll scroll-y rounded-2 h-100'>
                          {logsCollections
                            ?.sort((a: any, b: any) => (a.createdAt > b.createdAt ? 1 : -1))
                            .reverse()
                            .map((log: any) => (
                              <>
                                <div className='d-flex me-2'>
                                  <Avatar
                                    className={'symbol symbol-35px me-2'}
                                    name={log.displayName}
                                    avatarURL={log.photoURL}
                                    uid={log.uid}
                                  />
                                  <div className='d-flex flex-row-fluid flex-column'>
                                    <div className='d-flex flex-row-fluid flex-wrap'>
                                      <a href='#' className=' flex-fill  fs-7 fw-bold'>
                                        {log.displayName}
                                      </a>
                                      <span className='text-muted fw-semibold  fs-8'>
                                        {log?.createdAt &&
                                          moment
                                            .utc(new Date(log?.createdAt.toDate()))
                                            .local()
                                            .startOf('s')
                                            .fromNow(true)}
                                      </span>
                                    </div>
                                    <span className='text-muted fw-semibold d-block fs-7'>
                                      {log.action === 'ContentChangedStatus' && (
                                        <>
                                          İçeriği <strong>{log.oldValue}</strong> durumundan{' '}
                                          <strong>{log.newValue}</strong> olarak güncelledim.
                                        </>
                                      )}
                                      {log.action === 'ContentCreated' && (
                                        <span className='block'>İçeriği oluşturdum</span>
                                      )}
                                    </span>
                                  </div>
                                </div>

                                <div className='separator separator-dashed my-2'></div>
                              </>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <div className='card-footer p-5'>
        <div className='d-flex justify-content-between align-self-end'>
          <div className='symbol-group symbol-hover ms-0'>
            {channels &&
              SocialMediaChannelModels.map(
                (c: any, i) =>
                  channels[c.name] && (
                    <OverlayTrigger
                      key={`${i}-${c.name + '-' + c.type}`}
                      placement='top'
                      overlay={
                        <Tooltip id='tooltip-contentType-name'>
                          {c.name + ' ' + channels[c.name]?.map((type: any) => ' ' + type.type)}
                        </Tooltip>
                      }
                    >
                      <div
                        className='badge badge-square badge-light hover-primary w-30px h-30px me-1'
                        data-bs-toggle='tooltip'
                        key={`cw7-item-${i}`}
                      >
                        <i className={`${c?.icon} fs-6`}></i>
                      </div>
                    </OverlayTrigger>
                  )
              )}

            <div className='m-0'>
              <a
                href='#'
                className={clsx('position-relative w-30px h-30px', btnClass)}
                data-kt-menu-trigger='click'
                data-kt-menu-placement='top'
              >
                <KTSVG path='/media/icons/add.svg' className={'svg-icon-4'} />
              </a>
              <DropdownSocialMediaChannelType
                socialMediaSettings={socialMediaSettings}
                contentChannel={contentData?.channels}
                contentID={contentData.id}
              />
            </div>
          </div>
          <div>
            {/* <a
              onClick={() => setSettings(!settings)}
              className={`btn btn-sm btn-light h-30px px-3 me-1 ${settings && 'active'}`}
            >
              <i className='bi bi-chat-right-text-fill  fs-6'></i>({logsCollections?.length})
            </a>
            <a onClick={() => setSettings(!settings)} className='btn btn-sm btn-light h-30px px-3'>
              <i className='bi bi-images fs-6 pb-1'></i>
              (0)
            </a> */}
            <a
              className={clsx('position-relative w-30px h-30px', btnClass)}
              data-bs-toggle='tab'
              onClick={() => setSettings(!settings)}
            >
              <i className='bi bi-gear fs-4'></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export {CardSocialMediaContent}
