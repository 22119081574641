
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { isSupported } from "firebase/messaging/sw";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBzkoXCxHajx351pUqrCA3-9WDxtQwWBSQ",
  authDomain: "fokv2-4e38d.firebaseapp.com",
  projectId: "fokv2-4e38d",
  storageBucket: "fokv2-4e38d.appspot.com",
  messagingSenderId: "633461983874",
  appId: "1:633461983874:web:c322ad58d2af9a99ed829b"
};
 
// Initialize Firebase
initializeApp(firebaseConfig);
const app = initializeApp(firebaseConfig);


// init firestore
const db = getFirestore();

// init storage
const storage = getStorage(app);


// init firebase auth
const auth = getAuth()

// init firebase messaging

// const messaging =  getMessaging()


const messaging = isSupported() ? getMessaging() : null;



export const requestForToken = () => {
  return getToken(messaging, { vapidKey: `BBg9t4FSYxQhRpLDN0-LLxNaBUIIyvisurygMFyUrAjcEyYW4oL6uGcWISJbJhnWjAapxfIGy1R9Y0ZZkpuykx4` })
    .then((currentToken) => {
      if (currentToken) {
        return(currentToken);
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
        return(null);
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
};

// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker `messaging.onBackgroundMessage` handler.
export const onMessageListener = () =>
  new Promise((resolve) => {    
    onMessage(messaging, (payload) => {
      resolve(payload);
      console.log('congif payload')
      console.log(payload)
    });
  });

  

// Initialize Firebase Authentication and get a reference to the service
export {auth, db, storage}