/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {useCollection} from '../../../../hooks/useCollection'
import {Avatar} from '../../../../../_metronic/partials'
import {doc, getDoc} from 'firebase/firestore'
import {db} from '../../../../../firebase/config'
import {Link} from 'react-router-dom'

type Props = {
  className: string
  clientUID: string
}

const RolesWidget: React.FC<Props> = ({className, clientUID}) => {
  const [clientData, setClientData] = useState<any | null>(null)

  useEffect(() => {
    let docRef = doc(db, 'clients', clientUID)
    getDoc(docRef)
      .then((data) => {
        setClientData(data.data())
      })
      .catch((error) => {})
  }, [clientUID])

  const {documents: users} = useCollection('users')

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Roles</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>
            {clientData?.authorities?.filter((u: any) => u.role === 'team').length} Agency role,
            {'  '}
            {clientData?.authorities?.filter((u: any) => u.role === 'client').length} Client role
          </span>
        </h3>
        <div className='card-toolbar'>
          <ul className='nav'>
            <li className='nav-item'>
              <a
                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary active fw-bold px-4 me-1'
                data-bs-toggle='tab'
                href='#rolesAgency'
              >
                Agency
              </a>
            </li>
            <li className='nav-item'>
              <a
                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1'
                data-bs-toggle='tab'
                href='#rolesClient'
              >
                Client
              </a>
            </li>
          </ul>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3 scroll-y mh-325px pb-4'>
        <div className='tab-content'>
          {/* begin::Tap pane */}
          <div className='tab-pane fade show active' id='rolesAgency'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='border-0'>
                    <th className='p-0 w-50px'></th>
                    <th className='p-0 min-w-150px'></th>
                    <th className='p-0 min-w-50px'></th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {clientData?.authorities?.map(
                    (user: any) =>
                      users &&
                      users
                        .filter((u: any) => u.id === user.uid && user.role === 'team')
                        .map((u: any) => (
                          <tr>
                            <td>
                              <div className='symbol symbol-45px'>
                                <Avatar
                                  className={'symbol symbol-35px symbol-md-40px'}
                                  name={u.displayName}
                                  avatarURL={u?.photoURL}
                                />
                              </div>
                            </td>
                            <td className='ps-0'>
                              <a
                                href='#'
                                className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                              >
                                {u?.displayName}
                              </a>
                              <span className='text-muted fw-semibold d-block'>{user?.title}</span>
                            </td>

                            <td className='text-end'>
                              <Link
                                className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                                to={`/profile/${u.id}/overview`}
                              >
                                <KTSVG
                                  path='/media/icons/duotune/arrows/arr064.svg'
                                  className='svg-icon-2'
                                />
                              </Link>
                            </td>
                          </tr>
                        ))
                  )}
                </tbody>
                {/* end::Table body */}
              </table>
            </div>
            {/* end::Table */}
          </div>
          {/* end::Tap pane */}
          {/* begin::Tap pane */}
          <div className='tab-pane fade' id='rolesClient'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='border-0'>
                    <th className='p-0 w-50px'></th>
                    <th className='p-0 min-w-150px'></th>
                    <th className='p-0 min-w-50px'></th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {clientData?.authorities.map((user: any) =>
                    users
                      .filter((u: any) => u.id === user.uid && user.role === 'client')
                      .map((u: any) => (
                        <tr>
                          <td>
                            <div className='symbol symbol-45px'>
                              <Avatar
                                className={'symbol symbol-35px symbol-md-40px'}
                                name={u.displayName}
                                avatarURL={u?.photoURL}
                              />
                            </div>
                          </td>
                          <td className='ps-0'>
                            <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                              {u?.displayName}
                            </a>
                            <span className='text-muted fw-semibold d-block'>{user?.title}</span>
                          </td>

                          <td className='text-end'>
                            <a
                              href='#'
                              className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                            >
                              <KTSVG
                                path='/media/icons/duotune/arrows/arr064.svg'
                                className='svg-icon-2'
                              />
                            </a>
                          </td>
                        </tr>
                      ))
                  )}
                </tbody>
                {/* end::Table body */}
              </table>
            </div>
            {/* end::Table */}
          </div>
          {/* end::Tap pane */}
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}

export {RolesWidget}
