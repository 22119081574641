/* eslint-disable jsx-a11y/anchor-is-valid */
import {Component, FC, useEffect, useState} from 'react'
import {SocialMediaContentStatusModels} from '../../models/SocialMediaModels'

import {
  Timestamp,
  addDoc,
  arrayUnion,
  collection,
  doc,
  serverTimestamp,
  setDoc,
  updateDoc,
} from 'firebase/firestore'
import {useParams} from 'react-router-dom'
import {TagsInput} from 'react-tag-input-component'
import 'primereact/resources/themes/lara-light-indigo/theme.css'

import {FileUpload} from 'primereact/fileupload'
import {Image, Button} from 'react-bootstrap'

import {useAuthContext} from '../../../../../../../../auth'
import {db, storage} from '../../../../../../../../../../firebase/config'
import {useFilesUpload} from '../../../../../../../../../core/storage/useFilesUpload'
import {ProgressBar} from 'primereact/progressbar'
import {classNames} from 'primereact/utils'

import {FileUploader} from 'react-drag-drop-files'

import {arrayMoveImmutable} from 'array-move'
import {deleteObject, ref} from 'firebase/storage'

type Props = {
  contentData: any
}

const MediaContent: FC<Props> = ({contentData}) => {
  const {user} = useAuthContext()

  const {id, projectID} = useParams()
  const clientUID = id ? id : ''
  const projectUID = projectID ? projectID : ''

  const {fileUpload} = useFilesUpload()

  const fileTypes = ['JPG', 'PNG', 'MP4', 'MOV']
  const [uploadArea, setUploadArea] = useState<any | null>(
    <div className='w-100 btn bg-light-primary bg-hover-primary p-8 text-hover-white rounded fs-7 text-primary d-flex justify-content-center'>
      <i className='bi bi-upload color-white fs-2'></i>
      <span className='ms-2 fs-6'>Select or Drag Media Files</span>
    </div>
  )

  const [media, setMedia] = useState(contentData?.files)

  const [content, setContent] = useState(contentData?.contentText)
  const [tags, setTags] = useState<any | null>(contentData?.contentTags)

  useEffect(() => {
    setMedia(contentData?.files)
  }, [contentData])

  const docRef = doc(
    db,
    'clients',
    clientUID,
    'projects',
    projectUID,
    'socialMediaContents',
    contentData.id
  )

  const deleteMedia = async (fileName, id) => {
    setMedia(media.filter((f, index) => index !== id))
    setDoc(
      docRef,
      {
        files: media.filter((f, index) => index !== id),
      },
      {merge: true}
    )
      .then(async () => {
        const fileRef = ref(storage, `clients/${clientUID}/projects/${projectUID}/${fileName}`)
        deleteObject(fileRef)
          .then(() => {
            updateDoc(docRef, {
              logs: arrayUnion({
                displayName: user.displayName,
                uid: user.uid,
                photoURL: user?.photoURL,
                action: 'I deleted a media.',
                createdAt: Timestamp.now(),
                value: fileName,
              }),
            })
          })
          .catch((error) => {
            console.log(error.message)
          })
      })
      .catch((error) => {
        console.log(error.message)
      })
  }

  const sortMedia = async (type, id) => {
    type === 'before'
      ? setMedia(arrayMoveImmutable(media, id, id - 1))
      : setMedia(arrayMoveImmutable(media, id, id + 1))

    const medias =
      type === 'before'
        ? arrayMoveImmutable(media, id, id - 1)
        : arrayMoveImmutable(media, id, id + 1)

    setDoc(
      docRef,
      {
        files: medias,
      },
      {merge: true}
    )
      .then(async () => {
        updateDoc(docRef, {
          logs: arrayUnion({
            displayName: user.displayName,
            uid: user.uid,
            photoURL: user?.photoURL,
            action: 'I sorted a media.',
            createdAt: Timestamp.now(),
            value: {id: id, type: type},
          }),
        })
      })
      .catch((error) => {
        console.log(error.message)
      })
  }

  const customUpload = async (file) => {
    setUploadArea(
      <div className='w-100 btn btn-warning  p-8 rounded fs-7 text-white d-flex fw- justify-content-center'>
        <span
          className='spinner-border spinner-border align-middle'
          style={{width: '1.5rem', height: '1.5rem'}}
        ></span>
        <span className='ms-2 fw-bold fs-6'>Uploading</span>
      </div>
    )

    const docRef = doc(
      db,
      'clients',
      clientUID,
      'projects',
      projectUID,
      'socialMediaContents',
      contentData.id
    )

    fileUpload(file, `clients/${clientUID}/projects/${projectUID}/`, docRef).then((data) => {
      updateDoc(docRef, {
        logs: arrayUnion({
          displayName: user.displayName,
          uid: user.uid,
          photoURL: user?.photoURL,
          action: 'I added a media.',
          createdAt: Timestamp.now(),
          value: data,
        }),
      }).then(() => {
        setUploadArea(
          <div className='w-100 btn bg-success p-8 rounded fs-7 text-white fw-semibold d-flex justify-content-center'>
            <i className='bi bi-check-lg fs-2 text-white'></i>
            <span className='ms-2 fw-bold fs-6'>Uploaded!</span>
          </div>
        )
        setTimeout(() => {
          setUploadArea(
            <div className='w-100 btn bg-light-primary bg-hover-primary p-8 text-hover-white rounded fs-7 text-primary d-flex justify-content-center'>
              <i className='bi bi-upload color-white fs-2'></i>
              <span className='ms-2 fs-6'>Select or Drag Media Files</span>
            </div>
          )
        }, 1000)
      })
    })
  }

  return (
    <>
      {/* <label className='fw-row col-form-label w-100 py-2 text-primary fw-bold fs-6 mb-2'>
        Content Media
      </label> */}
      <div className='px-2'>
        <FileUploader
          handleChange={customUpload}
          name='file'
          multiple={true}
          types={fileTypes}
          children={uploadArea}
          classes={'w-100'}
          hoverTitle={' '}
        />
      </div>

      <div className='separator separator-dashed my-4 mx-2'></div>
      <div className='row mx-0'>
        {media &&
          media.map((f: any, index) => (
            <div className='col-sm-4 px-2 mb-4 lh-0' key={index}>
              {f.fileType === 'video/mp4' ? (
                <video controls className='rounded-1 w-100' object-fit='cover' aspect-ratio='1 / 1'>
                  <source src={f.downloadURL} type={f.fileType} />
                  Sorry, your browser doesn't support videos.
                </video>
              ) : (
                <img src={f?.downloadURL} className='border rounded w-100 card-img ' />
              )}

              <div className=' d-flex justify-content-center   '>
                <div className='d-flex justify-content-between bg-light-primary px-2 rounded mt-1 w-100 '>
                  <button
                    className={
                      'btn btn-sm btn-link btn-color-gray-500 btn-active-color-primary py-2'
                    }
                    onClick={() => sortMedia('before', index)}
                  >
                    <i className='bi bi-arrow-left-short fs-1'></i>
                  </button>

                  <button
                    className={
                      'btn btn-sm btn-link btn-color-gray-500 btn-active-color-primary py-2'
                    }
                    onClick={() => deleteMedia(f.fileName, index)}
                  >
                    <i className='bi bi-trash fs-5'></i>
                  </button>

                  <button
                    className={
                      'btn btn-sm btn-link btn-color-gray-500 btn-active-color-primary py-2'
                    }
                    onClick={() => sortMedia('after', index)}
                  >
                    <i className='bi bi-arrow-right-short fs-1'></i>
                  </button>
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  )
}

export {MediaContent}
