import clsx from 'clsx'
import {useEffect, useState} from 'react'
import {KTSVG} from '../../../helpers'
import {HeaderUserMenu, Search} from '../../../partials'
import {useAuthContext} from '../../../../app/modules/auth'
import {Avatar} from '../../../partials/content/user/Avatar'
import {useCollection} from '../../../../app/hooks/useCollection'
import {useCollectionChat} from '../../../../app/hooks/useCollectionChat'
import {Link, useNavigate, useLocation} from 'react-router-dom'
import React from 'react'
import {SelectClient} from './components/selectClient'
import {auth, db} from '../../../../firebase/config'
import {doc, setDoc} from 'firebase/firestore'
import StopWatch from '../../../../app/modules/components/StopWatch'
import moment from 'moment'
import {StopWorking} from './components/StopWorking'

const itemClass = 'ms-1 ms-lg-3'
const btnClass = 'btn btn-icon btn-light-primary w-35px h-35px w-md-40px h-md-40px'
const btnClassActive = 'btn btn-icon btn-light-primary w-35px h-35px w-md-40px h-md-40px active'
const btnNotificationClass = 'btn btn-icon btn-warning w-35px h-35px w-md-40px h-md-40px'

const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'svg-icon-1'
const btnIconNotificationClass = 'svg-icon-1 text-dark'

const Navbar = () => {
  const location = useLocation()

  const {hash, pathname, search} = location
  const [userStatus, setUserStatus] = useState('')

  const [chat, setChat] = useState(false)
  const [notification, setNotification] = useState(false)

  const [works, setWorks] = useState(false)
  const [workNotification, setworkNotification] = useState(0)

  const [timeTracker, setTimeTracker] = useState(false)
  const {user, userData} = useAuthContext()
  // const [content, setContent] = useState(config.app?.rightSidebar?.content);

  const [client, setClient] = useState('')
  const {documents: chatRooms} = useCollectionChat('chat', user.uid)
  const {documents: users} = useCollection('users')

  const navigate = useNavigate()

  function rigthSidebarClick(content) {
    const worksButton = document.getElementById('works-button')
    const chatButton = document.getElementById('chat-button')
    const timeTrackerButton = document.getElementById('timeTracker-button')

    const rightSidebarChatTab = document.getElementById('right-sidebar-chat')
    const rightSidebarWorksTab = document.getElementById('right-sidebar-works')
    const rightSidebarTimeTrackerTab = document.getElementById('right-sidebar-timeTracker')

    const rightSidebarStatus = document.body.getAttribute('data-right-sidebar')

    if (content === 'works') {
      if (rightSidebarStatus === 'on' && works) {
        document.body.setAttribute('data-right-sidebar', 'off')
        document.body.setAttribute('data-right-sidebar-content', 'none')
        setWorks(false)
        setChat(false)
        setTimeTracker(false)
        worksButton?.classList.remove('active')
      } else {
        worksButton?.classList.add('active')
        chatButton?.classList.remove('active')
        timeTrackerButton?.classList.remove('active')
        setWorks(true)
        setChat(false)
        setTimeTracker(false)
        rightSidebarChatTab?.classList.add('d-none')
        rightSidebarWorksTab?.classList.remove('d-none')
        document.body.setAttribute('data-right-sidebar', 'on')
        document.body.setAttribute('data-right-sidebar-content', 'works')
      }
    }

    if (content === 'chat') {
      if (rightSidebarStatus === 'on' && chat) {
        document.body.setAttribute('data-right-sidebar', 'off')
        document.body.setAttribute('data-right-sidebar-content', 'none')
        setWorks(false)
        setChat(false)
        setTimeTracker(false)
        chatButton?.classList.remove('active')
      } else {
        chatButton?.classList.add('active')
        worksButton?.classList.remove('active')
        timeTrackerButton?.classList.remove('active')
        setWorks(false)
        setTimeTracker(false)
        setChat(true)

        rightSidebarWorksTab?.classList.add('d-none')
        rightSidebarChatTab?.classList.remove('d-none')
        document.body.setAttribute('data-right-sidebar', 'on')
        document.body.setAttribute('data-right-sidebar-content', 'chat')
      }
    }

    if (content === 'timeTracker') {
      if (rightSidebarStatus === 'on' && timeTracker) {
        document.body.setAttribute('data-right-sidebar', 'off')
        document.body.setAttribute('data-right-sidebar-content', 'none')
        setWorks(false)
        setChat(false)
        setTimeTracker(false)
        timeTrackerButton?.classList.remove('active')
        console.log('time Kapatma')
      } else {
        timeTrackerButton?.classList.add('active')
        worksButton?.classList.remove('active')
        chatButton?.classList.remove('active')
        setWorks(false)
        setChat(false)
        setTimeTracker(true)
        rightSidebarWorksTab?.classList.add('d-none')
        rightSidebarChatTab?.classList.add('d-none')
        rightSidebarTimeTrackerTab?.classList.remove('d-none')
        document.body.setAttribute('data-right-sidebar', 'on')
        document.body.setAttribute('data-right-sidebar-content', 'timeTacker')
      }
    }
  }

  useEffect(() => {
    if (
      users.filter(
        (user: any) =>
          user.model.isAdmin === false &&
          user.model.isClient === false &&
          user.model.isManager === false &&
          user.model.isTeammate === false &&
          user.model.viewAgency === false &&
          user.model.viewBrand === false
      ).length > 0 &&
      users.filter((u: any) => u.id === user.uid && u.model.isManager).length > 0
    ) {
      setworkNotification(
        users.filter(
          (user: any) =>
            user.model.isAdmin === false &&
            user.model.isClient === false &&
            user.model.isManager === false &&
            user.model.isTeammate === false &&
            user.model.viewAgency === false &&
            user.model.viewBrand === false
        ).length
      )
    } else {
      setworkNotification(0)
    }
  }, [users])

  useEffect(() => {
    chatRooms &&
    user.uid &&
    chatRooms.filter((room: any) => room.isRead?.includes(user.uid)).length < chatRooms.length
      ? setNotification(true)
      : setNotification(false)
  }, [chatRooms, user.uid])

  return (
    <div className='app-navbar flex-shrink-0'>
      {/* 
      <div className={clsx('app-navbar-item', itemClass)}>
        <div id='kt_activities_toggle' className={btnClass}>
          <KTSVG path='/media/icons/duotune/general/gen032.svg' className={btnIconClass} />
        </div>
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          className={clsx('position-relative', btnClass)}
        >
          <KTSVG path='/media/icons/duotune/general/gen007.svg' className={btnIconClass} />
					<span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' />

        </div>
        <HeaderNotificationsMenu />
      </div>
			*/}
      {/* <div className={clsx('app-navbar-item me-4', itemClass)}>
        <span className='fw-semibold'>Last Updated: </span>
        <span className='fw-normal ms-1'> 01.11 17:00</span>
      </div> */}
      <StopWorking userData={users.find((u: any) => u.id === user.uid)} />

      <div className={clsx('app-navbar-item align-items-stretch', itemClass)}>
        <Search />
      </div>

      {userData?.model.isManager && (
        <div className={clsx('app-navbar-item', itemClass)}>
          <div
            id='teamTasks-button'
            title='Team Tasks'
            className={clsx(
              'position-relative',
              pathname === '/teamTasks' ? btnClassActive : btnClass
            )}
            onClick={() => navigate('/teamTasks')}
          >
            <i className={`bi bi-people fs-3 ${btnIconClass}`}></i>
          </div>
        </div>
      )}
      {userData?.model.isAdmin && (
        <div className={clsx('app-navbar-item', itemClass)}>
          <div
            id='chatbotApp-button'
            className={clsx(
              'position-relative',
              pathname === '/chatbotApp' ? btnClassActive : btnClass
            )}
            onClick={() => navigate('/chatbotApp')}
          >
            <i className={`bi bi-robot fs-3 ${btnIconClass}`}></i>
          </div>
        </div>
      )}
      {(userData?.model.isAdmin || userData?.model.isManager) && (
        <div className={clsx('app-navbar-item', itemClass)}>
          <div
            id='bugs-button'
            className={clsx('position-relative', pathname === '/bugs' ? btnClassActive : btnClass)}
            onClick={() => navigate('/bugs')}
          >
            <i className={`bi bi-bug fs-3 ${btnIconClass}`}></i>
          </div>
        </div>
      )}
      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div
          id='labs-button'
          className={clsx('position-relative', pathname === '/labs' ? btnClassActive : btnClass)}
          onClick={() => navigate('/labs')}
        >
          <i className={`bi bi-people fs-3 ${btnIconClass}`}></i>
        </div>
      </div> */}

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          id='works-button'
          className={clsx('position-relative', btnClass)}
          onClick={() => {
            rigthSidebarClick('works')
          }}
        >
          <KTSVG path='/media/icons/works.svg' className={btnIconClass} />
          {workNotification > 0 && (
            <>
              <label className='rounded-4 fw-bolder fs-9 w-15px h-15px  bg-warning text-primary shadow position-absolute top-25 start-75 translate-middle'>
                {workNotification}
              </label>
            </>
          )}
        </div>
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          id='timeTracker-button'
          className={clsx('position-relative', btnClass)}
          onClick={() => {
            rigthSidebarClick('timeTracker')
          }}
        >
          <KTSVG path='/media/icons/time.svg' className={btnIconClass} />
        </div>
      </div>

      {(userData?.model.isAdmin || userData?.model.isManager) && (
        <div className={clsx('app-navbar-item', itemClass)}>
          <div
            id='chat-button'
            className={clsx(
              'position-relative',
              notification
                ? btnNotificationClass
                : document.body.getAttribute('data-right-sidebar-content') === 'chat'
                ? btnClassActive
                : btnClass
            )}
            onClick={() => {
              rigthSidebarClick('chat')
            }}
          >
            <KTSVG
              path='/media/icons/chat-square.svg'
              className={notification ? btnIconNotificationClass : btnIconClass}
            />

            {/* {notification && (
            <label className='w-10px h-10px bg-warning me-4 rounded-4 shadow position-absolute top-25 start-75 translate-middle'></label>
          )} */}
          </div>
        </div>
      )}
      {/* Message / Chat Module
      <div className={clsx('app-navbar-item', itemClass)}>
        <div className={clsx('position-relative', btnClass)} id='kt_drawer_chat_toggle'>
          <KTSVG path='/media/icons/duotune/communication/com012.svg' className={btnIconClass} />
          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' />
        </div>
      </div>
			*/}
      {/* ThemeModeSwitcher / Chat Module
      <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div>
			*/}

      {/* d-none */}
      <SelectClient />
      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className='cursor-pointer'
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <Avatar
            className='symbol symbol-35px symbol-md-40px'
            name={user.displayName}
            avatarURL={user.photoURL}
            status={userStatus}
          />
        </div>
        <HeaderUserMenu />
      </div>
    </div>
  )
}

export {Navbar}
