
import { useAuthContext } from './useAuthContext';

import { auth, db} from '../../../../firebase/config'
import { signOut } from 'firebase/auth'
import { doc, setDoc } from 'firebase/firestore'



export const useLogout = () => {
	const { dispatch } = useAuthContext()

	

  const logout = async () => {
		
		// await setDoc(doc(db, "users", auth.currentUser.uid), {online:false}, { merge: true}).then(()=> {
		// 	console.log('offline')
		// })

		signOut(auth)
		.then(() => {
			console.log('user signed out')
			
			dispatch({ type: 'LOGOUT'})
		})
		.catch((err) => {
			console.log(err.message)
		})
	}

  return { logout}
}