import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import {ExtensionsWrapper} from '../pages/ExtensionsWrapper'
import {BugsPageWrapper} from '../pages/bugs/BugsPage'
import {LabsPageWrapper} from '../pages/labs/LabsPage'
import ClientPage from '../modules/client/ClientPage'
import ProjectPage from '../modules/client/components/projects/ProjectPage'
import {TeamsTasksWrapper} from '../pages/teamTasks/TeamTasksPage'
import ChatbotApp from '../pages/ChatbotApp/ChatbotApp'
import {useAuthContext} from '../modules/auth'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const SocialMediaPage = lazy(() => import('../modules/social-media/SocialMediaPage'))
  const AgencyPage = lazy(() => import('../modules/agency/AgencyPage'))
  const {user, userData} = useAuthContext()

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        <Route path='extensions' element={<ExtensionsWrapper />} />
        {/* Lazy Modules */}
        {(userData?.model.isAdmin || userData?.model.isManager) && (
          <Route
            path='profile/:id/*'
            element={
              <SuspensedView>
                <ProfilePage />
              </SuspensedView>
            }
          />
        )}
        <Route
          path='social-media/*'
          element={
            <SuspensedView>
              <SocialMediaPage />
            </SuspensedView>
          }
        />
        {(userData?.model.isAdmin || userData?.model.isManager) && (
          <>
            <Route
              path='agency/*'
              element={
                <SuspensedView>
                  <AgencyPage />
                </SuspensedView>
              }
            />

            <Route
              path='teamTasks'
              element={
                <SuspensedView>
                  <TeamsTasksWrapper />
                </SuspensedView>
              }
            />

            <Route
              path='bugs'
              element={
                <SuspensedView>
                  <BugsPageWrapper />
                </SuspensedView>
              }
            />

            <Route
              path='chatbotApp'
              element={
                <SuspensedView>
                  <ChatbotApp />
                </SuspensedView>
              }
            />
          </>
        )}
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='client/:id/*'
          element={
            <SuspensedView>
              <ClientPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />

        {/* <Route
          path='labs'
          element={
            <SuspensedView>
              <LabsPageWrapper />
            </SuspensedView>
          }
        /> */}
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
