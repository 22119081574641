import React, {useRef, useEffect} from 'react'
import {SidebarNotification, SidebarUser, SidebarWorks, SidebarChat} from '../../../partials'
import {useLayout} from '../../core'
import {SidebarTimeTracker} from '../../../partials/layout/right-sidebar-partials/SidebarTimeTracker'

const BG_COLORS = ['bg-white', 'bg-info']

export function RightSidebar() {
  const {config} = useLayout()
  return (
    <>
      <div></div>
      {config.app?.rightSidebar?.display && (
        <div
          id='kt_sidebar'
          className='sidebar'
          // data-kt-drawer='true'
          data-kt-drawer-name='sidebar'
          data-kt-drawer-activate='{default: true}'
          // data-kt-drawer-overlay='true'
          data-kt-drawer-width="{default:'200px', '350px': '300px'}"
          // data-kt-drawer-direction='end'
          // data-kt-drawer-toggle='#kt_sidebar_toggler'
        >
          {/* begin::Sidebar Content */}

          <div id='right-sidebar-works' className='d-flex flex-column sidebar-body works d-none'>
            <SidebarWorks />
          </div>
          <div id='right-sidebar-chat' className='d-flex flex-column sidebar-body works d-none'>
            <SidebarChat />
          </div>
          <div
            id='right-sidebar-timeTracker'
            className='d-flex flex-column sidebar-body timeTracker d-none'
          >
            <SidebarTimeTracker />
          </div>
          {/* end::Sidebar Content */}
        </div>
      )}
    </>
  )
}
