/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {useCollection} from '../../../../hooks/useCollection'
import {Avatar} from '../../../../../_metronic/partials'
import {doc, getDoc} from 'firebase/firestore'
import {db} from '../../../../../firebase/config'
import moment from 'moment'
import {Tooltip} from 'react-tooltip'

type Props = {
  className: string
  clientUID: string
}

const TimeTrackerWidget: React.FC<Props> = ({className, clientUID}) => {
  const {documents: myTimeTracker} = useCollection('clients/' + clientUID + '/timeTracker')

  console.log('timeTracker')
  console.log(myTimeTracker)
  const [clientData, setClientData] = useState<any | null>(null)

  let defaultDate = new Date()

  const [date, setDate] = useState(defaultDate)

  const onSetDate = (event) => {
    setDate(new Date(event.target.value))
    console.log(new Date(event.target.value))
  }

  useEffect(() => {
    let docRef = doc(db, 'clients', clientUID)
    getDoc(docRef)
      .then((data) => {
        setClientData(data.data())
      })
      .catch((error) => {})
  }, [clientUID])

  const {documents: users} = useCollection('users')

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Time Tracker</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>
            Toplam{' '}
            {myTimeTracker
              .filter((v: any) =>
                moment(moment(v?.date.toDate())).isSame(moment(date.toDateString()), 'day')
              )
              .reduce((a, v: any) => (a = a + v.timeValue), 0)}{' '}
            dk
          </span>
        </h3>
        <div className='card-toolbar'>
          <input
            type='date'
            className='form-control form-control-sm form-control-solid mb-3 mb-lg-0 w-125px'
            placeholder=''
            onChange={onSetDate}
            value={date.toLocaleDateString('en-CA')}
          />
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {myTimeTracker &&
          myTimeTracker
            .filter((v: any) =>
              moment(moment(v?.date.toDate())).isSame(moment(date.toDateString()), 'day')
            )
            .map(
              (value: any) =>
                users &&
                users
                  .filter((u: any) => u.id === value.userUID)
                  .map((u: any) => (
                    <div className='d-flex align-items-sm-center py-2 rounded-2 '>
                      <Avatar
                        className={'symbol symbol-40px me-2'}
                        name={u?.displayName}
                        avatarURL={u?.photoURL}
                      />

                      <div className='d-flex flex-column flex-fill'>
                        <div className='fw-bolder d-flex align-items-center fs-7'>
                          {u?.displayName}
                        </div>
                        <span className='fw-semibold text-muted fs-7'>{value?.workTitle}</span>
                      </div>
                      <div className='d-flex align-items-center '>
                        {value.note && (
                          <button
                            className='btn btn-icon btn-sm bg-hover-light me-2'
                            data-tooltip-id='my-toosltip'
                            data-tooltip-content={value.note}
                          >
                            <i className='bi bi-card-text fs-4'></i>
                            <Tooltip id='my-toosltip' />
                          </button>
                        )}

                        <span className='badge badge-sm badge-success fs-8 p-3'>
                          {value.timeValue} dk
                        </span>
                      </div>
                    </div>
                  ))
            )}
      </div>
    </div>
  )
}

export {TimeTrackerWidget}
