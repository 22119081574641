import React, {useEffect, useState} from 'react'
import {Navigate, Route, Routes, Outlet, useParams} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {ClientHeader} from './ClientHeader'
import {Overview} from './components/Overview'
import {Settings} from './components/settings/Settings'
import {useAuthContext} from '../auth'
import {doc, getDoc, onSnapshot} from 'firebase/firestore'
import {db} from '../../../firebase/config'
import {SocialMedia} from './services/social-media/SocialMedia'
import {Projects} from './components/projects/Projects'
import ProjectPage from './components/projects/ProjectPage'
import {Profit} from './components/profit/Profit'
import {useCollection} from '../../hooks/useCollection'

const clientBreadCrumbs: Array<PageLink> = [
  {
    title: 'Client',
    path: '/client/overview',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ClientPage: React.FC = () => {
  const {id} = useParams()
  const {user, userData} = useAuthContext()
  const {documents: clientProjects} = useCollection('clients/' + id + '/projects')

  const [view, setView] = useState(false)

  useEffect(() => {
    if (id) {
      let docRef = doc(db, 'clients', id)
      getDoc(docRef)
        .then((data) => {
          const clientData = data.data()
          console.log(clientData)
          clientData?.authorities.filter((a: any) => a.uid === user.uid).length > 0 && setView(true)
        })
        .catch((error) => {})
    }
  }, [id])

  useEffect(() => {
    if (clientProjects) {
      clientProjects
        ?.filter((p: any) => p.isActive === true)
        .filter(
          (p: any) =>
            p?.works?.filter(
              (w: any) => w?.authorities?.filter((a: any) => a.uid === user.uid).length > 0
            ).length > 0 || p.authorities.filter((a: any) => a.uid === user.uid).length > 0
        ).length > 0 && setView(true)
    }
  }, [clientProjects])

  return (
    (userData.model.isManager || view === true) && (
      <Routes>
        <Route
          element={
            <>
              <ClientHeader />
              <Outlet />
            </>
          }
        >
          <Route
            path='overview'
            element={
              <>
                <PageTitle breadcrumbs={clientBreadCrumbs}>Overview</PageTitle>
                <Overview />
              </>
            }
          />
          <Route
            path='social-media'
            element={
              <>
                <PageTitle breadcrumbs={clientBreadCrumbs}>Social Media</PageTitle>
                <SocialMedia />
              </>
            }
          />
          {userData?.model.isManager === true && (
            <>
              <Route
                path='settings'
                element={
                  <>
                    <PageTitle breadcrumbs={clientBreadCrumbs}>Settings</PageTitle>
                    <Settings />
                  </>
                }
              />
              <Route
                path='profit'
                element={
                  <>
                    <PageTitle breadcrumbs={clientBreadCrumbs}>Profit</PageTitle>
                    <Profit />
                  </>
                }
              />
            </>
          )}
          <Route
            path='projects'
            element={
              <>
                <PageTitle breadcrumbs={clientBreadCrumbs}>Projects</PageTitle>
                <Projects />
              </>
            }
          />

          <Route
            path='projects/:projectID/*'
            element={
              <>
                <PageTitle breadcrumbs={clientBreadCrumbs}>Projects</PageTitle>
                <ProjectPage />
              </>
            }
          />

          <Route index element={<Navigate to={`/client/${id}/overview`} />} />
        </Route>
      </Routes>
    )
  )
}

export default ClientPage
