import React, {useState} from 'react';
import {DndContext} from '@dnd-kit/core';

import {Droppable} from './Droppable';
import {Draggable} from './Draggable';

export default function ContentDraggable() {
  const containers = ['A', 'B', 'C', 'D', 'E', 'F'];
  const [parent, setParent] = useState(null);
  const draggableMarkup = (
    <Draggable id="draggable">Drag me</Draggable>
  );

  return (
		<div className='d-flex'>
    <DndContext onDragEnd={handleDragEnd}>
      {parent === null ? draggableMarkup : null}

      {containers.map((id) => (
        // We updated the Droppable component so it would accept an `id`
        // prop and pass it to `useDroppable`
				
        <Droppable key={id} id={id}>
					<div className='p-10 border'>
          {parent === id ? draggableMarkup : 'Drop here'}
					</div>
        </Droppable>
				
      ))}
    </DndContext>
		</div>
  );

  function handleDragEnd(event) {
    const {over} = event;

    // If the item is dropped over a container, set it as the parent
    // otherwise reset the parent to `null`
    setParent(over ? over.id : null);
  }
};