import { useState } from 'react'
import { useAuthContext } from './useAuthContext'

import { auth, db } from '../../../../firebase/config'
import { signInWithEmailAndPassword } from 'firebase/auth'
import {doc, setDoc, getDoc } from "firebase/firestore"; 


export const useLogin = () => {
  const [error, setError] = useState(null)
	const { dispatch } = useAuthContext()

  const login = (email, password) => {
    setError(null)
    signInWithEmailAndPassword(auth, email, password)
			.then(async (res) => {
				//setDoc(doc(db, "users", auth.currentUser.uid), {online:true}, { merge: true})

				// User Role
				let userData = null
				if (auth.currentUser) {
					const docRef = doc(db, "users", auth.currentUser.uid);
					try {
						const docSnap = await getDoc(docRef);
						userData = docSnap.data()
					} catch(error) {}
				}


				dispatch({ type: 'LOGIN', payload: res.user, userData:userData })
				// try {
				// 	const usersData = await getDoc(doc(db, "users", res.user.uid));
				// 	if(usersData.exists()) {
				// 		setRole(usersData.data().role)
				// 		console.log(usersData)
				// 		dispatch({ type: 'LOGIN', payload: res.user, role:role })
				// 	}
				// 	console.log(role)
					
				// } catch(error) {
				// 		console.log(error)
				// }

			})
			.catch((err) => {
				setError(err.code)
			})		
  }

  return { login, error }
}