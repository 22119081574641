/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {SocialMediaContentStatusModels} from '../models/SocialMediaModels'

import {db} from '../../../../../../../firebase/config'
import {addDoc, collection, doc, serverTimestamp, setDoc} from 'firebase/firestore'
import {useParams} from 'react-router-dom'
import {useAuthContext} from '../../../../../auth'

type Props = {
  contentData: any
}

const DropdownSocialMediaContentStatus: FC<Props> = ({contentData}) => {
  const {id} = useParams()
  const clientUID = id ? id : ''

  const {user} = useAuthContext()

  const handleSelect = async (event) => {
    event.stopPropagation()
    const docRef = doc(db, 'clients', clientUID, 'contents', contentData.id)

    setDoc(
      docRef,
      {
        status: event.target.value,
      },
      {merge: true}
    )
      .then(async () => {
        addDoc(collection(db, 'clients', clientUID, 'contents', contentData.id, 'logs'), {
          displayName: user.displayName,
          uid: user.uid,
          photoURL: user?.photoURL,
          action: 'ContentChangedStatus',
          oldValue: contentData.status,
          newValue: event.target.value,
          createdAt: serverTimestamp(),
        })
      })
      .catch((error) => {
        console.log(error.message)
      })
  }

  return (
    <select
      className='form-select form-select-sm form-select-solid fs-8'
      data-kt-select2='true'
      data-placeholder='Select option'
      data-allow-clear='true'
      defaultValue={contentData.status}
      onChange={handleSelect}
    >
      {SocialMediaContentStatusModels.map((status: any, i) => (
        <option key={i} value={status.name}>
          {status.name}
        </option>
      ))}
    </select>
    // <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
    //   <div className='px-7 py-5'>
    //     <div className='mb-7'>
    //       <label className='form-label fw-bold'>Content Status</label>
    //       <div>
    //         <select
    //           className='form-select form-select-solid'
    //           data-kt-select2='true'
    //           data-placeholder='Select option'
    //           data-allow-clear='true'
    //           defaultValue={contentData.status}
    //           onChange={handleSelect}

    //         >
    //           {SocialMediaContentStatusModels.map((status: any, i) => (
    //             <option key={i} value={status.name}>
    //               {status.name}
    //             </option>
    //           ))}
    //         </select>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  )
}

export {DropdownSocialMediaContentStatus}
