/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useLogout} from '../../../../app/modules/auth'

const AuthPending = () => {
  const {logout} = useLogout()

  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-root'>
      <div className='d-flex flex-column flex-center flex-column-fluid'>
        <div className='d-flex flex-column flex-center text-center p-10'>
          <div className='card card-flush w-300px py-5'>
            <div className='card-body py-150px'>
              <div className='text-center'>
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('/media/logos/fok-logo.svg')}
                  className='h-75px rounded'
                />
              </div>
              <h1 className='fw-bolder fs-2hx text-gray-900 mb-4 mt-20'>Sorun yok!</h1>
              <div className='fw-semibold fs-6 text-gray-500 mb-7 mt-10 mb-20'>
                Yekilendirme bekliyorsunuz. Lütfen iletişime geçiniz.
              </div>
              <div className='mb-0'>
                <Link to='/' onClick={logout} className='btn btn-sm btn-light-primary me-4'>
                  Çıkış Yap
                </Link>
                <Link
                  to='/'
                  onClick={() => (window.location.href = '/')}
                  className='btn btn-sm btn-primary w-50'
                >
                  Tekrar Dene
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {AuthPending}
