export const SocialMediaChannelModels = [
  {name: 'Facebook', icon: 'fab fa-facebook-f', types: ['Post', 'Story', 'Carousel', 'Event', 'Darkpost', 'Cover']},
  {name: 'Instagram', icon: 'fab fa-instagram', types: ['Post', 'Story']},
  {name: 'Youtube', icon: 'fab fa-youtube', types: ['Post', 'Shorts']},
  {name: 'Linkedin', icon: 'fab fa-linkedin', types: ['Post']},
  {name: 'Twitter', icon: 'fab fa-twitter', types: ['Post']},
  // {name: 'TikTok', icon: 'fab fa-tiktok', types: ['Post']},
  // {name: 'Pinterest', icon: 'fab fa-pinterest', types: ['Post']},

  
]

export const SocialMediaContentStatusModels = [
  {name: 'Blank Content', class: 'primary'},
  {name: 'Pending Content', class: 'warning'},
  {name: 'Pending Media', class: 'warning'},
  {name: 'Rejected', class: 'danger'},
  {name: 'Approved', class: 'success'},
  {name: 'Published', class: 'secondary'},
]

