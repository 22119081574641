import React, {useEffect, useState} from 'react'
import {ClientSettings} from './pages/ClientSettings'
import {UserRoles} from './pages/UserRoles'
import {Contracts} from './pages/Contracts'
import {ConnectedAccounts} from './pages/ConnectedAccounts'
import {AcvitePassive} from './pages/AcvitePassive'
import {EmailPreferences} from './pages/EmailPreferences'
import {Notifications} from './pages/Notifications'
import {DeactivateAccount} from './pages/DeactivateAccount'
import {useCollection} from '../../../../hooks/useCollection'
import {useAuthContext} from '../../../auth'
import {doc, getDoc} from 'firebase/firestore'
import {db} from '../../../../../firebase/config'
import {useParams} from 'react-router-dom'
import {Services} from './pages/Services'
import {Projects} from './pages/Projects'

export function Settings() {
  const {id} = useParams()
  const clientUID = id ? id : ''
  const [clientDetail, setClientDetail] = useState<any | null>(null)

  useEffect(() => {
    let docRef = doc(db, 'clients', clientUID)
    getDoc(docRef)
      .then((data) => {
        setClientDetail(data.data())
      })
      .catch((error) => {})
  }, [clientUID])

  return (
    <>
      {/* <Projects clientData={clientDetail} /> */}
      {/* <Contracts clientData={clientDetail} /> */}
      <ClientSettings />
      {/* <Services clientData={clientDetail} /> */}
      <UserRoles clientData={clientDetail} />

      <AcvitePassive clientData={clientDetail} />

      {/* <Notifications />
      <SignInMethod />
      <ConnectedAccounts />
      <EmailPreferences />
      <Notifications />
      <DeactivateAccount /> */}
    </>
  )
}
