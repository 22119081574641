/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {useCollection} from '../../../../hooks/useCollection'
import {Avatar} from '../../../../../_metronic/partials'
import {collection, doc, getDoc, getDocs} from 'firebase/firestore'
import {db} from '../../../../../firebase/config'
import {Link, useParams} from 'react-router-dom'
import moment from 'moment'
import {UsersAvatars} from '../../../components/Elements/UsersAvatars'
import {useCollectionWithParam} from '../../../../hooks/useCollectionWithParam'

type Props = {
  className: string
  clientUID?: string
  userUID?: string
}

const ProjectsWidget: React.FC<Props> = ({className, clientUID, userUID}) => {
  const {id} = useParams()
  const [projects, setProjects] = useState<any | null>(null)
  const {documents: users} = useCollection('users')
  const {documents: clients} = useCollection('clients')

  useEffect(() => {
    if (clientUID) {
      const projectsRef = collection(db, 'clients', clientUID, 'projects')
      const fetchProjects = async () => {
        try {
          const docsSnap = await getDocs(projectsRef)
          if (docsSnap) {
            let data: {
              name: string
              period: string
              id: string
              isActive: boolean
              authorities: any
              socialMedia: boolean
            }[] = []
            docsSnap.forEach((doc) => {
              data.push({
                name: doc.data().name,
                period: doc.data().period,
                id: doc.id,
                isActive: doc.data().isActive,
                authorities: doc.data()?.authorities,
                socialMedia: doc.data()?.services?.socialMedia?.isActive,
              })
            })
            setProjects(data)
          }
        } catch (error) {
          console.log(error)
        }
      }
      fetchProjects()
    }
  }, [clientUID])

  useEffect(() => {
    if (userUID) {
      const projectsRef = collection(db, 'projects')
      console.log(projectsRef)
      const fetchProjects = async () => {
        try {
          const docsSnap = await getDocs(projectsRef)
          if (docsSnap) {
            let data: {
              name: string
              period: string
              id: string
              isActive: boolean
              authorities: any
            }[] = []
            docsSnap.forEach((doc) => {
              doc.data().authorities.filter((u: any) => userUID === u.uid).length > 0 &&
                data.push({
                  name: doc.data().name,
                  period: doc.data().period,
                  id: doc.id,
                  isActive: doc.data().isActive,
                  authorities: doc.data()?.authorities,
                })
            })
            setProjects(data)
          }
        } catch (error) {
          console.log(error)
        }
      }
      fetchProjects()
    }
  }, [userUID])

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Projects</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>
            {projects?.filter((p: any) => p.isActive === true).length} Active
            {'  '}
            {projects?.filter((p: any) => p.isActive === false).length > 0 &&
              projects?.filter((p: any) => p.isActive === false).length + ' InActive'}
          </span>
        </h3>
        <div className='card-toolbar'>
          <ul className='nav'>
            <li className='nav-item'>
              <a
                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary active fw-semibold fs-8 p-2 me-1'
                data-bs-toggle='tab'
                href='#active'
              >
                Active
              </a>
            </li>
            <li className='nav-item'>
              <a
                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-semibold  fs-8 p-2'
                data-bs-toggle='tab'
                href='#inActive'
              >
                InActive
              </a>
            </li>
          </ul>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3 scroll-y mh-325px pb-4'>
        <div className='tab-content'>
          {/* begin::Tap pane */}
          <div className='tab-pane fade show active' id='active'>
            {projects &&
              projects
                .filter((p: any) => p.isActive === true)
                .map((project: any) => (
                  <>
                    <Link
                      to={`/client/${id}/projects/${project?.id}/${
                        project?.socialMedia === true ? 'social-media' : 'overview'
                      }`}
                    >
                      <div className='d-flex align-items-sm-center mb-2 flex-fill p-2 py-3 rounded-2 bg-hover-light'>
                        <div className='flex-fill me-2'>
                          <span className='text-primary fs-7 fw-bold'>
                            {project?.name}{' '}
                            <span className='text-primary-light fs-8 fw-normal'></span>
                          </span>
                          <span className='text-muted fw-semibold d-block fs-8'>
                            {moment(project?.startDate).format('DD.MM.YYYY')} -{' '}
                            {moment(project?.endDate).format('DD.MM.YYYY')}
                          </span>
                        </div>
                        <UsersAvatars
                          usersData={project?.authorities}
                          smybolGroupStyle={'justify-content-end min-h-30px me-6'}
                          symbolStyle={'opacity-75 opacity-100-hover symbol-25px symbol-circle'}
                          maxLeght={4}
                        />
                        <div className='d-flex w-40px justify-content-end'>
                          {project?.period === 'monthly' && (
                            <span className='badge badge-success float-end'>Fee</span>
                          )}
                          {project?.period === 'oneTime' && (
                            <span className='badge badge-warning float-end'>Project</span>
                          )}
                          {project?.period === 'yearly' && (
                            <span className='badge badge-primary float-end'>Yearly</span>
                          )}
                        </div>
                      </div>
                      <div className='separator separator-dashed my-2'></div>
                    </Link>
                  </>
                ))}
          </div>
          {/* end::Tap pane */}
          {/* begin::Tap pane */}
          <div className='tab-pane fade' id='inActive'>
            {projects &&
              projects
                .filter((p: any) => p.isActive === false)
                .map((project: any) => (
                  <>
                    <Link
                      to={`/client/${id}/projects/${project?.id}/${
                        project?.socialMedia === true ? 'social-media' : 'overview'
                      }`}
                    >
                      <div className='d-flex align-items-sm-center mb-2 flex-fill p-2 py-3 rounded-2 bg-hover-light'>
                        <div className='flex-fill me-2'>
                          <span className='text-primary fs-7 fw-bold'>
                            {project?.name}{' '}
                            <span className='text-primary-light fs-8 fw-normal'></span>
                          </span>
                          <span className='text-muted fw-semibold d-block fs-8'>
                            {moment(project?.startDate).format('DD.MM.YYYY')} -{' '}
                            {moment(project?.endDate).format('DD.MM.YYYY')}
                          </span>
                        </div>
                        <UsersAvatars
                          usersData={project?.authorities}
                          smybolGroupStyle={'justify-content-end min-h-30px me-6'}
                          symbolStyle={'opacity-75 opacity-100-hover symbol-25px symbol-circle'}
                          maxLeght={4}
                        />
                        <div className='d-flex w-40px justify-content-end'>
                          {project?.period === 'monthly' && (
                            <span className='badge badge-success float-end'>Fee</span>
                          )}
                          {project?.period === 'oneTime' && (
                            <span className='badge badge-warning float-end'>Project</span>
                          )}
                          {project?.period === 'yearly' && (
                            <span className='badge badge-primary float-end'>Yearly</span>
                          )}
                        </div>
                      </div>
                      <div className='separator separator-dashed my-2'></div>
                    </Link>
                  </>
                ))}
          </div>
          {/* end::Tap pane */}
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}

export {ProjectsWidget}
