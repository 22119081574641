/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Url} from 'url'
import {toAbsoluteUrl} from '../../../helpers'

type Props = {
  className: string
  online?: boolean
  name: string
  avatarURL?: string
  uid?: any
  status?: string
  working?: any
}

const Avatar: FC<Props> = ({className, name, avatarURL, uid, status, working}) => {
  const nameNS = name
    .split(' ')
    .map((n) => n[0])
    .join('')

  return (
    <>
      <div className={className} key={uid}>
        {status && (
          <label
            className={`rounded-4 fw-bolder fs-9 w-10px h-10px bg-${
              status === 'online' ? 'success' : ''
            }${status === 'offline' ? 'primary' : ''}${status === 'idle' ? 'warning' : ''}${
              status === 'busy' ? 'danger' : ''
            }  text-primary shadow position-absolute top-100 start-100 translate-middle`}
          ></label>
        )}
        {status === 'online' && (
          <label className='rounded-4 fw-bolder fs-9 w-10px h-10px bg-success text-primary shadow position-absolute top-100 start-100 translate-middle'></label>
        )}

        {avatarURL && <img alt='Avatar' title={name} src={avatarURL} />}
        {!avatarURL && (
          <span className='symbol-label bg-light-danger text-danger fs-3 fw-bolder' title={name}>
            {/* {name?.charAt(0).toUpperCase()}
            {name?.charAt(1).toUpperCase()} */}
            {nameNS}
          </span>
        )}
      </div>
    </>
  )
}

export {Avatar}
