/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {v4 as uuidv4} from 'uuid'

//firebase
import {addDoc, collection, serverTimestamp} from 'firebase/firestore'
import {db, storage} from '../../../firebase/config'
import {ref, getDownloadURL, uploadBytesResumable} from 'firebase/storage'

import {PageTitle} from '../../../_metronic/layout/core'

import {useAuthContext} from '../../modules/auth/core/useAuthContext'
import useAutosizeTextArea from '../../hooks/useAutosizeTextArea'
import {useCollection} from '../../hooks/useCollection'
import moment from 'moment'
import {Avatar} from '../../../_metronic/partials'

const profileDetailsSchema = Yup.object().shape({
  title: Yup.string().required('Lütfen sorun yaşıdığunız sayfa veya konuyu yazınız'),
  message: Yup.string().required('Lütfen detaylı bir açıklama yazınız.'),
})

const BugsPage: FC = () => {
  const [progresspercent, setProgresspercent] = useState(0)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const {user} = useAuthContext()

  const {documents: bugs} = useCollection('bugs')

  const [message, setMessage] = useState<string>('')

  const [file, setFile] = useState<File>()

  const [fileError, setFileError] = useState<any | null>(null)
  const [fileURL, setFileURL] = useState<any | null>(null)
  const [fileDownloadURL, setFileDownloadURL] = useState<any | null>(null)

  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  useAutosizeTextArea(textAreaRef.current, message)

  const handleChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    const val = evt.target?.value
    setMessage(val)
  }

  const handleFileChange = (event: React.FormEvent) => {
    const files = (event.target as HTMLInputElement).files

    if (files && files.length > 0) {
      let file = files[0]
      if (file.size > 5000000) {
        setFileError('Avatar file size must be less then 5mb')
        console.log('hataa')
        return
      }

      setFile(file)
      setFileURL(URL.createObjectURL(file))
    }
  }

  const handleFileDelete = () => {
    setFile(undefined)
    setFileURL(null)
  }

  const sendMessage = async () => {
    if (message.trim() === '') {
      return
    }
    setError(false)
    setLoading(true)
    console.log('dosya öncesi')
    const {uid, displayName, photoURL} = user
    if (file) {
      let fileName = `${uuidv4()}_${file.name}`
      const storageRef = ref(storage, `bugs/${user.uid}/${fileName}`)
      const uploadTask = uploadBytesResumable(storageRef, file)
      console.log('dosya var')
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
          //setProgresspercent(progress)
        },
        (error) => {
          console.log(error)
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setFileDownloadURL(downloadURL)

            addDoc(collection(db, 'bugs'), {
              message: message,
              displayName: displayName,
              photoURL: photoURL,
              file: downloadURL,
              createdAt: serverTimestamp(),
              isDone: false,
              uid,
            })
              .then(() => {
                setSuccess(true)
                setLoading(false)
                setFile(undefined)
                setFileURL(null)
                setMessage('')
                setTimeout(() => {
                  setSuccess(false)
                }, 750)
              })
              .catch((error) => {
                setError(true)
                setLoading(false)
              })
          })
        }
      )
    }
  }

  return (
    <>
      {/* begin::Header */}
      <div className='py-3 py-lg-5'>
        <div className='d-flex flex-stack'>
          <div className='page-title d-flex flex-wrap me-3 flex-column justify-content-center'>
            <h1 className='page-heading d-flex text-primary fw-bold fs-3 my-0 flex-column justify-content-center'>
              Bugs
            </h1>
            <span className='fw-semibold text-muted'>All Problem</span>
          </div>
        </div>
      </div>
      {/* end::Header */}
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body p-4'>
          <div className=' bg-white  d-flex align-items-center p-4'>
            <div className='flex-fill'>
              <textarea
                className='form-control form-control-flush me-2'
                style={{resize: 'none'}}
                rows={1}
                data-kt-element='input'
                placeholder='Yaşamış olduğunuz hataları anlatın ve ekran resmi ile hata aldığınız sayfayı atınız...'
                value={message}
                onChange={handleChange}
                ref={textAreaRef}
              ></textarea>
            </div>

            <div className='d-flex'>
              <div className='image-input me-3'>
                <div
                  className='image-input-wrapper w-75px h-75px'
                  style={{backgroundImage: `url(${fileURL})`}}
                ></div>

                {!file && (
                  <label
                    className='btn btn-sm btn-icon btn-active-color-primary bg-hover-light h-100 w-100'
                    data-kt-image-input-action='change'
                    data-bs-toggle='tooltip'
                    aria-label='Change avatar'
                    data-bs-original-title='Change avatar'
                    title='Change Avatar'
                    data-kt-initialized='1'
                    style={{left: '50%', top: '50%'}}
                  >
                    <i className='bi bi-paperclip fs-4'></i>
                    <input
                      type='file'
                      name='avatar'
                      accept='.png, .jpg, .jpeg'
                      onChange={(event) => {
                        handleFileChange(event)
                      }}
                    />
                    <input type='hidden' name='avatar_remove' onClick={handleFileDelete} />
                  </label>
                )}
                {file && (
                  <span
                    className='btn btn-icon btn-circle btn-active-color-primary bg-hover-light w-20px h-20px bg-body shadow'
                    data-kt-image-input-action='remove'
                    data-bs-toggle='tooltip'
                    aria-label='Cancel avatar'
                    data-bs-original-title='Cancel avatar'
                    title='Cancel Avatar'
                    onClick={handleFileDelete}
                    style={{left: '0', top: '0'}}
                  >
                    <i className='bi bi-x fs-4'></i>
                  </span>
                )}
              </div>
              <button
                type='button'
                className={`btn btn-primary btn-sm ${success && 'btn-success'}`}
                disabled={loading || success || file?.name === undefined || message === ''}
                data-kt-element='send'
                onClick={sendMessage}
              >
                {success && (
                  <>
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Sent!
                    </span>
                  </>
                )}
                {!success && (
                  <>
                    {!loading && 'Send'}
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        <span className='spinner-border spinner-border-sm align-middle'></span>
                      </span>
                    )}
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* begin::Row */}
      <div className='row gy-5 gx-xl-8'>
        {bugs &&
          bugs
            .sort((a: any, b: any) => (a.createdAt < b.createdAt ? 1 : -1))
            .map((bug: any, i) => (
              <div className='col-lg-4' key={i}>
                <div className='card card-custom card-flush'>
                  <div className='card-header'>
                    <h3 className='card-title'></h3>
                    <div className='card-toolbar'>
                      {bug.isDone ? (
                        <button type='button' className='btn btn-sm btn-light-success'>
                          Düzeltildi
                        </button>
                      ) : (
                        <button type='button' className='btn btn-sm btn-light-danger'>
                          Bekliyor
                        </button>
                      )}
                    </div>
                  </div>
                  <div className='card-body p-4'>
                    <img src={bug.file} className='w-100' />
                    <span className='fs-6 my-4 d-block'>{bug.message}</span>
                  </div>
                  <div className='card-footer'>
                    <Avatar
                      className={'symbol symbol-35px me-3'}
                      name={bug.displayName}
                      avatarURL={bug.photoURL}
                    />
                    {bug.displayName}
                    <span className='text-muted fs-8 ms-auto float-end'>
                      {bug.createdAt &&
                        moment
                          .utc(new Date(bug.createdAt.toDate()))
                          .local()
                          .startOf('s')
                          .fromNow(true)}
                    </span>
                  </div>
                </div>
              </div>
            ))}
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      {/* <div className='row gy-5 gx-xl-8'>
      <div className='col-xxl-4'>
        <ListsWidget3 className='card-xxl-stretch mb-xl-3' />
      </div>
      <div className='col-xl-8'>
        <TablesWidget10 className='card-xxl-stretch mb-5 mb-xl-8' />
      </div>
    </div> */}
      {/* end::Row */}

      {/* begin::Row */}
      {/* <div className='row gy-5 g-xl-8'>
      <div className='col-xl-4'>
        <ListsWidget2 className='card-xl-stretch mb-xl-8' />
      </div>
      <div className='col-xl-4'>
        <ListsWidget6 className='card-xl-stretch mb-xl-8' />
      </div>
      <div className='col-xl-4'>
        <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-8' items={5} />
      </div>
    </div> */}
      {/* end::Row */}

      {/* <div className='row g-5 gx-xxl-8'>
      <div className='col-xxl-4'>
        <MixedWidget8
          className='card-xxl-stretch mb-xl-3'
          chartColor='success'
          chartHeight='150px'
        />
      </div>
      <div className='col-xxl-8'>
        <TablesWidget5 className='card-xxl-stretch mb-5 mb-xxl-8' />
      </div>
    </div> */}
    </>
  )
}

const BugsPageWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <BugsPage />
    </>
  )
}

export {BugsPageWrapper}
