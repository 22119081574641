import React from 'react'
import {KTSVG} from '../../../_metronic/helpers'

type Props = {
  fileName: string
  fileType: string
  file?: File
  fileURL?: string
  className?: string
  style?: string
}
const FileIcon: React.FC<Props> = ({fileName, fileType, fileURL, file, className, style}) => {
  let returnData
  if (fileType === 'image/png') {
    returnData = (
      <div
        title={fileName}
        className={className}
        style={
          file
            ? {backgroundImage: `url(${URL.createObjectURL(file)})`, backgroundSize: 'cover'}
            : {backgroundImage: `url(${fileURL})`}
        }
      ></div>
    )
  } else if (fileType === 'application/pdf') {
    returnData = (
      <div
        title={fileName}
        className={`${className} text-center d-inline-flex align-items-center justify-content-center`}
      >
        <KTSVG path='/media/svg/files/pdf.svg' className='svg-icon-2tx fs-10 ' />
      </div>
    )
  } else if (fileType === 'application/zip') {
    returnData = (
      <div
        title={fileName}
        className={`${className} text-center d-inline-flex align-items-center justify-content-center`}
      >
        <KTSVG path='/media/svg/files/folder-document.svg' className='svg-icon-2tx fs-10 ' />
      </div>
    )
  } else {
    returnData = (
      <div
        title={fileName}
        className={`${className} text-center d-inline-flex align-items-center justify-content-center`}
      >
        <KTSVG path='/media/svg/files/folder-document.svg' className='svg-icon-2tx fs-10 ' />
      </div>
    )
  }

  return returnData
}

export default FileIcon
