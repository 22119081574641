/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link, Pathname, useNavigate} from 'react-router-dom'
import {Url} from 'url'
import {toAbsoluteUrl} from '../../../helpers'
import {Avatar} from '../user/Avatar'
import {
  Index,
  Timestamp,
  arrayRemove,
  arrayUnion,
  deleteField,
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
  updateDoc,
} from 'firebase/firestore'
import {db} from '../../../../firebase/config'
import {useAuthContext} from '../../../../app/modules/auth'
import StopWatch from '../../../../app/modules/components/StopWatch'
import addDays from 'date-fns/addDays'
import addWeeks from 'date-fns/addWeeks'
import addBusinessDays from 'date-fns/addBusinessDays'
import addMonths from 'date-fns/addMonths'
import addYears from 'date-fns/addYears'

type Props = {
  workData: any
  key: any
  showStart: boolean
  isTeamTaskPage: boolean
  userUID?: string
}
const CardWork: FC<Props> = ({workData, key, showStart, isTeamTaskPage, userUID}) => {
  const {user} = useAuthContext()
  const navigate = useNavigate()

  const startWork = (
    clientUID,
    clientName,
    clientLogo,
    projectUID,
    projectName,
    workUID,
    workName
  ) => {
    const userDocRef = doc(db, 'users', user.uid)
    const projectDocRef = doc(db, 'projects', projectUID)
    const clientProjectDocRef = doc(db, 'clients', clientUID, 'projects', projectUID)
    let userdata = {
      working: {
        clientUID: clientUID,
        clientName: clientName,
        clientLogo: clientLogo,
        projectUID: projectUID,
        projectName: projectName,
        workUID: workUID,
        workName: workName,
        startTime: serverTimestamp(),
      },
    }

    let projectData = {
      userUID: user.uid,
      workUID: workUID,
      workName: workName,
      startTime: Timestamp.now(),
    }

    setDoc(userDocRef, userdata, {merge: true})
      .then(() => console.log('User Updated.'))
      .catch((error) => {
        console.log(error.message)
      })
    updateDoc(projectDocRef, {
      working: arrayUnion(projectData),
    })
      .then(() => console.log('Project Updated.'))
      .catch((error) => console.log(error.message))

    updateDoc(clientProjectDocRef, {
      working: arrayUnion(projectData),
    })
      .then(() => console.log('Project Updated.'))
      .catch((error) => console.log(error.message))

    const linkService =
      workData?.services?.socialMedia?.isActive === true ? 'social-media' : 'overview'
    navigate('/client/' + clientUID + '/projects/' + projectUID + '/' + linkService)
  }

  const pinWork = (
    clientUID,
    clientName,
    clientLogo,
    projectUID,
    projectName,
    workUID,
    workName
  ) => {
    let docRef = doc(db, 'clients/' + clientUID + '/projects/' + projectUID)
    getDoc(docRef).then((data) => {
      let project: any = data.data()

      project?.works
        .filter((w: any) => w.id === workUID)
        .map((work: any) => {
          let date: any = null
          let index = project?.works.findIndex((w: any) => w.id === work?.id)
          let pinData
          if (isTeamTaskPage === true) {
            pinData = {
              uid: userUID,
              createdAt: Timestamp.now(),
              priority: true,
              priorityUserName: user.displayName,
              priorityUserUID: user.uid,
            }
          } else {
            pinData = {
              uid: user.uid,
              createdAt: Timestamp.now(),
            }
          }
          if (project.works[index]?.pins) {
            project.works[index].pins.push(pinData)
          } else {
            project.works[index].pins = [pinData]
          }
        })
      updateDoc(doc(db, 'clients', clientUID, 'projects', projectUID), project, {merge: true})
        .then(async (data) => {
          setDoc(doc(db, 'projects', projectUID), project, {
            merge: true,
          })
            .then(() => {
              console.log('pinlendi!')
            })
            .catch((err) => {
              console.log(err.message)
            })
        })
        .catch((err) => {
          console.log(err.message)
        })
    })
  }

  const removePinWork = (
    clientUID,
    clientName,
    clientLogo,
    projectUID,
    projectName,
    workUID,
    workName
  ) => {
    let docRef = doc(db, 'clients/' + clientUID + '/projects/' + projectUID)
    console.log(projectUID)
    getDoc(docRef).then((data) => {
      let project: any = data.data()

      project?.works
        .filter((w: any) => w.id === workUID)
        .map((work: any) => {
          let index = project?.works.findIndex((w: any) => w.id === work?.id)
          if (project.works[index]?.pins) {
            if (isTeamTaskPage === true) {
              project.works[index].pins = project.works[index]?.pins.filter(
                (p: any) => p.uid !== userUID
              )
            } else {
              project.works[index].pins = project.works[index]?.pins.filter(
                (p: any) => p.uid !== user.uid
              )
            }
          }
        })
      updateDoc(doc(db, 'clients', clientUID, 'projects', projectUID), project, {merge: true})
        .then(async (data) => {
          setDoc(doc(db, 'projects', projectUID), project, {
            merge: true,
          })
            .then(() => {
              console.log('pin kaldırıldı.!')
            })
            .catch((err) => {
              console.log(err.message)
            })
        })
        .catch((err) => {
          console.log(err.message)
        })
    })
  }

  const completeWork = (
    clientUID,
    clientName,
    clientLogo,
    projectUID,
    projectName,
    workUID,
    workName
  ) => {
    let docRef = doc(db, 'clients/' + clientUID + '/projects/' + projectUID)
    getDoc(docRef).then((data) => {
      let project: any = data.data()

      project?.works
        .filter((w: any) => w.id === workUID)
        .map((work: any) => {
          let date: any = null
          let index = project?.works.findIndex((w: any) => w.id === work?.id)
          console.log(index)
          if (work?.repeat && work?.repeat !== '') {
            if (work?.repeat === 'everyDay') {
              date = Timestamp.fromDate(addDays(work?.deadline.toDate(), 1))
              console.log(date)
              console.log('HERGÜN')
            }
            if (work?.repeat === 'weekDays') {
              date = Timestamp.fromDate(addBusinessDays(work?.deadline.toDate(), 1))
            }
            if (work?.repeat === 'perWeek') {
              date = Timestamp.fromDate(addWeeks(work?.deadline.toDate(), 1))
            }
            if (work?.repeat === 'perMonth') {
              date = Timestamp.fromDate(addMonths(work?.deadline.toDate(), 1))
            }
            if (work?.repeat === 'perYear') {
              date = Timestamp.fromDate(addYears(work?.deadline.toDate(), 1))
            }

            if (project.works[index]?.actions) {
              project.works[index].actions.push({
                uid: user.uid,
                oldDeadline: work?.deadline,
                newDeadline: date,
                createdAt: Timestamp.now(),
              })
            } else {
              project.works[index].actions = [
                {
                  uid: user.uid,
                  oldDeadline: work?.deadline,
                  newDeadline: date,
                  createdAt: Timestamp.now(),
                },
              ]
            }
            if (project.works[index]?.deadline) {
              delete project.works[index].deadline
              project.works[index].deadline = date
            } else {
              project.works[index].deadline = date
            }
          }
          if (!work?.repeat || work?.repeat === '') {
            project.works[index].isDone = true
            if (project.works[index]?.actions) {
              project.works[index].actions.push({
                uid: user.uid,
                createdAt: Timestamp.now(),
                isDone: true,
              })
            } else {
              project.works[index].actions = [
                {
                  uid: user.uid,
                  createdAt: Timestamp.now(),
                  isDone: true,
                },
              ]
            }
            console.log(project)
          }
          if (
            project.works[index]?.pins &&
            project.works[index]?.pins.filter((p: any) => p.uid === user.uid).length > 0
          ) {
            project.works[index].pins = project.works[index]?.pins.filter(
              (p: any) => p.uid !== user.uid
            )
          }
        })
      updateDoc(doc(db, 'clients', clientUID, 'projects', projectUID), project, {merge: true})
        .then(async (data) => {
          setDoc(doc(db, 'projects', projectUID), project, {
            merge: true,
          })
            .then(() => {
              console.log('kaydedildi.')
              project.works.map((w: any) => {
                const input = document.getElementById('checkbox-' + w.id) as HTMLInputElement | null

                if (input) {
                  console.log('var')
                  input.checked = false
                }
              })
            })
            .catch((err) => {
              console.log(err.message)
            })
        })
        .catch((err) => {
          console.log(err.message)
        })

      // <option value='everyDay'>Every Day</option>
      // <option value='weekDays'>Week Days</option>
      // <option value='perWeek'>Per Week</option>
      // <option value='perMonth'>Per Month</option>
      // <option value='perYear'>Per Year</option>

      // data.data()?.details && setDetailArray(data.data()?.details)
      // setAuthorities(data.data()?.authorities)
      // setIsActive(data.data()?.isActive)
      // setSelectedService(data.data()?.serviceUID)
      // setActiveInOrder(data.data()?.activeInOrder ? data.data()?.activeInOrder : false)
      // setSelectedServiceWorks(data.data()?.works ? data.data()?.works : [])
      // console.log(activeInOrder)
    })
  }

  return (
    <div key={key}>
      <div className='d-flex  bg-light align-items-sm-center mb-2 p-2 rounded-2 '>
        <Avatar
          className={'symbol symbol-35px symbol-md-35px me-2 align-self-start'}
          name={workData?.clientName}
          avatarURL={workData?.clientLogo}
        />
        <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
          <div className='d-flex flex-grow-1 me-2'>
            <div className='d-flex flex-fill flex-column'>
              <span className='text-primary fs-8 fw-bold'>{workData?.workName}</span>
              <div className='text-primary fs-8 '>{workData?.projectName}</div>
            </div>
            <div className='d-flex items-align-center'>
              <div className='form-check form-check-custom form-check-solid form-check-sm me-4'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  id={`checkbox-${workData?.workUID}`}
                  onChange={(e) =>
                    completeWork(
                      workData?.clientUID,
                      workData?.clientName,
                      workData?.clientLogo,
                      workData?.projectUID,
                      workData?.projectName,
                      workData?.workUID,
                      workData?.workName
                    )
                  }
                />
              </div>
              {workData?.pins &&
              workData?.pins.filter((p: any) =>
                isTeamTaskPage === false ? user.uid === p?.uid : userUID === p?.uid
              ).length > 0 ? (
                <button
                  type='button'
                  className='btn btn-sm btn-link btn-color-gray-500 btn-active-color-primary p-0 '
                  onClick={() =>
                    removePinWork(
                      workData?.clientUID,
                      workData?.clientName,
                      workData?.clientLogo,
                      workData?.projectUID,
                      workData?.projectName,
                      workData?.workUID,
                      workData?.workName
                    )
                  }
                >
                  {workData.pins
                    .filter((wPin: any) =>
                      isTeamTaskPage === false ? user.uid === wPin?.uid : userUID === wPin?.uid
                    )
                    .map((p: any) =>
                      p?.priority === true ? (
                        <i
                          className='bi bi-pin-angle-fill text-danger fs-3'
                          title={p?.priorityUserName}
                        ></i>
                      ) : (
                        <i className='bi bi-pin-angle-fill fs-3'></i>
                      )
                    )}
                </button>
              ) : (
                <button
                  type='button'
                  className='btn btn-sm btn-link btn-color-gray-500 btn-active-color-primary p-0'
                  onClick={(e) =>
                    pinWork(
                      workData?.clientUID,
                      workData?.clientName,
                      workData?.clientLogo,
                      workData?.projectUID,
                      workData?.projectName,
                      workData?.workUID,
                      workData?.workName
                    )
                  }
                >
                  <i className='bi bi-pin-angle fs-3'></i>
                </button>
              )}

              {workData?.working?.filter(
                (workUser: any) =>
                  workUser.workUID === workData?.workUID && user.uid === workUser.userUID
              ).length > 0
                ? workData?.working?.map(
                    (workingData: any) =>
                      workingData.workUID === workData?.workUID &&
                      (isTeamTaskPage === false
                        ? user.uid === workingData.userUID
                        : userUID === workingData.userUID) && (
                        <div className={'badge badge-danger m-1 me-0 ms-2'}>
                          <StopWatch
                            startTime={workingData?.startTime?.toDate()}
                            className={'d-flex justify-content-between fs-8 d-block'}
                            style={{width: '32px'}}
                          />
                        </div>
                      )
                  )
                : showStart === true && (
                    <button
                      type='button'
                      className='btn btn-sm btn-link btn-color-gray-500 btn-active-color-primary p-0 ms-2'
                      onClick={() =>
                        startWork(
                          workData?.clientUID,
                          workData?.clientName,
                          workData?.clientLogo,
                          workData?.projectUID,
                          workData?.projectName,
                          workData?.workUID,
                          workData?.workName
                        )
                      }
                    >
                      <i className='bi bi-play-fill fs-2'></i>
                    </button>
                  )}
            </div>
            {/* <StopWatch startTime={isWorking.startTime.toDate()} /> */}
          </div>

          {/* <Link className='btn btn-sm btn-light' to={link}>
            {service}
          </Link> */}
        </div>
      </div>
      <div className='d-flex flex-column mt-2'></div>
    </div>
  )
}

export {CardWork}
