import {Timestamp, addDoc, collection, doc, serverTimestamp, setDoc} from 'firebase/firestore'
import {FC, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'

import DatePicker from 'react-datepicker'
import './../../../../../../../../../_metronic/assets/sass/core/vendors/plugins/_datepicker.scss'

import subDays from 'date-fns/subDays'
import addDays from 'date-fns/addDays'
import addSeconds from 'date-fns/addSeconds'
import {useAuthContext} from '../../../../../../../auth'
import {db} from '../../../../../../../../../firebase/config'

type Props = {
  contents: any
}

const DropdownNewContent: FC<Props> = ({contents}) => {
  const {id, projectID} = useParams()
  const clientUID = id ? id : ''
  const projectUID = projectID ? projectID : ''

  const [startDate, setStartDate] = useState(new Date())

  const highlightWithRanges = [
    {
      'react-datepicker__day--highlighted-custom-1':
        contents && contents.map((content: any) => addSeconds(content.publishDate.toDate(), 1)),
    },
  ]

  const [brandName, setBrandName] = useState('')
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState('')

  const navigate = useNavigate()

  const {user} = useAuthContext()

  const handleChange = (evt) => {
    const val = evt.target?.value
    setBrandName(val)
  }

  const handleClick = () => {
    setLoading(true)
    console.log(startDate)
    let createdAt = serverTimestamp()
    const contentData = {
      publishDate: startDate,
      status: 'Blank Content',
      createdAt: createdAt,
      projectUID: projectUID,
      logs: [
        {
          displayName: user.displayName,
          uid: user.uid,
          photoURL: user?.photoURL,
          action: 'I created the content.',
          createdAt: Timestamp.now(),
        },
      ],
    }
    addDoc(
      collection(db, 'clients', clientUID, 'projects', projectUID, 'socialMediaContents'),
      contentData
    )
      .then((data) => {
        setDoc(doc(db, 'clients', clientUID, 'socialMediaContents', data.id), contentData).then(
          () => {
            setSuccess(true)
            setLoading(false)
            setTimeout(() => {
              setSuccess(false)
            }, 500)
          }
        )
      })
      .catch((err) => {
        setError(err.message)
        console.log(err.message)
        setLoading(false)
      })
  }

  return (
    <div className='menu menu-sub menu-sub-dropdown w-275px w-md-250px' data-kt-menu='true'>
      {/* <div className='px-7 py-3'>
        <div className='fs-7 text-dark fw-bolder'>Select Date</div>
      </div> */}

      <div className='px-2 py-2'>
        <div className='mb-2'>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            timeInputLabel=''
            dateFormat='MM/dd/yyyy hh:mm'
            calendarStartDay={1}
            highlightDates={highlightWithRanges}
            showTimeInput
            inline
            calendarClassName=''
          ></DatePicker>
        </div>

        <div className='d-flex justify-content-end'>
          <button
            type='reset'
            className='btn btn-sm btn-light btn-active-light-primary me-2'
            data-kt-menu-dismiss='true'
          >
            Close
          </button>

          <button
            type='submit'
            className={`btn btn-sm btn-primary ${success && 'btn-success'}`}
            disabled={loading}
            onClick={handleClick}
          >
            {success && (
              <>
                <span className='badge badge-success me-5 px-6'></span>
                <span className='' style={{display: 'block'}}>
                  <i className='bi bi-check fs-2'></i>
                  Success
                </span>
              </>
            )}
            {!success && (
              <>
                {!loading && 'Continue'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  )
}

export {DropdownNewContent}
