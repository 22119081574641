import React, {useEffect, useState} from 'react'
import {useAuthContext} from '../../../../auth'
import {doc, getDoc, serverTimestamp, setDoc, Timestamp} from 'firebase/firestore'
import {db} from '../../../../../../firebase/config'
import {useParams} from 'react-router-dom'
import {KTSVG} from '../../../../../../_metronic/helpers'
import clsx from 'clsx'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {Avatar} from '../../../../../../_metronic/partials'
import {useCollection} from '../../../../../hooks/useCollection'
import {Services} from '../components/Services'
import {arrayMoveImmutable} from 'array-move'
import {UsersAvatars} from '../../../../components/Elements/UsersAvatars'
import {Calendar} from 'primereact/calendar'
import {addLocale} from 'primereact/api'
import moment from 'moment'

export function Settings() {
  const {id, projectID} = useParams()

  const clientUID = id ? id : ''
  const projectUID = projectID ? projectID : ''

  const [clientDetail, setClientDetail] = useState<any | null>(null)
  const {documents: users} = useCollection('users')
  const {documents: oldServices} = useCollection('services')
  const {documents: services} = useCollection('agency/data/services')
  const {documents: serviceWorks} = useCollection('agency/data/serviceWorks')
  const {documents: currencies} = useCollection('agency/data/currencies')
  const [currency, setCurrency] = useState('')

  const [selectedUser, setSelectedUser] = useState('')

  addLocale('tr', {
    firstDayOfWeek: 1,
  })

  const [selectedService, setSelectedService] = useState('')
  const [selectedServiceWorks, setSelectedServiceWorks] = useState<any | null>(null)

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<any | null>(null)
  const [success, setSuccess] = useState(false)

  const [activeInOrder, setActiveInOrder] = useState(false)

  const [projectData, setProjectData] = useState<any | null>(null)
  const [detailArray, setDetailArray] = useState([{value: ''}])
  const [authorities, setAuthorities] = useState<any | null>(null)
  const [isActive, setIsActive] = useState(false)

  const divclass = 'col-lg-6 col-md-6 mb-8'

  useEffect(() => {
    let docRef = doc(db, 'clients/' + id + '/projects/' + projectID)
    getDoc(docRef)
      .then((data) => {
        setProjectData(data.data())
        data.data()?.details && setDetailArray(data.data()?.details)
        setAuthorities(data.data()?.authorities)
        setIsActive(data.data()?.isActive)
        setSelectedService(data.data()?.serviceUID)
        setActiveInOrder(data.data()?.activeInOrder ? data.data()?.activeInOrder : false)
        setSelectedServiceWorks(data.data()?.works ? data.data()?.works : [])
        console.log(activeInOrder)
      })
      .catch((error) => {})
  }, [clientUID])

  useEffect(() => {
    let docRef = doc(db, 'clients', clientUID)
    getDoc(docRef)
      .then((data) => {
        setClientDetail(data.data())
      })
      .catch((error) => {})
  }, [clientUID])

  const initialValues = {
    name: projectData?.name ? projectData.name : '',
    serviceUID: projectData?.serviceUID ? projectData.serviceUID : '',
    connectedServices: projectData?.connectedServices ? projectData.connectedServices : '',
    startDate: projectData?.startDate ? projectData.startDate : '',
    endDate: projectData?.endDate ? projectData.endDate : '',
    period: projectData?.period ? projectData.period : '',
    budget: projectData?.budget ? projectData.budget : '',
    currency: projectData?.currency ? projectData?.currency : '',
    description: projectData?.description ? projectData.description : '',
  }

  const contractsSchema = Yup.object().shape({
    startDate: Yup.date().required('Sözleşme başlangıç tarihini belirtmek zorundasın.'),
    endDate: Yup.date().required('Sözleşme bitiş tarihini belirtmek zorundasın.'),
    period: Yup.string().required('Sözelşemeye bağlı hizmetlerin dönemlerini seçmelisin.'),
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: contractsSchema,
    onSubmit: async (values, {resetForm}) => {
      setLoading(true)
      console.log(values)
      console.log(selectedService)
      const selectedServiceD: any = services.find((s: any) => s.id === selectedService)
      console.log('selectedServices:' + selectedServiceD)
      console.log('activeInOrder:' + activeInOrder)
      console.log('Service :' + values.name)
      const ProjectData = {
        name: selectedServiceD.name,
        serviceUID: selectedService,
        works: selectedServiceWorks,
        connectedServices: values.connectedServices,
        startDate: values.startDate,
        endDate: values.endDate,
        period: values.period,
        details: detailArray,
        authorities: authorities,
        budget: values.budget,
        currency: values.currency,
        activeInOrder: activeInOrder,
        isActive: isActive,
        description: values.description,

        updatedAt: serverTimestamp(),
      }
      setDoc(doc(db, 'clients', clientUID, 'projects', projectUID), ProjectData, {merge: true})
        .then(async (data) => {
          setDoc(doc(db, 'projects', projectUID), ProjectData, {
            merge: true,
          }).then(() => {
            setLoading(false)
            setSuccess(true)

            setTimeout(() => {
              setSuccess(false)
            }, 1500)
            setLoading(false)
          })
        })
        .catch((err) => {
          setError(err.message)
          console.log(err.message)
          setLoading(false)
        })
    },
  })

  const handleInputChange = (index, event) => {
    const values = [...detailArray]
    values[index].value = event.target.value
    setDetailArray(values)
  }

  const handleAddInput = () => {
    setDetailArray([...detailArray, {value: ''}])
  }

  const handleRemoveInput = (index) => {
    const values = [...detailArray]
    values.splice(index, 1)
    setDetailArray(values)
  }

  const actionWork = async (type, id) => {
    type === 'before' &&
      setSelectedServiceWorks(arrayMoveImmutable(selectedServiceWorks, id, id - 1))
    type === 'after' &&
      setSelectedServiceWorks(arrayMoveImmutable(selectedServiceWorks, id, id + 1))
    type === 'delete' &&
      setSelectedServiceWorks(selectedServiceWorks.filter((w: any, index) => index !== id))
  }
  const handleChange = (event) => {
    let userRolee
    if (
      users.filter((u: any) => u.id === event.target.id && u?.model.isTeammate === true).length > 0
    ) {
      userRolee = 'team'
    }
    if (
      users.filter((u: any) => u.id === event.target.id && u?.model.isFreelancer === true).length >
      0
    ) {
      userRolee = 'freelancer'
    }
    setAuthorities([
      ...authorities,
      {uid: event.target.id, title: event.target.value, role: userRolee},
    ])
  }

  const addProjectManager = (user) => {
    console.log(user)
    setAuthorities([...authorities, {uid: user.id}])
    console.log('eklediii')
    console.log(authorities)
  }

  const deleteProjectManager = (user) => {
    setAuthorities(authorities.filter((u: any) => u.uid !== user.id))
  }

  const addServiceWork = (id) => {
    const selectedWork = serviceWorks.find((w: any) => w.id === id)
    setSelectedServiceWorks([...selectedServiceWorks, selectedWork])
    console.log(selectedServiceWorks)
  }

  const addWorkUser = (id, user) => {
    const selectedWork = selectedServiceWorks[id]
    if (selectedWork?.authorities) {
      selectedWork.authorities.push({uid: user.id})
    } else {
      selectedWork.authorities = [{uid: user.id}]
    }
    let newArray = [...selectedServiceWorks]
    newArray[id] = selectedWork
    setSelectedServiceWorks(newArray)
  }

  const addWorkDeadline = (id, date) => {
    console.log('date : ' + date)

    const selectedWork = selectedServiceWorks[id]

    if (selectedWork?.deadline) {
      delete selectedWork.deadline
      selectedWork.deadline = Timestamp.fromDate(date)
    } else {
      selectedWork.deadline = Timestamp.fromDate(date)
    }

    let newArray = [...selectedServiceWorks]
    newArray[id] = selectedWork
    setSelectedServiceWorks(newArray)
  }

  const addWorkRepeat = (id, value) => {
    const selectedWork = selectedServiceWorks[id]

    selectedWork.repeat = value

    let newArray = [...selectedServiceWorks]
    newArray[id] = selectedWork
    setSelectedServiceWorks(newArray)
  }
  const deleteWorkUser = (id, user) => {
    const selectedWork = selectedServiceWorks[id]
    const newAuthorities = selectedWork.authorities?.filter((u: any) => u.uid !== user.id)
    selectedWork.authorities = newAuthorities
    let newArray = [...selectedServiceWorks]
    newArray[id] = selectedWork
    setSelectedServiceWorks(newArray)
  }

  const DeleteRole = (val, role) => {
    console.log(val)
    console.log(role)
    setAuthorities(authorities.filter((u: any) => u.uid !== val))
  }

  return (
    <>
      <div className='d-flex flex-wrap flex-stack my-4'>
        <h3 className='fw-bolder text-primary my-3 ps-1'>Project Settings</h3>
      </div>
      {projectData && (
        <div className='card card-flush px-4  mb-8'>
          <form
            className='form fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            onSubmit={formik.handleSubmit}
          >
            {formik.status && (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
            )}
            <div className='row'>
              <div className='col-md-8'>
                <div className='card card-flush my-6 ps-4  '>
                  <div className='d-flex flex-wrap fs-8 text-muted  me-8'>
                    <label className='form-label fw-bolder text-primary fs-6'>Select Service</label>
                    <select
                      className='form-select form-select-lg form-select-solid'
                      defaultValue={projectData?.serviceUID ? projectData?.serviceUID : ''}
                      {...formik.getFieldProps('serviceUID')}
                      onChange={(e) => setSelectedService(e.target.value)}
                      value={selectedService}
                    >
                      <option selected disabled value=''>
                        Select Project Service
                      </option>
                      {/* {services &&
                      services
                        .sort((a: any, b: any) => a.department.localeCompare(b.department))
                        .map((service: any) => 
                        
                        <option value={service.id}>{service.name}</option>)} */}
                      {services &&
                        services
                          .reduce((categories: any, service: any) => {
                            if (!categories.includes(service?.category)) {
                              categories.push(service.category)
                            }
                            return categories
                          }, [])
                          .map((category: any, id) => (
                            <optgroup label={category} key={id}>
                              {services
                                .filter((service: any) => service?.category === category)
                                .map((service: any) => (
                                  <option value={service.id} key={service.id}>
                                    {service.name}
                                  </option>
                                ))}
                            </optgroup>
                          ))}
                    </select>
                  </div>
                  {selectedService && (
                    <>
                      <h4 className='fw-bolder text-primary mt-6 fs-6'>Service Works / Task</h4>
                      <div className='d-flex flex-wrap'>
                        {selectedService &&
                          serviceWorks.map(
                            (work: any) =>
                              work?.services.filter((s: any) => s.id === selectedService).length >
                                0 && (
                                <button
                                  type='button'
                                  className='btn btn-light btn-sm me-2 mb-3 btn-light'
                                  onClick={() => {
                                    addServiceWork(work.id)
                                  }}
                                >
                                  {work?.name}
                                </button>
                              )
                          )}
                      </div>
                    </>
                  )}

                  {selectedServiceWorks.length > 0 && (
                    <div className='d-flex align-items-center border-bottom border-gray-300 py-2 pt-3 border-bottom-dashed me-7'>
                      <h4 className='fw-bolder text-primary  fs-6 flex-fill'>
                        Selected Works / Task
                      </h4>
                      <div className='form-check form-switch form-check-custom form-check-solid '>
                        <label className='form-check-label fs-8 me-2' htmlFor='flexSwitch30x50'>
                          Activate in order
                        </label>
                        <input
                          className='form-check-input h-20px w-30px'
                          type='checkbox'
                          defaultChecked={activeInOrder}
                          onChange={() => setActiveInOrder(!activeInOrder)}
                          id='flexSwitch30x50'
                        />
                      </div>
                    </div>
                  )}
                  <div className='d-flex flex-column me-6 mt-2'>
                    {selectedServiceWorks &&
                      selectedServiceWorks.map((work: any, index) => (
                        <div
                          className='d-flex bg-gray-100 my-2 p-4 rounded align-items-center'
                          key={index}
                        >
                          <div className='flex-fill fw-semibold'>{work.name}</div>
                          <div className='symbol-group symbol-hover ms-0 pe-4 ps-6 py-4'>
                            {users?.map(
                              (user: any) =>
                                work?.teams.filter(
                                  (team: any) =>
                                    user?.teams?.filter((uteam: any) => uteam.uid === team.id)
                                      .length > 0
                                ).length > 0 && (
                                  <div className='overlay'>
                                    <div className='overlay-wrapper'>
                                      <Avatar
                                        className={`symbol symbol-30px ${
                                          work?.authorities?.filter((u: any) => u.uid === user.id)
                                            .length > 0
                                            ? ''
                                            : 'opacity-25 opacity-75-hover'
                                        } symbol-circle`}
                                        name={user.displayName}
                                        avatarURL={user?.photoURL}
                                      />
                                    </div>
                                    {work?.authorities?.filter((u: any) => u.uid === user.id)
                                      .length > 0 ? (
                                      <div className='symbol symbol-30px overlay-layer symbol-circle bg-danger bg-opacity-75'>
                                        <button
                                          className='btn btn-link'
                                          type='button'
                                          onClick={() => deleteWorkUser(index, user)}
                                        >
                                          <i className='bi bi-x fs-2 text-white pe-0'></i>
                                        </button>
                                      </div>
                                    ) : (
                                      <div className='symbol symbol-30px overlay-layer symbol-circle bg-success bg-opacity-75'>
                                        <button
                                          className='btn btn-link'
                                          type='button'
                                          onClick={() => addWorkUser(index, user)}
                                        >
                                          <i className='bi bi-check fs-2 text-white pe-0'></i>
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                )
                            )}
                          </div>
                          {!activeInOrder && (
                            <>
                              <Calendar
                                inputClassName='form-control w-90px text-center form-control-sm form-control-solid'
                                placeholder='Deadline'
                                dateFormat='dd.mm.yy'
                                value={work?.deadline ? work?.deadline.toDate() : null}
                                onChange={(e) => addWorkDeadline(index, e.value)}
                                locale='tr'
                              />

                              <select
                                className='form-control form-control-sm form-control-solid w-90px text-center me-2'
                                placeholder='Repeat'
                                defaultValue={work?.repeat}
                                value={work?.repeat}
                                onChange={(e) => addWorkRepeat(index, e.target.value)}
                              >
                                <option value=''>No Repeat</option>

                                <option value='everyDay'>Every Day</option>
                                <option value='weekDays'>Week Days</option>
                                <option value='perWeek'>Per Week</option>
                                <option value='perMonth'>Per Month</option>
                                <option value='perYear'>Per Year</option>
                              </select>
                            </>
                          )}

                          <div>
                            <button
                              type='button'
                              className={
                                'btn btn-sm btn-link btn-color-gray-500 btn-active-color-primary p-0'
                              }
                              onClick={() => actionWork('after', index)}
                            >
                              <i className='bi bi-arrow-down-short fs-1 pe-0'></i>
                            </button>

                            <button
                              type='button'
                              className={
                                'btn btn-sm btn-link btn-color-gray-500 btn-active-color-primary p-0 me-2'
                              }
                              onClick={() => actionWork('before', index)}
                            >
                              <i className='bi bi-arrow-up-short fs-1  pe-0'></i>
                            </button>
                            <button
                              type='button'
                              className={
                                'btn btn-sm btn-link btn-color-gray-500 btn-active-color-primary p-0'
                              }
                              onClick={() => actionWork('delete', index)}
                            >
                              <i className='bi bi-trash fs-4'></i>
                            </button>
                          </div>
                        </div>
                      ))}
                  </div>
                  {/* <h4 className='fw-bolder mt-6 fs-6'>Project User Roles</h4> */}

                  {/* <div className='card-body card-scroll h-600px p-0 pe-8 '>
                    {users
                      .sort(
                        (a: any, b: any) =>
                          a.displayName.toLowerCase().charCodeAt(0) -
                          b.displayName.toLowerCase().charCodeAt(0)
                      )

                      .filter(
                        (u: any) => u?.model.isTeammate === true || u?.model.isManager === true
                      )

                      .map((user: any, i) => {
                        return (
                          <div
                            className='d-flex flex-stack w-100 py-4 border-bottom border-gray-300 border-bottom-dashed'
                            key={i}
                          >
                            <div className='d-flex flex-fill align-items-center'>
                              <Avatar
                                className={'symbol symbol-40px'}
                                name={user.displayName}
                                avatarURL={user?.photoURL}
                              />

                              <div className='ms-5'>
                                <a
                                  href='#'
                                  className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-2'
                                >
                                  {user.displayName}
                                </a>
                                <div className='fw-bold text-muted'>{user.email}</div>
                              </div>
                            </div>
                            <div className='me-4'>
                              {loading && selectedUser === user.id && (
                                <span className='indicator-progress' style={{display: 'block'}}>
                                  <span className='spinner-border spinner-border-sm  me-4'></span>
                                </span>
                              )}
                              {success && selectedUser === user.id && (
                                <>
                                  <span className='text-success'>
                                    <i className='bi bi-check text-success fs-2x me-2 mt-2'></i>
                                  </span>
                                </>
                              )}
                              {error && selectedUser === user.id && (
                                <>
                                  <span className='text-danger pt-1'>
                                    {error}
                                    <i className='bi bi-exclamation-circle text-danger fs-2 me-2'></i>
                                  </span>
                                </>
                              )}
                            </div>
                            <div className='me-4 '>
                              {user.model.isTeammate === true && (
                                <span className='badge badge-success p-3'>Teammate</span>
                              )}
                              {user.model.isClient === true && (
                                <span className='badge badge-warning p-3'>Client</span>
                              )}
                            </div>
                            {authorities
                              ?.filter((u: any) => u.uid === user.id)
                              .map((user: any) => (
                                <div className='me-4'>
                                  <span className='badge badge-primary p-3'>{user?.title}</span>
                                </div>
                              ))}

                            {authorities?.filter((u: any) => u.uid === user.id).length < 1 && (
                              <>
                                <div className='me-0'>
                                  <select
                                    id={user.id}
                                    name={user.id}
                                    className='form-select form-select-solid form-select-sm'
                                    onChange={handleChange}
                                  >
                                    <option selected value='remove'>
                                      No Role
                                    </option>

                                    {user.model.isTeammate === true && (
                                      <>
                                        <optgroup label='Kreatif Departman'>
                                          <option>Metin Yazarı</option>
                                          <option>Jr. Metin Yazarı</option>
                                          <option>Stratejist</option>
                                          <option>Art Direktör</option>
                                          <option>Jr. Art Direktör</option>
                                          <option>Motion Designer</option>
                                          <option>Video Editörü</option>
                                          <option>Prodüksiyon</option>
                                        </optgroup>

                                        <optgroup label='Dijital Medya Departmanı'>
                                          <option>Sosyal Medya Uzmanı</option>
                                          <option>Dijital Pazarlama Uzmanı </option>
                                          <option>CRM Sorumlusu</option>
                                        </optgroup>

                                        <optgroup label='Marka ve Proje Yönetimi Departmanı'>
                                          <option>Müşteri Temsilcisi</option>
                                          <option>Marka Yöneticisi</option>
                                          <option>Proje Yöneticisi</option>
                                        </optgroup>

                                        <optgroup label='Web ve Yazılım Departmanı'>
                                          <option>Web Proje Yöneticisi</option>
                                          <option>Arayüz Tasarımcısı</option>
                                          <option>UI/UX Tasarımcısı</option>
                                          <option>Front-End Geliştirici</option>
                                          <option>Back-End Geliştirici</option>
                                          <option>Full-Stack Geliştirici</option>
                                          <option>Mobil Uygulama Geliştirici</option>
                                          <option>Web İçerik Yöneticisi</option>
                                          <option>SEO/SEM Uzmanı</option>
                                          <option>Hosting / Domain Yöneticisi</option>
                                        </optgroup>

                                        <optgroup label='Gastronomi Departmanı'>
                                          <option>Şef</option>
                                        </optgroup>

                                        <optgroup label='Diğer'>
                                          <option>İnsan Kaynakları Uzmanı</option>
                                          <option>Listede yok.</option>
                                        </optgroup>
                                      </>
                                    )}
                                  </select>
                                </div>
                              </>
                            )}
                            {authorities?.filter((u: any) => u.uid === user.id).length > 0 && (
                              <a
                                href='#'
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm '
                                onClick={() => DeleteRole(user.id, 'teams')}
                              >
                                <KTSVG
                                  path='/media/icons/duotune/general/gen027.svg'
                                  className='svg-icon-3'
                                />
                              </a>
                            )}
                          </div>
                        )
                      })}
                  </div> */}
                </div>
              </div>
              <div className='col-md-4 border-start border-1 border-start-dotted'>
                <div className='card card-flush my-6 ps-6'>
                  <div className='d-flex fs-8 text-muted mt-6 me-8'>
                    <div className='d-flex flex-column w-50 me-2'>
                      <label className='form-label fw-bold text-primary fs-6'>
                        Project Managers
                      </label>
                      <div className='symbol-group symbol-hover ms-0 pe-4 ps-2'>
                        {users?.map(
                          (user: any) =>
                            user?.teams?.filter(
                              (uteam: any) =>
                                uteam.name === 'Project Manager' || uteam.name === 'Brand Manager'
                            ).length > 0 && (
                              <div className='overlay'>
                                <div className='overlay-wrapper'>
                                  <Avatar
                                    className={`symbol symbol-35px ${
                                      authorities?.filter((u: any) => u.uid === user.id).length > 0
                                        ? ''
                                        : 'opacity-25 opacity-75-hover'
                                    } symbol-circle`}
                                    name={user.displayName}
                                    avatarURL={user?.photoURL}
                                  />
                                </div>
                                {authorities?.filter((u: any) => u.uid === user.id).length > 0 ? (
                                  <div className='symbol symbol-30px overlay-layer symbol-circle bg-danger bg-opacity-75'>
                                    <button
                                      className='btn btn-link'
                                      type='button'
                                      onClick={() => deleteProjectManager(user)}
                                    >
                                      <i className='bi bi-x fs-2 text-white pe-0'></i>
                                    </button>
                                  </div>
                                ) : (
                                  <div className='symbol symbol-30px overlay-layer symbol-circle bg-success bg-opacity-75'>
                                    <button
                                      className='btn btn-link'
                                      type='button'
                                      onClick={() => addProjectManager(user)}
                                    >
                                      <i className='bi bi-check fs-2 text-white pe-0'></i>
                                    </button>
                                  </div>
                                )}
                              </div>
                            )
                        )}
                      </div>
                    </div>
                    <div className='d-flex flex-column w-50 ms-2'>
                      <label className='form-label fw-bold text-primary fs-6'>Project Period</label>
                      <select
                        className='form-select form-select form-select-solid'
                        defaultValue={projectData?.period}
                        {...formik.getFieldProps('period')}
                      >
                        <option selected value=''>
                          Verilen hizmetlerin periyodunu seçmelisin.
                        </option>
                        <option value='oneTime'>Project</option>
                        <option value='monthly'>Fee</option>
                        <option value='yearly'>Yearly</option>
                      </select>
                      {formik.touched.period && formik.errors.period && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.period.toString()}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='d-flex flex-column fs-8 text-muted mt-6 me-8'>
                    <label className='form-label fw-bold text-primary fs-6'>Project Budget</label>
                    <div className='d-flex'>
                      <input
                        placeholder='Bütçeyi periyoda göre girmelisin.'
                        type='number'
                        autoComplete='off'
                        {...formik.getFieldProps('budget')}
                        className='form-control form-control form-control-solid flex-grow-1 me-4'
                      />
                      <select
                        className='form-select form-select-solid flex-auto w-auto'
                        data-placeholder='Select currency'
                        defaultValue={currencies.map((c: any) => c.isBase === true && c.uid)}
                        {...formik.getFieldProps('currency')}
                      >
                        {currencies &&
                          currencies.map((c: any) => (
                            <option value={c.uid} selected={c.isBase}>
                              {c.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>

                  <div className='d-flex  fs-8 text-muted mt-6 me-8'>
                    <div className='d-flex flex-column flex-fill me-2'>
                      <label className='form-label fw-bold text-primary fs-6'>
                        Project Start Date
                      </label>

                      <input
                        type='date'
                        className='form-control form-control form-control-solid flex-grow-1 me-4'
                        placeholder=''
                        {...formik.getFieldProps('startDate')}
                      />
                    </div>
                    <div className='d-flex flex-column flex-fill ms-2'>
                      <label className='form-label fw-bold text-primary fs-6'>
                        Project End Date
                      </label>

                      <input
                        type='date'
                        className='form-control form-control form-control-solid'
                        placeholder=''
                        {...formik.getFieldProps('endDate')}
                      />
                    </div>
                  </div>

                  <div className='d-flex flex-wrap fs-8 text-muted me-8 mt-6'>
                    <label className='form-label fw-bold text-primary fs-6'>Description</label>

                    <textarea
                      className='form-control  form-control-solid '
                      rows={4}
                      data-kt-element='input'
                      placeholder='You can add a note to your leave request. (Optional)'
                      {...formik.getFieldProps('description')}
                    ></textarea>
                  </div>

                  {/* <div className=' col-md-12 mb-8 mt-10'>
                    <label className='form-label fw-bolder text-dark fs-6'>Sözleşme Detayı</label>
                    {detailArray.map((data, index) => (
                      <div className='row' key={index}>
                        <div className='col-md-10 col-10 mb-4'>
                          <input
                            type='text'
                            value={data.value}
                            name='link'
                            className='form-control bg-transparent'
                            onChange={(e) => handleInputChange(index, e)}
                            placeholder='Sözleme detaylarını madde olarak ekleyebilirsin.'
                          />
                        </div>
                        <div className='col-md-2 col-2 mb-4'>
                          <button
                            type='button'
                            className='btn btn btn-primary'
                            onClick={() => handleRemoveInput(index)}
                          >
                            Sil
                          </button>
                        </div>
                      </div>
                    ))}

                    <div className='col-md-12 mb-8'>
                      <button
                        type='button'
                        className='position-relative w-35px h-35px btn btn-sm btn-icon btn-primary h-25px w-25px'
                        onClick={handleAddInput}
                      >
                        <KTSVG path='/media/icons/add.svg' className={'svg-icon-4'} />
                      </button>
                    </div>
                  </div> */}

                  {/* <div className='col-lg-6 col-md-6 mb-8'>
                     <label className='form-label fw-bolder text-dark fs-6'>
                       Sözleşme Dosyası (PDF)
                     </label>
                     <input
                       className='form-control bg-transparent'
                       type='file'
                       id='formFileMultiple'
                       onChange={handleFileChange}
                       multiple
                     />
                     <label htmlFor='formFileMultiple' className='text-muted mt-1'>
                       Birden fazla dosya ekleyebilirsin.
                     </label>
                   </div> */}
                </div>
              </div>
            </div>
            <div className='card-footer'>
              <div className='d-flex flex-stack'>
                <div className='d-flex align-items-center'>
                  <div className='d-flex me-5 justify-content-end'>
                    <div className='form-check form-switch'>
                      <input
                        className='form-check-input w-45px h-30px'
                        type='checkbox'
                        id='web-notification-input'
                        value='on'
                        defaultChecked={isActive}
                        onChange={() => setIsActive(!isActive)}
                      />
                    </div>
                  </div>
                  <div className='d-flex flex-column '>
                    <span className='fs-5 text-dark fw-bolder'>Change Project Status</span>
                    <div className='fs-6 fw-bold text-gray-400'>
                      {isActive === true ? 'Active' : 'Passive'}
                    </div>
                  </div>
                </div>

                <button
                  type='submit'
                  className={`btn btn-primary  float-end ${success && 'btn-success'} mb-8`}
                  disabled={formik.isSubmitting || !formik.isValid || loading || success}
                >
                  {success && (
                    <>
                      <span className='badge badge-success me-5  px-6'></span>
                      <span className='' style={{display: 'block'}}>
                        <i className='bi bi-check fs-2'></i>
                        Success
                      </span>
                    </>
                  )}
                  {!success && (
                    <>
                      {!loading && 'Save Changes'}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </>
                  )}
                </button>
              </div>

              {error && (
                <>
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{error}</span>
                    </div>
                  </div>
                </>
              )}
            </div>
          </form>
        </div>
      )}
      <Services projectData={projectData} />
    </>
  )
}
