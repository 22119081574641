/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, {useState, useEffect, useRef} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../helpers'
import {Dropdown1} from '../../../content/dropdown/Dropdown1'
import useAutosizeTextArea from '../../../../../app/hooks/useAutosizeTextArea'
import {useAuthContext} from '../../../../../app/modules/auth'

import {db, storage} from '../../../../../firebase/config'
import {
  addDoc,
  arrayUnion,
  collection,
  doc,
  serverTimestamp,
  setDoc,
  updateDoc,
} from 'firebase/firestore'
import {ref, getDownloadURL, uploadBytesResumable} from 'firebase/storage'

import {useCollectionWithParam} from '../../../../../app/hooks/useCollectionWithParam'

import {Avatar} from '../../../content/user/Avatar'
import moment from 'moment'
import {v4 as uuidv4} from 'uuid'
import {Button, Toast} from 'react-bootstrap'
import {sendNotification} from '../../../../../app/hooks/sendNotification'
import {useCollection} from '../../../../../app/hooks/useCollection'

export function ChatRoom({room}) {
  const {user} = useAuthContext()
  const {documents: users} = useCollection('users')

  const [message, setMessage] = useState<string>('')
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  // test
  const [show, setShow] = useState(false)

  const [file, setFile] = useState<File>()
  const [fileError, setFileError] = useState<any | null>(null)
  const [fileURL, setFileURL] = useState<any | null>(null)
  const [fileDownloadURL, setFileDownloadURL] = useState<any | null>(null)
  const [notificationTokens, setNotificationTokens] = useState<any | null>(null)

  const scroll = useRef<HTMLDivElement>(null)

  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    const data: string[] = []
    users &&
      room &&
      room?.hasRole?.map((uid) => {
        users
          .filter(
            (u: any) =>
              u.id === uid && u.notification?.isPushNotification === true && u.id !== user.uid
          )
          .map((us: any) => data.push(us.notification.pushNotificationToken))

        // setNotificationTokens([...notificationTokens, users.find((user: any) => user.uid === uid)])
      })

    // setNotificationUsers(
    //   users.filter((u: any) => room?.hasRole?.filter((u) => u !== user.uid).includes(u.uid))
    // )
    // console.log(
    //   users.filter((us: any) => room?.hasRole.filter((u) => u !== user.uid).includes(us.uid))
    // )
    setNotificationTokens(data)
  }, [users])

  const {documents: allMessages} = useCollectionWithParam(
    'chat/' + room.id + '/messages/',
    'createdAt',
    100
  )

  function setMessageRead(messages) {
    allMessages.map((message: any, i) => {
      const changeRef = doc(db, 'chat/', room.id, '/messages', message.id)

      message.uid !== user.uid &&
        updateDoc(changeRef, {
          isRead: arrayUnion(user.uid),
        })
    })
    const changeRef = doc(db, 'chat/', room?.id)
    updateDoc(changeRef, {
      isRead: arrayUnion(user.uid),
    })
  }

  useAutosizeTextArea(textAreaRef.current, message)

  const handleChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    const val = evt.target?.value
    setMessage(val)
  }

  const handleFileChange = (event: React.FormEvent) => {
    const files = (event.target as HTMLInputElement).files

    if (files && files.length > 0) {
      let file = files[0]
      if (file.size > 5000000) {
        setFileError('Avatar file size must be less then 5mb')
        return
      }
      setFile(file)
      setFileURL(URL.createObjectURL(file))
    }
  }

  const handleFileDelete = () => {
    setFile(undefined)
    setFileURL(null)
  }

  const sendMessage = async () => {
    if (message.trim() === '') {
      return
    }
    setError(false)
    setLoading(true)

    const {uid, displayName, photoURL} = user
    const roomPath = 'chat/' + room.id
    console.log(roomPath)
    if (file) {
      let fileName = `${uuidv4()}_${file.name}`
      const storageRef = ref(storage, `users/${user.uid}/${fileName}`)
      const uploadTask = uploadBytesResumable(storageRef, file)

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
          //setProgresspercent(progress)
        },
        (error) => {
          alert(error)
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setFileDownloadURL(downloadURL)

            addDoc(collection(db, roomPath, '/messages'), {
              message: message,
              displayName: displayName,
              photoURL: photoURL,
              file: downloadURL,
              createdAt: serverTimestamp(),
              isRead: [uid],
              uid,
            }).then(() => {
              setDoc(
                doc(db, roomPath),
                {
                  message: message,
                  displayName: displayName,
                  photoURL: photoURL,
                  createdAt: serverTimestamp(),
                  isRead: [uid],
                  uid,
                },
                {merge: true}
              )
                .then(() => {
                  let to = notificationTokens
                  let notification = {
                    title: displayName,
                    body: message,
                    icon: photoURL,
                    click_action: 'https://bake-agency-app.web.app/',
                  }
                  sendNotification(to, notification)
                  setSuccess(true)
                  setLoading(false)
                  setFile(undefined)
                  setFileURL(null)
                  setMessage('')
                  setTimeout(() => {
                    setSuccess(false)
                  }, 500)
                })
                .catch((error) => {
                  setError(true)
                  setLoading(false)
                })
            })
          })
        }
      )
    } else {
      console.log(roomPath)
      const dbRef = collection(db, roomPath, '/messages')
      addDoc(dbRef, {
        message: message,
        displayName: displayName,
        photoURL: photoURL,
        createdAt: serverTimestamp(),
        isRead: [uid],
        uid,
      })
        .then((dbRef) => {
          setDoc(
            doc(db, roomPath),
            {
              lastMessageId: dbRef.id,
              message: message,
              displayName: displayName,
              photoURL: photoURL,
              createdAt: serverTimestamp(),
              isRead: [uid],
              uid,
            },
            {merge: true}
          )
            .then(() => {
              let to = notificationTokens
              let notification = {
                title: displayName,
                body: message,
                icon: photoURL,
                click_action: 'https://bake-agency-app.web.app/',
              }
              sendNotification(to, notification)
              setSuccess(true)
              setLoading(false)
              setMessage('')
              setTimeout(() => {
                setSuccess(false)
              }, 500)
            })
            .catch((error) => {
              setError(true)
              setLoading(false)
            })
        })
        .catch((error) => {
          setError(true)
          setLoading(false)
        })
    }
  }

  const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault()
      sendMessage()
    }
  }

  useEffect(() => {
    scroll.current?.scroll({
      top: scroll.current.scrollHeight,
      behavior: 'smooth',
    })
    setMessageRead(allMessages)
  }, [allMessages])

  return (
    <>
      <header className='App-header'></header>
      <div
        className='card-body card-scroll vh-100 pb-280'
        style={{paddingBottom: '320px'}}
        ref={scroll}
      >
        {allMessages?.map((message: any, i, arr) => (
          <div key={i}>
            <div
              className={`d-flex d-flex w-100 ${
                i > 0 && message?.uid !== arr[i - 1]['uid'] ? 'mt-6' : 'my-1'
              } 
            ${
              // {***} UID olarak değişmesi gerekiyor.
              message?.uid === user.uid ? 'flex-row-reverse' : ''
            }`}
              key={i}
            >
              <div className='d-flex justify-content-between  align-items-start  '>
                {/* {***} UID olarak değişmesi gerekiyor. */}
                {message?.uid !== user.uid && (
                  <>
                    <div className='d-flex min-w-35px mb-2 me-2 '>
                      {i == 0 ? (
                        <>
                          <Avatar
                            className='symbol symbol-35px me-2'
                            name={message.displayName}
                            avatarURL={message.photoURL}
                          />
                        </>
                      ) : (
                        <>
                          {i > 0 && message?.uid !== arr[i - 1]['uid'] && (
                            // {***} UID olarak değişmesi gerekiyor.
                            <>
                              <Avatar
                                className='symbol symbol-35px me-2'
                                name={message.displayName}
                                avatarURL={message.photoURL}
                              />
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </>
                )}

                <div className='d-flex flex-column w-100'>
                  {i === 0 ? (
                    <>
                      <div className='d-flex w-100 align-items-top'>
                        {/* {***} UID olarak değişmesi gerekiyor. */}
                        {message?.uid !== user.uid ? (
                          <>
                            <a
                              href='#'
                              className='fs-7 fw-bold text-gray-700 text-hover-primary me-1 flex-fill '
                            >
                              {message.displayName}
                            </a>
                          </>
                        ) : (
                          <>
                            <span className='fs-7 fw-bold text-gray-700  me-1 flex-fill '>Me</span>
                          </>
                        )}
                        {message?.uid !== user.uid && (
                          <>
                            <span className='text-muted fs-8 ms-auto'>
                              {message?.createdAt &&
                                moment
                                  .utc(new Date(message?.createdAt.toDate()))
                                  .local()
                                  .startOf('s')
                                  .fromNow(true)}
                            </span>
                          </>
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      {i > 0 && message?.uid !== arr[i - 1]['uid'] && (
                        <>
                          <div className='d-flex w-100 align-items-top'>
                            {/* {***} UID olarak değişmesi gerekiyor. */}
                            {message?.uid !== user.uid ? (
                              <>
                                <a
                                  href='#'
                                  className='fs-7 fw-bold text-gray-700 text-hover-primary me-1 flex-fill '
                                >
                                  {message.displayName}
                                </a>
                              </>
                            ) : (
                              <>
                                <span className='fs-7 fw-bold text-gray-700  me-1 flex-fill '>
                                  Me
                                </span>
                              </>
                            )}
                            {message?.uid !== user.uid && (
                              <>
                                <span className='text-muted fs-8 ms-auto'>
                                  {message?.createdAt &&
                                    moment
                                      .utc(new Date(message?.createdAt.toDate()))
                                      .local()
                                      .startOf('s')
                                      .fromNow(true)}
                                </span>
                              </>
                            )}
                          </div>
                        </>
                      )}
                    </>
                  )}

                  <div
                    className='p-1 rounded fw-semibold bg-light min-w-50px mw-250px text-start '
                    data-kt-element='message-text'
                  >
                    {message.file && (
                      <img src={message.file} className='image-input-wrapper rounded-4 p-2 w-100' />
                    )}
                    <div className={`d-block p-2 text-primary fw-bold  mw-250px`}>
                      {message['message']}

                      {i > 0 && message?.uid === arr[i - 1]['uid'] && (
                        <>
                          <span className='text-muted fs-9 ms-4 pt-4 fw-light float-end'>
                            {message?.createdAt &&
                              moment
                                .utc(new Date(message?.createdAt.toDate()))
                                .local()
                                .startOf('minutes')
                                .fromNow(true)}
                          </span>
                        </>
                      )}

                      {i > 0 && message?.uid !== arr[i - 1]['uid'] && message?.uid === user.uid && (
                        <>
                          <span className='text-muted fs-9 ms-4 pt-4 fw-light float-end'>
                            {message?.createdAt &&
                              moment
                                .utc(new Date(message?.createdAt.toDate()))
                                .local()
                                .startOf('seconds')
                                .fromNow(true)}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div
        className='card-footer bg-white position-fixed bottom-0 d-flex align-items-center p-4'
        style={{width: '-webkit-fill-available'}}
      >
        <textarea
          className='form-control form-control-flush pe-2'
          style={{resize: 'none'}}
          rows={1}
          data-kt-element='input'
          placeholder='Type a message'
          value={message}
          onChange={handleChange}
          onKeyDown={onEnterPress}
          ref={textAreaRef}
        ></textarea>

        <div className='d-flex'>
          <div className='image-input me-3'>
            <div
              className='image-input-wrapper w-40px h-40px'
              style={{backgroundImage: `url(${fileURL})`}}
            ></div>

            {!file && (
              <label
                className='btn btn-sm btn-icon btn-active-color-primary bg-hover-light h-100 w-100'
                data-kt-image-input-action='change'
                data-bs-toggle='tooltip'
                aria-label='Add Image'
                data-bs-original-title='Add Image'
                title='Add Image'
                data-kt-initialized='1'
                style={{left: '50%', top: '50%'}}
              >
                <i className='bi bi-paperclip fs-4'></i>
                <input
                  type='file'
                  name='avatar'
                  accept='.png, .jpg, .jpeg'
                  onChange={(event) => {
                    handleFileChange(event)
                  }}
                />
                <input type='hidden' name='avatar_remove' onClick={handleFileDelete} />
              </label>
            )}
            {file && (
              <span
                className='btn btn-icon btn-circle btn-active-color-primary bg-hover-light w-20px h-20px bg-body shadow'
                data-kt-image-input-action='remove'
                data-bs-toggle='tooltip'
                aria-label='Cancel avatar'
                data-bs-original-title='Cancel avatar'
                title='Cancel Avatar'
                onClick={handleFileDelete}
                style={{left: '0', top: '0'}}
              >
                <i className='bi bi-x fs-4'></i>
              </span>
            )}
          </div>
          <button
            type='button'
            className={`btn btn-primary btn-sm ${success && 'btn-success'}`}
            disabled={loading || success || message === ''}
            data-kt-element='send'
            onClick={sendMessage}
          >
            {success && (
              <>
                <span className='indicator-progress' style={{display: 'block'}}>
                  Sent!
                </span>
              </>
            )}
            {!success && (
              <>
                {!loading && 'Send'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    <span className='spinner-border spinner-border-sm align-middle'></span>
                  </span>
                )}
              </>
            )}
          </button>
        </div>
      </div>
    </>
  )
}
