/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {useIntl} from 'react-intl'

const HrLayout = () => {
  const intl = useIntl()

  const agencyBGColor = '#ffda44'
  //const agencyColor = '#ffda44'
  const agencyTextColor = '#122d44'

  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div>
      {/* begin::Aside */}
      <div className=' flex-lg-row-fluid d-lg-block'>
        {/* begin::Content */}
        <div
          className='d-flex flex-column flex-center py-10 px-5 px-md-15 w-100 mb-10'
          style={{backgroundColor: agencyBGColor}}
        >
          {/* begin::Logo */}
          <Link to='/' className='mb-10'>
            <img alt='Logo' src={toAbsoluteUrl('/media/logos/fok-logo.svg')} className='h-100px' />
          </Link>
          {/* end::Logo */}

          {/* begin::Image */}
          {/* <img
            className='mx-auto w-250px w-md-50 w-xl-400px mb-10 mb-lg-15'
            src={toAbsoluteUrl('/media/misc/auth-screens.png')}
            alt=''
          /> */}
          {/* end::Image */}

          {/* begin::Title */}
          <h1 className='fs-2qx fw-bolder text-center mb-7' style={{color: agencyTextColor}}>
            {/* {intl.formatMessage({id: 'AUTH.GENERAL.AGENCY.MESSAGE.TITLE'})} */}O Fok Sen Misin?
          </h1>
          {/* end::Title */}

          {/* begin::Text */}
          <div className='fs-base text-center mb-10' style={{color: agencyTextColor}}>
            Sen de derin sularda yüzmeyi severim, en taze fikirlerle en canlı balıkları yakalarım
            diyorsan aramıza katılmak üzere aşağıdaki formu doldurabilirsin. Başvuru formunu
            doldurup kaydettiğinde, CV havuzumuza gireceksin. Böylece ihtiyaç halinde, birlikte
            derin sulara dalmak için ilk seninle iletişime geçeceğiz.
          </div>
          {/* end::Text */}
        </div>
        <Outlet />
        {/* end::Content */}
      </div>
      {/* end::Aside */}
    </div>
  )
}

export {HrLayout}
