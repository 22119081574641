import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import { useLogin } from '../core/useLogin'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(6, 'Minimum 6 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(6, 'Minimum 6 symbols')
    .max(30, 'Maximum 30 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
	const {login, error} = useLogin()

	const intl = useIntl()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, bag) => {
      setLoading(true)
      login(values.email, values.password)
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-11 w-100'>
        <h1 className='text-dark fw-bolder mb-3'>{intl.formatMessage({id: 'APP.LOGIN.HEADER.TEXT'})}</h1>
        <div className='text-gray-500 fw-semibold fs-6'>{intl.formatMessage({id: 'APP.LOGIN.SUBHEADER.TEXT'})}</div>
      </div>
      {/* begin::Heading */}

      {error && (
        <div className='mb-10 bg-gray-300 p-6 rounded'>
          <div className='text-gray-700 font-weight-bold'>
          {
            {
              'auth/wrong-password': <>{intl.formatMessage({id: 'AUTH.VALIDATION.INVALID_LOGIN'})}</>,
              'auth/user-not-found': <>{intl.formatMessage({id: 'AUTH.VALIDATION.INVALID_LOGIN'})}</>
            }[error] || <div>{error}</div>
          }
          </div>
        </div>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-8 w-100'>
        <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({id: 'APP.GENERAL.EMAIL'})}</label>
        <input
          placeholder={intl.formatMessage({id: 'AUTH.INPUT.EMAIL'})}
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>{intl.formatMessage({id: 'APP.GENERAL.PASSWORD'})}</label>
        <input
					placeholder={intl.formatMessage({id: 'AUTH.INPUT.PASSWORD'})}
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />

        {/* begin::Link */}
        <Link to='/auth/forgot-password' className='link-primary'>
				{intl.formatMessage({id: 'AUTH.GENERAL.FORGOT_BUTTON'})}
        </Link>
        {/* end::Link */}
      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          
          {!loading && error === null && <span className='indicator-label'>{intl.formatMessage({id: 'APP.GENERAL.CONTINUE'})}</span>}
          {loading && error === null && (
            <span className='indicator-progress' style={{display: 'block'}}>
              {intl.formatMessage({id: 'APP.GENERAL.WAITMESSAGE'})}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
          {loading && error !== null && <span className='indicator-label'>{intl.formatMessage({id: 'APP.GENERAL.CONTINUE'})}</span>}

        </button>
      </div>
      {/* end::Action */}

      <div className='text-gray-500 text-center fw-semibold fs-6'>
        {intl.formatMessage({id: 'AUTH.GENERAL.NOTMEMBERYET'})}{' '}
        <Link to='/auth/registration' className='link-primary'>
          {intl.formatMessage({id: 'AUTH.GENERAL.SIGNUP_BUTTON'})}
        </Link>
      </div>
    </form>
  )
}
