import {addDoc, collection, doc, serverTimestamp, setDoc} from 'firebase/firestore'
import {useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {db} from '../../../../../../firebase/config'
import {useAuthContext} from '../../../../auth'

/* eslint-disable jsx-a11y/anchor-is-valid */
export function NewProjectDropdown() {
  const {id} = useParams()
  const clientUID = id
  const [projectName, setProjectName] = useState('')
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState('')

  const navigate = useNavigate()
  const {user} = useAuthContext()

  const handleChange = (evt) => {
    const val = evt.target?.value
    setProjectName(val)
  }

  const handleClick = () => {
    setLoading(true)

    const projectData = {
      name: projectName,
      createdAt: serverTimestamp(),
      createdUID: user.uid,
      clientUID: clientUID,
      isActive: false,
      authorities: [],
    }

    addDoc(collection(db, 'projects'), projectData)
      .then((data) => {
        const docRef = doc(db, 'clients', clientUID!, '/projects/', data.id)

        setDoc(docRef, projectData)
          .then(() => {
            setSuccess(true)
            setLoading(false)
            navigate('/client/' + id + '/projects/' + data.id + '/settings')
            setTimeout(() => {
              setSuccess(false)
            }, 500)
          })
          .catch((err) => {
            setError(err.message)
            console.log(err.message)
            setLoading(false)
          })
      })
      .catch((err) => {
        setError(err.message)
        console.log(err.message)
        setLoading(false)
      })
  }

  return (
    <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
      <div className='px-7 py-5'>
        <div className='fs-5 text-dark fw-bolder'>Create Project</div>
      </div>

      <div className='separator border-gray-200'></div>

      <div className='px-7 py-5'>
        <div className='mb-10'>
          <label className='form-label fw-bold'>Project Name</label>

          <div className='fv-row'>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
              placeholder='Write project name'
              value={projectName}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className='mb-10'></div>

        <div className='d-flex justify-content-end'>
          <button
            type='reset'
            className='btn btn-sm btn-light btn-active-light-primary me-2'
            onClick={() => setProjectName('')}
            data-kt-menu-dismiss='true'
          >
            Cancel
          </button>

          <button
            type='submit'
            className={`btn btn-sm btn-primary ${success && 'btn-success'}`}
            disabled={loading || projectName === ''}
            onClick={handleClick}
          >
            {success && (
              <>
                <span className='badge badge-success me-5 px-6'></span>
                <span className='' style={{display: 'block'}}>
                  <i className='bi bi-check fs-2'></i>
                  Success
                </span>
              </>
            )}
            {!success && (
              <>
                {!loading && 'Continue'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  )
}
