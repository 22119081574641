import React, {useEffect, useState} from 'react'

import {doc, getDoc} from 'firebase/firestore'
import {Link, useParams} from 'react-router-dom'
import clsx from 'clsx'
import {Contents} from './components/Contents'
import {SocialMediaChannelModels} from './components/models/SocialMediaModels'
import {useCollection} from '../../../../../../hooks/useCollection'
import {db} from '../../../../../../../firebase/config'

export function SocialMedia() {
  const {id, projectID} = useParams()
  const clientUID = id ? id : ''
  const projectUID = projectID ? projectID : ''
  const [projectData, setProjectData] = useState<any | null>(null)
  const {documents: users} = useCollection('users')
  useEffect(() => {
    let docRef = doc(db, 'clients', clientUID, 'projects', projectUID)
    getDoc(docRef)
      .then((data) => {
        setProjectData(data.data())
      })
      .catch((error) => {})
  }, [projectUID])

  return (
    <>
      {/* <div className='card'>
        <div className='card-body'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='d-flex flex-wrap'>
                <div className='d-flex flex-column bg-light rounded align-items-center me-3 h-90px min-w-100px justify-content-start'>
                  <span className='text-primary-light flex-center fw-bolder fs-2 pt-3 px-4'>
                    0/{projectData?.services.socialMedia?.settings?.monthlyContent}
                  </span>
                  <div className='symbol-60px flex-column rounded-bottom  px-4 py-2'>
                    <span className='d-flex flex-column align-items-center justify-content-center text-center'>
                      <span className='fs-7 fw-bolder'>Content</span>
                      <span className='fs-9'>Month</span>
                    </span>
                  </div>
                </div>
                {projectData?.services?.socialMedia?.settings?.channels?.map(
                  (channel: any, i) =>
                    channel?.content > 0 && (
                      <div
                        className='d-flex flex-column bg-light rounded align-items-center me-3 h-90px   justify-content-start'
                        key={i}
                      >
                        <a
                          href={channel?.URL}
                          target='_blank'
                          className={`btn btn-icon btn-sm btn-${channel.name.toLowerCase()} w-30px h-30px mt-3`}
                          rel='noreferrer'
                          title={channel?.name}
                        >
                          <i
                            className={`${
                              SocialMediaChannelModels.find((c: any) => c.name === channel.name)
                                ?.icon
                            } fs-4`}
                          ></i>
                          {}
                        </a>
                        <div className='symbol-60px flex-column rounded-bottom  px-4 py-2'>
                          <span className='d-flex flex-column align-items-center justify-content-center text-center'>
                            <span className='fs-7 fw-bolder'>0/{channel.content}</span>
                            <span className='fs-9'>Content</span>
                          </span>
                        </div>
                      </div>
                    )
                )}
              </div>
            </div>
            <div className='col-md-6 d-flex justify-content-end'>
              <div className='d-flex flex-column bg-light rounded align-items-center h-90px justify-content-end'>
                <span className='text-primary-light flex-center fw-bold fs-4 pt-4 px-4'>
                  Channels
                </span>
                <div className='symbol-group symbol-hover ms-0 pe-4 ps-6 py-4'>
                  {projectData?.services?.socialMedia?.settings?.channels?.map(
                    (channel: any, i) => (
                      <a
                        href={channel?.URL}
                        target='_blank'
                        className={`btn btn-icon btn-sm btn-${channel.name.toLowerCase()} symbol w-30px h-30px symbol-circle`}
                        rel='noreferrer'
                        title={channel?.name}
                        key={i}
                      >
                        <i
                          className={`${
                            SocialMediaChannelModels.find((c: any) => c.name === channel.name)?.icon
                          } fs-6`}
                        ></i>
                      </a>
                    )
                  )}
                </div>
              </div>
              {/* <div className='d-flex flex-column bg-light rounded align-items-center me-3 h-90px justify-content-end'>
                <span className='text-primary-light flex-center fw-bold fs-4 pt-4 px-4'>Teams</span>
                <div className='symbol-group symbol-hover ms-0 pe-4 ps-6 py-4'>
                  {clientDetail?.teams?.map((teammate: any) =>
                    users.map(
                      (user: any, i) =>
                        user.id === teammate.uid && (
                          <Link to={`/profile/${user.id}/overview`} key={i}>
                            <Avatar
                              className={'symbol symbol-30px symbol-circle'}
                              name={user.displayName}
                              avatarURL={user?.photoURL}
                            />
                          </Link>
                        )
                    )
                  )}
                </div>
              </div>
              <div className='d-flex flex-column bg-light rounded align-items-center h-90px justify-content-end float-end'>
                <span className='text-primary-light flex-center fw-bold fs-4 pt-4 px-4'>
                  Clients
                </span>
                <div className='symbol-group symbol-hover ms-0 pe-4 ps-6 py-4'>
                  {clientDetail?.users?.map((u: any) =>
                    users.map(
                      (user: any, i) =>
                        user.id === u.uid && (
                          <Link to={`/profile/${user.id}/overview`} key={i}>
                            <Avatar
                              className={'symbol symbol-30px symbol-circle'}
                              name={user.displayName}
                              avatarURL={user?.photoURL}
                            />
                          </Link>
                        )
                    )
                  )}
                </div>
              </div> 
            </div>
          </div>
        </div>
      </div> */}

      <Contents socialMediaSettings={projectData?.services.socialMedia} />
    </>
  )
}
