import {useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useAuthContext} from '../../../../auth'

//firebase
import {setDoc, doc, addDoc, collection, getDoc} from 'firebase/firestore'
import {db, storage} from '../../../../../../firebase/config'
import {ref, getDownloadURL, uploadBytesResumable} from 'firebase/storage'

//uuid
import {v4 as uuidv4} from 'uuid'
import {useParams} from 'react-router-dom'
import {useCollection} from '../../../../../hooks/useCollection'

const profileDetailsSchema = Yup.object().shape({
  brandName: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(25, 'Maximum 25 symbols')
    .required('Brand name is required'),
  companyName: Yup.string().required('Company name is required'),
  address: Yup.string(),
  taxOffice: Yup.string(),
  taxNumber: Yup.string(),
})

const ClientSettings: React.FC = () => {
  const [progresspercent, setProgresspercent] = useState(0)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(false)
  const {user} = useAuthContext()

  const [logo, setLogo] = useState<File>()
  const [logoError, setLogoError] = useState<any | null>(null)
  const [logoURL, setlogoURL] = useState<any | null>(null)
  const [logoDownloadURL, setLogoDownloadURL] = useState<any | null>(null)

  const {id} = useParams()
  const clientUID = id ? id : ''
  const [clientDetail, setClientDetail] = useState<any | null>(null)
  const {documents: clientIndustries} = useCollection('agency/data/clientIndustries')

  const [selectedIndustries, setSelectedIndustries] = useState<any | null>([])

  useEffect(() => {
    let docRef = doc(db, 'clients', clientUID)
    getDoc(docRef)
      .then((data) => {
        setClientDetail(data.data())
        setSelectedIndustries(data.data()?.industries ? data.data()?.industries : [])
        setlogoURL(data.data()?.logo)
      })
      .catch((error) => {})
  }, [clientUID])

  const handleLogoChange = (event: React.FormEvent) => {
    setLogoError(null)
    const files = (event.target as HTMLInputElement).files

    if (files && files.length > 0) {
      let file = files[0]
      if (file.size > 100000) {
        setLogoError('Logo file size must be less then 100kb')
        return
      }
      setLogo(file)
      setlogoURL(URL.createObjectURL(file))
    }
  }

  const handleLogoDelete = () => {
    setLogo(undefined)
    setlogoURL(null)
  }

  const addIndustries = (id) => {
    const industry: any = clientIndustries.find((c: any) => c.id === id)
    // setSelectedServices({service: service?.name, serviceID: service?.id})
    setSelectedIndustries((selectedServices) => [...selectedServices, industry])
  }

  const formik = useFormik({
    initialValues: {
      logo: '',
      brandName: clientDetail?.brandName ? clientDetail.brandName : '',
      companyName: clientDetail?.companyName ? clientDetail.companyName : '',
      address: clientDetail?.address ? clientDetail.address : '',
      taxOffice: clientDetail?.taxOffice ? clientDetail.taxOffice : '',
      taxNumber: clientDetail?.taxNumber ? clientDetail.taxNumber : '',
      type: clientDetail?.type ? clientDetail.type : '',
    },
    enableReinitialize: true,
    validationSchema: profileDetailsSchema,
    onSubmit: (values) => {
      setLoading(true)

      if (logo) {
        let fileName = `${uuidv4()}_${logo.name}`
        const storageRef = ref(storage, `clients/${id}/${fileName}`)
        const uploadTask = uploadBytesResumable(storageRef, logo)

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
            setProgresspercent(progress)
          },
          (error) => {
            alert(error)
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              setLogoDownloadURL(downloadURL)
              const docRef = collection(db, 'clients', clientUID, 'media')
              const data = {
                from: 'settings',
                name: logo.name,
                size: logo.size,
                type: logo.type,
                url: `users/${user.uid}/${fileName}`,
                downloadURL,
              }
              addDoc(docRef, data)
                .then(() => {
                  const docRef = doc(db, 'clients', clientUID)
                  let data = {logo: downloadURL}
                  setDoc(docRef, data, {merge: true})
                    .then(() => {
                      setLoading(false)
                    })
                    .catch((error) => {
                      setError(error.message)
                    })
                  const chatLogo = doc(db, 'chat', clientUID)
                  setDoc(chatLogo, {settings: {logo: downloadURL}}, {merge: true})
                })
                .catch((error) => {
                  setLoading(false)
                  setError(error.message)
                })
            })
          }
        )
      }
      const docRef = doc(db, 'clients', clientUID)
      let data = {
        brandName: values.brandName,
        companyName: values.companyName,
        address: values.address,
        taxOffice: values.taxOffice,
        taxNumber: values.taxNumber,
        type: values.type,
        industries: selectedIndustries,
      }
      setDoc(docRef, data, {merge: true})
        .then(() => {
          setLoading(false)
          setSuccess(true)
          setTimeout(() => {
            setSuccess(false)
          }, 3000)
        })
        .catch((error) => {
          setError(error.message)
        })
    },
  })

  return (
    <div className='card mb-5'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder text-primary m-0'>Client Settings</h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse'>
        <form onSubmit={formik.handleSubmit} className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Logo</label>
              <div className='col-lg-8'>
                <div className='image-input image-input-outline' data-kt-image-input='false'>
                  <div
                    className='image-input-wrapper w-125px h-125px  text-center  d-flex align-items-center'
                    style={{backgroundImage: `url(${logoURL})`}}
                  >
                    {!logoURL && (
                      <span className='fs-8 w-100'>
                        <span className='fs-6 fw-semibold d-block'>Logo</span> Square 250 x 250
                      </span>
                    )}
                  </div>

                  <label
                    className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                    data-kt-image-input-action='change'
                    data-bs-toggle='tooltip'
                    aria-label='Change logo'
                    data-bs-original-title='Change logo'
                    title='Change Logo'
                    data-kt-initialized='1'
                  >
                    <i className='bi bi-pencil-fill fs-7'></i>
                    <input
                      type='file'
                      name='logo'
                      accept='.png, .jpg, .jpeg'
                      onChange={(event) => {
                        handleLogoChange(event)
                      }}
                    />
                    <input type='hidden' name='logo_remove' onClick={handleLogoDelete} />
                  </label>

                  <span
                    className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                    data-kt-image-input-action='cancel'
                    data-bs-toggle='tooltip'
                    aria-label='Cancel logo'
                    data-bs-original-title='Cancel logo'
                    title='Cancel Logo'
                    onClick={handleLogoDelete}
                  >
                    <i className='bi bi-x fs-2'></i>
                  </span>

                  <span
                    className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                    data-kt-image-input-action='remove'
                    data-bs-toggle='tooltip'
                    aria-label='Remove logo'
                    data-bs-original-title='Remove logo'
                    title='Remove Logo'
                    onClick={handleLogoDelete}
                  >
                    <i className='bi bi-x fs-2'></i>
                  </span>
                </div>
                <div className='form-text'>Allowed file types: png, jpg, jpeg. Size: Square</div>
                {logoError && <div className='form-text text-danger'>{logoError}</div>}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Brand name</label>
              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Brand name'
                  {...formik.getFieldProps('brandName')}
                />
                {formik.touched.brandName && formik.errors.brandName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.brandName.toString()}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Company name</label>
              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Company name'
                  {...formik.getFieldProps('companyName')}
                />
                {formik.touched.companyName && formik.errors.companyName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.companyName.toString()}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Address</label>
              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Company address'
                  {...formik.getFieldProps('address')}
                />
                {formik.touched.address && formik.errors.address && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.address.toString()}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Tax Office</label>
              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Tax Office'
                  {...formik.getFieldProps('taxOffice')}
                />
                {formik.touched.taxOffice && formik.errors.taxOffice && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.taxOffice.toString()}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Tax Number</label>
              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Tax Number'
                  {...formik.getFieldProps('taxNumber')}
                />
                {formik.touched.taxNumber && formik.errors.taxNumber && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.taxNumber.toString()}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Compnay Type</label>

              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-lg fw-semibold'
                  {...formik.getFieldProps('type')}
                >
                  <option value=''>Select Type Client</option>
                  <option value='Client'>Client</option>
                  <option value='Vendor'>Vendor</option>
                  <option value='Partner'>Partner</option>
                </select>
                {formik.touched.type && formik.errors.type && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.type.toString()}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Industries</label>

              <div className='col-lg-8 fv-row'>
                <select
                  name='services'
                  className='form-select form-select-lg form-select-solid'
                  onChange={(e) => e.target.value !== 'Select' && addIndustries(e.target.value)}
                >
                  <option value={'Select'} selected>
                    Select Industry
                  </option>
                  {clientIndustries &&
                    clientIndustries
                      .filter(
                        (industry: any) =>
                          selectedIndustries.filter((s: any) => s.id === industry.id) < 1
                      )

                      .map((service: any) => <option value={service.id}>{service.name}</option>)}
                </select>
                <div className='d-flex  fs-8 text-muted my-2'>
                  {selectedIndustries?.map((d: any) => (
                    <button
                      className='btn btn-light btn-sm me-2 '
                      type='reset'
                      onClick={() => {
                        setSelectedIndustries(selectedIndustries.filter((a) => a.id !== d.id))
                      }}
                    >
                      {d.name}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            {error && <span className='badge badge-danger me-10'>{error}</span>}

            <button
              type='submit'
              className={`btn btn-primary ${success && 'btn-success'}`}
              disabled={loading || success}
            >
              {success && (
                <>
                  <span className='badge badge-success me-5 px-6'></span>
                  <span className='' style={{display: 'block'}}>
                    <i className='bi bi-check fs-2'></i>
                    Success
                  </span>
                </>
              )}
              {!success && (
                <>
                  {!loading && 'Save Changes'}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export {ClientSettings}
