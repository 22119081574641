/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import InputMask from 'react-input-mask'

import {KTSVG} from '../../../../_metronic/helpers'
import {v4 as uuidv4} from 'uuid'

import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'

//Firebase
import {db, storage} from '../../../../firebase/config'
import {addDoc, collection, doc, serverTimestamp, setDoc} from 'firebase/firestore'
import {ref, uploadBytesResumable, getDownloadURL} from 'firebase/storage'

const jobTypes = [
  {title: 'İş Başvurusu', desc: '(Tam Zamanlı)', value: 'work'},
  {title: 'Proje Bazlı', desc: '(Freelancer)', value: 'freelance'},
  {title: 'Staj', desc: '(Zorunlu Staj)', value: 'staj'},
]

const initialValues = {
  displayName: '',
  email: '',
  phoneNumber: '',
  city: '',
  title: '',
  year: '',
  message: '',
  workType: '',
  link: [],
  acceptTerms: false,
}

const registrationSchema = Yup.object().shape({
  displayName: Yup.string()
    .min(6, 'Minimum 6 karakter olmalı.')
    .max(50, 'Maksimum 50 karakter olmalı.')
    .required('Tam adınızı girmeniz gerekli.'),
  email: Yup.string().email('E-posta formatı hatalı.').required('E-posta girmeniz gerekli.'),
  phoneNumber: Yup.string().required('Cep telefonu girmeniz gerekli.'),
  city: Yup.string()
    .min(3, 'Minimum 3 karakter olmalı.')
    .max(25, 'Maksimum 25 karakter olmalı.')
    .required('Yaşadığınız şehri girmeniz gerekli.'),
  title: Yup.string().required('İş tanımını seçmen gerekiyor.'),
  acceptTerms: Yup.bool().required('KVKK Aydınlatma metnini okuyarak onaylaman gerekiyor.'),
})

function RadioGroup({options, selectedOption, onChange}) {
  return (
    <>
      <div className=' d-flex text-center '>
        {options.map((option, index) => (
          <div
            key={index}
            className='form-check flex-column form-check-custom form-check-solid rounded-1 border px-2 py-4 pb-8 w-125px me-4'
          >
            <input
              className='form-check-input h-30px w-30px my-4 cursor-pointer'
              type='radio'
              value={option.value}
              checked={selectedOption === option.value}
              onChange={(e) => onChange(e.target.value)}
              name='workType'
            />

            <label
              className='form-check-label fs-6 fw-bold mx-2 mt-4 cursor-pointer'
              htmlFor={option.value}
            >
              {option.title}{' '}
            </label>
            <label
              className='form-check-label mx-2 fs-8 fw-normal cursor-pointer'
              htmlFor={option.value}
            >
              {option.desc}
            </label>
          </div>
        ))}
      </div>
    </>
  )
}

export function ApplicationForm() {
  const [isDone, setIsDone] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selectedOption, setSelectedOption] = useState(null)
  const [dataArray, setDataArray] = useState([{value: ''}])
  const [error, setError] = useState('')

  const [selectedFiles, setSelectedFiles] = useState<any | null>(null)
  const [uploadProgress, setUploadProgress] = useState({})

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values) => {
      setLoading(true)
      addDoc(collection(db, 'applications'), {
        displayName: values.displayName,
        email: values.email,
        phoneNumber: values.phoneNumber,
        city: values.city,
        title: values.title,
        workType: selectedOption,
        year: values.year,
        message: values.message,
        link: dataArray,
        acceptTerms: values.acceptTerms,
        createdAt: serverTimestamp(),
      })
        .then(async (data) => {
          if (selectedFiles) {
            let uploaded: {fileName: any; fileSize: string; downloadURL: string}[] = []
            for (const file of selectedFiles) {
              let fileName = `${uuidv4()}_${file.name}`
              const fileRef = ref(storage, `applications/${data.id}/${fileName}`)
              const uploadTask = uploadBytesResumable(fileRef, file)

              uploadTask.on('state_changed', (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                setUploadProgress((prevProgress) => ({
                  ...prevProgress,
                  [file.name]: progress,
                }))
              })

              await uploadTask

              const downloadURL = await getDownloadURL(fileRef)
              uploaded.push({fileName: file?.name, fileSize: file?.size, downloadURL: downloadURL})
            }

            const docRef = doc(db, 'applications', data.id)

            setDoc(docRef, {files: uploaded}, {merge: true})
              .then(() => {
                setLoading(false)
                setIsDone(true)
              })
              .catch((error) => {
                setError(error.message)
              })
          } else {
            setLoading(false)
            setIsDone(true)
          }
        })
        .catch((err) => {
          setError(err.message)
          console.log(err.message)
          setLoading(false)
        })
    },
  })

  const handleInputChange = (index, event) => {
    const values = [...dataArray]
    values[index].value = event.target.value
    setDataArray(values)
  }

  const handleAddInput = () => {
    setDataArray([...dataArray, {value: ''}])
  }

  const handleRemoveInput = (index) => {
    const values = [...dataArray]
    values.splice(index, 1)
    setDataArray(values)
  }

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files)
    setSelectedFiles(files)
  }

  const handleOptionChange = (value) => {
    setSelectedOption(value)
  }

  return (
    <>
      {isDone ? (
        <>
          <div className='row px-5 px-md-20 text-center px-sm-10 mt-20 '>
            <i className='bi bi-check' style={{fontSize: '200px'}}></i>

            <h1 className='fs-1qx fw-bolder  mb-7'>Başvurunuz kaydettik!</h1>
            <div className='col-md-12 text-center'>
              Derin sulara dalmadan önceki ilk aşamayı geçtin! CV havuzumuz, ihtiyaç halinde
              baktığımız öncelikli yer olacak.
            </div>
          </div>
        </>
      ) : (
        <>
          <form
            className='form w-100 px-5 px-md-20 px-sm-10 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='applicationFrom'
            onSubmit={formik.handleSubmit}
          >
            {/* end::Heading */}
            {formik.status && (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
            )}

            {/* begin::Form group Firstname */}
            <div className='row  mb-8'>
              <div className='row '>
                <div className='col-md-6 mb-8'>
                  <label className='form-label fw-bolder text-dark fs-6'>Tam Adınız</label>
                  <input
                    placeholder='Tam adınızı giriniz.'
                    type='text'
                    autoComplete='off'
                    {...formik.getFieldProps('displayName')}
                    className={clsx(
                      'form-control bg-transparent',
                      {
                        'is-invalid': formik.touched.displayName && formik.errors.displayName,
                      },
                      {
                        'is-valid': formik.touched.displayName && !formik.errors.displayName,
                      }
                    )}
                  />
                  {formik.touched.displayName && formik.errors.displayName && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.displayName}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='col-md-6 mb-8'>
                  <label className='form-label fw-bolder text-dark fs-6'>E-Posta</label>
                  <input
                    placeholder='E-posta adresinizi giriniz.'
                    type='email'
                    autoComplete='off'
                    {...formik.getFieldProps('email')}
                    className={clsx(
                      'form-control bg-transparent',
                      {'is-invalid': formik.touched.email && formik.errors.email},
                      {
                        'is-valid': formik.touched.email && !formik.errors.email,
                      }
                    )}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.email}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row '>
                <div className='col-md-6 mb-8'>
                  <label className='form-label fw-bolder text-dark fs-6'>Telefon (GSM)</label>
                  <InputMask
                    mask='0 999 999 99 99'
                    placeholder='Telefon (GSM) numaranızı yazınız.'
                    type='tel'
                    autoComplete='off'
                    {...formik.getFieldProps('phoneNumber')}
                    className={clsx(
                      'form-control bg-transparent',
                      {
                        'is-invalid': formik.touched.phoneNumber && formik.errors.phoneNumber,
                      },
                      {
                        'is-valid': formik.touched.phoneNumber && !formik.errors.phoneNumber,
                      }
                    )}
                  />
                  {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.phoneNumber}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='col-md-6 mb-8'>
                  <label className='form-label fw-bolder text-dark fs-6'>Şehir</label>
                  <input
                    placeholder='Yaşadığın şehri yazınız.'
                    type='text'
                    autoComplete='off'
                    {...formik.getFieldProps('city')}
                    className={clsx(
                      'form-control bg-transparent',
                      {
                        'is-invalid': formik.touched.city && formik.errors.city,
                      },
                      {
                        'is-valid': formik.touched.city && !formik.errors.city,
                      }
                    )}
                  />
                  {formik.touched.city && formik.errors.city && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.city}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row'>
                <div className='col-md-12 mb-8'>
                  <label className='form-label fw-bolder text-dark fs-6'>Başvuru Amacınız</label>
                  <RadioGroup
                    onChange={handleOptionChange}
                    options={jobTypes}
                    selectedOption={selectedOption}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-md-6 mb-8'>
                  <label className='form-label fw-bolder text-dark fs-6'>
                    İş Ünvanı / Departman
                  </label>

                  <select
                    className='form-select form-control bg-transparent'
                    {...formik.getFieldProps('title')}
                  >
                    <option disabled value=''>
                      Hangi departmana başvuru yapıyorsun?
                    </option>

                    <optgroup label='Kreatif Departman'>
                      <option>Art Direktör</option>
                      <option>Jr. Art Direktör</option>
                      <option>Motion Design</option>
                      <option>Metin Yazarı</option>
                      <option>Jr. Metin Yazarı</option>
                      <option>Video Editörü</option>
                      <option>Prodüksiyon</option>
                      <option>Stratejist</option>
                    </optgroup>

                    <optgroup label='Dijital Medya Departmanı'>
                      <option>Sosyal Medya Uzmanı</option>
                      <option>Dijital Pazarlama Uzmanı </option>
                    </optgroup>

                    <optgroup label='Marka ve Proje Yönetimi Departmanı'>
                      <option>Müşteri Temsilcisi</option>
                      <option>Marka Yöneticisi</option>
                      <option>Proje Yöneticisi</option>
                    </optgroup>

                    <optgroup label='Web ve Yazılım Departmanı'>
                      <option>Arayüz Tasarımcısı</option>
                      <option>UI/UX Tasarımcısı</option>
                      <option>Front-End Geliştirici</option>
                      <option>Back-End Geliştirici</option>
                      <option>Full-Stack Geliştirici</option>
                      <option>Web İçerik Yöneticisi</option>
                    </optgroup>

                    <optgroup label='Gastronomi Departmanı'>
                      <option>Şef</option>
                    </optgroup>

                    <optgroup label='Diğer'>
                      <option>İnsan Kaynakları Uzmanı</option>
                      <option>Listede yok.</option>
                    </optgroup>
                  </select>
                  {formik.touched.title && formik.errors.title && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.title}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='col-md-6 mb-8'>
                  <label className='form-label fw-bolder text-dark fs-6'>Tecrübe (Yıl)</label>
                  <input
                    placeholder='Kaç yıl tecrübeye sahipsin?'
                    type='number'
                    autoComplete='off'
                    {...formik.getFieldProps('year')}
                    className='form-control bg-transparent'
                  />
                </div>
              </div>

              <div className='row'>
                <div className='col-md-12 mb-8'>
                  <div className='form-group'>
                    <label className='form-label fw-bolder text-dark fs-6'>Ön Yazı</label>
                    <textarea
                      placeholder='Kendini tanımlayan bir ön yazı yazabilirsin.'
                      className='form-control bg-transparent'
                      {...formik.getFieldProps('message')}
                    ></textarea>
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-md-6'>
                  <label className='form-label fw-bolder text-dark fs-6'>Link (URL)</label>
                  {dataArray.map((data, index) => (
                    <div className='row' key={index}>
                      <div className='col-md-9 col-sm-12 mb-4'>
                        <input
                          type='text'
                          value={data.value}
                          name='link'
                          className='form-control bg-transparent'
                          onChange={(e) => handleInputChange(index, e)}
                          placeholder='Porfolyo, Sosyal Medya hesabını ekleyebilirsin.'
                        />
                      </div>
                      <div className='col-md-3 mb-4'>
                        <button
                          type='button'
                          className='btn btn btn-primary'
                          onClick={() => handleRemoveInput(index)}
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  ))}
                  <div className='col-md-12 mb-8'>
                    <button
                      type='button'
                      className='position-relative w-35px h-35px btn btn-sm btn-icon btn-primary h-25px w-25px'
                      onClick={handleAddInput}
                    >
                      <KTSVG path='/media/icons/add.svg' className={'svg-icon-4'} />
                    </button>
                  </div>
                </div>
                <div className='col-md-6 mb-8'>
                  <label className='form-label fw-bolder text-dark fs-6'>
                    CV / Portfolyo (PDF)
                  </label>
                  <input
                    className='form-control bg-transparent'
                    type='file'
                    id='formFileMultiple'
                    onChange={handleFileChange}
                    multiple
                  />
                  <label htmlFor='formFileMultiple' className='text-muted mt-1'>
                    Birden fazla dosya ekleyebilirsin.
                  </label>
                </div>
              </div>

              <div className='row mb-8'>
                <div className='col-md-12'>
                  <label className='form-check' htmlFor='kt_login_toc_agree'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='kt_login_toc_agree'
                      {...formik.getFieldProps('acceptTerms')}
                    />
                    <span>
                      <button
                        type='button'
                        className='btn btn-link link-primary p-0 ms-1 fs-6 '
                        data-bs-toggle='modal'
                        data-bs-target='#kvkk'
                      >
                        KVKK Aydınlatma Metni
                      </button>
                      'ni Okudum ve Onaylıyorum{' '}
                    </span>
                  </label>
                  {formik.touched.acceptTerms && formik.errors.acceptTerms && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.acceptTerms}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-20'>
                <div className='col-md-12'>
                  <button
                    type='submit'
                    id='kt_sign_up_submit'
                    className='btn btn-lg btn-primary w-100'
                    disabled={
                      formik.isSubmitting ||
                      !formik.isValid ||
                      !formik.values.acceptTerms ||
                      !selectedOption
                    }
                  >
                    {!loading && <span className='indicator-label'>Kaydet</span>}
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                  {error && (
                    <>
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{error}</span>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              {/* end::Form group */}
            </div>
            {/* end::Form group */}
          </form>

          <div className='modal fade' tabIndex={-1} id='kvkk'>
            <div className='modal-dialog'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h5 className='modal-title'>KVKK Aydınlatma Metni</h5>
                  <div
                    className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                    data-bs-dismiss='modal'
                    aria-label='Close'
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr061.svg'
                      className='svg-icon svg-icon-2x'
                    />
                  </div>
                </div>
                <div className='modal-body'>
                  <p>K</p>
                </div>
                <div className='modal-footer'>
                  <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                    Kapat
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
