import {useState} from 'react'
import {useCollection} from '../../../../../hooks/useCollection'
import {useParams} from 'react-router-dom'
import {addDoc, collection, doc, serverTimestamp, setDoc} from 'firebase/firestore'
import {useAuthContext} from '../../../../auth'
import {db} from '../../../../../../firebase/config'

/* eslint-disable jsx-a11y/anchor-is-valid */
export function BudgetAddDropdown() {
  const {id, projectID} = useParams()
  const clientUID = id ? id : ''
  const projectUID = projectID ? projectID : ''
  const {user} = useAuthContext()

  const {documents: currencies} = useCollection('agency/data/currencies')
  const [reason, setReason] = useState('')
  const [expense, setExpense] = useState('')
  const [currency, setCurrency] = useState('')

  const handleClick = () => {
    console.log(expense)
    !currency && currencies.map((c: any) => c.isBase === true && setCurrency(c.name))
    console.log(reason)
    let currencyData: any = currencies.find((c: any) => c.isBase === true)

    const expenseData = {
      expense: expense,
      currency: currency ? currency : currencyData?.name,
      createdAt: serverTimestamp(),
      reason: reason,
      user: {name: user.displayName, uid: user.uid},
    }
    console.log(expenseData)
    addDoc(collection(db, 'projects', projectUID, 'expenses'), expenseData)
      .then((data) => {
        setDoc(
          doc(db, 'clients', clientUID, 'projects', projectUID, 'expenses', data.id),
          expenseData
        ).then(() => {
          setReason('')
          setExpense('')
          console.log('Added')
        })
      })
      .catch((err) => {
        console.log(err.message)
      })
  }

  return (
    <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
      <div className='px-7 py-5'>
        <div className='fs-5 text-dark fw-bolder'>Add Expense</div>
      </div>

      <div className='separator border-gray-200'></div>

      <div className='px-7 py-5'>
        <div className='mb-4'>
          {/* <label className='form-label fw-bold'>Price</label> */}
          <div className='row'>
            <div className='col-md-8 pe-0'>
              <input
                className='form-control form-control-solid'
                placeholder='Expense'
                type='number'
                value={expense}
                onChange={(e) => setExpense(e.target.value)}
              />
            </div>
            <div className='col-md-4'>
              <select
                className='form-select form-select-solid'
                data-placeholder='Select currency'
                defaultValue={currencies.map((c: any) => c.isBase === true && c.uid)}
                onChange={(e) => setCurrency(e.target.value)}
              >
                {currencies &&
                  currencies.map((c: any) => (
                    <option value={c.uid} selected={c.isBase}>
                      {c.name}
                    </option>
                  ))}
              </select>
            </div>
            {/* <label className='form-label fs-8 text-primary text-muted ms-1 mt-1'>
              Enter a expense excluding taxes.
            </label> */}
          </div>
        </div>

        <div className='mb-4'>
          {/* <label className='form-label fw-bold required'>Reason for spending</label> */}

          <div className='d-flex'>
            <textarea
              className='form-control  form-control-solid '
              rows={2}
              data-kt-element='input'
              value={reason}
              placeholder='You can add a note to your leave request. (Optional)'
              onChange={(event) => setReason(event.target.value)}
            ></textarea>
          </div>
        </div>

        <div className='d-flex justify-content-end'>
          <button
            type='reset'
            className='btn btn-sm btn-light btn-active-light-primary me-2'
            data-kt-menu-dismiss='true'
          >
            Reset
          </button>

          <button
            type='submit'
            className='btn btn-sm btn-primary'
            data-kt-menu-dismiss='true'
            onClick={handleClick}
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  )
}
