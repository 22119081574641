/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {Avatar, Dropdown1} from '../../../../../../_metronic/partials'
import moment from 'moment'
import {useCollection} from '../../../../../hooks/useCollection'
import {useParams} from 'react-router-dom'
import {Tooltip} from 'react-tooltip'
import {BudgetAddDropdown} from './BudgetAddDropdown'
import {useAuthContext} from '../../../../auth'
import {deleteDoc, doc} from 'firebase/firestore'
import {db} from '../../../../../../firebase/config'

type Props = {
  className: string
  color: string
  title: string
  date: string
  type: string
  budget?: number
  timeTracker?: any
  users?: any
  clientDetail?: any
  userCosts?: any
  period?: any
}

const BudgetCard: React.FC<Props> = ({
  className,
  color,
  title,
  date,
  type,
  budget,
  timeTracker,
  users,
  clientDetail,
  userCosts,
  period,
}) => {
  const {id, projectID} = useParams()
  const clientUID = id ? id : ''
  const projectUID = projectID ? projectID : ''

  const {user} = useAuthContext()

  const {documents: expenses} = useCollection('projects/' + projectUID + '/expenses')

  const [timeTrackerBudgets, setTimeTrackerBudgets] = useState(0)
  const [expenseBudgets, setExpenseBudgets] = useState(0)

  useEffect(() => {
    if (type === 'expense' || type === 'profit') {
      setTimeTrackerBudgets(
        timeTracker?.reduce((a, t: any) => (a = a + Number(t?.cost ? t?.cost : 0)), 0)
      )
    }
  }, [timeTracker])

  useEffect(() => {
    if (type === 'expense' || type === 'profit') {
      let expenseTotal = 0

      expenses?.map((e: any) => (expenseTotal += Number(e?.expense)))

      setExpenseBudgets(expenseTotal)
    }
  }, [expenses])

  const deleteExpense = (dataUID) => {
    const projectDocRef = doc(db, 'projects', projectUID, '/expenses/', dataUID)
    const clientDocRef = doc(
      db,
      'clients',
      clientUID,
      'projects',
      projectUID,
      '/expenses/',
      dataUID
    )
    deleteDoc(projectDocRef)
      .then(() => {
        deleteDoc(clientDocRef).then(() => {
          console.log('Entire Document has been deleted successfully.')
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }
  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='card-body p-0'>
        {/* begin::Header */}
        <div
          className={`px-9 pt-7 card-rounded ${
            type !== 'profit' ? 'h-275px ' : 'h-100 '
          }w-100 bg-${color}`}
        >
          {/* begin::Heading */}
          <div className='d-flex flex-stack'>
            <div className='flex-column'>
              <h3 className='m-0 text-white fw-bold fs-3'>{title}</h3>

              <h5 className='m-0 text-white fw-bold fs-6'>{`${moment(date).format(
                'MMMM YYYY'
              )}`}</h5>
            </div>
            {type === 'expense' && (
              <div className='ms-1'>
                {/* begin::Menu */}
                <button
                  type='button'
                  className={`btn btn-sm btn-icon btn-color-white btn-active-white btn-active-color-${color} border-0 me-n3`}
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                >
                  <i className='bi bi-plus-lg fs-2'></i>
                </button>
                <BudgetAddDropdown />
                {/* end::Menu */}
              </div>
            )}
          </div>
          {/* end::Heading */}
          {/* begin::Balance */}
          <div className='d-flex  flex-column text-white pt-8'>
            <span className='fw-semibold fs-7'>Total</span>
            <span className='fw-bold fs-2x pt-1'>
              {type === 'income' && budget + ' TL'}
              {type === 'expense' && (timeTrackerBudgets + expenseBudgets).toFixed(2) + ' TL'}
              {type === 'profit' &&
                budget &&
                (budget - timeTrackerBudgets - expenseBudgets).toFixed(2) + ' TL'}
            </span>
          </div>
          {/* end::Balance */}
        </div>
        {/* end::Header */}
        {/* begin::Items */}
        {type !== 'profit' && (
          <div
            className='card-rounded scroll-y h-250px mb-9 px-6 py-9 position-relative z-index-1 bg-body'
            style={{marginTop: '-100px'}}
          >
            {type === 'income' && (
              <>
                {/* begin::Item */}
                <div className='d-flex align-items-center mb-6'>
                  {/* begin::Symbol */}
                  <div className='symbol symbol-45px w-40px me-5'>
                    <span className='symbol-label bg-lighten'>
                      <KTSVG path='/media/icons/duotune/maps/map004.svg' className='svg-icon-1' />
                    </span>
                  </div>
                  {/* end::Symbol */}
                  {/* begin::Description */}
                  <div className='d-flex align-items-center flex-wrap w-100'>
                    {/* begin::Title */}
                    <div className='mb-1 pe-3 flex-grow-1'>
                      <a href='#' className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                        {type === 'income' && 'Project Budgets'}
                      </a>
                      {period && period === 'monthly' && (
                        <div className='text-gray-400 fw-semibold fs-7'>Monthly Budget</div>
                      )}
                      {period && period === 'oneTime' && (
                        <div className='text-gray-400 fw-semibold fs-7'>One Time Budget</div>
                      )}
                      {period && period === 'yearly' && (
                        <div className='text-gray-400 fw-semibold fs-7'>Yearly Budget</div>
                      )}
                    </div>
                    {/* end::Title */}
                    {/* begin::Label */}
                    <div className='d-flex align-items-center'>
                      <div className='fw-bold fs-5 text-gray-800 pe-1'>
                        {type === 'income' && budget + ' TL'}
                      </div>
                    </div>
                    {/* end::Label */}
                  </div>
                  {/* end::Description */}
                </div>
                {/* end::Item */}
              </>
            )}

            {type === 'expense' &&
              timeTracker?.map((value: any) => (
                <div className='d-flex align-items-center mb-6'>
                  {users &&
                    users.map(
                      (u: any) =>
                        u.id === value.userUID && (
                          <>
                            <Avatar
                              className={'symbol symbol-40px me-2'}
                              name={u?.displayName}
                              avatarURL={u?.photoURL}
                            />

                            <div className='d-flex flex-column flex-fill me-6'>
                              <div className='fw-bolder d-flex align-items-center fs-7'>
                                {u?.displayName}
                              </div>
                              <span className='fw-semibold text-muted fs-7'>
                                {clientDetail?.authorities
                                  .filter((u: any) => u.uid === value.userUID)
                                  .map((user: any) => u.title)}
                              </span>
                            </div>
                          </>
                        )
                    )}

                  <div className='d-flex align-items-center '>
                    {value.note && (
                      <button
                        className='btn btn-icon btn-sm bg-hover-light me-1'
                        data-tooltip-id={`tooltip-${value.id}`}
                        data-tooltip-content={value.note}
                      >
                        <i className='bi bi-card-text fs-4'></i>
                        <Tooltip id={`tooltip-${value.id}`} />
                      </button>
                    )}

                    <span className='badge badge-sm badge-success fs-8 p-2 py-3 me-2'>
                      {value.timeValue} dk
                    </span>
                    <span className='badge badge-sm badge-danger fs-8 p-2 py-3'>
                      {value?.cost} TL
                      {/* {userCosts
                        ?.filter((u: any) => u.userUID === value.userUID)
                        .map(
                          (u: any) => ((u?.cost / 9600) * value.timeValue * 2).toFixed(0) + ' TL'
                        )} */}
                    </span>
                  </div>
                </div>
              ))}

            {type === 'expense' &&
              expenses &&
              expenses?.map((value: any) => (
                <div className='d-flex align-items-center mb-6'>
                  {users &&
                    users.map(
                      (u: any) =>
                        u.id === value?.user?.uid && (
                          <>
                            <Avatar
                              className={'symbol symbol-40px me-2'}
                              name={u?.displayName}
                              avatarURL={u?.photoURL}
                            />

                            <div className='d-flex flex-column flex-fill me-6'>
                              <div className='fw-bolder d-flex align-items-center fs-7'>
                                {u?.displayName}
                              </div>
                              <span className='fw-semibold text-muted fs-7'>{value?.reason}</span>
                            </div>
                          </>
                        )
                    )}

                  <div className='d-flex align-items-center '>
                    {value.note && (
                      <button
                        className='btn btn-icon btn-sm bg-hover-light me-1'
                        data-tooltip-id={`tooltip-${value.id}`}
                        data-tooltip-content={value.note}
                      >
                        <i className='bi bi-card-text fs-4'></i>
                        <Tooltip id={`tooltip-${value.id}`} />
                      </button>
                    )}
                    {value.user.uid === user.uid && (
                      <button
                        className='btn btn-icon btn-sm bg-hover-light me-2'
                        onClick={() => deleteExpense(value.id)}
                      >
                        <i className='bi bi-trash fs-4'></i>
                      </button>
                    )}
                    <span className='badge badge-sm badge-success fs-8 p-2 py-3 me-2'>Expense</span>
                    <span className='badge badge-sm badge-danger fs-8 p-2 py-3'>
                      {value?.expense} {value?.currency}
                    </span>
                  </div>
                </div>
              ))}
          </div>
        )}
        {/* end::Items */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {BudgetCard}
