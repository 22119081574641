/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {SocialMediaContentStatusModels} from '../../models/SocialMediaModels'

import {
  Timestamp,
  addDoc,
  arrayUnion,
  collection,
  doc,
  serverTimestamp,
  setDoc,
  updateDoc,
} from 'firebase/firestore'
import {useParams} from 'react-router-dom'
import {TagsInput} from 'react-tag-input-component'

import {useAuthContext} from '../../../../../../../../auth'
import {db} from '../../../../../../../../../../firebase/config'

import DatePicker from 'react-datepicker'
import moment from 'moment'

type Props = {
  contentData: any
}

const BudgetContent: FC<Props> = ({contentData}) => {
  const [content, setContent] = useState(contentData?.contentText)
  const [tags, setTags] = useState<any | null>(contentData?.contentTags)

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState('')

  const [startDate, setStartDate] = useState(
    contentData?.budget?.startDate ? contentData?.budget?.startDate.toDate() : null
  )
  const [endDate, setEndDate] = useState(
    contentData?.budget?.endDate ? contentData?.budget?.endDate.toDate() : null
  )
  const [budget, setBudget] = useState(
    contentData?.budget?.budget ? contentData?.budget?.budget : 0
  )
  const [note, setNote] = useState(contentData?.budget?.note ? contentData?.budget?.note : '')

  const {id, projectID} = useParams()
  const clientUID = id ? id : ''
  const projectUID = projectID ? projectID : ''

  const {user} = useAuthContext()

  const onChange = (dates) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
    console.log(new Date())
  }

  const handleContent = (event) => {
    setContent(event.target.value)
  }
  const handleClick = async () => {
    console.log(startDate)
    console.log(endDate)
    console.log(budget)
    console.log(note)
    setLoading(true)
    const docRef = doc(
      db,
      'clients',
      clientUID,
      'projects',
      projectUID,
      'socialMediaContents',
      contentData.id
    )
    let budgetContent = {
      startDate: startDate,
      endDate: endDate,
      budget: budget,
      note: note,
    }
    setDoc(
      docRef,
      {
        budget: budgetContent,
      },
      {merge: true}
    )
      .then(async () => {
        updateDoc(docRef, {
          logs: arrayUnion({
            displayName: user.displayName,
            uid: user.uid,
            photoURL: user?.photoURL,
            action: 'I added a budget.',
            createdAt: Timestamp.now(),
            value: budgetContent,
          }),
        })
        setLoading(false)
        setSuccess(true)
        setTimeout(() => {
          setSuccess(false)
        }, 1000)
      })
      .catch((error) => {
        setLoading(false)
        console.log(error.message)
      })
  }

  return (
    <>
      <label className='fw-row col-form-label pt-1 text-primary fw-bold fs-6 mb-1'>
        Content Budget
      </label>

      <div className='mb-4'>
        <div className='fv-row mb-4'>
          <label className='form-label text-primary'>Choose ad publication dates.</label>
          <DatePicker
            selected={startDate}
            onChange={onChange}
            startDate={startDate}
            endDate={endDate}
            minDate={new Date()}
            dateFormat='dd.MM.yyyy'
            selectsRange
            calendarStartDay={1}
            autoComplete='off'
            className={'form-control  form-control-solid'}
            placeholderText='Choose ad publication dates'
            // showYearDropdown
            // yearDropdownItemNumber={15}
            // scrollableYearDropdown
          />
        </div>

        <div className='fv-row mb-4'>
          <label className='form-label text-primary'>Budget</label>
          <input
            className='form-control form-control-solid'
            type='number'
            onChange={(e: any) => setBudget(e.target.value)}
            defaultValue={budget}
            placeholder='Write Target Audience, Brief, Notes etc.'
          />
        </div>

        <div className=' fv-row'>
          <label className='form-label text-primary'>Notes</label>
          <textarea
            className='form-control form-control-solid'
            rows={3}
            onChange={(e: any) => setNote(e.target.value)}
            defaultValue={note}
            placeholder='Write Target Audience, Brief, Notes etc.'
          ></textarea>
        </div>
      </div>

      <div className='d-flex justify-content-end'>
        {/* <button
          type='reset'
          className='btn btn-sm btn-light btn-active-light-primary me-2'
          data-kt-menu-dismiss='true'
        >
          Cancel
        </button> */}

        <button
          type='submit'
          className={`btn btn-sm btn-primary ${success && 'btn-success'}`}
          disabled={loading}
          onClick={handleClick}
        >
          {success && (
            <>
              <span className='badge badge-success me-5 px-6'></span>
              <span className='' style={{display: 'block'}}>
                <i className='bi bi-check fs-2'></i>
                Success
              </span>
            </>
          )}
          {!success && (
            <>
              {!loading && 'Save'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </>
          )}
        </button>
      </div>
    </>
  )
}

export {BudgetContent}
