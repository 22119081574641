/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../_metronic/layout/core'
import {
  ListsWidget2,
  ListsWidget4,
  ListsWidget6,
  TablesWidget5,
  MixedWidget8,
	ExtensionWidget,
} from '../../_metronic/partials/widgets'

const ExtensionPage: FC = () => (
  <>
		<div className="py-3 py-lg-5">
			<div className="d-flex flex-stack">
				<div className='page-title d-flex flex-wrap me-3 flex-column justify-content-center'>
					<h1 className="page-heading d-flex text-primary fw-bold fs-3 my-0 flex-column justify-content-center">Extensions</h1>
					<span className="fw-semibold text-muted">All app extensions</span>
				</div>
			</div>
		</div>
    {/* begin::Row */}
    <div className='row gy-5 gx-xl-8'>
      <div className='col-lg-4 col-md-6'>
			<ExtensionWidget
            className='card-xl-stretch mb-xl-8'
            image='/media/svg/brand-logos/plurk.svg'
            time='7 hours ago'
            title='PitStop - Multiple Email Generator'
						isAdded={true}
            description='Pitstop creates quick email campaigns.<br/>We help to strengthen your brand<br/>for your every purpose.'
          />
      </div>
      <div className='col-lg-4 col-md-6'>
			<ExtensionWidget
            className='card-xl-stretch mb-xl-8'
            image='/media/svg/social-logos/facebook.svg'
            time='Social Media'
            title='İçerik Yönetme ve Planlama'
						isAdded={false}
            description='İçeriklerinizi marka yönetimi ile birlikte hızlı bir şekilde oluşturabilir, planlama yapabilir ve diğer hizmetler ile entegrasyon yapabilirsiniz.'
          />
      </div>
			<div className='col-lg-4 col-md-6'>
			<ExtensionWidget
            className='card-xl-stretch mb-xl-8'
            image='/media/svg/brand-logos/plurk.svg'
            time='7 hours ago'
            title='PitStop - Multiple Email Generator'
						isAdded={false}
            description='Pitstop creates quick email campaigns.<br/>We help to strengthen your brand<br/>for your every purpose.'
          />
      </div>
			<div className='col-lg-4 col-md-6'>
			<ExtensionWidget
            className='card-xl-stretch mb-xl-8'
            image='/media/svg/brand-logos/plurk.svg'
            time='7 hours ago'
            title='PitStop - Multiple Email Generator'
						isAdded={true}
            description='Pitstop creates quick email campaigns.<br/>We help to strengthen your brand<br/>for your every purpose.'
          />
      </div>
			<div className='col-lg-4 col-md-6'>
			<ExtensionWidget
            className='card-xl-stretch mb-xl-8'
            image='/media/svg/brand-logos/plurk.svg'
            time='7 hours ago'
            title='PitStop - Multiple Email Generator'
						isAdded={true}
            description='Pitstop creates quick email campaigns.<br/>We help to strengthen your brand<br/>for your every purpose.'
          />
      </div>
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    <div className='row gy-5 g-xl-8'>
      <div className='col-xl-4'>
        <ListsWidget2 className='card-xl-stretch mb-xl-8' />
      </div>
      <div className='col-xl-4'>
        <ListsWidget6 className='card-xl-stretch mb-xl-8' />
      </div>
      <div className='col-xl-4'>
        <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-8' items={5} />
        {/* partials/widgets/lists/_widget-4', 'class' => 'card-xl-stretch mb-5 mb-xl-8', 'items' => '5' */}
      </div>
    </div>
    {/* end::Row */}

    <div className='row g-5 gx-xxl-8'>
      <div className='col-xxl-4'>
        <MixedWidget8
          className='card-xxl-stretch mb-xl-3'
          chartColor='success'
          chartHeight='150px'
        />
      </div>
      <div className='col-xxl-8'>
        <TablesWidget5 className='card-xxl-stretch mb-5 mb-xxl-8' />
      </div>
    </div>
  </>
)

const ExtensionsWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.EXTENSIONS'})}</PageTitle>
      <ExtensionPage />
    </>
  )
}

export {ExtensionsWrapper}
