import clsx from 'clsx'
import {
  Timestamp,
  addDoc,
  arrayRemove,
  collection,
  deleteField,
  doc,
  getDoc,
  setDoc,
  updateDoc,
} from 'firebase/firestore'
import {useEffect, useState} from 'react'
import {Link, Navigate, useNavigate} from 'react-router-dom'
import {useCollection} from '../../../../../app/hooks/useCollection'
import {useAuthContext} from '../../../../../app/modules/auth'
import {db} from '../../../../../firebase/config'
import {KTSVG, toAbsoluteUrl} from '../../../../helpers'
import {Avatar} from '../../../../partials'
import StopWatch from '../../../../../app/modules/components/StopWatch'
import moment from 'moment'
import {Form, InputGroup} from 'react-bootstrap'

type Props = {
  userData: any
}

const StopWorking: React.FC<Props> = ({userData}) => {
  const [isWorking, setIsWorking] = useState<any | null>(null)
  const [workTime, setWorkTime] = useState(0)
  const [loading, setLoading] = useState(false)

  const stopWork = (
    clientUID,
    clientName,
    clientLogo,
    projectUID,
    projectName,
    workUID,
    workName
  ) => {
    setLoading(true)
    const docRef = collection(db, 'users', userData.id, 'timeTracker')
    // Ajans Genel Giderler + Yönetim Maliyet / Çalışan Sayısı
    const agencyCostPerUser = 125000 / 12 / 9600

    const cost = ((userData?.cost / 9600 + agencyCostPerUser) * workTime).toFixed(2)

    const timeTrackerData = {
      clientUID: clientUID,
      userUID: userData.id,
      workUID: workUID,
      projectUID: projectUID,
      projectName: projectName,
      workTitle: workName,
      timeValue: workTime,
      cost: cost,
      date: Timestamp.now(),
      note: '',
    }
    addDoc(docRef, timeTrackerData)
      .then((data) => {
        const docRef = doc(db, 'clients', clientUID, '/timeTracker/', data.id)
        setDoc(docRef, timeTrackerData).then(() => {
          const docRef = doc(db, 'timeTracker/', data.id)
          setDoc(docRef, timeTrackerData).then(() => {})
        })
      })
      .catch((error) => console.log(error))

    deleteWork(clientUID, clientName, clientLogo, projectUID, projectName, workUID, workName)
    setLoading(false)
  }

  const deleteWork = (
    clientUID,
    clientName,
    clientLogo,
    projectUID,
    projectName,
    workUID,
    workName
  ) => {
    const userDocRef = doc(db, 'users', userData.id)
    const projectDocRef = doc(db, 'projects', projectUID)
    const clientProjectDocRef = doc(db, 'clients', clientUID, 'projects', projectUID)
    const workData = userData?.working
    console.log(workData)
    const userdata = {
      working: deleteField(),
    }

    updateDoc(userDocRef, userdata).then(() => {
      console.log('Working Field has been deleted successfully')
    })

    getDoc(projectDocRef).then((data) => {
      const workData = data.data()?.working.find((w: any) => w.userUID === userData.id)
      console.log(workData)
      updateDoc(projectDocRef, {
        working: arrayRemove(workData),
      })
        .then(() => console.log('Project Work Deleted.'))
        .catch((error) => console.log(error.message))

      updateDoc(clientProjectDocRef, {
        working: arrayRemove(workData),
      })
        .then(() => console.log('Client Project Work Deleted.'))
        .catch((error) => console.log(error.message))
    })
    setLoading(false)
  }

  useEffect(() => {
    userData?.working ? setIsWorking(userData.working) : setIsWorking(null)
  }, [userData])

  function calculateMinutes() {
    const duration = moment.duration(moment().diff(isWorking?.startTime?.toDate()))
    setWorkTime(Number(duration.asMinutes().toFixed(0)))
  }

  return (
    isWorking && (
      <div className={'app-navbar-item'}>
        <div
          className={'btn btn-danger h-35px h-md-40px p-2 ps-3 py-3'}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-dismiss='click'
          data-kt-menu-placement='bottom'
          onClick={calculateMinutes}
        >
          <StopWatch
            startTime={isWorking?.startTime?.toDate()}
            className={'d-inline-block w-40px fs-7 text-start'}
            style={{width: '40px'}}
          />
          <i className={`bi bi-stop-fill fs-1 text-white pe-0`}></i>
        </div>
        <div
          className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold fs-6 w-275px'
          data-kt-menu='true'
        >
          <div className='d-flex align-items-sm-center mb-2 p-2 rounded-2 '>
            <Avatar
              className={'symbol symbol-35px symbol-md-40px me-4 align-self-start'}
              name={isWorking?.clientName}
              avatarURL={isWorking?.clientLogo}
            />
            <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
              <div className='flex-grow-1 me-2'>
                <span className='text-primary fs-7 fw-semibold'>
                  {isWorking?.workName} <span className='text-primary-light fs-8 fw-normal'></span>
                </span>
                <span className='text-muted fw-semibold d-block fs-8'>
                  {isWorking?.projectName}
                </span>
              </div>

              {/* <Link className='btn btn-sm btn-light' to={link}>
          {service}
        </Link> */}
            </div>
          </div>
          <div className='separator separator-dashed mx-2'></div>
          <div className='d-flex p-3 align-items-center'>
            <input
              className='form-control form-control-sm form-control-solid me-2'
              type='number'
              value={workTime}
              onChange={(e) => setWorkTime(Number(e.target.value))}
            />

            {/* <button
              type='button'
              className='btn btn-sm btn-light'
              onClick={() =>
                stopWork(
                  isWorking?.clientUID,
                  isWorking?.clientName,
                  isWorking?.clientLogo,
                  isWorking?.projectUID,
                  isWorking?.projectName,
                  isWorking?.workUID,
                  isWorking?.workName
                )
              }
            >
              Save
            </button> */}

            <button
              type='reset'
              className='btn btn-sm btn-light me-2'
              onClick={() =>
                deleteWork(
                  isWorking?.clientUID,
                  isWorking?.clientName,
                  isWorking?.clientLogo,
                  isWorking?.projectUID,
                  isWorking?.projectName,
                  isWorking?.workUID,
                  isWorking?.workName
                )
              }
            >
              Cancel
            </button>

            <button
              type='submit'
              className='btn btn-sm btn-primary'
              disabled={workTime === 0}
              onClick={() =>
                stopWork(
                  isWorking?.clientUID,
                  isWorking?.clientName,
                  isWorking?.clientLogo,
                  isWorking?.projectUID,
                  isWorking?.projectName,
                  isWorking?.workUID,
                  isWorking?.workName
                )
              }
            >
              {!loading && <span className='indicator-label'>Save</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  <span className='spinner-border spinner-border-sm align-middle mx-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
    )
  )
}

export {StopWorking}
