/* eslint-disable jsx-a11y/anchor-is-valid */
import {doc, getDoc} from 'firebase/firestore'
import {FC, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {db} from '../../../../../../firebase/config'
import {CardSocialMediaContent} from '../components/cards/CardSocialMediaContent'
import ContentCalendar from '../../../../../../_metronic/partials/layout/social-media/content-calendar/ContentCalendar'
import ContentDraggable from '../../../../../../_metronic/partials/layout/social-media/content-draggable/ContentDraggable'
import {useCollection} from '../../../../../hooks/useCollection'
import {DropdownNewContent} from './dropdown/DropdownNewContent'

type Props = {
  socialMediaSettings: any
}

const Contents: FC<Props> = ({socialMediaSettings}) => {
  const divClass = 'col-md-6 col-lg-4 col-xl-4 col-xxl-3'

  const {id} = useParams()
  const clientUID = id ? id : ''
  // const [clientDetail, setClientDetail] = useState<any | null>(null)

  const {documents: contents} = useCollection('clients/' + clientUID + '/contents')

  // useEffect(() => {
  //   let docRef = doc(db, 'clients', clientUID)
  //   getDoc(docRef)
  //     .then((data) => {
  //       setClientDetail(data.data())
  //     })
  //     .catch((error) => {})
  // }, [clientUID])

  return (
    <>
      <div className='d-flex flex-wrap flex-stack my-6'>
        <h3 className='fw-bolder my-2 ps-1'>
          Contents
          <span className='fs-6 text-gray-400 fw-bold ms-1'>(12)</span>
        </h3>

        <div className='d-flex flex-wrap my-2'>
          <ul className='nav fs-6'>
            <li className='nav-item'>
              <a
                className='btn btn-sm btn-icon btn-light me-4 active'
                data-bs-toggle='tab'
                href='#tab_grid'
              >
                <i className='bi bi-grid-3x3-gap fs-4'></i>
              </a>
            </li>
            <li className='nav-item'>
              <a
                className='btn btn-sm btn-icon btn-light me-4'
                data-bs-toggle='tab'
                href='#tab_calendar'
              >
                <i className='bi bi-calendar3 fs-4'></i>
              </a>
            </li>
            <li className='nav-item'>
              <a
                className='btn btn-sm btn-icon btn-light me-4 '
                data-bs-toggle='tab'
                href='#tab_draggable'
              >
                <i className='bi bi-calendar3 fs-4'></i>
              </a>
            </li>
          </ul>
          <div className='me-4'>
            <select
              name='status'
              data-control='select2'
              data-hide-search='true'
              className='form-select form-select-sm form-select-white w-125px'
              defaultValue='Active'
            >
              <option value='Active'>April</option>
              <option value='Approved'>In Progress</option>
              <option value='Declined'>To Do</option>
              <option value='In Progress'>Completed</option>
            </select>
          </div>

          <div className='me-0'>
            <a
              href='#'
              className='btn btn-primary btn-sm'
              // onClick={() => setSelectedClient('')}
              // data-bs-toggle='modal'
              // data-bs-target='#modal_client_settings'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
            >
              Create Content
            </a>
            <DropdownNewContent contents={contents} />
          </div>
        </div>
      </div>
      <div className='tab-content' id='tabContent'>
        <div className='tab-pane fade show active' id='tab_grid' role='tabpanel'>
          <div className='row g-6 g-xl-9'>
            {contents
              ?.sort((a: any, b: any) => (a.publishDate > b.publishDate ? 1 : -1))
              .map((content: any, i) => (
                <div className='col min-w-350px mw-450px' key={i}>
                  <CardSocialMediaContent
                    contentData={content}
                    socialMediaSettings={socialMediaSettings}
                    media='https://storage.googleapis.com/fok-cave/API/posts/thumb/6be2f6669df965d3d812ca231f4da7c4cc66d237lh.png'
                    publishDate={content?.publishDate}
                    publishTime='09:00'
                    badgeColor='success'
                    status='Shared'
                    statusColor='success'
                    content='ssss'
                    logsCount='2'
                    date='November 10, 2021'
                    budget='$284,900.00'
                    progress={50}
                  />
                </div>
              ))}
          </div>
        </div>
        <div className='tab-pane fade' id='tab_calendar' role='tabpanel'>
          <ContentCalendar />
        </div>
        <div className='tab-pane fade' id='tab_draggable' role='tabpanel'>
          <ContentDraggable />
        </div>
      </div>
    </>
  )
}

export {Contents}
