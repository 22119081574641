/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {useState} from 'react'
import { Link } from 'react-router-dom'
import {KTSVG} from '../../../../helpers'
import {CreateAppModal, Dropdown1} from '../../../../partials'
import {useLayout} from '../../../core'

const ToolbarSocialMedia = () => {
  const {config} = useLayout()
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)
  const daterangepickerButtonClass = config.app?.toolbar?.fixed?.desktop
    ? 'btn-light'
    : 'bg-body btn-color-gray-700 btn-active-color-primary'

  return (
    <div className='d-flex align-items-center gap-2 gap-lg-3'>
      <Link
				className={
					'btn btn-sm fw-bold btn-light-primary me-3 ' + (window.location.pathname === '/social-media/overview' && 'active')
				}
				to='/social-media/overview'
			>
				Overview
			</Link>
			<Link
				className={
					'btn btn-sm fw-bold btn-light-primary me-3 ' +
					(window.location.pathname === '/social-media/projects' && 'active')
				}
				to='/social-media/co'
			>
				Contents
			</Link>
			<Link
				className={
					'btn btn-sm fw-bold btn-light-primary py-4 me-3 ' +
					(window.location.pathname === '/social-media/campaigns' && 'active')
				}
				to='/social-media/campaigns'
			>
				Messages
			</Link>
			<Link
				className={
					'btn btn-sm fw-bold btn-light-primary py-4 me-3 ' +
					(window.location.pathname === '/social-media/documents' && 'active')
				}
				to='/social-media/documents'
			>
				Reports
			</Link>
			<Link
				className={
					'btn btn-sm fw-bold btn-light-primary py-4 me-0 ' +
					(window.location.pathname === '/social-media/connections' && 'active')
				}
				to='/social-media/connections'
			>
				Library
			</Link>
    </div>
  )
}

export {ToolbarSocialMedia}
