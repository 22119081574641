/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'

import {Link, useParams} from 'react-router-dom'
import moment, {Moment} from 'moment'
import {collection, doc, getDoc, getDocs} from 'firebase/firestore'
import {useCollection} from '../../../../hooks/useCollection'
import {db} from '../../../../../firebase/config'
import {UsersAvatars} from '../../../components/Elements/UsersAvatars'
import {currencyFormat} from '../../../../helpers'

export function Profit() {
  const {id} = useParams()
  const clientUID = id ? id : ''
  const {documents: projects} = useCollection('clients/' + id + '/projects')
  const [header, setHeader] = useState('Projects')

  const {documents: timeTrackers} = useCollection('clients/' + id + '/timeTracker')
  const {documents: users} = useCollection('users')
  const {documents: userCosts} = useCollection('agency/fok/userCosts')

  const [clientDetail, setClientDetail] = useState<any | null>(null)
  const [projectProfit, setProjectProfit] = useState<any | null>(null)

  const [timeTrackerM, setTimeTrackerM] = useState<any | null>(null)

  const [filteredProjects, setFilteredProjects] = useState<any | null>(projects)

  useEffect(() => {
    if (projects) {
      setFilteredProjects(projects)
    }
  }, [projects])

  const fetchExpenses = async (project, expensesRef) => {
    let expensesTotal = 0
    try {
      const docsSnap = await getDocs(expensesRef)
      if (docsSnap) {
        if (project.period === 'monthly') {
          const endDate: Moment = moment().subtract(1, 'months').endOf('month')
          docsSnap.forEach((doc: any) => {
            if (moment(doc.data().createdAt.toDate()).isSameOrBefore(endDate)) {
              expensesTotal += Number(doc.data()?.expense)
            }
            if (doc.data().createdAt) expensesTotal += Number(doc.data()?.expense)
          })
        } else {
          docsSnap.forEach((doc: any) => {
            expensesTotal += Number(doc.data()?.expense)
          })
        }
      }
    } catch (error) {
      console.log(error)
    } finally {
      return expensesTotal
    }
  }

  const fetchTimeTrackerCost = (project) => {
    // console.log(project)
    // console.log('timeTrackerData:')
    // console.log(timeTrackers?.filter((t: any) => t.projectUID === project.id))

    if (project.period === 'monthly') {
      const endDate: Moment = moment().subtract(1, 'months').endOf('month')
      return timeTrackers
        ?.filter(
          (t: any) => t.projectUID === project.id && moment(t.date.toDate()).isSameOrBefore(endDate)
        )
        ?.reduce((a, v: any) => (a = a + Number(v?.cost)), 0)
    } else {
      return timeTrackers
        ?.filter((t: any) => t.projectUID === project.id)
        ?.reduce((a, v: any) => (a = a + Number(v?.cost)), 0)
    }
  }

  const fetchIncomes = (project) => {
    if (project.period === 'monthly') {
      const startDate: Moment = moment(project?.startDate)
      const endDate: Moment = moment()

      const monthsArray = getMonthsBetween(startDate, endDate)

      return monthsArray.length * project?.budget
    } else {
      return Number(project?.budget)
    }
  }

  const getMonthsBetween = (start, end) => {
    let currentMonth = moment(start)
    const months: string[] = []

    while (currentMonth.isBefore(end, 'month')) {
      currentMonth && months.push(currentMonth.format('MM-DD-YYYY'))
      currentMonth.add(1, 'month')
    }

    return months.reverse()
  }

  useEffect(() => {
    if (filteredProjects) {
      const promises = filteredProjects.map(async (project: any) => {
        const expensesRef = collection(db, 'clients', clientUID, 'projects', project.id, 'expenses')
        const timeTrackerCosts = await fetchTimeTrackerCost(project) // fetchTimeTrackerCost'i bekleyin
        const expenses = await fetchExpenses(project, expensesRef) // fetchExpenses'i bekleyin
        const incomes = await fetchIncomes(project) // fetchIncomes'i bekleyin
        console.log('EX: ' + expenses)
        console.log('IN: ' + incomes)
        console.log('Tot: ' + (Number(expenses) + Number(incomes)))
        return {
          id: project.id,
          incomes: incomes,
          expenses: Number(expenses) + Number(timeTrackerCosts),
        }
      })
      Promise.all(promises).then((ProfitData) => {
        setProjectProfit(ProfitData)
        console.log(ProfitData)
      })
    }
  }, [filteredProjects])

  // useEffect(() => {
  //   console.log('filteredProjects')
  //   console.log(filteredProjects)
  //   if (filteredProjects) {
  //     let ProfitData: {
  //       id: string
  //       incomes: number
  //       expenses: number
  //     }[] = []
  //     console.log('değişti')

  //     filteredProjects.map(async (project: any) => {
  //       const expensesRef = collection(db, 'clients', clientUID, 'projects', project.id, 'expenses')
  //       const timeTrackerCosts = fetchTimeTrackerCost(project.id)
  //       const expenses = await fetchExpenses(expensesRef)
  //       const incomes = fetchIncomes(project)
  //       ProfitData.push({
  //         id: project.id,
  //         incomes: incomes,
  //         expenses: expenses + timeTrackerCosts,
  //       })
  //     })
  //     setProjectProfit(ProfitData)
  //     console.log(ProfitData)
  //   }
  // }, [filteredProjects])

  useEffect(() => {
    let docRef = doc(db, 'clients/' + id)
    getDoc(docRef)
      .then((data) => {
        setClientDetail(data.data())
      })
      .catch((error) => {
        console.log(error.message)
      })
  }, [id])

  const [expenseBudgets, setExpenseBudgets] = useState(0)

  const handleChange = (event) => {
    console.log(event.target.value)
    event.target.value === 'all' && setFilteredProjects(projects)
    event.target.value === 'all' && setHeader('All Projects')
    event.target.value === 'active' &&
      setFilteredProjects(projects.filter((p: any) => p.isActive === true))
    event.target.value === 'active' && setHeader('Active Projects')
    event.target.value === 'passive' &&
      setFilteredProjects(projects.filter((p: any) => p.isActive === false))
    event.target.value === 'passive' && setHeader('Inactive Projects')
    event.target.value === 'Not Started' &&
      setFilteredProjects(projects.filter((p: any) => p?.status === 'Not Started'))
    event.target.value === 'Not Started' && setHeader('Not Started')
    event.target.value === 'To Do' &&
      setFilteredProjects(projects.filter((p: any) => p?.status === 'To Do'))
    event.target.value === 'To Do' && setHeader('To Do')
    event.target.value === 'In Progress' &&
      setFilteredProjects(projects.filter((p: any) => p?.status === 'In Progress'))
    event.target.value === 'In Progress' && setHeader('In Progress')
    event.target.value === 'Done' &&
      setFilteredProjects(projects.filter((p: any) => p?.status === 'Done'))
    event.target.value === 'Done' && setHeader('Done')
  }

  return (
    <>
      <div className='d-flex flex-wrap flex-stack'>
        <h3 className='fw-bolder text-primary my-2 ps-1'>Profit</h3>
        {/* <div className='d-flex flex-wrap my-2'>
          <div className='align-self-center'>
            <select
              name='status'
              data-control='select2'
              data-hide-search='true'
              className='form-select form-select-sm form-select-white w-125px'
              defaultValue='Active'
            >
              <option value='Active'>Active</option>
              <option value='Approved'>In Progress</option>
              <option value='Declined'>To Do</option>
              <option value='In Progress'>Completed</option>
            </select>
          </div>
        </div> */}
      </div>
      {/* <div className='card card-flush my-5'>
        <div className='card-header'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder text-primary m-0'>
              Budgets
              <span className='fs-6 text-gray-400 fw-bold ms-1'>Active</span>
            </h3>
          </div>

          <div className='d-flex flex-wrap my-2'></div>
        </div>
      </div> */}

      <div className='row g-6 g-xl-9'>
        <div className='col-md-4'>
          <div className={`card  mb-xl-4 bg-primary pb-8`}>
            {/* begin::Body */}
            <div className='card-body p-0'>
              {/* begin::Header */}
              <div className={`px-9 pt-7 card-rounded`}>
                {/* begin::Heading */}
                <div className='d-flex flex-stack'>
                  <div className='flex-column'>
                    <h3 className='m-0 text-white fw-bold fs-3'>Incomes</h3>
                  </div>
                </div>
                {/* end::Heading */}
                {/* begin::Balance */}
                <div className='d-flex  flex-column text-white pt-8'>
                  <span className='fw-semibold fs-7'>Total</span>
                  <span className='fw-bold fs-2x pt-1'>
                    {currencyFormat(
                      projectProfit?.reduce((a, v: any) => (a = a + Number(v?.incomes)), 0)
                    )}{' '}
                    TL
                  </span>
                </div>
                {/* end::Balance */}
              </div>
              {/* end::Header */}
            </div>
            {/* end::Body */}
          </div>
        </div>
        <div className='col-md-4'>
          <div className={`card  mb-xl-4 bg-danger pb-8`}>
            {/* begin::Body */}
            <div className='card-body p-0'>
              {/* begin::Header */}
              <div className={`px-9 pt-7 card-rounded`}>
                {/* begin::Heading */}
                <div className='d-flex flex-stack'>
                  <div className='flex-column'>
                    <h3 className='m-0 text-white fw-bold fs-3'>Expense</h3>
                  </div>
                </div>
                {/* end::Heading */}
                {/* begin::Balance */}
                <div className='d-flex  flex-column text-white pt-8'>
                  <span className='fw-semibold fs-7'>Total</span>
                  <span className='fw-bold fs-2x pt-1'>
                    {currencyFormat(
                      projectProfit?.reduce((a, v: any) => (a = a + Number(v?.expenses)), 0)
                    )}{' '}
                    TL
                  </span>
                </div>
                {/* end::Balance */}
              </div>
              {/* end::Header */}
            </div>
            {/* end::Body */}
          </div>
        </div>
        <div className='col-md-4'>
          <div className={`card mb-xl-4 bg-success pb-8`}>
            {/* begin::Body */}
            <div className='card-body p-0'>
              {/* begin::Header */}
              <div className={`px-9 pt-7 card-rounded`}>
                {/* begin::Heading */}
                <div className='d-flex flex-stack'>
                  <div className='flex-column'>
                    <h3 className='m-0 text-white fw-bold fs-3'>Profit</h3>
                  </div>
                </div>
                {/* end::Heading */}
                {/* begin::Balance */}
                <div className='d-flex  flex-column text-white pt-8'>
                  <span className='fw-semibold fs-7'>Total</span>
                  <span className='fw-bold fs-2x pt-1'>
                    {currencyFormat(
                      projectProfit?.reduce((a, v: any) => (a = a + Number(v?.incomes)), 0) -
                        projectProfit?.reduce((a, v: any) => (a = a + Number(v?.expenses)), 0)
                    )}{' '}
                    TL
                  </span>
                </div>
                {/* end::Balance */}
              </div>
              {/* end::Header */}
            </div>
            {/* end::Body */}
          </div>
        </div>
      </div>

      <div className='d-flex flex-wrap flex-stack my-4'>
        <h3 className='fw-bolder my-2 ps-1'>
          {header}
          <span className='fs-6 text-gray-400 fw-bold ms-1'>({filteredProjects.length})</span>
        </h3>

        <div className='d-flex flex-wrap my-2'>
          {/* <div className='me-4'>
            <input
              type='text'
              className='form-control form-control-solid form-control-sm'
              placeholder='Search...'
              onChange={search}
            />
          </div> */}

          <div className='me-4'>
            <select
              name='status'
              className='form-select form-select-sm form-select-white w-125px'
              defaultValue='all'
              onChange={handleChange}
            >
              <optgroup label='Active / Inactive'>
                <option value='all'>All ({projects.length})</option>
                <option value='active'>
                  Active ({projects.filter((c: any) => c.isActive === true).length})
                </option>
                <option value='passive'>
                  InActive ({projects.filter((c: any) => c.isActive === false).length})
                </option>
              </optgroup>
              <optgroup label='Type'>
                <option value='Not Started'>
                  Not Started ({projects.filter((p: any) => p?.status === 'Not Started').length})
                </option>
                <option value='To Do'>
                  To Do ({projects.filter((p: any) => p?.status === 'To Do').length})
                </option>
                <option value='In Progress'>
                  In Progress ({projects.filter((p: any) => p?.status === 'In Progress').length})
                </option>
                <option value='Done'>
                  Done ({projects.filter((p: any) => p?.status === 'Done').length})
                </option>
              </optgroup>
            </select>
          </div>
          {/* <div className='me-0'>
            <a
              href='#'
              className='btn btn-primary btn-sm'
              // data-bs-toggle='modal'
              // data-bs-target='#modal_client_settings'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
            >
              New Client
            </a>
            <DropdownNewClient />
          </div> */}
        </div>
      </div>

      <div className='row'>
        {filteredProjects &&
          filteredProjects
            ?.sort((a: any, b: any) => a.clientUID.localeCompare(b.clientUID))
            ?.map((project: any, index) => (
              <div className='card card-flush mb-5' key={index}>
                <div className='card-header px-2'>
                  <div className='card-title m-0'>
                    <div className='flex-grow-1 mx-2'>
                      <span className='text-primary fs-7 fw-bold'>
                        {project?.name} <span className='text-primary-light fs-8 fw-normal'></span>
                      </span>
                      <span className='text-muted fw-semibold d-block fs-8'>
                        {moment(project?.startDate).format('DD.MM.YYYY')} -{' '}
                        {moment(project?.endDate).format('DD.MM.YYYY')}
                      </span>
                    </div>
                  </div>

                  <div className='d-flex flex-wrap my-2'>
                    <div className='align-self-center me-3'>
                      <div className='d-flex text-end'>
                        {/* <div className='text-primary fw-bold text-muted mb-1'>Teams</div> */}
                        <UsersAvatars
                          usersData={project?.authorities}
                          maxLeght={10}
                          smybolGroupStyle='justify-content-end symbol-hover min-h-30px'
                          symbolStyle='symbol-30px symbol-circle'
                        />

                        <div className='d-flex flex-row-reverse me-2 ms-6'>
                          {project?.period === 'monthly' && (
                            <span className='badge badge-success w-50px'>
                              <span className='text-center w-100'>Fee</span>
                            </span>
                          )}
                          {project?.period === 'oneTime' && (
                            <span className='badge badge-warning w-50px'>
                              <span className='text-center w-100'>Project</span>
                            </span>
                          )}
                          {project?.period === 'yearly' && (
                            <span className='badge badge-primary w-50px'>
                              <span className='text-center w-100'>Yearly</span>
                            </span>
                          )}
                        </div>
                        <div className='d-flex flex-row-reverse me-6'>
                          {project?.status === 'Not Started' && (
                            <span className='badge badge-primary w-80px'>
                              <span className='text-center w-100'>Not Started</span>
                            </span>
                          )}
                          {project?.status === 'To Do' && (
                            <span className='badge badge-info w-80px'>
                              <span className='text-center w-100'>To Do</span>
                            </span>
                          )}
                          {project?.status === 'In Progress' && (
                            <span className='badge badge-warning w-80px'>
                              <span className='text-center w-100'>In Progress</span>
                            </span>
                          )}
                          {project?.status === 'Done' && (
                            <span className='badge badge-success w-80px'>
                              <span className='text-center w-100'>Done</span>
                            </span>
                          )}
                        </div>
                        <div className='d-flex'>
                          <div className='w-100px bg-primary text-white rounded p-2 px-4 fs-7 fw-bold text-center ms-2'>
                            <h5 className='text-white fs-9 my-1 text-normal'>Incomes</h5>
                            <span className='fw-bold'>
                              {projectProfit?.map(
                                (p: any) => p.id === project.id && currencyFormat(p?.incomes)
                              )}
                            </span>
                          </div>
                          <div className='w-100px bg-danger text-white rounded p-2 px-4 fs-7 fw-bold text-center ms-2'>
                            <h5 className='text-white fs-9 my-1 text-normal'>Expenses</h5>
                            <span className='fw-bold'>
                              {' '}
                              {projectProfit?.map(
                                (p: any) => p.id === project.id && currencyFormat(p?.expenses)
                              )}
                            </span>
                          </div>
                          <div className='w-100px bg-success text-white rounded p-2 px-4 fs-7 fw-bold text-center ms-2'>
                            <h5 className='text-white fs-9 my-1 text-normal'>Profit</h5>
                            <span className='fw-bold'>
                              {projectProfit?.map(
                                (p: any) =>
                                  p.id === project.id && currencyFormat(p?.incomes - p?.expenses)
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
      </div>
    </>
  )
}
