import moment from 'moment'
import React from 'react'
import {useStopwatch, useTimer} from 'react-timer-hook'

const StopWatch = ({startTime, className, style}) => {
  const duration = moment.duration(moment().diff(startTime))

  const formatTime = (time) => {
    return String(time).padStart(2, '0')
  }

  const offsetTimestamp = new Date()
  offsetTimestamp.setSeconds(offsetTimestamp.getSeconds() + Number(duration.asSeconds().toFixed(0)))
  const {seconds, minutes, hours, days, start, pause} = useStopwatch({
    offsetTimestamp: offsetTimestamp,

    autoStart: true, // Timer otomatik olarak başlasın mı?
  })

  return (
    <span className={className} style={style}>
      <span className='text-start'>{formatTime(minutes)}</span>
      <span className='text-center'>:</span>
      <span className='text-end'>{formatTime(seconds)}</span>
    </span>
  )
}

export default StopWatch
