/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {SocialMediaContentStatusModels} from '../../models/SocialMediaModels'

import {
  Timestamp,
  addDoc,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  serverTimestamp,
  setDoc,
  updateDoc,
} from 'firebase/firestore'
import {useParams} from 'react-router-dom'
import {TagsInput} from 'react-tag-input-component'

import {useAuthContext} from '../../../../../../../../auth'
import {db, storage} from '../../../../../../../../../../firebase/config'

import DatePicker from 'react-datepicker'
import moment from 'moment'

import addSeconds from 'date-fns/addSeconds'
import {deleteObject, ref} from 'firebase/storage'

type Props = {
  contentData: any
  contents: any
}

const Settings: FC<Props> = ({contentData, contents}) => {
  const [content, setContent] = useState(contentData?.contentText)
  const [tags, setTags] = useState<any | null>(contentData?.contentTags)

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  const [copyLoading, setCopyLoading] = useState(false)
  const [copySuccess, setCopySuccess] = useState(false)
  const [error, setError] = useState('')

  const highlightWithRanges = [
    {
      'react-datepicker__day--highlighted-custom-1':
        contents && contents.map((content: any) => addSeconds(content?.publishDate?.toDate(), 1)),
    },
  ]

  const [publishDate, setPublishDate] = useState(
    contentData?.publishDate ? contentData?.publishDate.toDate() : null
  )

  const [startDate, setStartDate] = useState(
    contentData?.budget?.startDate ? contentData?.startDate?.endDate.toDate() : null
  )

  const [endDate, setEndDate] = useState(
    contentData?.budget?.endDate ? contentData?.budget?.endDate.toDate() : null
  )
  const [budget, setBudget] = useState(
    contentData?.budget?.budget ? contentData?.budget?.budget : 0
  )
  const [note, setNote] = useState(contentData?.budget?.note ? contentData?.budget?.note : '')

  const {id, projectID} = useParams()
  const clientUID = id ? id : ''
  const projectUID = projectID ? projectID : ''

  const {user} = useAuthContext()

  const onChange = (dates) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
    console.log(new Date())
  }

  const handleContent = (event) => {
    setContent(event.target.value)
  }
  const handleClick = async (type) => {
    console.log(type)
    const docRef = doc(
      db,
      'clients',
      clientUID,
      'projects',
      projectUID,
      'socialMediaContents',
      contentData.id
    )
    if (type === 'Move') {
      setLoading(true)
      const tabs = document.getElementById(`contents-${contentData.id}`)
      tabs?.click()
      const oldDate = contentData?.publishDate.toDate()
      setDoc(
        docRef,
        {
          publishDate: publishDate,
        },
        {merge: true}
      )
        .then(async () => {
          let actionValue =
            'I changed publish date from ' +
            moment(oldDate).format('DD.MM.YYYY hh:mm') +
            ' to ' +
            moment(publishDate).format('DD.MM.yyyy hh:mm') +
            '.'
          updateDoc(docRef, {
            logs: arrayUnion({
              displayName: user.displayName,
              uid: user.uid,
              photoURL: user?.photoURL,
              action: actionValue,
              createdAt: Timestamp.now(),
              value: publishDate,
            }),
          })
          setLoading(false)
          setSuccess(true)
          setTimeout(() => {
            setSuccess(false)
          }, 1000)
        })
        .catch((error) => {
          setLoading(false)
          console.log(error.message)
        })
    }
    if (type === 'Copy') {
      setCopyLoading(true)

      addDoc(
        collection(db, 'clients', clientUID, 'projects', projectUID, 'socialMediaContents'),
        contentData
      ).then((data) => {
        console.log(data.id)
        let newDataID = data.id

        const newDocRef = doc(
          db,
          'clients',
          clientUID,
          'projects',
          projectUID,
          'socialMediaContents',
          newDataID
        )
        setDoc(
          newDocRef,
          {
            publishDate: publishDate,
          },
          {merge: true}
        )
          .then((data) => {
            let actionValue =
              'I copied this content to ' + moment(publishDate).format('DD.MM.yyyy hh:mm')
            updateDoc(docRef, {
              logs: arrayUnion({
                displayName: user.displayName,
                uid: user.uid,
                photoURL: user?.photoURL,
                action: actionValue,
                createdAt: Timestamp.now(),
                value: contentData,
              }),
            })
            setCopyLoading(false)
            setCopySuccess(true)
            setTimeout(() => {
              setCopySuccess(false)
            }, 1000)
          })
          .catch((error) => {
            setLoading(false)
            console.log(error.message)
          })
      })
      // console.log(doc)
      // if (token) {
      //   setDoc(
      //     docRef,
      //     {
      //       publishDate: publishDate,
      //     },
      //     {merge: true}
      //   )
      //     .then(async () => {
      //       addDoc(
      //         collection(
      //           db,
      //           'clients',
      //           clientUID,
      //           'projects',
      //           projectUID,
      //           'socialMediaContents',
      //           contentData.id,
      //           'logs'
      //         ),
      //         {
      //           displayName: user.displayName,
      //           uid: user.uid,
      //           photoURL: user?.photoURL,
      //           action: 'publishDateChanged',
      //           createdAt: serverTimestamp(),
      //         }
      //       )
      //       setLoading(false)
      //       setSuccess(true)
      //       setTimeout(() => {
      //         setSuccess(false)
      //       }, 1000)
      //     })
      //     .catch((error) => {
      //       setLoading(false)
      //       console.log(error.message)
      //     })
    }
    if (type === 'Delete') {
      if (contentData?.files) {
        console.log('Dosya Var')
        // const fileRef = ref(storage, `clients/${clientUID}/projects/${projectUID}/${fileName}`)
        // deleteObject(fileRef)
        // .then(() => {
        //   console.log('File deleted.')
        // })
        // .catch((error) => {
        //   console.log(error.message)
        // })

        contentData?.files.forEach(async (file: any) => {
          console.log(file)
          let storageRef = ref(
            storage,
            `clients/${clientUID}/projects/${projectUID}/${file.fileName}`
          )
          await deleteObject(storageRef)
            .then(() => {
              console.log(file.id + ' File deleted.')
            })
            .catch((error) => {
              console.log(error.message)
            })
        })
      }
      const tabs = document.getElementById(`contents-${contentData.id}`)
      tabs?.click()

      const docRef = doc(
        db,
        'clients',
        clientUID,
        'projects',
        projectUID,
        'socialMediaContents',
        contentData.id
      )
      deleteDoc(docRef)
        .then(() => {
          console.log('Entire Document has been deleted successfully.')
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  return (
    <>
      <label className='fw-row col-form-label pt-1 text-primary fw-bold fs-6 mb-1'>Settings</label>

      <div className='mb-4'>
        <label className='form-label text-primary'>Change Publish Date</label>

        <div className='fv-row d-flex mb-4'>
          <DatePicker
            selected={publishDate}
            onChange={(date) => setPublishDate(date)}
            timeInputLabel=''
            // minDate={new Date()}
            dateFormat='dd.MM.yyyy hh:mm'
            calendarStartDay={1}
            highlightDates={highlightWithRanges}
            showTimeInput
            className={'form-control  form-control-solid'}
          ></DatePicker>
          <button
            className={`btn btn-sm btn-primary mx-3 ${success && 'btn-success'}`}
            disabled={loading}
            onClick={() => handleClick('Move')}
          >
            {success && (
              <>
                <span className='badge badge-success me-5 px-6'></span>
                <span className='' style={{display: 'block'}}>
                  <i className='bi bi-check fs-2'></i>
                  Success
                </span>
              </>
            )}
            {!success && (
              <>
                {!loading && 'Move'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </>
            )}
          </button>
          <button
            type='submit'
            className={`btn btn-sm btn-primary ${copySuccess && 'btn-success'}`}
            disabled={copyLoading}
            onClick={() => handleClick('Copy')}
          >
            {copySuccess && (
              <>
                <span className='badge badge-success me-5 px-6'></span>
                <span className='' style={{display: 'block'}}>
                  <i className='bi bi-check fs-2'></i>
                </span>
              </>
            )}
            {!copySuccess && (
              <>
                {!copyLoading && 'Copy'}
                {copyLoading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </>
            )}
          </button>
        </div>
        <div className='fv-row mb-4'></div>

        <div className='fv-row mb-4'>
          <label className='form-label text-primary'>Delete Content</label>
          <button
            type='submit'
            className={`btn  btn-light-primary w-100 ${success && 'btn-success'}`}
            disabled={loading}
            onClick={() => handleClick('Delete')}
          >
            {success && (
              <>
                <span className='badge badge-success me-5 px-6'></span>
                <span className='' style={{display: 'block'}}>
                  <i className='bi bi-check fs-2'></i>
                  Success
                </span>
              </>
            )}
            {!success && (
              <>
                {!loading && 'Delete Content & Files'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </>
            )}
          </button>
        </div>
      </div>

      <div className='d-flex justify-content-end'>
        {/* <button
          type='reset'
          className='btn btn-sm btn-light btn-active-light-primary me-2'
          data-kt-menu-dismiss='true'
        >
          Cancel
        </button> */}
      </div>
    </>
  )
}

export {Settings}
