/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import moment from 'moment'

import {useCollection} from '../../../hooks/useCollection'
import {CardWork} from '../../../../_metronic/partials/content/cards/CardWork'
import {InputText} from 'primereact/inputtext'
import {useGetWorks} from '../Functions/useGetWorks'
import {useAuthContext} from '../../auth'

type Props = {
  userUID: string
  scroll?: boolean
  showStart?: boolean
}

const UserWorks: FC<Props> = ({userUID, scroll, showStart}) => {
  const {worksDocuments: works, pinWorksDocuments: pinWorks} = useGetWorks(userUID)

  const [search, setSearch] = useState('')
  return (
    <div
      className={`px-0 pt-0 card-body ${scroll === true && 'pe-4 card-scroll vh-100'}`}
      style={{paddingBottom: '150px'}}
    >
      <span className='form-control form-control-sm form-control-solid p-0 p-input-icon-left p-input-icon-right mt-2 mb-4'>
        <i className='bi bi-search w-30px' />
        <InputText
          autoFocus
          placeholder='Search work or task...'
          className='form-control form-control-sm  form-control-solid ps-10'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        {search !== '' && (
          <i className='bi bi-x-circle cursor-pointer' onClick={(e) => setSearch('')} />
        )}
      </span>

      {pinWorks &&
        pinWorks.length > 0 &&
        pinWorks.filter(
          (workD: any, i) =>
            workD?.projectName.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
            workD?.clientName.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
            workD?.workName.toLowerCase().indexOf(search.toLowerCase()) !== -1
        ).length > 0 && (
          <div className='mb-4'>
            <div className='d-flex align-items-center mt-1'>
              <span className='fs-7 text-primary fw-bold flex-fill'>Pinned Works</span>
              <span className='badge badge-sm badge-warning fs-9   me-1'>
                {' '}
                <i className='bi bi-pin-angle-fill text-white fs-3'></i>
              </span>
            </div>
            <div className='separator separator-dashed my-2'></div>
            {pinWorks.map(
              (workD: any, i) =>
                (workD?.projectName.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                  workD?.clientName.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                  workD?.workName.toLowerCase().indexOf(search.toLowerCase()) !== -1) && (
                  <CardWork
                    workData={workD}
                    key={i}
                    showStart={showStart ? showStart : false}
                    isTeamTaskPage={false}
                    userUID={userUID}
                  />
                )
            )}
          </div>
        )}

      {works &&
        works
          .sort((a: any, b: any) => (a.workDeadline < b.workDeadline ? -1 : 1))
          .map(
            (work: any, index) =>
              work[1]?.filter(
                (workD: any, i) =>
                  workD?.projectName.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                  workD?.clientName.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                  workD?.workName.toLowerCase().indexOf(search.toLowerCase()) !== -1
              ).length > 0 && (
                <div className='mb-4' key={index}>
                  <div className='d-flex align-items-center mt-1'>
                    <span className='fs-7 text-primary fw-bold flex-fill'>
                      {moment(work[0]).format('DD MMM dddd')}
                    </span>

                    {/* {moment(work[0]).fromNow()} */}
                    {moment.duration(moment(work[0]).diff(moment().startOf('day'))).asDays() ===
                      0 && (
                      <span className='badge badge-sm badge-success fs-9 p-2  me-1'> Today</span>
                    )}
                    {moment.duration(moment(work[0]).diff(moment().startOf('day'))).asDays() <
                      0 && (
                      <span className='badge badge-sm badge-danger fs-9 p-2  me-1'>
                        {' '}
                        {moment(work[0]).fromNow()}
                      </span>
                    )}
                    {moment.duration(moment(work[0]).diff(moment().startOf('day'))).asDays() >
                      0 && (
                      <span className='badge badge-sm badge-primary fs-9 p-2  me-1'>
                        {' '}
                        {moment(work[0]).fromNow()}
                      </span>
                    )}
                  </div>
                  <div className='separator separator-dashed my-2'></div>
                  {work[1]
                    ?.sort((a: any, b: any) => (a.workDeadline < b.workDeadline ? -1 : 1))
                    .map(
                      (workD: any, i) =>
                        (workD?.projectName.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                          workD?.clientName.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                          workD?.workName.toLowerCase().indexOf(search.toLowerCase()) !== -1) && (
                          <CardWork
                            workData={workD}
                            key={i}
                            showStart={showStart ? showStart : false}
                            isTeamTaskPage={false}
                            userUID={''}
                          />
                        )
                    )}
                </div>
              )
          )}
    </div>
  )
}

export {UserWorks}
