/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import clsx from 'clsx'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import moment from 'moment'
import {DropdownSocialMediaChannelType} from '../dropdown/DropdownSocialMediaChannelType'
import {
  SocialMediaChannelModels,
  SocialMediaContentStatusModels,
} from '../../components/models/SocialMediaModels'
import {DropdownSocialMediaContentStatus} from '../dropdown/DropdownSocialMediaContentStatus'

import {TextContent} from './tabs/TextContent'
import {useCollection} from '../../../../../../../../hooks/useCollection'
import {KTSVG, toAbsoluteUrl} from '../../../../../../../../../_metronic/helpers'
import {Avatar} from '../../../../../../../../../_metronic/partials'
import {MediaContent} from './tabs/MediaContent'
import {BriefContent} from './tabs/BriefContent'
import {BudgetContent} from './tabs/BudgetContent'
import {Settings} from './tabs/Settings'
import {Logs} from './tabs/Logs'
import {Image} from 'primereact/image'
import {Channels} from './tabs/Channels'

const btnClass = 'btn btn-sm btn-icon btn-light-primary h-31px w-31px'

type Props = {
  contents: any
  contentData?: any
  socialMediaSettings?: any
  media: string
  isStory?: boolean
  badgeColor: string
  publishDate?: any
  publishTime?: string
  status: string
  statusColor: string
  content: string
  logsCount?: string
  date: string
  budget: string
  progress: number
}

const CardSocialMediaContent: FC<Props> = ({
  contents,
  media,
  socialMediaSettings,
  contentData,
  badgeColor,
  isStory,
  publishDate,
  publishTime,
  status,
  statusColor,
  logsCount,
  content,
  date,
  budget,
  progress,
}) => {
  const mediaClick = () => {
    const tabs = document.getElementById(`mediasID-${contentData.id}`)
    tabs?.click()
  }

  return (
    <div
      className='d-flex col mb-6 card  p-0  mx-3'
      style={{minWidth: '400px', maxWidth: '450px'}}
      key={contentData.id}
      id={contentData.id}
    >
      <div className='d-flex h-100' key={contentData.id}>
        <div className='d-flex flex-column'>
          <div className='bg-light-primary p-2 border rounded-end-0 rounded-bottom-0 border-end-0 border-bottom-0 pt-3 rounded h-45px'>
            {/* {contentData?.loading && <span className='indicator-progress mx-2 mt-1 mb-3 ' style={{display: 'block'}}>
              <span className='spinner-border-sm spinner-border align-middle  w-20px h-20px'></span>
            </span>
            } */}
            <span
              className={`badge badge-circle badge-${
                SocialMediaContentStatusModels.find((s) => s.name === contentData?.status)?.class
              } mx-2 mt-1 mb-3 w-20px h-20px `}
            ></span>
          </div>
          <ul className='nav d-flex flex-fill bg-light-primary p-2 border border-top-0 border-end-0 rounded-top-0 rounded-end-0 pt-1 rounded flex-column fs-7'>
            <li>
              <OverlayTrigger
                placement='right'
                overlay={<Tooltip id='tooltip-contentType-name'>View</Tooltip>}
              >
                <a
                  id={`contents-${contentData.id}`}
                  className={clsx('position-relative w-30px h-30px mb-2 active', btnClass)}
                  data-bs-toggle='tab'
                  href={`#content-${contentData.id}`}
                >
                  <i className='bi bi-eye fs-4'></i>
                </a>
              </OverlayTrigger>
            </li>
            <li>
              <OverlayTrigger
                placement='right'
                overlay={<Tooltip id='tooltip-contentType-name'>Text</Tooltip>}
              >
                <a
                  className={clsx('position-relative w-30px h-30px mb-2', btnClass)}
                  data-bs-toggle='tab'
                  href={`#textContent-${contentData.id}`}
                >
                  <i className='bi bi-type fs-4'></i>
                </a>
              </OverlayTrigger>
            </li>
            <li>
              <OverlayTrigger
                placement='right'
                overlay={<Tooltip id='tooltip-contentType-name'>Media</Tooltip>}
              >
                <a
                  id={`mediasID-${contentData.id}`}
                  className={clsx('position-relative w-30px h-30px mb-2', btnClass)}
                  data-bs-toggle='tab'
                  href={`#medias-${contentData.id}`}
                >
                  <i className='bi bi-images fs-4'></i>
                </a>
              </OverlayTrigger>
            </li>
            <li>
              <OverlayTrigger
                placement='right'
                overlay={<Tooltip id='tooltip-contentType-name'>Brief</Tooltip>}
              >
                <a
                  className={clsx('position-relative w-30px h-30px mb-2', btnClass)}
                  data-bs-toggle='tab'
                  href={`#brief-${contentData.id}`}
                >
                  <i className='bi bi-lightbulb fs-4'></i>
                  {contentData?.brief && (
                    <label className='rounded-4 fw-bolder fs-9 w-10px h-10px bg-warning text-primary shadow position-absolute top-25 start-75 translate-middle'></label>
                  )}
                </a>
              </OverlayTrigger>
            </li>
            <li>
              <OverlayTrigger
                placement='right'
                overlay={<Tooltip id='tooltip-contentType-name'>Budget</Tooltip>}
              >
                <a
                  className={clsx('position-relative w-30px h-30px mb-2', btnClass)}
                  data-bs-toggle='tab'
                  href={`#budget-${contentData.id}`}
                >
                  <i className='bi bi-currency-dollar fs-4'></i>
                  {(contentData?.budget?.startDate ||
                    contentData?.budget?.endDate ||
                    contentData?.budget?.budget ||
                    contentData?.budget?.note) && (
                    <label className='rounded-4 fw-bolder fs-9 w-10px h-10px bg-warning text-primary shadow position-absolute top-25 start-75 translate-middle'></label>
                  )}
                </a>
              </OverlayTrigger>
            </li>
            <li>
              <OverlayTrigger
                placement='right'
                overlay={<Tooltip id='tooltip-contentType-name'>Logs</Tooltip>}
              >
                <a
                  className={clsx('position-relative w-30px h-30px mb-2', btnClass)}
                  data-bs-toggle='tab'
                  href={`#logs-${contentData.id}`}
                >
                  <i className='bi bi-chat-right-text fs-4'></i>
                </a>
              </OverlayTrigger>
            </li>
            <li>
              <OverlayTrigger
                key={`settings-${contentData.id}`}
                placement='right'
                overlay={<Tooltip id='tooltip-contentType-name'>Settings</Tooltip>}
              >
                <a
                  className={clsx('position-relative w-30px h-30px mb-2', btnClass)}
                  data-bs-toggle='tab'
                  href={`#settings-${contentData.id}`}
                >
                  <i className='bi bi-gear fs-4'></i>
                </a>
              </OverlayTrigger>
            </li>
            <li className='mt-auto'>
              <Channels contentData={contentData} socialMediaSettings={socialMediaSettings} />
            </li>
          </ul>
        </div>
        <div className='d-flex flex-column  w-100   border border-start-0  rounded rounded-start-0'>
          <div
            className='card-header bg-light-primary  rounded-start-0  border-bottom-0 ps-1 pe-3 mb-4'
            style={{minHeight: '45px'}}
          >
            <div className='card-title m-0 fs-8 fw-bold text-primary'>
              {/* <span
                className={`badge badge-circle badge-${
                  SocialMediaContentStatusModels.find((s) => s.name === contentData?.status)?.class
                } p-1 w-15px h-15px me-2`}
              ></span> */}
              {moment(contentData.publishDate.toDate()).format('dddd / DD MMMM ')}
            </div>

            <div className='card-toolbar m-0'>
              <div className='m-0'>
                {/* <a
              href='#'
              className={`badge badge-light-${
                SocialMediaContentStatusModels.find((s) => s.name === contentData?.status)?.class
              } fw-bolder px-4 py-3 me-2`}
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
            >
              {SocialMediaContentStatusModels.find((s) => s.name === contentData?.status)?.name}
            </a> */}
                <DropdownSocialMediaContentStatus contentData={contentData} />
              </div>
              {/* <div className='me-2'>
            <a href='#' className={clsx('position-relative', btnClass)}>
              <KTSVG path='/media/icons/search.svg' className={'svg-icon-2'} />
            </a>
          </div> */}
              {/* <div className='m-0'>
            <a
              href='#'
              className={clsx('position-relative', btnClass)}
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
            >
              <KTSVG path='/media/icons/three-dots.svg' className={'svg-icon-2'} />
            </a>
            <DropdownSocialMediaContentSettings contentData={contentData} />
          </div> */}
            </div>
          </div>

          <div className='card-body w-100 p-4 py-0 ps-4 pb-6' key={`tabContent-${contentData.id}`}>
            <div
              className='tab-content'
              id={`tabContent-${contentData.id}`}
              key={`tabContent-${contentData.id}`}
            >
              <div
                className='tab-pane fade show active'
                id={`content-${contentData.id}`}
                role='tabpanel'
              >
                {(!contentData?.files || contentData?.files?.length === 0) && (
                  <button className='btn btn-link p-0' onClick={mediaClick}>
                    <img
                      src={toAbsoluteUrl('/media/content/blankContent.png')}
                      alt='Add Media'
                      className='rounded-1 w-100'
                      object-fit='cover'
                      aspect-ratio='1 / 1'
                    />
                  </button>
                )}

                {contentData?.files &&
                  contentData?.files?.length === 1 &&
                  contentData?.files.map(
                    (file: any, i) =>
                      i === 0 && (
                        <div className='w-100 h-auto lh-0' key={i}>
                          {file.fileType === 'image/png' && (
                            <Image
                              src={file.downloadURL}
                              alt='Image'
                              width='100%'
                              className='rounded'
                              imageClassName='rounded'
                              preview
                              downloadable
                            />

                            // <img
                            //   src={file.downloadURL}
                            //   alt='card2'
                            //   className='rounded-1 w-100'
                            //   object-fit='cover'
                            //   aspect-ratio='1 / 1'
                            // />
                          )}
                          {file.fileType === 'video/mp4' && (
                            <video
                              controls
                              className='rounded-1 w-100'
                              object-fit='cover'
                              aspect-ratio='1 / 1'
                            >
                              <source src={file.downloadURL} type={file.fileType} />
                              Sorry, your browser doesn't support videos.
                            </video>
                          )}
                        </div>
                      )
                  )}

                {contentData?.files?.length > 1 && (
                  <div
                    id={`kt_carousel_${contentData.id}`}
                    className='carousel carousel-custom slide'
                    data-bs-ride='false'
                    data-bs-interval='false'
                  >
                    <div className='carousel-inner'>
                      {contentData?.files &&
                        contentData?.files?.length > 1 &&
                        contentData?.files.map((file: any, i) => (
                          <div className={`carousel-item ${i === 0 && 'active'}`} key={i}>
                            <div className='w-100 h-auto lh-0'>
                              {file.fileType === 'image/png' && (
                                <Image
                                  src={file.downloadURL}
                                  alt='Image'
                                  width='100%'
                                  className='rounded'
                                  imageClassName='rounded'
                                  preview
                                  downloadable
                                />
                                // <img
                                //   src={file.downloadURL}
                                //   alt='card2'
                                //   className='rounded-1 w-100'
                                //   object-fit='cover'
                                //   aspect-ratio='1 / 1'
                                // />
                              )}
                              {file.fileType === 'video/mp4' && (
                                <video
                                  controls
                                  className='rounded-1 w-100'
                                  object-fit='contain'
                                  aspect-ratio='1 / 1'
                                >
                                  <source src={file.downloadURL} type={file.fileType} />
                                  Sorry, your browser doesn't support videos.
                                </video>
                              )}
                            </div>
                          </div>
                        ))}
                    </div>
                    <div className='d-flex align-items-end justify-content-end flex-wrap'>
                      <ol className='carousel-indicators carousel-indicators-dots h-15px mt-2'>
                        {contentData?.files &&
                          contentData?.files?.length > 1 &&
                          contentData?.files.map((file: any, i) => (
                            <li
                              data-bs-target={`#kt_carousel_${contentData.id}`}
                              data-bs-slide-to={i}
                              className={`ms-1 ${i === 0 && 'active'}`}
                              key={i}
                            ></li>
                          ))}
                      </ol>
                    </div>
                  </div>
                )}

                {contentData?.contentText && (
                  <p className='text-primary fs-6 ms-1 mb-1 mt-2 '>{contentData?.contentText}</p>
                )}
                {contentData?.contentTags?.length > 0 && (
                  <p className='text-primary fs-6 ms-1 mb-2 '>
                    {contentData?.contentTags?.map((tag: any) => '#' + tag + ' ')}
                  </p>
                )}
              </div>
              <div
                className='tab-pane fade card-scroll scroll-y h-375px'
                id={`textContent-${contentData.id}`}
                role='tabpanel'
              >
                <TextContent contentData={contentData} />
              </div>
              <div
                className='tab-pane fade card-scroll scroll-y h-375px'
                id={`medias-${contentData.id}`}
                role='tabpanel'
              >
                <MediaContent contentData={contentData} />
              </div>

              <div
                className='tab-pane fade card-scroll scroll-y h-375px'
                id={`brief-${contentData.id}`}
                role='tabpanel'
              >
                <BriefContent contentData={contentData} />
              </div>

              <div
                className='tab-pane fade card-scroll scroll-y h-375px'
                id={`budget-${contentData.id}`}
                role='tabpanel'
              >
                <BudgetContent contentData={contentData} />
              </div>

              <div className='tab-pane fade' id={`settings-${contentData.id}`} role='tabpanel'>
                <Settings contentData={contentData} contents={contents} />
              </div>

              <div
                className='tab-pane fade card-scroll scroll-y h-100'
                id={`logs-${contentData.id}`}
                role='tabpanel'
              >
                <Logs contentData={contentData} />
              </div>
            </div>
          </div>
        </div>
        {/* <div className='card-footer p-5'>
        <div className='d-flex justify-content-between align-self-end'>
          <div className='symbol-group symbol-hover ms-0'>
            {channels &&
              SocialMediaChannelModels.map(
                (c: any, i) =>
                  channels[c.name] && (
                    <OverlayTrigger
                      key={`${i}-${c.name + '-' + c.type}`}
                      placement='top'
                      overlay={
                        <Tooltip id='tooltip-contentType-name'>
                          {c.name + ' ' + channels[c.name]?.map((type: any) => ' ' + type.type)}
                        </Tooltip>
                      }
                    >
                      <div
                        className='badge badge-square badge-light hover-primary w-30px h-30px me-1'
                        data-bs-toggle='tooltip'
                        key={`cw7-item-${i}`}
                      >
                        <i className={`${c?.icon} fs-6`}></i>
                      </div>
                    </OverlayTrigger>
                  )
              )}

            <div className='m-0'>
              <a
                href='#'
                className={clsx('position-relative w-30px h-30px', btnClass)}
                data-kt-menu-trigger='click'
                data-kt-menu-placement='top'
              >
                <KTSVG path='/media/icons/add.svg' className={'svg-icon-4'} />
              </a>
              <DropdownSocialMediaChannelType
                socialMediaSettings={socialMediaSettings}
                contentChannel={contentData?.channels}
                contentID={contentData.id}
              />
            </div>
          </div>
          <div>
            <a
              onClick={() => setSettings(!settings)}
              className={`btn btn-sm btn-light h-30px px-3 me-1 ${settings && 'active'}`}
            >
              <i className='bi bi-chat-right-text-fill  fs-6'></i>({contentData?.logs.length})
            </a>
            <a onClick={() => setSettings(!settings)} className='btn btn-sm btn-light h-30px px-3'>
              <i className='bi bi-images fs-6 pb-1'></i>
              (0)
            </a>
            <a
              className={clsx('position-relative w-30px h-30px', btnClass)}
              data-bs-toggle='tab'
              onClick={() => setSettings(!settings)}
            >
              <i className='bi bi-gear fs-4'></i>
            </a>
          </div>
        </div>
      </div> */}
      </div>
    </div>
  )
}

export {CardSocialMediaContent}
