import {doc, setDoc} from 'firebase/firestore'
import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {db} from '../../../../../../firebase/config'

type Props = {
  clientData: any
}

const AcvitePassive: React.FC<Props> = (clientData: any) => {
  const currentClient = clientData?.clientData
  const {id} = useParams()
  const clientUID = id ? id : ''

  const [checked, setChecked] = React.useState(false)

  useEffect(() => {
    if (currentClient) {
      currentClient.isActive === true && setChecked(true)
      console.log(currentClient)
    }
  }, [currentClient])

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<any | null>(null)
  const [success, setSuccess] = useState(false)

  const handleChange = async () => {
    setError(null)
    setLoading(true)
    setSuccess(false)
    const docRef = doc(db, 'clients', clientUID)

    setDoc(
      docRef,
      {
        isActive: !checked,
      },
      {merge: true}
    )
      .then(async () => {
        setChecked(!checked)
        setSuccess(true)
        setSuccess(true)
        setTimeout(() => {
          setSuccess(false)
        }, 3000)
        setLoading(false)
      })
      .catch((error) => {
        setError(error.message)
        console.log(error.message)
      })
  }

  return (
    currentClient && (
      <div className='card mb-5'>
        <div
          className='card-header border-0 cursor-pointer align-items-center'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#activePassive'
          aria-expanded='true'
          aria-controls='activePassive'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder text-primary m-0'>Status</h3>
          </div>
          <div className='float-end'>
            {checked ? (
              <span className='badge badge-success p-2'>Active</span>
            ) : (
              <span className='badge badge-danger p-2'>InActive</span>
            )}
          </div>
        </div>

        <div id='activePassive' className='collapse'>
          <div className='card-body border-top p-9'>
            <div className='py-2'>
              <div className='d-flex flex-stack'>
                <div className='d-flex'>
                  <div className='d-flex flex-column'>
                    <span className='fs-5 text-dark fw-bolder'>Change Client Status</span>
                    <div className='fs-6 fw-bold text-gray-400'>
                      You can change the brand to active or inactive
                    </div>
                  </div>
                </div>
                <div className='d-flex justify-content-end'>
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      <span className='spinner-border spinner-border-sm align-middle me-4 mt-2'></span>
                    </span>
                  )}
                  {success && (
                    <>
                      <span className='text-success'>
                        <i className='bi bi-check text-success fs-2x me-2 mt-2'></i>
                      </span>
                    </>
                  )}
                  {error && (
                    <>
                      <span className='text-danger pt-1'>
                        {error}
                        <i className='bi bi-exclamation-circle text-danger fs-2 me-2 pt-4'></i>
                      </span>
                    </>
                  )}
                  <div className='form-check form-switch'>
                    <input
                      className='form-check-input w-45px h-30px'
                      type='checkbox'
                      id='web-notification-input'
                      value='on'
                      defaultChecked={currentClient?.isActive === true && true}
                      onChange={handleChange}
                    />
                    <label className='form-check-label' htmlFor='web-notification-input'></label>
                  </div>
                </div>
              </div>

              {/* <div className='separator separator-dashed my-5'></div> */}
            </div>
          </div>
        </div>
      </div>
    )
  )
}

export {AcvitePassive}
