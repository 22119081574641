import {useEffect, useRef, useState} from 'react'
import {
  ContentStatusWidget,
  PlanWidget,
} from '../../../../../../_metronic/partials/widgets/social-media'
import {useParams} from 'react-router-dom'
import {
  arrayUnion,
  doc,
  getDoc,
  updateDoc,
  Timestamp,
  onSnapshot,
  setDoc,
  addDoc,
  collection,
} from 'firebase/firestore'
import {db} from '../../../../../../firebase/config'
import {CardWork} from '../../../../../../_metronic/partials/content/cards/CardWork'
import {Avatar} from '../../../../../../_metronic/partials'
import {useCollection} from '../../../../../hooks/useCollection'
import moment from 'moment'
import {FileUploader} from 'react-drag-drop-files'
import {useFilesUpload} from '../../../../../core/storage/useFilesUpload'
import {toAbsoluteUrl} from '../../../../../helpers'
import {useAuthContext} from '../../../../auth'
import {KTSVG} from '../../../../../../_metronic/helpers'
import FileIcon from '../../../../components/FileIcon'
import DropdownCreateTask from '../../../../../../_metronic/partials/content/dropdown/DropdownCreateTask'

export function Overview() {
  const {user} = useAuthContext()
  const {id, projectID} = useParams()
  const clientUID = id ? id : ''
  const projectUID = projectID ? projectID : ''

  const {documents: users} = useCollection('users')
  const {documents: messages} = useCollection('projects/' + projectUID + '/messages')

  const {documents: serviceWorks} = useCollection('agency/data/serviceWorks')
  // const {documents: currencies} = useCollection('agency/data/currencies')

  const [projectData, setProjectData] = useState<any | null>(null)

  const [uploadFiles, setUploadFiles] = useState<any | null>([])
  const [files, setFiles] = useState<any | null>([])

  const [message, setMessage] = useState<string>('')
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  const scroll = useRef<HTMLDivElement>(null)

  const {fileUpload} = useFilesUpload()

  const fileTypes = ['JPG', 'PNG', 'MP4', 'MOV']

  const [uploadArea, setUploadArea] = useState<any | null>(
    <div className=' btn btn-lg btn-icon bg-light-primary bg-hover-primary  text-hover-white rounded '>
      <i className='bi bi-upload color-white fs-3'></i>
    </div>
  )

  const customUpload = (FilesA) => {
    let fileArray: File[] = uploadFiles
    for (const file of FilesA) {
      fileArray.push(file)
    }
    setUploadFiles(fileArray)
    // const veri = {...uploadFiles, ...FilesA}
    // setUploadFiles(veri)
    // const docRef = doc(db, 'clients', clientUID, 'projects', projectUID)
    // let filess = []
    // for (const file of FilesA) {
    //   setFiles([...files, file])
    //   // console.log(file)
    // }

    // console.log(uploadFiles)
    // fileUpload(file, `clients/${clientUID}/projects/${projectUID}/`, docRef).then((data) => {
    //   updateDoc(docRef, {
    //     logs: arrayUnion({
    //       displayName: user.displayName,
    //       uid: user.uid,
    //       photoURL: user?.photoURL,
    //       action: 'I added a media.',
    //       createdAt: Timestamp.now(),
    //       value: data,
    //     }),
    //   }).then(() => {
    //     setUploadArea(
    //       <div className=' btn btn-lg btn-icon bg-success text-white rounded '>
    //         <i className='bi bi-check text-white fs-1'></i>
    //       </div>
    //     )
    //     setTimeout(() => {
    //       setUploadArea(
    //         <div className=' btn btn-lg btn-icon bg-light-primary bg-hover-primary  text-hover-white rounded '>
    //           <i className='bi bi-upload  fs-3'></i>
    //         </div>
    //       )
    //     }, 1000)
    //   })
    // })
    setUploadArea(
      <div className=' btn btn-lg btn-icon bg-light-primary bg-hover-primary  text-hover-white rounded '>
        <i className='bi bi-upload  fs-3'></i>
      </div>
    )
  }

  const sendMessage = () => {
    const docRef = doc(db, 'clients', clientUID, 'projects', projectUID)

    if (uploadFiles.length > 0) {
      fileUpload(uploadFiles, `clients/${clientUID}/projects/${projectUID}/`, docRef).then(
        (data) => {
          const MessageData = {
            userUID: user.uid,
            userName: user.displayName,
            message: message,
            createdAt: Timestamp.now(),
            files: data,
          }
          addDoc(collection(db, 'projects', projectUID, 'messages'), MessageData)
            .then(async (data) => {
              const docRef = doc(
                db,
                'clients',
                clientUID!,
                '/projects/',
                projectUID,
                '/messages/',
                data.id
              )
              setDoc(docRef, MessageData).then(() => {
                setLoading(false)
                setSuccess(true)
                setTimeout(() => {
                  setSuccess(false)
                }, 1500)
                setLoading(false)
              })
            })
            .catch((err) => {
              console.log(err.message)
              setLoading(false)
            })
        }
      )
    } else {
      const MessageData = {
        userUID: user.uid,
        userName: user.displayName,
        message: message,
        createdAt: Timestamp.now(),
      }
      addDoc(collection(db, 'projects', projectUID, 'messages'), MessageData)
        .then(async (data) => {
          const docRef = doc(
            db,
            'clients',
            clientUID!,
            '/projects/',
            projectUID,
            '/messages/',
            data.id
          )
          setDoc(docRef, MessageData).then(() => {
            setLoading(false)
            setSuccess(true)
            setTimeout(() => {
              setSuccess(false)
            }, 1500)
            setLoading(false)
          })
        })
        .catch((err) => {
          console.log(err.message)
          setLoading(false)
        })
    }
  }
  // const unsub = onSnapshot(
  //   doc(db, 'projects', projectUID),
  //   {includeMetadataChanges: true},
  //   (doc) => {
  //     console.log('Current data: ', doc.data())
  //     setProjectData(doc.data())
  //   }
  // )

  useEffect(() => {
    let docRef = doc(db, 'clients/' + id + '/projects/' + projectID)
    getDoc(docRef)
      .then((data) => {
        const pData: any = data.data()
        setProjectData(pData)
        console.log(pData)
        // data.data()?.details && setDetailArray(data.data()?.details)
        // setAuthorities(data.data()?.authorities)
        // setIsActive(data.data()?.isActive)
        // setSelectedService(data.data()?.serviceUID)
        // setActiveInOrder(data.data()?.activeInOrder ? data.data()?.activeInOrder : false)
        // setSelectedServiceWorks(data.data()?.works ? data.data()?.works : [])
      })
      .catch((error) => {})
  }, [projectID])
  return (
    <>
      <div className='row mt-6'>
        <div className='col-md-8 '>
          <div className='d-flex flex-column'>
            {messages.map((message: any) => (
              <div className='card card-custom card-flush mb-4'>
                <div className='card-header p-6 pt-4 pb-4 align-items-center'>
                  <div className='d-flex'>
                    {users.map(
                      (user: any) =>
                        user.id === message.userUID && (
                          <>
                            <Avatar
                              className={'symbol symbol-35px symbol-md-35px me-3'}
                              name={user.displayName}
                              avatarURL={user?.photoURL}
                            />
                            <div className='d-flex flex-column'>
                              <span className='text-primary fs-7 fw-semibold'>
                                {user.displayName}
                              </span>
                              <span className='text-primary fs-8 text-muted'>
                                {moment(message?.createdAt.toDate()).fromNow()}
                              </span>
                            </div>
                          </>
                        )
                    )}
                  </div>

                  <div className='card-toolbar'>
                    {projectData?.works?.filter((w: any) => w?.messageUID === message.id).length >
                    0 ? (
                      projectData?.works?.map(
                        (work: any) =>
                          work?.messageUID === message.id && (
                            <>
                              <span className='badge badge-light p-2 px-2 fw-semibold '>
                                {work.name}
                                <div className='symbol-group symbol-hover ms-6 '>
                                  {work?.authorities?.map((u: any) =>
                                    users?.map(
                                      (user: any) =>
                                        u.uid === user.id && (
                                          <Avatar
                                            className={`symbol symbol-25px symbol-circle`}
                                            name={user.displayName}
                                            avatarURL={user?.photoURL}
                                          />
                                        )
                                    )
                                  )}
                                </div>
                              </span>
                            </>
                          )
                      )
                    ) : (
                      <>
                        <button
                          type='button'
                          className='btn btn-sm btn-icon  btn-light'
                          data-kt-menu-trigger='click'
                          data-kt-menu-placement='bottom-end'
                        >
                          <i className='bi bi-send-plus fs-4'></i>
                        </button>
                        <DropdownCreateTask messageUID={message.id} projectData={projectData} />
                      </>
                    )}
                  </div>
                </div>
                <div className='card-body px-6 pt-0 pb-4'>{message.message}.</div>
                {message?.files && (
                  <div className='card-footer px-6 pt-0 pb-4'>
                    <div className='d-flex'>
                      {message.files.map((f: any, index) => (
                        <div
                          className='image-input w-50px h-50px me-4 bg-light-primary  '
                          data-kt-image-input='false'
                          // style={{backgroundImage: `url(${toAbsoluteUrl('/media/svg/avatars/blank.svg')})`}}
                        >
                          <a href={f.downloadURL} target='_blank' rel='noreferrer'>
                            <FileIcon
                              className={'image-input-wrapper shadow-none w-50px h-50px'}
                              fileName={f.fileName}
                              fileType={f.fileType}
                              fileURL={f.downloadURL}
                            />
                          </a>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className='col-md-4 border-start border-1 border-start-dotted'>
          <div className='d-flex flex-column'>
            {projectData?.works
              ?.sort((a: any, b: any) => (a?.deadline < b?.deadline ? -1 : 1))
              .map((work: any, i) => (
                <div className='d-flex px-3 rounded bg-white mb-2 align-items-center '>
                  <div className='form-check form-check-custom form-check-solid form-check-sm me-3'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      disabled
                      checked={work?.isDone}
                      // id={`checkbox-${workData?.workUID}`}
                      // onChange={(e) =>
                      //   completeWork(
                      //     workData?.clientUID,
                      //     workData?.clientName,
                      //     workData?.clientLogo,
                      //     workData?.projectUID,
                      //     workData?.projectName,
                      //     workData?.workUID,
                      //     workData?.workName
                      //   )
                      // }
                    />
                  </div>
                  <div className='d-flex flex-column flex-fill'>
                    <span className='fs-8 fw-semibold text-primary'>{work.name}</span>
                  </div>
                  <div className='symbol-group symbol-hover ms-0 pe-4 ps-6 py-4'>
                    {work?.authorities?.map((u: any) =>
                      users?.map(
                        (user: any) =>
                          u.uid === user.id && (
                            <Avatar
                              className={`symbol symbol-30px symbol-circle`}
                              name={user.displayName}
                              avatarURL={user?.photoURL}
                            />
                          )
                      )
                    )}
                  </div>

                  {work?.deadline && (
                    <>
                      {' '}
                      <i className='bi bi-arrow-repeat me-2'></i>
                      <span className='fs-8 fw-semibold w-75px text-end text-primary me-2'>
                        {/* {moment(work?.deadline).fromNow()} */}
                        {moment
                          .duration(moment(work?.deadline.toDate()).diff(moment().startOf('day')))
                          .asDays() < 1 &&
                          moment
                            .duration(moment(work?.deadline.toDate()).diff(moment().startOf('day')))
                            .asDays() > 0 && (
                            <span className='badge badge-sm badge-success fs-9 p-2  me-1'>
                              {' '}
                              Today
                            </span>
                          )}
                        {moment
                          .duration(moment(work?.deadline.toDate()).diff(moment().startOf('day')))
                          .asDays() < 0 && (
                          <span className='badge badge-sm badge-danger fs-9 p-2  me-1'>
                            {' '}
                            {moment(work?.deadline.toDate()).fromNow()}
                          </span>
                        )}
                        {moment
                          .duration(moment(work?.deadline.toDate()).diff(moment().startOf('day')))
                          .asDays() > 1 && (
                          <span className='badge badge-sm badge-primary fs-9 p-2  me-1'>
                            {' '}
                            {moment(work?.deadline.toDate()).fromNow()}
                          </span>
                        )}
                      </span>
                    </>
                  )}
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className='d-flex  shadow-sm fixed-bottom mx-9 bg-white rounded-top align-items-center'>
        {/* <div className='card card-custom  card-flush'>
          <div className='card-header p-6'>
            <div className='d-flex'>
              <Avatar className={'symbol symbol-35px symbol-md-40px me-3'} name={'Emre YIlmaz'} />
              <div className='d-flex flex-column'>
                <span>Emre Yılmaz</span>
                <span>10dk</span>
              </div>
            </div>

            <div className='card-toolbar'>
              <button type='button' className='btn btn-sm btn-light'>
                Action
              </button>
            </div>
          </div>
          <div className='card-body py-5'>Lorem Ipsum is simply dummy text...</div>
        </div> */}

        <textarea
          className='form-control form-control-flush p-6 shadow me-10 mb-4'
          style={{resize: 'vertical'}}
          rows={2}
          data-kt-element='input'
          placeholder='Type a message, work or task...'
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        ></textarea>
        <div className='d-flex align-items-center '>
          {uploadFiles &&
            uploadFiles?.map((f: any, index) => (
              <div
                className='image-input w-50px h-50px me-4 bg-light-primary  '
                data-kt-image-input='false'
                // style={{backgroundImage: `url(${toAbsoluteUrl('/media/svg/avatars/blank.svg')})`}}
              >
                <FileIcon
                  file={f}
                  className={'image-input-wrapper shadow-none w-50px h-50px'}
                  style={undefined}
                  fileName={f.name}
                  fileType={f.type}
                />

                <label
                  className='btn btn-icon btn-circle btn-active-color-primary w-20px h-20px bg-body shadow'
                  data-kt-image-input-action='change'
                  data-bs-toggle='tooltip'
                  aria-label='Delete File'
                  data-bs-original-title='Delete File'
                  title='Delete File'
                  data-kt-initialized='1'
                  onClick={() =>
                    setUploadFiles(uploadFiles.filter((file: any, indexS) => indexS !== index))
                  }
                >
                  <i className='bi bi-x fs-2'></i>
                </label>
              </div>
            ))}
          <FileUploader
            handleChange={customUpload}
            name='file'
            multiple={true}
            // types={fileTypes}
            children={uploadArea}
            classes={'m-4'}
            hoverTitle={' '}
          />
        </div>
        <button
          className='btn btn-lg btn-icon m-4 ms-0 px-20 btn-primary me-6'
          type='button'
          data-kt-element='send'
          onClick={sendMessage}
        >
          Send
        </button>
      </div>
    </>
  )
}
