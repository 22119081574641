/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {v4 as uuidv4} from 'uuid'

//firebase
import {addDoc, collection, serverTimestamp, Timestamp} from 'firebase/firestore'
import {db, storage} from '../../../firebase/config'
import {ref, getDownloadURL, uploadBytesResumable} from 'firebase/storage'

import {PageTitle} from '../../../_metronic/layout/core'

import {useAuthContext} from '../../modules/auth/core/useAuthContext'
import useAutosizeTextArea from '../../hooks/useAutosizeTextArea'
import {useCollection} from '../../hooks/useCollection'
import moment from 'moment'
import {Avatar} from '../../../_metronic/partials'
import {CardWork} from '../../../_metronic/partials/content/cards/CardWork'
import StopWatch from '../../modules/components/StopWatch'
import {useGetWorks} from '../../modules/components/Functions/useGetWorks'
import {UserWorks} from '../../modules/components/Elements/UserWorks'

const profileDetailsSchema = Yup.object().shape({
  title: Yup.string().required('Lütfen sorun yaşıdığunız sayfa veya konuyu yazınız'),
  message: Yup.string().required('Lütfen detaylı bir açıklama yazınız.'),
})

const TeamsTasksPage: FC = () => {
  const {documents: users} = useCollection('users')

  return (
    <>
      {/* begin::Header */}
      <div className='d-flex flex-stack mb-4'>
        <div className='page-title d-flex flex-wrap me-3 flex-column justify-content-center'>
          <h1 className='page-heading d-flex text-primary fw-bold fs-3 my-0 flex-column justify-content-center'>
            {/* {selectedUser.userName ? selectedUser.userName : 'Team Tasks'}  */}
            Works
          </h1>
          <span className='fw-semibold text-muted'>
            {/* {selectedUser?.teams.length > 0
              ? selectedUser?.teams.map((t: any) => (
                  <span className='badge badge-light fw-semibold  me-2'>{t?.name}</span>
                ))
              : 'Team Tasks'} */}
            All Team
          </span>
        </div>
        <div className='page-title d-flex flex-wrap  flex-column justify-content-center'>
          <ul className='nav'>
            <li className='nav-item'>
              <a
                className=' btn btn-sm fw-bold btn-light-primary me-2 btn-active-primary active'
                data-bs-toggle='tab'
                href='#teams'
              >
                Teams
              </a>
            </li>
            <li className='nav-item'>
              <a
                className=' btn btn-sm fw-bold btn-light-primary btn-active-primary'
                data-bs-toggle='tab'
                href='#working'
              >
                Working
              </a>
            </li>
          </ul>
          {/* <div className='symbol-group symbol-hover'>
            {users &&
              users.map(
                (user: any, i) =>
                  user?.model?.isTeammate && (
                    <button
                      type='button'
                      className={`btn btn-link ${
                        user.id === selectedUser.userUID
                          ? 'opacity-100 opacity-100-hover'
                          : 'opacity-25 opacity-100-hover'
                      }`}
                      onClick={() =>
                        setSelectedUser({
                          userName: user?.displayName,
                          userUID: user.id,
                          teams: user?.teams ? user?.teams : [],
                        })
                      }
                    >
                      <Avatar
                        className={'symbol symbol-40px symbol-circle'}
                        name={user.displayName}
                        avatarURL={user?.photoURL}
                      />
                    </button>
                  )
              )}
          </div> */}
        </div>
      </div>
      {/* end::Header */}

      {/* begin::Row */}
      <div className='tab-content'>
        {/* begin::Tap pane */}
        <div className='tab-pane fade show active' id='teams' role='tabpanel'>
          <div className='row'>
            {users &&
              users
                .filter((u: any) => u?.model?.isTeammate === true)
                .sort((a: any, b: any) => a.displayName.localeCompare(b.displayName))

                .sort((a: any, b: any) =>
                  a?.model?.isManager === false && b?.model?.isManager === true ? -1 : 1
                )

                .map((user: any, index) => (
                  <div
                    className='d-flex col mb-6 card  p-0  mx-4 mb-8 shadow-none'
                    style={{minWidth: '400px', maxWidth: '450px'}}
                    key={index}
                    id={index.toExponential()}
                  >
                    <div className='card card-custom pb-4 shadow-none'>
                      <div className='card-header px-4'>
                        <div className='d-flex align-items-center'>
                          <Avatar
                            className='symbol symbol-40px me-3'
                            name={user.displayName}
                            avatarURL={user?.photoURL}
                          />
                          <div className='d-flex justify-content-start flex-column'>
                            <a href='#' className='text-primary fw-bold text-hover-primary fs-6'>
                              {user.displayName}
                            </a>
                            <div className='d-flex flex-wrap'>
                              {user?.teams.map((t: any, i) => (
                                <>
                                  <span className=' fs-8 fw-semibold text-muted me-1'>
                                    {t?.name}
                                    {i !== user?.teams?.length - 1 && ','}
                                  </span>
                                </>
                              ))}
                            </div>
                          </div>

                          {/* <div className='card-toolbar'>
                            <button type='button' className='btn btn-sm btn-light'>
                              Action
                            </button>
                          </div> */}
                        </div>
                      </div>
                      <div className='card-body card-scroll p-2 px-4 h-400px '>
                        <UserWorks userUID={user.id} showStart={false} />
                      </div>
                    </div>
                  </div>
                ))}
          </div>
        </div>
        <div className='tab-pane fade' id='working' role='tabpanel'>
          <div className='d-flex'>
            {users &&
              users.map(
                (user: any) =>
                  user?.working && (
                    <div className='d-flex w-350px flex-column mb-4 bg-white rounded p-4'>
                      <div className='d-flex'>
                        <Avatar
                          className={'symbol symbol-40px'}
                          name={user.displayName}
                          avatarURL={user?.photoURL}
                        />
                        <div className='d-flex flex-fill flex-column ms-2'>
                          <div className='text-primary fw-bold'>{user.displayName}</div>
                          {/* <span className='fs-7 text-muted'>{user.email}</span> */}
                          {user?.title ? (
                            <span className='fs-7 text-muted'>
                              {user.title.toString().substring(0, 20)}
                            </span>
                          ) : (
                            <span className='card-title fs-7 text-primary'></span>
                          )}
                        </div>
                        <span className='badge badge-warning ms-4 p-2'>Working On</span>
                      </div>
                      <div className='d-flex bg-light align-items-sm-center mt-3 p-2 rounded '>
                        <Avatar
                          className={'symbol symbol-35px symbol-md-35px me-2 align-self-start'}
                          name={user.working?.clientName}
                          avatarURL={user.working?.clientLogo}
                        />
                        <div className='d-flex flex-fill align-items-center'>
                          <div className='d-flex flex-fill flex-column'>
                            <span className='text-primary fs-8 fw-bold'>
                              {user.working?.workName}
                            </span>
                            <div className='text-primary fs-8 '>{user.working?.projectName}</div>
                          </div>
                          <div className='d-flex'>
                            <div className={'badge badge-danger m-1 me-0'}>
                              <StopWatch
                                startTime={user.working?.startTime.toDate()}
                                className={'d-flex justify-content-between fs-8 d-block'}
                                style={{width: '32px'}}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
              )}
          </div>
        </div>
      </div>
      {/*       
      {selectedUser.userUID ? (
        <div className='d-flex flex-nowrap scroll-x hover-scroll-overlay pb-10 '>
          {pinWorks && pinWorks.length > 0 && (
            <div className='min-w-400px mw-400px h-600px bg-white rounded  p-4 me-6'>
              <div className='mb-4'>
                <div className='d-flex align-items-center mt-1'>
                  <span className='fs-7 text-primary fw-bold flex-fill'>Pinned Works</span>
                  <span className='badge badge-sm badge-warning fs-9   me-1'>
                    {' '}
                    <i className='bi bi-pin-fill text-white fs-5'></i>
                  </span>
                </div>
                <div className='separator separator-dashed my-2'></div>
                {pinWorks.map((workD: any, i) => (
                  <CardWork
                    workData={workD}
                    key={i}
                    showStart={false}
                    isTeamTaskPage={true}
                    userUID={selectedUser.userUID}
                  />
                ))}
              </div>
            </div>
          )}

          {works &&
            works
              .sort((a: any, b: any) => (a.workDeadline < b.workDeadline ? -1 : 1))
              .map((work, index) => (
                <div className='min-w-400px mw-400px h-100 bg-white rounded p-4 me-6' key={index}>
                  <div className='mb-4'>
                    <div className='d-flex align-items-center mt-1'>
                      <span className='fs-7 text-primary fw-bold flex-fill'>
                        {moment(work[0]).format('DD MMM dddd')}
                      </span>

                      {/* {moment(work[0]).fromNow()} */}
      {/* {moment.duration(moment(work[0]).diff(moment().startOf('day'))).asDays() ===
                        0 && (
                        <span className='badge badge-sm badge-success fs-9 p-2  me-1'> Today</span>
                      )}
                      {moment.duration(moment(work[0]).diff(moment().startOf('day'))).asDays() <
                        0 && (
                        <span className='badge badge-sm badge-danger fs-9 p-2  me-1'>
                          {' '}
                          {moment(work[0]).fromNow()}
                        </span>
                      )}
                      {moment.duration(moment(work[0]).diff(moment().startOf('day'))).asDays() >
                        0 && (
                        <span className='badge badge-sm badge-primary fs-9 p-2  me-1'>
                          {' '}
                          {moment(work[0]).fromNow()}
                        </span>
                      )} 
                    </div>
                    <div className='separator separator-dashed my-2'></div>
                    {work[1]
                      ?.sort((a: any, b: any) => (a.workDeadline < b.workDeadline ? -1 : 1))
                      .map((workD: any, i) => (
                        <CardWork
                          workData={workD}
                          key={i}
                          showStart={false}
                          isTeamTaskPage={true}
                          userUID={selectedUser.userUID}
                        />
                      ))}
                  </div>
                </div>
              ))}
        </div>
      ) : (
        <div className='d-flex'>
          {users &&
            users.map(
              (user: any) =>
                user?.working && (
                  <div className='d-flex w-350px flex-column mb-4 bg-white rounded p-4'>
                    <div className='d-flex'>
                      <Avatar
                        className={'symbol symbol-40px'}
                        name={user.displayName}
                        avatarURL={user?.photoURL}
                      />
                      <div className='d-flex flex-fill flex-column ms-2'>
                        <div className='text-primary fw-bold'>{user.displayName}</div>
                        {user?.title ? (
                          <span className='fs-7 text-muted'>
                            {user.title.toString().substring(0, 20)}
                          </span>
                        ) : (
                          <span className='card-title fs-7 text-primary'></span>
                        )}
                      </div>
                      
                      <span className='badge badge-warning ms-4 p-2'>Working On</span>
                    </div>
                    <div className='d-flex bg-light align-items-sm-center mt-3 p-2 rounded '>
                      <Avatar
                        className={'symbol symbol-35px symbol-md-35px me-2 align-self-start'}
                        name={user.working?.clientName}
                        avatarURL={user.working?.clientLogo}
                      />
                      <div className='d-flex flex-fill align-items-center'>
                        <div className='d-flex flex-fill flex-column'>
                          <span className='text-primary fs-8 fw-bold'>
                            {user.working?.workName}
                          </span>
                          <div className='text-primary fs-8 '>{user.working?.projectName}</div>
                        </div>
                        <div className='d-flex'>
                          <div className={'badge badge-danger m-1 me-0'}>
                            <StopWatch
                              startTime={user.working?.startTime.toDate()}
                              className={'d-flex justify-content-between fs-8 d-block'}
                              style={{width: '32px'}}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
            )}
        </div>
      )}
       */}
    </>
  )
}

const TeamsTasksWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <TeamsTasksPage />
    </>
  )
}

export {TeamsTasksWrapper}
