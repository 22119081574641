/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import moment from 'moment'
import {ProgressBar} from 'react-bootstrap'

import {doc, getDoc, serverTimestamp, setDoc} from 'firebase/firestore'
import {useCollection} from '../../../hooks/useCollection'
import {db} from '../../../../firebase/config'
import {Avatar} from '../../../../_metronic/partials'
import {UsersAvatars} from '../Elements/UsersAvatars'

function statusBadge(endDate) {
  const days = moment.duration(moment(endDate).diff(moment().startOf('day'))).asDays()
  if (days < 5 && days > 0) {
    return (
      <span className='badge badge-light-warning p-2 fs-7 fw-bold me-2 '>
        {moment(endDate).format('DD.MM.YYYY')}
      </span>
    )
  }
  if (days < 0) {
    return (
      <span className='badge badge-light-danger p-2 fs-7 fw-bold me-2 '>
        {moment(endDate).format('DD.MM.YYYY')}
      </span>
    )
  }
  if (days > 5) {
    return (
      <span className='badge badge-light-primary p-2 fs-7 fw-bold me-2 '>
        {moment(endDate).format('DD.MM.YYYY')}
      </span>
    )
  }
}

function isActiveBadge(status) {
  if (status) {
    return (
      <span className='badge badge-success p-3 rounded-4 ms-3' title='Active'>
        {' '}
      </span>
    )
  } else {
    return (
      <span className='badge badge-light-primary p-3  rounded-4 ms-3' title='Inactive'>
        {' '}
      </span>
    )
  }
}

type Props = {
  uid: string
  title: string
  description: any
  startDate: string
  endDate: string
  budget: string
  isActive: boolean
  authorities: any
  status?: string
  logo?: boolean
  projectData: any
}

const ProjectCard: FC<Props> = ({
  uid,
  title,
  description,
  startDate,
  endDate,
  budget,
  isActive,
  authorities,
  status,
  logo,
  projectData,
}) => {
  const {documents: users} = useCollection('users')
  const {documents: clients} = useCollection('clients')
  const [projectDetail, setProjectDetail] = useState(projectData)
  const [projectStatus, setProjectStatus] = useState(status ? status : '')
  const linkService =
    projectDetail?.services?.socialMedia?.isActive === true ? 'social-media' : 'overview'
  const navigate = useNavigate()
  const changeStatus = (clientUID, projectUID, status) => {
    console.log(clientUID)
    console.log(projectUID)
    console.log(status)
    const statusData = {
      status: status,
      updatedAt: serverTimestamp(),
    }
    setProjectStatus(status)
    setDoc(doc(db, 'clients', clientUID, 'projects', projectUID), statusData, {merge: true})
      .then(async (data) => {
        setDoc(doc(db, 'projects', projectUID), statusData, {
          merge: true,
        }).then((data2) => {
          console.log()
          getDoc(doc(db, 'projects/' + projectUID))
            .then((data) => {
              setProjectDetail(data.data())
            })
            .catch((error) => {
              console.log(error.message)
            })
        })
      })
      .catch((err) => {
        console.log(err.message)
      })
  }

  return (
    <div
      className={'col mt-0 min-w-400px mw-500px mb-8 mx-4 card card-custom card-flush card-stretch'}
    >
      <div className='card-header px-4 border-opacity-10' style={{minHeight: '60px'}}>
        {logo ? (
          <>
            {clients.map(
              (client: any) =>
                client.id === projectDetail.clientUID && (
                  <>
                    <div className='card-title m-0 align-items-center mb-4'>
                      <Link to={`/client/${projectDetail.clientUID}/overview`}>
                        <Avatar
                          className={'symbol symbol-40px me-2  mt-4'}
                          name={client.brandName}
                          avatarURL={client?.logo}
                        />
                      </Link>
                      <div className='d-flex flex-column mt-4'>
                        <Link
                          to={`/client/${projectDetail.clientUID}/projects/${projectDetail.id}/overview`}
                        >
                          <span className='text-muted text-primary fw-bold fs-6'>{title}</span>
                        </Link>
                        {/* <span className='fs-6 text-primary'>{title}</span> */}
                        {/* <span className='fs-6 text-primary pt-1'>{isActiveBadge(isActive)}</span> */}
                        <span className='text-muted fw-semibold fs-8'>
                          {moment(startDate).format('DD.MM.YYYY')} -{' '}
                          {moment(endDate).format('DD.MM.YYYY')}
                        </span>
                      </div>
                    </div>
                    <div className='card-toolbar d-flex align-items-center'>
                      <UsersAvatars
                        usersData={authorities}
                        smybolGroupStyle={'min-h-25px justify-content-end me-3'}
                        symbolStyle={'opacity-75 opacity-100-hover symbol-25px symbol-circle'}
                        maxLeght={6}
                      />
                      <div className='d-flex '>
                        {projectDetail?.period === 'monthly' && (
                          <span className='badge badge-success float-end'>Fee</span>
                        )}
                        {projectDetail?.period === 'oneTime' && (
                          <span className='badge badge-warning float-end'>Project</span>
                        )}
                        {projectDetail?.period === 'yearly' && (
                          <span className='badge badge-primary float-end'>Yıllık</span>
                        )}
                      </div>
                      {isActiveBadge(isActive)}
                    </div>
                  </>
                )
            )}
          </>
        ) : (
          <>
            <div className='card-title m-0 min-h-40px my-4 align-items-center'>
              <Link key={uid} to={`${uid}/${linkService}`} className='d-flex flex-column'>
                <span className='text-muted text-primary fw-bold fs-6'>{title}</span>
                {/* <span className='fs-6 text-primary pt-1'>{isActiveBadge(isActive)}</span> */}
                <span className='text-muted fw-semibold fs-8'>
                  {moment(startDate).format('DD.MM.YYYY')} - {moment(endDate).format('DD.MM.YYYY')}
                </span>
              </Link>
            </div>
            <div className='card-toolbar'>
              <UsersAvatars
                usersData={authorities}
                smybolGroupStyle={'min-h-25px justify-content-end me-3'}
                symbolStyle={'opacity-75 opacity-100-hover symbol-25px symbol-circle'}
                maxLeght={6}
              />
              <div className='d-flex '>
                {projectDetail?.period === 'monthly' && (
                  <span className='badge badge-success float-end'>Fee</span>
                )}
                {projectDetail?.period === 'oneTime' && (
                  <span className='badge badge-warning float-end'>Project</span>
                )}
                {projectDetail?.period === 'yearly' && (
                  <span className='badge badge-primary float-end'>Yıllık</span>
                )}
              </div>
              {isActiveBadge(isActive)}
            </div>
          </>
        )}
      </div>

      <div className='card-body p-4 py-0'>
        <div className='text-muted text-primary fs-7' style={{minHeight: '70px'}}>
          <div className='text-primary fw-normal my-1 mb-4'> {projectData?.description}</div>
          {projectData?.works && (
            <>
              <div className='text-primary fw-semibold my-1 text-muted'>Works & Task</div>
              {projectData?.works &&
                projectData?.works?.map((w: any, i) => (
                  <span className='badge badge-light p-2 px-2 fw-semibold me-1 mb-1'>
                    {w.name}
                    <UsersAvatars
                      usersData={w?.authorities}
                      smybolGroupStyle={'justify-content-end ms-6'}
                      symbolStyle={'opacity-75 opacity-100-hover symbol-20px symbol-circle'}
                      maxLeght={6}
                    />
                  </span>
                ))}
            </>
          )}
        </div>
        {/* <div
          className='h-4px w-100 bg-light mb-5'
          data-bs-toggle='tooltip'
          title='This project completed'
        >
          <div
            className={`bg-${statusColor} rounded h-4px`}
            role='progressbar'
            style={{width: `${progress}%`}}
          ></div>
        </div>

        <UsersList users={users} /> */}
        <div className='d-flex justify-content-between mb-6 mt-4'>
          {/* <div className='d-flex flex-column me-0'>
            <div className='text-primary fw-bold text-muted'>Start-End Days</div>
            <span className='text-muted fw-semibold d-block fs-8 my-2'>
              <span className='badge badge-light-primary p-2 fs-7 fw-bold me-2'>
                {moment(startDate).format('DD.MM.YYYY')}
              </span>
              {statusBadge(endDate)}
            </span>
          </div> */}
          {/* <div className='d-flex flex-column text-end'>
            <div className='text-primary fw-bold text-muted mb-1'>Teams</div>
            <UsersAvatars
              usersData={authorities}
              smybolGroupStyle={'min-h-30px justify-content-end'}
              symbolStyle={'opacity-75 opacity-100-hover symbol-30px symbol-circle'}
              maxLeght={6}
            />
          </div> */}
        </div>
        {projectData?.period === 'oneTime' && (
          <ProgressBar className='mb-6' style={{height: '20px'}}>
            <ProgressBar
              variant={`${projectStatus === 'Not Started' ? 'primary' : 'light-primary'}`}
              className='bg-hover-primary'
              now={25}
              label={'Not Started'}
              key={1}
              onClick={() => changeStatus(projectDetail.clientUID, uid, 'Not Started')}
            />
            <ProgressBar
              variant={`${projectStatus === 'To Do' ? 'info' : 'light-info'}`}
              className='bg-hover-info'
              now={25}
              label={'To Do'}
              key={1}
              onClick={() => changeStatus(projectDetail.clientUID, uid, 'To Do')}
            />
            <ProgressBar
              variant={`${projectStatus === 'In Progress' ? 'warning' : 'light-warning'}`}
              className='bg-hover-warning'
              now={25}
              label={'In Progress'}
              key={1}
              onClick={() => changeStatus(projectDetail.clientUID, uid, 'In Progress')}
            />
            <ProgressBar
              variant={`${projectStatus === 'Done' ? 'success' : 'light-success'}`}
              className='bg-hover-success'
              now={25}
              label={'Done'}
              key={1}
              onClick={() => changeStatus(projectDetail.clientUID, uid, 'Done')}
            />
          </ProgressBar>
        )}
      </div>
    </div>
  )
}

export {ProjectCard}
