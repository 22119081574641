import React, {FC, useEffect, useRef, useState} from 'react'
import {Link} from 'react-router-dom'
import {useCollection} from '../../../../app/hooks/useCollection'
import {useAuthContext} from '../../../../app/modules/auth'
import {SearchComponent} from '../../../assets/ts/components'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {Avatar} from '../../content/user/Avatar'

const Search: FC = () => {
  const {documents: users} = useCollection('users')
  const {documents: clients} = useCollection('clients')
  const {user} = useAuthContext()
  const [search, setSearch] = useState('')
  const [menuState, setMenuState] = useState<'main' | 'advanced' | 'preferences'>('main')
  const element = useRef<HTMLDivElement | null>(null)
  const wrapperElement = useRef<HTMLDivElement | null>(null)
  const resultsElement = useRef<HTMLDivElement | null>(null)
  const suggestionsElement = useRef<HTMLDivElement | null>(null)
  const emptyElement = useRef<HTMLDivElement | null>(null)

  const processs = (search: SearchComponent) => {
    setTimeout(function () {
      const number = Math.floor(Math.random() * 6) + 1

      // Hide recently viewed
      suggestionsElement.current!.classList.add('d-none')

      if (number === 3) {
        // Hide results
        resultsElement.current!.classList.add('d-none')
        // Show empty message
      } else {
        // Show results
        resultsElement.current!.classList.remove('d-none')
        // Hide empty message
      }

      // Complete search
      search.complete()
    }, 100)
  }

  const clear = (search: SearchComponent) => {
    // Show recently viewed
    suggestionsElement.current!.classList.remove('d-none')
    // Hide results
    resultsElement.current!.classList.add('d-none')
    // Hide empty message
    emptyElement.current!.classList.add('d-none')
  }

  useEffect(() => {
    // Initialize search handler
    const searchObject = SearchComponent.createInsance('#kt_header_search')

    // Search handler
    searchObject!.on('kt.search.process', processs)

    // Clear handler
    searchObject!.on('kt.search.clear', clear)
  }, [])

  useEffect(() => {
    if (
      users.filter(
        (user: any) => user?.displayName.toLowerCase().indexOf(search.toLowerCase()) !== -1
      ).length +
        clients.filter(
          (client: any) =>
            // client.teams?.filter((u: any) => user.uid === u.uid).length > 0 &&
            client?.isActive === true &&
            client?.brandName.toLowerCase().indexOf(search.toLowerCase()) !== -1
        ).length <
      1
    ) {
      if (search !== '') {
        emptyElement.current!.classList.remove('d-none')
      }
    } else {
      emptyElement.current!.classList.add('d-none')
    }
  }, [search])

  return (
    <>
      <div
        id='kt_header_search'
        className='d-flex align-items-stretch'
        data-kt-search-keypress='true'
        data-kt-search-min-length='2'
        data-kt-search-enter='enter'
        data-kt-search-layout='menu'
        data-kt-menu-trigger='auto'
        data-kt-menu-overflow='false'
        data-kt-menu-permanent='true'
        data-kt-menu-placement='bottom-end'
        ref={element}
      >
        <div
          className='d-flex align-items-center'
          data-kt-search-element='toggle'
          id='kt_header_search_toggle'
        >
          <div className='btn btn-icon btn-light-primary w-35px h-35px w-md-40px h-md-40px'>
            <KTSVG path='/media/icons/search.svg' className='svg-icon-1' />
          </div>
        </div>

        <div
          data-kt-search-element='content'
          className='menu menu-sub menu-sub-dropdown p-2 px-4 w-325px w-md-375px'
        >
          <div
            className={`${menuState === 'main' ? '' : 'd-none'}`}
            ref={wrapperElement}
            data-kt-search-element='wrapper'
          >
            <form
              data-kt-search-element='form'
              className='w-100 position-relative'
              autoComplete='off'
            >
              <KTSVG
                path='/media/icons/duotune/general/gen021.svg'
                className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 translate-middle-y ms-0'
              />

              <input
                type='text'
                className='form-control form-control-flush ps-10'
                placeholder='Search...'
                data-kt-search-element='input'
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />

              <span
                className='position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-1'
                data-kt-search-element='spinner'
              >
                <span className='spinner-border h-15px w-15px align-middle text-gray-400' />
              </span>

              <span
                className='btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 d-none'
                data-kt-search-element='clear'
              >
                <KTSVG path='/media/icons/close.svg' className='svg-icon-3  me-0' />
              </span>

              {/* <div
                className='position-absolute top-50 end-0 translate-middle-y'
                data-kt-search-element='toolbar'
              >
                <div
                  data-kt-search-element='preferences-show'
                  className='btn btn-icon w-20px btn-sm btn-active-color-primary me-1'
                  data-bs-toggle='tooltip'
                  onClick={() => {
                    setMenuState('preferences')
                  }}
                  title='Show search preferences'
                >
                  <KTSVG path='/media/icons/duotune/coding/cod001.svg' className='svg-icon-1' />
                </div>

                <div
                  data-kt-search-element='advanced-options-form-show'
                  className='btn btn-icon w-20px btn-sm btn-active-color-primary'
                  data-bs-toggle='tooltip'
                  onClick={() => {
                    setMenuState('advanced')
                  }}
                  title='Show more search options'
                >
                  <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-2' />
                </div>
              </div> */}
            </form>

            <div ref={resultsElement} data-kt-search-element='results' className='d-none'>
              <div className='separator mt-2'></div>
              <div className='scroll-y mh-200px mh-lg-350px mt-4'>
                {/* {users &&
                  users.filter(
                    (user: any) =>
                      user?.displayName.toLowerCase().indexOf(search.toLowerCase()) !== -1
                  ).length > 0 && (
                    <>
                      <h3 className='fs-5 text-muted m-0 ' data-kt-search-element='category-title'>
                        Team & Users
                      </h3>
                      <div className='separator separator-dashed my-2'></div>
                    </>
                  )} */}
                {users.map(
                  (user: any, i) =>
                    user?.displayName.toLowerCase().indexOf(search.toLowerCase()) !== -1 && (
                      <Link to={`/profile/${user.id}/`} key={i}>
                        <div className='menu-content bg-hover-light-primary rounded-2 d-flex align-items-center p-2 me-2 '>
                          <Avatar
                            className={'symbol symbol-35px me-3'}
                            name={user.displayName}
                            avatarURL={user?.photoURL}
                          />

                          <div className='d-flex flex-column flex-fill'>
                            <div className='fw-bolder d-flex align-items-center fs-7'>
                              {user.displayName}
                            </div>
                            <span className='fw-semibold text-muted fs-7'>
                              {user.title ? user.title : user.email}
                            </span>
                          </div>
                        </div>
                      </Link>
                    )
                )}

                {/* {clients &&
                  clients.filter(
                    (client: any) =>
                      client.teams?.filter((u: any) => user.uid === u.uid).length > 0 &&
                      client?.brandName.toLowerCase().indexOf(search.toLowerCase()) !== -1
                  ).length > 0 && (
                    <>
                      <h3
                        className='fs-5 text-muted m-0 mt-2'
                        data-kt-search-element='category-title'
                      >
                        Clients
                      </h3>
                      <div className='separator separator-dashed my-2'></div>
                    </>
                  )} */}
                {clients.map(
                  (client: any, i) =>
                    client?.isActive === true &&
                    client?.brandName.toLowerCase().indexOf(search.toLowerCase()) !== -1 && (
                      <Link to={`/client/${client.id}/`} key={i}>
                        <div className='menu-content bg-hover-light-primary rounded-2 d-flex align-items-center p-2 me-2 '>
                          <Avatar
                            className={'symbol symbol-35px me-3'}
                            name={client.brandName}
                            avatarURL={client?.logo}
                          />

                          <div className='d-flex flex-column flex-fill'>
                            <div className='fw-bolder d-flex align-items-center fs-7'>
                              {client.brandName}
                            </div>
                            <span className='fw-semibold text-muted fs-7'>
                              {client.companyName ? client.companyName : 'undefined'}
                            </span>
                          </div>
                        </div>
                      </Link>
                    )
                )}
              </div>
            </div>

            <div ref={suggestionsElement} data-kt-search-element='main'></div>

            <div ref={emptyElement} data-kt-search-element='empty' className='text-center d-none'>
              <div className='pt-10 pb-10'>
                <KTSVG
                  path='/media/icons/duotune/files/fil024.svg'
                  className='svg-icon-4x opacity-50'
                />
              </div>

              <div className='pb-15 fw-bold'>
                <h3 className='text-gray-600 fs-5 mb-2'>No result found</h3>
                <div className='text-muted fs-7'>Please try again with a different query</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {Search}
