/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {SocialMediaContentStatusModels} from '../../models/SocialMediaModels'

import {db} from '../../../../../../../../firebase/config'
import {addDoc, collection, doc, serverTimestamp, setDoc} from 'firebase/firestore'
import {useParams} from 'react-router-dom'
import {useAuthContext} from '../../../../../../auth'
import {TagsInput} from 'react-tag-input-component'

type Props = {
  contentData: any
}

const Contents: FC<Props> = ({contentData}) => {
  const [content, setContent] = useState(contentData?.contentText)
  const [tags, setTags] = useState<any | null>(contentData?.contentTags)

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState('')
  const {id} = useParams()
  const clientUID = id ? id : ''

  const {user} = useAuthContext()

  const handleContent = (event) => {
    setContent(event.target.value)
  }
  const handleClick = async () => {
    setLoading(true)
    const docRef = doc(db, 'clients', clientUID, 'contents', contentData.id)
    setDoc(
      docRef,
      {
        contentText: content,
        contentTags: tags,
      },
      {merge: true}
    )
      .then(async () => {
        addDoc(collection(db, 'clients', clientUID, 'contents', contentData.id, 'logs'), {
          displayName: user.displayName,
          uid: user.uid,
          photoURL: user?.photoURL,
          action: 'ContentChanged',
          createdAt: serverTimestamp(),
        })
        setLoading(false)
        setSuccess(true)
        setTimeout(() => {
          setSuccess(false)
        }, 1000)
      })
      .catch((error) => {
        setLoading(false)
        console.log(error.message)
      })
  }

  return (
    <>
      <div className='mb-2'>
        <label className='col-lg-4 col-form-label py-2 fw-bold fs-6'>Content</label>
        <div className=' fv-row'>
          <textarea
            className='form-control form-control-solid'
            rows={3}
            onChange={handleContent}
            defaultValue={content}
          ></textarea>
        </div>
      </div>
      <div className='mb-4'>
        <label className='col-lg-4 col-form-label py-2 fw-bold fs-6'>Tags</label>
        <div className=' fv-row form-control  form-control-solid '>
          <TagsInput
            value={tags}
            onChange={setTags}
            name='tags'
            placeHolder='Write tag and hit enter button'
            classNames={{
              tag: '',
              input: 'my-2 bg-transparent fw-semibold w-100 ',
            }}
          />
        </div>
      </div>
      <div className='d-flex justify-content-end'>
        {/* <button
          type='reset'
          className='btn btn-sm btn-light btn-active-light-primary me-2'
          data-kt-menu-dismiss='true'
        >
          Cancel
        </button> */}

        <button
          type='submit'
          className={`btn btn-sm btn-primary ${success && 'btn-success'}`}
          disabled={loading}
          onClick={handleClick}
        >
          {success && (
            <>
              <span className='badge badge-success me-5 px-6'></span>
              <span className='' style={{display: 'block'}}>
                <i className='bi bi-check fs-2'></i>
                Success
              </span>
            </>
          )}
          {!success && (
            <>
              {!loading && 'Save'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </>
          )}
        </button>
      </div>
    </>
  )
}

export {Contents}
