/* eslint-disable jsx-a11y/anchor-is-valid */
import {Link, useParams} from 'react-router-dom'
import {useAuthContext} from '../../../modules/auth'
import {format} from 'date-fns'

import {useEffect, useState} from 'react'
import {doc, getDoc} from 'firebase/firestore'
import {db} from '../../../../firebase/config'
import {ContentStatusWidget, PlanWidget} from '../../../../_metronic/partials/widgets/social-media'
import {RolesWidget} from './widgets/RolesWidget'
import {TimeTrackerWidget} from './widgets/TimeTrackerWidget'
import {ProjectsWidget} from './widgets/ProjectsWidget'

export function Overview(data: any) {
  const {id} = useParams()
  const clientUID = id ? id : ''
  const [clientDetail, setClientDetail] = useState<any | null>(null)

  useEffect(() => {
    let docRef = doc(db, 'clients', clientUID)
    getDoc(docRef)
      .then((data) => {
        setClientDetail(data.data())
      })
      .catch((error) => {})
  }, [clientUID])
  return (
    <>
      {/* <div className='row g-5 g-xxl-8'>
        <div className='col-md-12 col-lg-6 col-xl-6 col-xxl-6'>
          <ContentStatusWidget
            className=''
            title='Content Status'
            description='Februry 2023'
            icon={false}
            stats={357}
            labelColor='dark'
            textColor='gray-300'
          />
        </div>
        <div className='col-md-12 col-lg-6 col-xl-6 col-xxl-6'>
          <PlanWidget
            className=''
            title='Weekly Plan'
            description='Today'
            icon={false}
            stats={357}
            labelColor='dark'
            textColor='gray-300'
          />
        </div>
      </div> */}

      <div className='row gy-10 gx-xl-10'>
        {/* <div className='col-xl-6'>
          <ListsWidget5 className='card-xxl-stretch mb-5 mb-xl-10' />
        </div> */}

        <div className='col-lg-4'>
          <ProjectsWidget className='mb-5' clientUID={clientUID} />
        </div>

        <div className='col-lg-4'>
          <RolesWidget className='mb-5' clientUID={clientUID} />
        </div>
        <div className='col-lg-4'>
          <TimeTrackerWidget className='mb-5' clientUID={clientUID} />
        </div>

        {/* <div className='col-lg-4'>
          <ProfitWidgets
            className='card-xl-stretch mb-5 mb-xl-8'
            chartColor='success'
            chartColor2='danger'
            chartHeight='150px'
          />
        </div> */}
      </div>
    </>
  )
}
