import React, {useEffect, useState} from 'react'

import {useParams} from 'react-router-dom'
import {useCollection} from '../../../../hooks/useCollection'
import {NewProjectDropdown} from './components/NewProjectDropdown'
import {ProjectCard} from '../../../components/Cards/ProjectCard'

export function Projects() {
  const {id} = useParams()
  const {documents: projects} = useCollection('clients/' + id + '/projects')

  const [header, setHeader] = useState('Projects')
  const [filteredProjects, setFilteredProjects] = useState<any | null>(projects)

  useEffect(() => {
    if (projects) {
      setFilteredProjects(projects)
    }
  }, [projects])

  const handleChange = (event) => {
    console.log(event.target.value)
    event.target.value === 'all' && setFilteredProjects(projects)
    event.target.value === 'all' && setHeader('All Projects')
    event.target.value === 'active' &&
      setFilteredProjects(projects.filter((p: any) => p.isActive === true))
    event.target.value === 'active' && setHeader('Active Projects')
    event.target.value === 'passive' &&
      setFilteredProjects(projects.filter((p: any) => p.isActive === false))
    event.target.value === 'passive' && setHeader('Inactive Projects')
    event.target.value === 'Not Started' &&
      setFilteredProjects(projects.filter((p: any) => p?.status === 'Not Started'))
    event.target.value === 'Not Started' && setHeader('Not Started')
    event.target.value === 'To Do' &&
      setFilteredProjects(projects.filter((p: any) => p?.status === 'To Do'))
    event.target.value === 'To Do' && setHeader('To Do')
    event.target.value === 'In Progress' &&
      setFilteredProjects(projects.filter((p: any) => p?.status === 'In Progress'))
    event.target.value === 'In Progress' && setHeader('In Progress')
    event.target.value === 'Done' &&
      setFilteredProjects(projects.filter((p: any) => p?.status === 'Done'))
    event.target.value === 'Done' && setHeader('Done')
  }

  return (
    <>
      <div className='card card-flush mb-5'>
        <div className='card-header'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder text-primary my-2 ps-1'>
              {header}
              <span className='fs-6 text-primary fw-bold ms-1'>({filteredProjects.length})</span>
            </h3>
          </div>

          <div className='d-flex flex-wrap my-2'>
            <div className='align-self-center me-4'>
              <select
                name='status'
                className='form-select form-select-sm form-select-white w-125px'
                defaultValue='all'
                onChange={handleChange}
              >
                <optgroup label='Active / Inactive'>
                  <option value='all'>All ({projects.length})</option>
                  <option value='active'>
                    Active ({projects.filter((c: any) => c.isActive === true).length})
                  </option>
                  <option value='passive'>
                    InActive ({projects.filter((c: any) => c.isActive === false).length})
                  </option>
                </optgroup>
                <optgroup label='Type'>
                  <option value='Not Started'>
                    Not Started ({projects.filter((p: any) => p?.status === 'Not Started').length})
                  </option>
                  <option value='To Do'>
                    To Do ({projects.filter((p: any) => p?.status === 'To Do').length})
                  </option>
                  <option value='In Progress'>
                    In Progress ({projects.filter((p: any) => p?.status === 'In Progress').length})
                  </option>
                  <option value='Done'>
                    Done ({projects.filter((p: any) => p?.status === 'Done').length})
                  </option>
                </optgroup>
              </select>
            </div>

            <div className='align-self-center'>
              <a
                href='#'
                className='btn btn-primary btn-sm'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
              >
                New Project
              </a>
              <NewProjectDropdown />
            </div>
          </div>
        </div>
      </div>

      <div className='row pt-2'>
        {filteredProjects.map((project: any) => (
          <ProjectCard
            uid={project?.id}
            logo={false}
            title={project?.name}
            description={project?.details}
            startDate={project?.startDate}
            endDate={project?.endDate}
            budget={project?.budget}
            isActive={project?.isActive}
            authorities={project?.authorities}
            status={project?.status}
            projectData={project}
          />
        ))}
      </div>
    </>
  )
}
