/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {useIntl} from 'react-intl'

const AuthLayout = () => {
	const intl = useIntl()

	const agencyBGColor = '#ffda44'
	//const agencyColor = '#ffda44'
	const agencyTextColor = '#122d44'

  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
      {/* begin::Body */}
      <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1  scroll-y'>
        {/* begin::Form */}
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          {/* begin::Wrapper */}
          <div className='w-100 mw-500px p-5'>
						<div className='d-block d-lg-none mb-12 text-center'>
							<img alt='Logo' src={toAbsoluteUrl('/media/logos/fok-logo.svg')} className='h-75px rounded' />
						</div>
            <Outlet />
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Form */}

        {/* begin::Footer */}
        <div className='d-flex flex-center flex-wrap px-5'>
          {/* begin::Links */}
          <div className='d-flex fw-semibold text-primary fs-base'>
            <a href='#' className='px-5' target='_blank'>
							{intl.formatMessage({id: 'APP.GENERAL.CONTACTUS'})}
            </a>
          </div>
          {/* end::Links */}
        </div>
        {/* end::Footer */}
      </div>
      {/* end::Body */}

      {/* begin::Aside */}
      <div
        className='d-flex flex-lg-row-fluid w-lg-50 order-1 order-lg-2 d-none d-lg-block'
        style={{backgroundColor: agencyBGColor}}
      >
        {/* begin::Content */}
        <div className='d-flex flex-column flex-center py-10 px-5 px-md-15 w-100'>
          {/* begin::Logo */}
          <Link to='/' className='mb-10'>
            <img alt='Logo' src={toAbsoluteUrl('/media/logos/fok-logo.svg')} className='h-100px' />
          </Link>
          {/* end::Logo */}

          {/* begin::Image */}
          <img
            className='mx-auto w-250px w-md-50 w-xl-400px mb-10 mb-lg-15'
            src={toAbsoluteUrl('/media/misc/auth-screens.png')}
            alt=''
          />
          {/* end::Image */}

          {/* begin::Title */}
          <h1 
						className='fs-2qx fw-bolder text-center mb-7'
						style={{color:agencyTextColor}}
					>
						{intl.formatMessage({id: 'AUTH.GENERAL.AGENCY.MESSAGE.TITLE'})}
          </h1>
          {/* end::Title */}

          {/* begin::Text */}
          <div 
						className='fs-base text-center'
						style={{color:agencyTextColor}}
					>
						{intl.formatMessage({id: 'AUTH.GENERAL.AGENCY.MESSAGE'})}
          </div>
          {/* end::Text */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::Aside */}
    </div>
  )
}

export {AuthLayout}
