/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, {useState, useEffect, useRef} from 'react'
import {KTSVG} from '../../../../helpers'

import {useCollection} from '../../../../../app/hooks/useCollection'
import {ChatRoom} from './chatRoom'
import {useAuthContext} from '../../../../../app/modules/auth'
import {RoomCard} from './components/RoomCard'
import {DropdownRoom} from './components/DropdownRoom'
import {useCollectionChat} from '../../../../../app/hooks/useCollectionChat'
import {Avatar} from '../../../content/user/Avatar'

const itemClass = 'ms-1 ms-lg-3'
const btnClass = 'btn btn-sm btn-icon btn-light-primary w-25px h-25px w-md-35px h-md-35px'
const btnIconClass = 'svg-icon-2'

export function SidebarChat(data: any) {
  const [error, setError] = useState(false)
  const [room, setRoom] = useState('')
  const [name, setName] = useState('')
  const [logo, setLogo] = useState('')
  const [title, setTitle] = useState('')
  const {user} = useAuthContext()

  const {documents: rooms} = useCollectionChat('chat', user.uid)
  const {documents: users} = useCollection('users')

  function closeHandle() {
    const rightSidebarStatus = document.body.getAttribute('data-right-sidebar')
    //const rightSidebarContent = document.body.getAttribute("data-right-sidebar-content");
    document.body.setAttribute('data-right-sidebar', 'off')
    document.body.setAttribute('data-right-sidebar-content', 'none')

    const element = document.getElementById('chat-button')
    element?.classList.remove('active')
    setRoom('')
  }

  useEffect(() => {
    const rightSidebarStatus = document.body.getAttribute('data-right-sidebar')
    rightSidebarStatus && rightSidebarStatus === 'off' && setRoom('')
    //Rooms her update olduğunda personal odalardaki kullanıcının kendi id si hariç hepsini state olarak atıcak dropdown'a d bu statei göndericiz.
  }, [rooms])

  return (
    <>
      <div className='pe-8 my-4'>
        <div className='d-flex flex-stack'>
          <div className='page-title d-flex flex-wrap me-3 flex-column justify-content-center'>
            <h1 className='page-heading d-flex text-dark fw-bold fs-3 my-0 flex-column justify-content-center'>
              Chat
            </h1>
            <span className='fw-semibold text-muted'> Private & Group</span>
          </div>
          <div className='d-flex align-items-center gap-2 gap-lg-3'>
            <div className={clsx('position-relative', btnClass)} onClick={closeHandle}>
              <KTSVG path='/media/icons/close.svg' className={btnIconClass} />
            </div>
          </div>
        </div>
      </div>

      <div className='card mt-2'>
        <div className='card-header  px-4 pe-8'>
          {room && (
            <>
              <div className='card-toolbar'>
                <div className=''>
                  {rooms.filter((room: any) => room.isRead?.includes(user.uid)).length <
                  rooms.length ? (
                    <button className='btn btn-sm btn-icon bg-warning ' onClick={() => setRoom('')}>
                      <i className='bi bi-arrow-left fs-2 text-white'></i>
                    </button>
                  ) : (
                    <button
                      className='btn btn-sm btn-icon bg-hover-light-primary'
                      onClick={() => setRoom('')}
                    >
                      <i className='bi bi-arrow-left fs-2'></i>
                    </button>
                  )}
                </div>
              </div>
            </>
          )}
          {!room && (
            <>
              <div className='card-title'>
                <div className='symbol-group symbol-hover fw-bold text-dark'>Rooms</div>
              </div>
            </>
          )}
          {room && name && (
            <>
              <div className='d-flex align-items-sm-center flex-fill'>
                <Avatar
                  className='symbol symbol-40px  align-self-center rounded-2  ms-2 me-2'
                  name={name}
                  avatarURL={logo}
                />
                <div className='flex-grow-1'>
                  <span className='text-primary fs-5 fw-bold'>{name}</span>
                  <span className='text-muted fw-semibold d-block fs-8'>{title}</span>
                </div>
              </div>
            </>
          )}
          {!room && (
            <div className='card-toolbar'>
              <div className=''>
                <button
                  className='btn btn-sm btn-icon btn-active-light-primary'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                >
                  <i className='bi bi-plus-lg fs-2'></i>
                </button>
                <DropdownRoom users={users} rooms={rooms} />
              </div>
            </div>
          )}
        </div>

        {!room && (
          <div className='card-body card-scroll vh-100 px-4 pt-4'>
            {rooms
              .sort((a: any, b: any) => (a.createdAt > b.createdAt ? 1 : -1))
              .reverse()
              .map((room: any, i) => (
                <div key={i}>
                  {room.id && room?.settings && (
                    <>
                      {room.settings.isPersonal &&
                        room.settings.users.find((users) => users.uid === user.uid) && (
                          <>
                            <a
                              onClick={function () {
                                setRoom(room.id)
                                setName(
                                  room.settings.users.find((users) => users.uid !== user.uid).name
                                )
                                setLogo(
                                  room.settings.users.find((users) => users.uid !== user.uid)
                                    .photoURL
                                )
                                setTitle('Private Chat')
                              }}
                            >
                              <RoomCard
                                id={room?.id}
                                chatTitle={
                                  room.settings.users.find((users) => users.uid !== user.uid).name
                                }
                                logo={
                                  room.settings.users.find((users) => users.uid !== user.uid)
                                    .photoURL
                                }
                                notification={!room.isRead?.includes(user.uid)}
                                displayName={room?.displayName}
                                message={room?.message}
                                createdAt={room?.createdAt}
                              />
                            </a>
                          </>
                        )}

                      {!room.settings.isPersonal && (
                        <>
                          <a
                            onClick={function () {
                              setRoom(room.id)
                              setName(room.settings.name)
                              setLogo(room.settings?.logo)
                              setTitle('Group Chat')
                            }}
                          >
                            <RoomCard
                              id={room?.id}
                              chatTitle={room.settings.name}
                              logo={room.settings?.logo}
                              notification={!room.isRead?.includes(user.uid)}
                              displayName={room?.displayName}
                              message={room?.message}
                              createdAt={room?.createdAt}
                            />
                          </a>
                        </>
                      )}
                    </>
                  )}
                </div>
              ))}
          </div>
        )}

        {room && (
          <>
            <ChatRoom room={rooms.find((r: any) => r.id === room)} />
          </>
        )}
      </div>
    </>
  )
}
