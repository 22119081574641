/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {SocialMediaContentStatusModels} from '../../models/SocialMediaModels'

import {
  Timestamp,
  addDoc,
  arrayUnion,
  collection,
  doc,
  serverTimestamp,
  setDoc,
  updateDoc,
} from 'firebase/firestore'
import {useParams} from 'react-router-dom'
import {TagsInput} from 'react-tag-input-component'

import {useAuthContext} from '../../../../../../../../auth'
import {db} from '../../../../../../../../../../firebase/config'

type Props = {
  contentData: any
}

const TextContent: FC<Props> = ({contentData}) => {
  const [content, setContent] = useState(contentData?.contentText)
  const [tags, setTags] = useState<any | null>(contentData?.contentTags)

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState('')
  const {id, projectID} = useParams()
  const clientUID = id ? id : ''
  const projectUID = projectID ? projectID : ''

  const {user} = useAuthContext()

  const handleContent = (event) => {
    setContent(event.target.value)
  }
  const handleClick = async () => {
    setLoading(true)
    const docRef = doc(
      db,
      'clients',
      clientUID,
      'projects',
      projectUID,
      'socialMediaContents',
      contentData.id
    )
    setDoc(
      docRef,
      {
        contentText: content,
        contentTags: tags ? tags : [],
      },
      {merge: true}
    )
      .then(async () => {
        updateDoc(docRef, {
          logs: arrayUnion({
            displayName: user.displayName,
            uid: user.uid,
            photoURL: user?.photoURL,
            action: 'I added content text.',
            createdAt: Timestamp.now(),
            value: {
              contentText: content,
              contentTags: tags ? tags : [],
            },
          }),
        })
        setLoading(false)
        setSuccess(true)
        setTimeout(() => {
          setSuccess(false)
        }, 1000)
      })
      .catch((error) => {
        setLoading(false)
        console.log(error.message)
      })
  }

  return (
    <>
      <label className='fw-row col-form-label pt-1 text-primary fw-bold fs-6 mb-1'>
        Content & Tags
      </label>

      <div className='mb-4'>
        <div className=' fv-row'>
          <textarea
            className='form-control form-control-solid'
            rows={4}
            onChange={handleContent}
            defaultValue={content}
            placeholder='Write content'
          ></textarea>
        </div>
      </div>
      <div className='mb-4'>
        <div className=' fv-row form-control form-control-solid '>
          <TagsInput
            value={tags}
            onChange={setTags}
            name='tags'
            placeHolder='Write tag and hit enter button'
            classNames={{
              tag: 'ms-0 me-2 px-2 fs-7',
              input: 'bg-transparent  w-100 form-control form-control-solid my-0 px-0',
            }}
          />
        </div>
      </div>
      <div className='d-flex justify-content-end'>
        {/* <button
          type='reset'
          className='btn btn-sm btn-light btn-active-light-primary me-2'
          data-kt-menu-dismiss='true'
        >
          Cancel
        </button> */}

        <button
          type='submit'
          className={`btn btn-sm btn-primary ${success && 'btn-success'}`}
          disabled={loading}
          onClick={handleClick}
        >
          {success && (
            <>
              <span className='badge badge-success me-5 px-6'></span>
              <span className='' style={{display: 'block'}}>
                <i className='bi bi-check fs-2'></i>
                Success
              </span>
            </>
          )}
          {!success && (
            <>
              {!loading && 'Save'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </>
          )}
        </button>
      </div>
    </>
  )
}

export {TextContent}
