import {useState} from 'react'

import {OpenAI} from 'openai'
import {useAuthContext} from '../../modules/auth'
import {Avatar} from '../../../_metronic/partials'

const ChatbotApp = () => {
  const {user, userData} = useAuthContext()

  const openai = new OpenAI({
    organization: 'org-qq5SoKrbLakJ1gqWEhgZmisT',
    apiKey: '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',
    dangerouslyAllowBrowser: true,
  })

  const [threadID, setThreadID] = useState('')
  const [prompt, setPrompt] = useState('')
  const [messages, setMessages] = useState<any | null>([])
  const [loading, setLoading] = useState(false)

  function listenGPT(threadID) {
    setInterval(async () => {
      const runMessages = await openai.beta.threads.messages.list(threadID)
      messages !== runMessages.data && setMessages(runMessages.data)
    }, 3000)
  }
  // Mesajları düzenli bir şekilde yazdırmak için bir fonksiyon tanımlayabiliriz
  function displayMessages(messagesList) {
    messagesList.data.forEach((messageItem, index) => {
      console.log(`Message ${index + 1}:`)
      console.log(`Role: ${messageItem.role}`)
      console.log(`Content: ${messageItem.content}`)
      console.log('---') // Mesajlar arası ayırıcı
    })
  }

  // Mesajları düzenli bir şekilde konsola yazdır

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    // asst_EtQlNkdG4usNaUAVVyiTbNmB
    let newThreadID = ''
    // Assistant ve thread oluşturuldu
    const myAssistant = await openai.beta.assistants.retrieve('asst_EtQlNkdG4usNaUAVVyiTbNmB')
    console.log(myAssistant)

    if (threadID === '') {
      const thread = await openai.beta.threads.create()
      setThreadID(thread.id)
      newThreadID = thread.id
    } else {
      newThreadID = threadID
    }

    // Soru mesajı gönderildi
    const message = await openai.beta.threads.messages.create(newThreadID, {
      role: 'user',
      content: prompt,
    })
    setPrompt('')
    const newMessages = messages
    newMessages.unshift(message)
    setMessages(newMessages.unshift(message))

    // Assistant çalıştırıldı
    const run = await openai.beta.threads.runs.create(newThreadID, {
      assistant_id: myAssistant.id,
    })
    console.log(run.id)

    // Assistant'tan gelen yanıtları almak için bir süre beklemek gerekebilir
    // Bu süre API'nin yanıt süresine göre değişebilir

    // Assistant çalıştırdıktan sonra gelen mesajları listele

    setTimeout(async () => {
      // Assistant çalıştırdıktan sonra gelen mesajları listele
      const runMessages = await openai.beta.threads.messages.list(newThreadID)

      // Yanıtları yazdır
      runMessages.data.forEach((msg: any) => {
        if (msg.role === 'system' || msg.role === 'assistant') {
          console.log(`Assistant's reply: ${msg.content}`)
        }
      })
      setMessages(runMessages.data)
      listenGPT(newThreadID)
      setLoading(false)
    }, 3000)

    // const myAssistant = await openai.beta.assistants.create({
    //   name: 'HR Personal',
    //   instructions:
    //     'You are a human resources personnel working in an advertising agency. If necessary, I may ask you to make a recommendation among the applicants.',
    //   tools: [{type: 'retrieval'}],
    //   model: 'gpt-4-1106-preview',
    //   file_ids: ['file-2iQerf6wK1ZphkbtM6ZsZD5N'],
    // })

    // console.log(myAssistant)

    // console.log(e)
    // try {
    //   const message = await openai.beta.threads.messages.create(
    //     thread.id,
    //     {
    //       role: "user",
    //       content: prompt
    //     }
    //   )

    //   //console.log("response", result.data.choices[0].text);
    //   // console.log(message.choices[0])
    //   // setApiResponse(completion.choices[0])
    // } catch (e: any) {
    //   console.log(e)
    //   if (e.response && e.response.status === 429) {
    //     // 429 hatası durumunda bekleme süresi ekleyin (örneğin, 1 dakika)
    //     setTimeout(() => {
    //       // Tekrar deneme
    //       handleSubmit(e)
    //     }, 60000) // 60 saniye = 1 dakika
    //   } else {
    //     setApiResponse('Something is going wrong, Please try again.')
    //   }
    // }
  }

  return (
    <>
      <div className='row'>
        <h4 className='text-primary fw-bold my-4'>Conversation with HR Assistant</h4>

        <form onSubmit={handleSubmit}>
          <div className='d-flex'>
            <textarea
              value={prompt}
              className='form-control form-control-solid me-4'
              placeholder='Please ask to openai'
              onChange={(e) => setPrompt(e.target.value)}
            ></textarea>
            <button
              className='btn btn-primary'
              disabled={loading || prompt.length === 0}
              type='submit'
            >
              {loading ? 'Asking...' : 'Ask'}
            </button>
          </div>
        </form>
      </div>
      {messages && (
        <div className='d-flex flex-column mt-4'>
          {messages.length > 0 &&
            messages.map((message: any, index) => (
              <div className='d-flex  p-4 bg-light text-primary rounded mb-2' key={index}>
                {message.role === 'system' || message.role === 'assistant' ? (
                  <div className='symbol symbol-40px h-40px w-40px text-center me-4 bg-white rounded pt-2'>
                    <i className={`bi bi-robot fs-1 `}></i>
                  </div>
                ) : (
                  <Avatar
                    className={'symbol symbol-40px me-4'}
                    name={user.displayName}
                    avatarURL={user?.photoURL}
                  />
                )}
                <div className='d-flex flex-column'>
                  <h5 className='text-primary'>
                    {message.role === 'system' || message.role === 'assistant'
                      ? 'HR Assistant'
                      : user.displayName}
                  </h5>
                  <span>{message.content[0].text.value}</span>
                </div>
              </div>
            ))}
        </div>
      )}
    </>
  )
}

export default ChatbotApp
