/* eslint-disable jsx-a11y/anchor-is-valid */
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {Link, useParams} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import clsx from 'clsx'
import {useCollection} from '../../../../hooks/useCollection'
import {useEffect, useState} from 'react'
import {Avatar} from '../../../../../_metronic/partials'
import moment from 'moment'
import {doc, getDoc} from 'firebase/firestore'
import {db} from '../../../../../firebase/config'
import {SocialMediaChannelModels} from './services/social-media/components/models/SocialMediaModels'
import {useAuthContext} from '../../../auth'

type Props = {
  projectData: any
}

const ProjectHeader: React.FC<Props> = ({projectData}) => {
  const location = useLocation()
  const {id, projectID} = useParams()
  const {user, userData} = useAuthContext()

  function isActiveBadge(status) {
    if (status) {
      return <span className='badge badge-light-success p-6  fs-6 fw-bolder'>Active</span>
    } else {
      return <span className='badge badge-light-danger p-6 my-4 fs-6 fw-bolder '>Passive</span>
    }
  }

  function statusBadge(endDate) {
    const days = moment.duration(moment(endDate).diff(moment().startOf('day'))).asDays()
    if (days > 30) {
      return <span className='badge badge-light-success p-6 my-4 fs-6 fw-bolder'>Active</span>
    }
    if (days < 31 && days > 0) {
      return <span className='badge badge-light-danger p-6 my-4 fs-6 fw-bolder '>Renew</span>
    }
    if (days < 30) {
      return <span className='badge badge-light p-6 my-4 fs-6 fw-bolder '>Passive</span>
    }
  }

  return (
    <>
      {/* <div className='card card-flush mb-5'>
        {projectData && (
          <div className='card-header w-100 px-4 ps-8'>
            <div className='d-flex w-100 h-90px py-4'>
              <div className='card-title d-flex flex-fill flex-column m-0'>
                <h4 className='fw-bolder text-primary m-0'>{projectData?.name}</h4>
                <Link className='fs-6 text-gray-400 fw-bold' to='../../projects'>
                  Projects
                </Link>
              </div>
              {projectData?.startDate && (
                  <div className='border border-gray-300 border-dashed rounded text-center p-4 me-3'>
                    <div className='fs-7 text-gray-800 fw-bolder'>
                      {moment(projectData?.startDate).format('DD.MM.YYYY')}
                    </div>
                    <div className='fw-bold fs-8 text-gray-400'>Start Date</div>
                  </div>
                )}
                {projectData?.endDate && (
                  <div className='border border-gray-300 border-dashed rounded text-center p-4 me-3'>
                    <div className='fs-7 text-gray-800 fw-bolder'>
                      {moment(projectData?.endDate).format('DD.MM.YYYY')}
                    </div>
                    <div className='fw-bold fs-8 text-gray-400'>End Date</div>
                  </div>
                )}
              {projectData?.services?.socialMedia?.settings?.monthlyContent && (
                <div className='d-flex flex-column bg-light rounded align-items-center me-3  min-w-100px justify-content-start'>
                  <span className='text-primary-light flex-center fw-bolder fs-6 pt-2 px-4'>
                    0/{projectData?.services.socialMedia?.settings?.monthlyContent}
                  </span>
                  <div className='symbol-60px flex-column rounded-bottom  px-4'>
                    <span className='d-flex flex-column align-items-center justify-content-center text-center'>
                      <span className='fs-8 fw-bolder'>Content</span>
                      <span className='fs-9'>Month</span>
                    </span>
                  </div>
                </div>
              )}
              {projectData?.services?.socialMedia?.settings?.channels?.map(
                (channel: any, i) =>
                  channel?.content > 0 && (
                    <div
                      className='d-flex flex-column bg-light rounded align-items-center me-3   justify-content-start'
                      key={i}
                    >
                      <a
                        href={channel?.URL}
                        target='_blank'
                        className={`btn btn-icon btn-sm btn-${channel.name.toLowerCase()} w-25px h-25px mt-2 mb-1`}
                        rel='noreferrer'
                        title={channel?.name}
                      >
                        <i
                          className={`${
                            SocialMediaChannelModels.find((c: any) => c.name === channel.name)?.icon
                          } fs-7`}
                        ></i>
                        {}
                      </a>
                      <div className='symbol-60px flex-column rounded-bottom px-3'>
                        <span className='d-flex flex-column align-items-center '>
                          <span className='fs-9 fw-bolder'>0/{channel.content}</span>
                          <span className='fs-9'>Content</span>
                        </span>
                      </div>
                    </div>
                  )
              )}
              {isActiveBadge(projectData.isActive)}
            </div>
            {projectData?.budget && (
                <div className='border border-gray-300 border-dashed rounded text-center my-4 p-4 me-3'>
                  <div className='fs-7 text-gray-800 fw-bolder'>{projectData?.budget} TL</div>
                  {projectData?.period === 'monthly' && (
                    <div className='fw-bold fs-8 text-gray-400'>Fee</div>
                  )}
                  {projectData?.period === 'oneTime' && (
                    <div className='fw-bold fs-8 text-gray-400'>Project</div>
                  )}
                  {projectData?.period === 'yearly' && (
                    <div className='fw-bold fs-8 text-gray-400'>Yıllık</div>
                  )}
                </div>
              )}

            {statusBadge('01-01-2024')}
          </div>
        )}
      </div> */}

      <div className='d-flex flex-row-reverse flex-wrap flex-stack mt-n15 '>
        <div className='d-flex flex-wrap '>
          {projectData?.services?.socialMedia?.isActive === true && (
            <Link
              className={
                'btn btn-sm fw-bold btn-light-primary flex-fill me-2 ' +
                (location.pathname ===
                  `/client/${id}/projects/${projectID}/${projectData?.services?.socialMedia.slug}` &&
                  'active')
              }
              to={projectData?.services?.socialMedia.slug}
            >
              {projectData?.services?.socialMedia.name}
            </Link>
          )}
          <Link
            className={
              'btn btn-sm btn-icon btn-light-primary h-31px w-31px me-2 ' +
              (location.pathname === `/client/${id}/projects/${projectID}/overview` && 'active')
            }
            to='overview'
          >
            <i className='bi bi-clipboard-data'></i>
          </Link>
          {userData?.model.isManager === true && (
            <>
              <Link
                className={
                  'btn btn-sm btn-icon btn-light-primary h-31px w-31px me-2 ' +
                  (location.pathname === `/client/${id}/projects/${projectID}/timeActivity` &&
                    'active')
                }
                to='timeActivity'
              >
                <KTSVG path='/media/icons/time.svg' className={'svg-icon-3'} />
              </Link>
              <Link
                className={
                  'btn btn-sm btn-icon btn-light-primary h-31px w-31px me-2 ' +
                  (location.pathname === `/client/${id}/projects/${projectID}/budgets` && 'active')
                }
                to='budgets'
              >
                <i className='bi bi-currency-dollar fs-4'></i>
              </Link>

              <Link
                className={
                  'btn btn-sm btn-icon btn-light-primary h-31px w-31px me-2 ' +
                  (location.pathname === `/client/${id}/projects/${projectID}/settings` && 'active')
                }
                to='settings'
              >
                <i className='bi bi-gear fs-4'></i>
              </Link>
            </>
          )}
          <Link
            className={'btn btn-sm btn-icon btn-light-primary h-31px w-31px'}
            to='../../projects'
          >
            <i className='bi bi-arrow-left-short fs-4'></i>
          </Link>
        </div>
        {/* 
        <Link
          className={
            'btn btn-sm fw-bold btn-light-primary me-3 ' +
            (location.pathname === '/agency/documents' && 'active')
          }
          to='/agency/reports'
        >
          Reports
        </Link>
        <Link
          className={
            'btn btn-sm fw-bold btn-light-primary me-0 ' +
            (location.pathname === '/agency/connections' && 'active')
          }
          to='/agency/library'
        >
          Library
        </Link> */}
      </div>
    </>
  )
}

export {ProjectHeader}
