/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {useCollection} from '../../../../app/hooks/useCollection'
import {useAuthContext} from '../../../../app/modules/auth'
import {KTSVG} from '../../../helpers'
import {Dropdown1} from '../../content/dropdown/Dropdown1'
import {useEffect, useState} from 'react'
import {Avatar} from '../../content/user/Avatar'
import {addDoc, collection, deleteDoc, doc, getDocs, setDoc} from 'firebase/firestore'
import {db} from '../../../../firebase/config'
import moment from 'moment'
import {Tooltip} from 'react-tooltip'
import {Link, useLocation} from 'react-router-dom'
import {useCollectionWithParam} from '../../../../app/hooks/useCollectionWithParam'

const btnClass = 'btn btn-sm btn-icon btn-light-primary w-25px h-25px w-md-35px h-md-35px'
const btnIconClass = 'svg-icon-2'
export function SidebarTimeTracker() {
  const location = useLocation()

  const {user, userData} = useAuthContext()
  const {documents: clients} = useCollection('clients')
  const {documents: projects} = useCollection('projects')
  const {documents: myTimeTracker} = useCollectionWithParam(
    'users/' + user.uid + '/timeTracker',
    'date',
    50
  )

  function closeHandle() {
    const rightSidebarStatus = document.body.getAttribute('data-right-sidebar')
    //const rightSidebarContent = document.body.getAttribute("data-right-sidebar-content");
    document.body.setAttribute('data-right-sidebar', 'off')
    const element = document.getElementById('timeTracker-button')
    element?.classList.remove('active')
  }
  const [loading, setLoading] = useState(false)

  const [myTrackerData, setMyTrackerData] = useState<any | null>(null)

  const [clientUID, setClientUID] = useState<any | null>(null)
  const [selectedProjectUID, setSelectedProjectUID] = useState('')
  const [note, setNote] = useState('')
  const [clientContracts, setClientContracts] = useState<any | null>(null)
  const [clientProjects, setClientProjects] = useState<any | null>(null)
  const [workTitle, setWorkTitle] = useState('')
  const [workUID, setWorkUID] = useState('')
  const [timeValue, setTimeValue] = useState(0)
  const [timeValueText, setTimeValueText] = useState('')

  let defaultDate = new Date()

  const [date, setDate] = useState(defaultDate)

  const onSetDate = (event) => {
    setDate(new Date(event.target.value))
  }

  useEffect(() => {
    if (myTimeTracker.length > 0) {
      const groupedData = myTimeTracker
        .sort((a: any, b: any) => (a.date < b.date ? 1 : -1))
        .reduce((acc, item: any) => {
          const dateInSeconds = item?.date.seconds
          const dateKey = new Date(dateInSeconds * 1000).toDateString()

          if (!acc[dateKey]) {
            acc[dateKey] = []
          }

          acc[dateKey].push(item)
          return acc
        }, {})
      setMyTrackerData(Object.entries(groupedData))
    }
  }, [myTimeTracker])

  useEffect(() => {
    // if (userData) {
    //   userData.activeClient && setClientUID(userData.activeClient)
    // }
  }, [userData])

  useEffect(() => {
    if (clientUID) {
      const projectsRef = collection(db, 'clients', clientUID, 'projects')
      const fetchProjects = async () => {
        try {
          const docsSnap = await getDocs(projectsRef)
          if (docsSnap) {
            let data: {name: string; type: string; id: string}[] = []
            docsSnap.forEach((doc) => {
              data.push({name: doc.data().name, type: doc.data().period, id: doc.id})
            })
            setClientProjects(data)
            console.log('dataaa')
            console.log(data)
          }
        } catch (error) {
          console.log(error)
        }
      }
      fetchProjects()
      // setClientContracts(null)
      // setClientProjects(null)
      // const contractsRef = collection(db, 'clients', clientUID, 'contracts')
      // const fetchContracts = async () => {
      //   try {
      //     const docsSnap = await getDocs(contractsRef)
      //     if (docsSnap) {
      //       let data: {name: string; type: string; id: string}[] = []
      //       docsSnap.forEach((doc) => {
      //         data.push({name: doc.data().name, type: 'contract', id: doc.id})
      //       })
      //       setClientContracts(data)
      //     }
      //   } catch (error) {
      //     console.log(error)
      //   }
      // }
      // const projectsRef = collection(db, 'clients', clientUID, 'projects')
      // const fetchProjects = async () => {
      //   try {
      //     const docsSnap = await getDocs(projectsRef)
      //     if (docsSnap) {
      //       let data: {name: string; type: string; id: string}[] = []
      //       docsSnap.forEach((doc) => {
      //         data.push({name: doc.data().name, type: 'contract', id: doc.id})
      //       })
      //       setClientProjects(data)
      //     }
      //   } catch (error) {
      //     console.log(error)
      //   }
      // }
      // fetchContracts()
      // fetchProjects()
    }
  }, [clientUID])

  const handleChange = (event) => {
    setTimeValue(event.target.value)
    console.log(event.target.value)
    if (event.target.value == 0) {
      setTimeValueText(`0 dk`)
    }

    if (event.target.value > 0 && event.target.value < 11) {
      const timeText = event.target.value * 5
      setTimeValueText(`${timeText} dk`)
    }
    if (event.target.value == 12) {
      setTimeValueText(`1 Saat`)
    }

    if (event.target.value > 12 && event.target.value < 23) {
      const timeText = (event.target.value - 12) * 5
      setTimeValueText(`1 Saat ${timeText} dk`)
    }

    if (event.target.value == 24) {
      setTimeValueText(`2 Saat`)
    }

    if (event.target.value > 24 && event.target.value < 36) {
      const timeText = (event.target.value - 24) * 5
      setTimeValueText(`2 Saat ${timeText} dk`)
    }
    if (event.target.value == 36) {
      setTimeValueText(`3 Saat`)
    }

    if (event.target.value > 36 && event.target.value < 48) {
      const timeText = (event.target.value - 36) * 5
      setTimeValueText(`3 Saat ${timeText} dk`)
    }
    if (event.target.value == 48) {
      setTimeValueText(`4 Saat`)
    }
  }

  // const handleSelectChange = (event) => {
  //   const id = event.target.value
  //   setWorkUID(id)
  //   if (clientContracts.filter((contract: any) => contract.id === id).length > 0) {
  //     setWorkType('contract')
  //     clientContracts.map((contract: any) => contract.id === id && setWorkTitle(contract.name))
  //   }
  //   if (clientProjects.filter((project: any) => project.id === id).length > 0) {
  //     setWorkType('project')
  //     clientProjects.map((project: any) => project.id === id && setWorkTitle(project.name))
  //   }
  // }

  const handleSubmit = () => {
    console.log(date)
    setLoading(true)
    const docRef = collection(db, 'users', user.uid, 'timeTracker')
    // Ajans Genel Giderler + Yönetim Maliyet / Çalışan Sayısı
    const agencyCostPerUser = 125000 / 12 / 9600

    const cost = ((userData?.cost / 9600 + agencyCostPerUser) * (timeValue * 5)).toFixed(2)
    const selectedProject: any = projects.find((p: any) => p.id === selectedProjectUID)

    const workData = {
      clientUID: selectedProject.clientUID,
      userUID: user.uid,
      workUID: selectedProjectUID,
      projectUID: selectedProjectUID,
      projectName: selectedProject.name,
      workTitle: selectedProject.name,
      timeValue: timeValue * 5,
      cost: cost,
      date: date,
      note: note,
    }
    addDoc(docRef, workData)
      .then((data) => {
        const docRef = doc(db, 'clients', selectedProject.clientUID, '/timeTracker/', data.id)
        setDoc(docRef, workData).then(() => {
          const docRef = doc(db, 'timeTracker/', data.id)
          setDoc(docRef, workData).then(() => {
            clearForm()
            setLoading(false)
          })
        })
      })
      .catch((error) => console.log(error))
  }

  const clearForm = () => {
    setWorkUID('')
    setTimeValue(0)
    setTimeValueText('')
    setSelectedProjectUID('')
    setWorkTitle('')
    setDate(defaultDate)
    setNote('')
  }

  const deleteTimeTracker = (clientUID, dataUID) => {
    const userDocRef = doc(db, 'users', user.uid, '/timeTracker/', dataUID)
    const clientDocRef = doc(db, 'clients', clientUID, '/timeTracker/', dataUID)
    const timeTrackerDocRef = doc(db, 'timeTracker/', dataUID)
    deleteDoc(userDocRef)
      .then(() => {
        deleteDoc(clientDocRef).then(() => {
          deleteDoc(timeTrackerDocRef).then(() => {
            console.log('Entire Document has been deleted successfully.')
          })
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <>
      <div className='pe-8 my-4'>
        <div className='d-flex flex-stack'>
          <div className='page-title d-flex flex-wrap me-3 flex-column justify-content-center'>
            <h1 className='page-heading d-flex text-dark fw-bold fs-3 my-0 flex-column justify-content-center'>
              Time Tracker
            </h1>
            <span className='fw-semibold text-muted'> Contracts & Projects</span>
          </div>
          <div className='d-flex align-items-center gap-2 gap-lg-3'>
            <div className={clsx('position-relative', btnClass)} onClick={closeHandle}>
              <KTSVG path='/media/icons/close.svg' className={btnIconClass} />
            </div>
          </div>
        </div>
      </div>

      <div className='card mt-2'>
        <div className='px-4 py-2 card-body card-scroll vh-100'>
          {/* <div className='row'>
            <div
              className={clsx('cursor-pointer')}
              data-kt-menu-trigger="{default: 'click'}"
              data-kt-menu-attach='parent'
              data-kt-dismiss='click'
              data-kt-menu-placement='bottom'
            >
              <div
                className='menu-content bg-hover-light-primary  rounded-2 mt-2 d-flex align-items-center  menu-item'
                data-kt-menu-trigger='hover'
                data-kt-menu-placement='left-start'
                data-kt-menu-flip='bottom'
              >
                {!selectedProjectUID && (
                  <a href='#' className='menu-link'>
                    <div className='d-flex flex-column flex-fill'>
                      <div className='fw-bolder d-flex align-items-center fs-7'>Proje Seç</div>
                      <span className='fw-semibold text-muted fs-7'>
                        Çalışma süresi girmek için proje seçiniz.
                      </span>
                    </div>
                    <div>
                      <i className='fa-solid fa-angle-down'></i>
                    </div>
                  </a>
                )}

                {selectedProjectUID &&
                  projects
                    ?.filter((p: any) => p.id === selectedProjectUID)
                    ?.map((project: any, i) => (
                      <a
                        className='menu-link bg-hover-light-primary p-2'
                        onClick={() => setSelectedProjectUID(project.id)}
                        key={i}
                      >
                        <div className='d-flex w-100 align-items-center'>
                          {clients
                            ?.filter((c: any) => c.id === project.clientUID)
                            .map((client: any, i) => (
                              <div className='d-flex w-100 align-items-center' key={i}>
                                <Avatar
                                  className={'symbol symbol-40px me-3'}
                                  name={client.brandName}
                                  avatarURL={client?.logo}
                                />
                                <div className='d-flex flex-fill flex-column'>
                                  <div className='fw-bold d-flex align-items-center fs-7'>
                                    {client?.brandName}
                                  </div>
                                  <span className='fw-bold text-muted fs-8'>{project?.name}</span>
                                </div>
                                <div className='d-flex me-4'>
                                  {project?.period === 'monthly' && (
                                    <span className='badge badge-success float-end'>Fee</span>
                                  )}
                                  {project?.period === 'oneTime' && (
                                    <span className='badge badge-warning float-end'>Project</span>
                                  )}
                                  {project?.period === 'yearly' && (
                                    <span className='badge badge-primary float-end'>Yıllık</span>
                                  )}
                                </div>
                                <div>
                                  <i className='fa-solid fa-angle-down me-2'></i>
                                </div>
                              </div>
                            ))}
                        </div>
                      </a>
                    ))}
              </div>
              {clientUID &&
                clients
                  .filter((c: any) => c.id === clientUID)
                  .map((selectedClient: any, i) => (
                    <div
                      className='menu-content bg-hover-light-primary rounded-2 mt-2 d-flex align-items-center  menu-item'
                      data-kt-menu-trigger='hover'
                      data-kt-menu-placement='left-start'
                      data-kt-menu-flip='bottom'
                      key={i}
                    >
                      <a href='#' className='menu-link px-4'>
                        <Avatar
                          className={'symbol symbol-40px me-3'}
                          name={selectedClient.brandName}
                          avatarURL={selectedClient?.logo}
                        />

                        <div className='d-flex flex-column flex-fill'>
                          <div className='fw-bolder d-flex align-items-center fs-7'>Proje Seç</div>
                          <span className='fw-semibold text-muted fs-7'>
                            {selectedClient.companyName}
                          </span>
                        </div>
                      </a>
                    </div>
                  ))}
            </div>
            <div
              className='menu menu-sub w-350px menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold fs-6 w-275px'
              data-kt-menu='true'
            >
              <div className='scroll-y mh-325px py-2'>
                {projects
                  ?.filter(
                    (p: any) =>
                      p?.authorities.filter((u: any) => u.uid === user.uid).length > 0 &&
                      p.isActive === true
                  )
                  ?.map((project: any, i) => (
                    <div className='menu-item  px-4 pe-3' key={i}>
                      <a
                        className='menu-link bg-hover-light-primary p-2'
                        onClick={() => setSelectedProjectUID(project.id)}
                      >
                        <div className='d-flex w-100 align-items-center'>
                          {clients
                            ?.filter((c: any) => c.id === project.clientUID)
                            .map((client: any, i) => (
                              <div className='d-flex w-100 align-items-center' key={i}>
                                <Avatar
                                  className={'symbol symbol-40px me-3'}
                                  name={client.brandName}
                                  avatarURL={client?.logo}
                                />
                                <div className='d-flex flex-fill flex-column'>
                                  <div className='fw-bold d-flex align-items-center fs-7'>
                                    {client?.brandName}
                                  </div>
                                  <span className='fw-bold text-muted fs-8'>{project?.name}</span>
                                </div>
                                <div className='d-flex '>
                                  {project?.period === 'monthly' && (
                                    <span className='badge badge-success float-end'>Fee</span>
                                  )}
                                  {project?.period === 'oneTime' && (
                                    <span className='badge badge-warning float-end'>Project</span>
                                  )}
                                  {project?.period === 'yearly' && (
                                    <span className='badge badge-primary float-end'>Yıllık</span>
                                  )}
                                </div>
                              </div>
                            ))}
                        </div>
                      </a>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className='row px-6'>
            <div className='col-md-6 mt-4 ps-0'>
              <div className='form-group'>
                <label className='form-label fs-7'>Tarih</label>
                <input
                  type='date'
                  className='form-control form-control-sm form-control-solid mb-3 mb-lg-0'
                  placeholder=''
                  onChange={onSetDate}
                  value={date.toLocaleDateString('en-CA')}
                />
              </div>
            </div>

            <div className='col-md-6 mt-4 pe-0'>
              <div className='form-gruop'>
                <div className='form-label fs-7 d-flex justify-content-between pt-2 '>
                  <span className='mb-1'>Süre </span>
                  <span className='badge badge-success float-end'>
                    {timeValueText && timeValueText}
                  </span>
                </div>

                <input
                  type='range'
                  className='form-range'
                  min='0'
                  max='48'
                  onChange={handleChange}
                  value={timeValue}
                  id='customRange2'
                />
              </div>
            </div>

            <div className='form-gruop px-1 mt-6'>
              <textarea
                placeholder='Çalışma ile ilgili ek bilgi yazabilirsin. (Opsiyonel)'
                className='form-control bg-transparent fs-7'
                value={note}
                onChange={(e) => setNote(e.target.value)}
              ></textarea>
            </div>
            <div className='form-gruop px-1 mt-4'>
              <button
                type='submit'
                className='btn btn-sm btn-primary w-150px float-end'
                disabled={timeValue === 0 || selectedProjectUID === ''}
                onClick={handleSubmit}
              >
                {!loading && <span className='indicator-label'>Save</span>}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
              <button
                className='btn btn-sm btn-light-primary float-end me-2'
                onClick={clearForm}
                type='reset'
              >
                <span className='indicator-label'>Clear</span>
              </button>
            </div>
          </div> */}
          <div className='row mt-4 px-2' style={{paddingBottom: '150px'}}>
            {myTrackerData &&
              myTrackerData.map((item, index) => (
                <div className='mb-8 mt-2' key={index}>
                  <div className='d-flex align-items-center'>
                    <div className='flex-fill'>
                      <span className='fs-7 text-primary fw-bolder text-muted flex-fill'>
                        {item[0]}
                      </span>
                    </div>

                    <span className='badge badge-sm badge-success fs-9 p-2  me-1'>
                      Toplam {item[1].reduce((a, v: any) => (a = a + v.timeValue), 0)} dk
                    </span>
                  </div>
                  <div className='separator separator-dashed my-2'></div>
                  {item[1]
                    ?.sort((a: any, b: any) => (a.date < b.date ? 1 : -1))
                    .map((value: any, i) => (
                      <div className='d-flex align-items-sm-center p-0 py-2 rounded-2 ' key={i}>
                        {clients &&
                          clients.map(
                            (client: any, i) =>
                              client.id === value.clientUID && (
                                <div className='d-flex align-items-sm-center w-100' key={i}>
                                  <Avatar
                                    className={'symbol symbol-40px me-2'}
                                    name={client?.brandName}
                                    avatarURL={client?.logo}
                                  />

                                  <div className='d-flex flex-column flex-fill'>
                                    <div className='fw-bolder text-primary d-flex align-items-center fs-7'>
                                      {client?.brandName}
                                    </div>
                                    <span className='fw-semibold text-muted fs-7'>
                                      {value?.workTitle}
                                    </span>
                                  </div>
                                </div>
                              )
                          )}

                        <div className='d-flex align-items-center '>
                          {value.note && (
                            <button
                              className='btn btn-icon btn-sm bg-hover-light me-1'
                              data-tooltip-id='my-toosltip'
                              data-tooltip-content={value.note}
                            >
                              <i className='bi bi-card-text fs-4'></i>
                              <Tooltip id='my-toosltip' />
                            </button>
                          )}

                          <span className='badge badge-sm badge-success fs-9 p-2 py-2 me-1'>
                            {value.timeValue} dk
                          </span>
                          <button
                            className='btn btn-icon btn-sm bg-hover-light'
                            onClick={() => deleteTimeTracker(value.clientUID, value.id)}
                          >
                            <i className='bi bi-trash fs-4'></i>
                          </button>
                        </div>
                      </div>
                    ))}
                </div>
              ))}

            {myTimeTracker.length > 4 && (
              <Link
                className={`btn btn-sm btn-light-primary w-100 my-4 `}
                to='/account/myTimeActivity'
                onClick={closeHandle}
              >
                All My Activity
              </Link>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

// function getCss(el: HTMLElement, styleProp: string) {
//   const defaultView = (el.ownerDocument || document).defaultView
//   if (!defaultView) {
//     return ''
//   }

//   // sanitize property name to css notation
//   // (hyphen separated words eg. font-Size)
//   styleProp = styleProp.replace(/([A-Z])/g, '-$1').toLowerCase()
//   return defaultView.getComputedStyle(el, null).getPropertyValue(styleProp)
// }
