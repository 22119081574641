import clsx from 'clsx'
import {addDoc, collection, serverTimestamp} from 'firebase/firestore'
import {FC, Key, useState} from 'react'
import {useAuthContext} from '../../../../../../app/modules/auth'
import {db} from '../../../../../../firebase/config'
import {Avatar} from '../../../../content/user/Avatar'

export function DropdownRoom(data) {
  const {user} = useAuthContext()

  function createRoom(uid, displayName, photoURL) {
    addDoc(collection(db, 'chat'), {
      hasRole: [uid, user.uid],
      settings: {
        isPersonal: true,

        users: [
          {name: displayName, photoURL: photoURL, uid: uid},
          {name: user.displayName, photoURL: user.photoURL, uid: user.uid},
        ],
      },
      createdAt: serverTimestamp(),
    })
      .then(() => {})
      .catch((error) => {
        console.log(error.message)
      })
  }
  return (
    <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
      <div className='px-7 py-5'>
        <div className='fs-5 text-dark fw-bolder'>Start Conversation with </div>
      </div>
      <div className='separator border-gray-200 mb-4'></div>

      {data.users &&
      data.users.filter((user) => user.model.isTeammate === true).length - 1 !==
        data.rooms.filter((room: any) => room.settings.isPersonal === true).length ? (
        data.users
          .filter((user) => user.model.isTeammate === true)
          .sort((a, b) => a.displayName.localeCompare(b.displayName))
          .map(
            (chatUser: any, index) =>
              data.rooms.filter(
                (room: any) =>
                  room.settings.isPersonal === true &&
                  room.hasRole?.includes(chatUser.id) &&
                  room.hasRole?.includes(user.uid)
              ).length < 1 && (
                <div
                  className='d-flex align-items-sm-center mb-2 p-2 rounded-2 bg-hover-light mx-4'
                  onClick={() => createRoom(chatUser.id, chatUser.displayName, chatUser.photoURL)}
                  key={index}
                  data-kt-menu-dismiss='true'
                >
                  <Avatar
                    className='symbol symbol-35px symbol-md-40px'
                    name={chatUser?.displayName}
                    avatarURL={chatUser?.photoURL}
                  />
                  <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
                    <div className='flex-grow-1 flex-column mx-3'>
                      <span className='text-primary fs-6 fw-bold d-block'>
                        {chatUser?.displayName}
                      </span>
                      <span className='text-muted fw-normal  fs-8'>{chatUser?.title}</span>
                    </div>

                    <span className='text-muted fs-9 me-4'>
                      <i className='bi bi-arrow-right'></i>
                    </span>
                  </div>
                </div>
              )
          )
      ) : (
        <span className='w-100  my-6 mb-10 text-center'>Sorry, there are no new users..</span>
      )}
    </div>
  )
}
