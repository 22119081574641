/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {useCollection} from '../../../../app/hooks/useCollection'
import {useAuthContext} from '../../../../app/modules/auth'
import {KTSVG} from '../../../helpers'
import {CardBirthday} from '../../content/cards/CardBirthday'
import {CardProject2} from '../../content/cards/CardProject2'
import {CardUserUpdate} from '../../content/cards/CardUserUpdate'
import {Dropdown1} from '../../content/dropdown/Dropdown1'
import moment from 'moment'
import {CardProject} from '../../content/cards/CardProject'
import {useEffect, useState} from 'react'
import {CardWork} from '../../content/cards/CardWork'
import {Timestamp} from 'firebase/firestore'
import {InputText} from 'primereact/inputtext'
import {useGetWorks} from '../../../../app/modules/components/Functions/useGetWorks'
import {UserWorks} from '../../../../app/modules/components/Elements/UserWorks'

const btnClass = 'btn btn-sm btn-icon btn-light-primary w-25px h-25px w-md-35px h-md-35px'
const btnIconClass = 'svg-icon-2'
export function SidebarWorks() {
  const {user} = useAuthContext()

  function closeHandle() {
    const rightSidebarStatus = document.body.getAttribute('data-right-sidebar')
    //const rightSidebarContent = document.body.getAttribute("data-right-sidebar-content");
    document.body.setAttribute('data-right-sidebar', 'off')
    const element = document.getElementById('works-button')
    element?.classList.remove('active')
  }

  return (
    <>
      <div className='pe-8'>
        <div className='d-flex flex-stack'>
          <div className='page-title d-flex flex-wrap me-3 flex-column my-1 justify-content-center'>
            <h1 className='page-heading d-flex text-primary fw-bold fs-3 my-0 flex-column justify-content-center'>
              Works
            </h1>
            <span className='fw-semibold text-muted'> Task & Projects</span>
          </div>
          <div className='d-flex align-items-center gap-2 gap-lg-3'>
            <div className='m-0'>
              <a
                href='#'
                className={clsx('position-relative', btnClass)}
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
              >
                <KTSVG path='/media/icons/filter.svg' className={btnIconClass} />
              </a>
              <Dropdown1 />
            </div>
            <div className={clsx('position-relative', btnClass)} onClick={closeHandle}>
              <KTSVG path='/media/icons/close.svg' className={btnIconClass} />
            </div>
          </div>
        </div>
      </div>
      <div className='card mt-2 p-4 pe-0 pb-20'>
        <UserWorks userUID={user.uid} scroll={true} showStart={true} />
      </div>
      {/* <div className='card mt-2'>
        <div className='px-4 py-2 card-body card-scroll vh-100'> */}
      {/* <span className='form-control form-control-sm form-control-solid p-0 p-input-icon-left p-input-icon-right mt-2 mb-4'>
            <i className='bi bi-search w-30px' />
            <InputText
              autoFocus
              placeholder='Search work or task...'
              className='form-control form-control-sm  form-control-solid ps-10'
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            {search !== '' && (
              <i className='bi bi-x-circle cursor-pointer' onClick={(e) => setSearch('')} />
            )}
          </span> */}

      {/* <KTSVG path='/media/icons/search.svg' className='svg-icon-2' /> */}
      {/* {users &&
            users.filter((u: any) => u.id === user.uid && u.model.isManager).length > 0 &&
            users
              .filter((user: any) => user.model.isPending === true)
              .map((user: any, i) => (
                <div key={i}>
                  <CardUserUpdate
                    name={user.displayName}
                    avatar={user?.photoURL}
                    email={user.email}
                    uid={user.id}
                    link='crafted/pages/profile/overview'
                  />
                </div>
              ))} */}

      {/* {users
            .sort((a: any, b: any) =>
              moment(a.birthDate).format('DD') > moment(b.birthDate).format('DD') ? 1 : -1
            )
            .filter(
              (u: any) =>
                u.birthDate &&
                moment(u.birthDate).format('MM') === moment().format('MM') &&
                moment(u.birthDate).format('DD') < moment().format('DD')
            )
            .map((user: any) => (
              <>
                <CardBirthday
                  avatar={user?.photoURL}
                  name={user.displayName}
                  date={user?.birthDate}
                  link={'#'}
                />
                <div className='separator separator-dashed my-2'></div>
              </>
            ))} */}

      {/* {projects &&
            projects
              .filter(
                (p: any) =>
                  p?.works?.filter(
                    (w: any) => w?.authorities?.filter((u: any) => u.uid === user.uid).length > 0
                  ).length > 0 && p.isActive === true
              )
              .map((project: any) =>
                clients.map(
                  (client: any, i) =>
                    client.id === project.clientUID && (
                      <div key={i}>
                        <CardProject
                          clientUID={client.id}
                          clientName={client?.brandName}
                          clientLogo={client?.logo}
                          projectName={project?.name}
                          services={project?.services}
                          projectUID={project?.id}
                          working={project?.working ? project?.working : []}
                          works={project?.works?.filter(
                            (w: any) =>
                              w?.authorities.filter((u: any) => u.uid === user.uid).length > 0
                          )}
                          deadline={''}
                          link={`client/${client?.id}/projects/${project?.id}/${
                            project?.services?.socialMedia?.isActive === true ? 'social-media' : ''
                          }`}
                        />
                        <div className='separator separator-dashed my-2'></div>
                      </div>
                    )
                )
              )} */}
      {/* 
          {pinWorks &&
            pinWorks.length > 0 &&
            pinWorks.filter(
              (workD: any, i) =>
                workD?.projectName.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                workD?.clientName.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                workD?.workName.toLowerCase().indexOf(search.toLowerCase()) !== -1
            ).length > 0 && (
              <div className='mb-4'>
                <div className='d-flex align-items-center mt-1'>
                  <span className='fs-7 text-primary fw-bold flex-fill'>Pinned Works</span>
                  <span className='badge badge-sm badge-warning fs-9   me-1'>
                    {' '}
                    <i className='bi bi-pin-angle-fill text-white fs-3'></i>
                  </span>
                </div>
                <div className='separator separator-dashed my-2'></div>
                {pinWorks.map(
                  (workD: any, i) =>
                    (workD?.projectName.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                      workD?.clientName.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                      workD?.workName.toLowerCase().indexOf(search.toLowerCase()) !== -1) && (
                      <CardWork
                        workData={workD}
                        key={i}
                        showStart={true}
                        isTeamTaskPage={false}
                        userUID={''}
                      />
                    )
                )}
              </div>
            )}

          {works &&
            works
              .sort((a: any, b: any) => (a.workDeadline < b.workDeadline ? -1 : 1))
              .map(
                (work: any, index) =>
                  work[1]?.filter(
                    (workD: any, i) =>
                      workD?.projectName.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                      workD?.clientName.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                      workD?.workName.toLowerCase().indexOf(search.toLowerCase()) !== -1
                  ).length > 0 && (
                    <div className='mb-4' key={index}>
                      <div className='d-flex align-items-center mt-1'>
                        <span className='fs-7 text-primary fw-bold flex-fill'>
                          {moment(work[0]).format('DD MMM dddd')}
                        </span>

                        {/* {moment(work[0]).fromNow()} */}
      {/* {moment.duration(moment(work[0]).diff(moment().startOf('day'))).asDays() ===
                          0 && (
                          <span className='badge badge-sm badge-success fs-9 p-2  me-1'>
                            {' '}
                            Today
                          </span>
                        )}
                        {moment.duration(moment(work[0]).diff(moment().startOf('day'))).asDays() <
                          0 && (
                          <span className='badge badge-sm badge-danger fs-9 p-2  me-1'>
                            {' '}
                            {moment(work[0]).fromNow()}
                          </span>
                        )}
                        {moment.duration(moment(work[0]).diff(moment().startOf('day'))).asDays() >
                          0 && (
                          <span className='badge badge-sm badge-primary fs-9 p-2  me-1'>
                            {' '}
                            {moment(work[0]).fromNow()}
                          </span>
                        )} */}
      {/* </div>
                      <div className='separator separator-dashed my-2'></div>
                      {work[1]
                        ?.sort((a: any, b: any) => (a.workDeadline < b.workDeadline ? -1 : 1))
                        .map(
                          (workD: any, i) =>
                            (workD?.projectName.toLowerCase().indexOf(search.toLowerCase()) !==
                              -1 ||
                              workD?.clientName.toLowerCase().indexOf(search.toLowerCase()) !==
                                -1 ||
                              workD?.workName.toLowerCase().indexOf(search.toLowerCase()) !==
                                -1) && (
                              <CardWork
                                workData={workD}
                                key={i}
                                showStart={true}
                                isTeamTaskPage={false}
                                userUID={''}
                              />
                            )
                        )}
                    </div>
                  )
              )} */}

      {/* {projects &&
            projects
              .filter(
                (p: any) =>
                  p?.authorities.filter((u: any) => u.uid === user.uid).length > 0 &&
                  p.isActive === true
              )
              .map((project: any) =>
                clients.map(
                  (client: any, i) =>
                    client.id === project.clientUID && (
                      <div key={i}>
                        <CardProject
                          clientName={client?.brandName}
                          clientLogo={client?.logo}
                          projectName={project?.name}
                          service={project?.connectedServices}
                          deadline={''}
                          link={`client/${client?.id}/projects/${project?.id}/${
                            project?.services?.socialMedia?.isActive === true ? 'social-media' : ''
                          }`}
                        />
                        <div className='separator separator-dashed my-2'></div>
                      </div>
                    )
                )
              )} */}

      {/* <CardProject
            logo='/media/clients/lezzet-fikirleri.png'
            job='Backend'
            service='Web Site'
            deadline='17 March'
            buttonText='Project'
            link='extensions'
          /> */}
      {/* <CardTask
            logo='/media/clients/lezzet-fikirleri.png'
            job='Content'
            service='Social Media'
            deadline='Today'
            buttonText='Media'
            link='/client/v3PnHq7OWc9j3SqCCJ7X/social-media'
          />

          <CardTask
            logo='/media/clients/lezzet-fikirleri.png'
            job='Content'
            service='Social Media'
            deadline='Tomorrow'
            buttonText='Media'
            link='/client/v3PnHq7OWc9j3SqCCJ7X/social-media'
          />
          <div className='separator separator-dashed my-2'></div>
          
          <div className='separator separator-dashed my-2'></div>
          <CardProject
            logo='/media/clients/lezzet-fikirleri.png'
            job='Backend'
            service='Web Site'
            deadline='17 March'
            buttonText='Project'
            link='extensions'
          />
          <div className='separator separator-dashed my-2'></div>
          <CardTask
            logo='/media/clients/lezzet-fikirleri.png'
            job='Content'
            service='Social Media'
            deadline='17 Februry'
            buttonText='Media'
            link='extensions'
          />
          <div className='separator separator-dashed my-2'></div>
          <CardTask
            logo='/media/clients/lezzet-fikirleri.png'
            job='Content'
            service='Social Media'
            deadline='17 Februry'
            buttonText='Media'
            link='extensions'
          />
          <div className='separator separator-dashed my-2'></div>
          <CardTask
            logo='/media/clients/lezzet-fikirleri.png'
            job='Content'
            service='Social Media'
            deadline='17 Februry'
            buttonText='Media'
            link='extensions'
          />
          <div className='separator separator-dashed my-2'></div>
          <CardTask
            logo='/media/clients/lezzet-fikirleri.png'
            job='Content'
            service='Social Media'
            deadline='17 Februry'
            buttonText='Media'
            link='extensions'
          />
          <div className='separator separator-dashed my-2'></div>
          <CardTask
            logo='/media/clients/lezzet-fikirleri.png'
            job='Content'
            service='Social Media'
            deadline='17 Februry'
            buttonText='Media'
            link='extensions'
          />
          <div className='separator separator-dashed my-2'></div> */}
      {/* </div>
      </div>
       */}
    </>
  )
}

function getCss(el: HTMLElement, styleProp: string) {
  const defaultView = (el.ownerDocument || document).defaultView
  if (!defaultView) {
    return ''
  }

  // sanitize property name to css notation
  // (hyphen separated words eg. font-Size)
  styleProp = styleProp.replace(/([A-Z])/g, '-$1').toLowerCase()
  return defaultView.getComputedStyle(el, null).getPropertyValue(styleProp)
}
