import { createContext, useReducer, useEffect} from 'react'
import { auth, db } from '../../../../firebase/config'
import { onAuthStateChanged } from 'firebase/auth'
import { doc, getDoc } from 'firebase/firestore'

export const AuthContext = createContext()

export const authReducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      return { ...state, user: action.payload, userData:action.userData}
    case 'LOGOUT':
      return { ...state, user: null, role:null }
    case 'AUTH_IS_READY':
      return { user: action.payload, authIsReady: true, userData:action.userData}
    default:
      return state
  }
}


export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, { 
    user: null,
    authIsReady: false,
    userData: null
  })

  useEffect(() => {
    const unsub = onAuthStateChanged(auth, async user => {
    let isUserTeammate = null
    if (auth.currentUser) {
      const docRef = doc(db, "users", auth.currentUser.uid);
      try {
        const docSnap = await getDoc(docRef);
        if(docSnap.data().model.viewBrand === true || docSnap.data().model.viewAgency === true ) {isUserTeammate = docSnap.data()} 
      } catch(error) {
          console.log(error)
      }
    }
    dispatch({ type: 'AUTH_IS_READY', payload: user, userData:isUserTeammate})
    unsub()
    })
  }, [])

  
  return (
    <AuthContext.Provider value={{ ...state, dispatch }}>
      { children }
    </AuthContext.Provider>
  )

}