/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {SocialMediaContentStatusModels} from '../models/SocialMediaModels'

import {db} from '../../../../../../../../../firebase/config'
import {
  Timestamp,
  addDoc,
  arrayUnion,
  collection,
  doc,
  serverTimestamp,
  setDoc,
  updateDoc,
} from 'firebase/firestore'
import {useParams} from 'react-router-dom'
import {useAuthContext} from '../../../../../../../auth'

type Props = {
  contentData: any
}

const DropdownSocialMediaContentStatus: FC<Props> = ({contentData}) => {
  const {id, projectID} = useParams()
  const clientUID = id ? id : ''
  const projectUID = projectID ? projectID : ''

  const {user} = useAuthContext()

  const handleSelect = async (event) => {
    event.stopPropagation()

    const docRef = doc(db, 'clients', clientUID, 'socialMediaContents', contentData.id)
    // if (token) {
    //   setDoc(
    //     docRef,
    //     {
    //       status: event.target.value,
    //     },
    //     {merge: true}
    //   )
    //     .then(async () => {
    //       let actionValue =
    //         'I changed the content status from ' +
    //         contentData.status +
    //         ' to ' +
    //         event.target.value +
    //         '.'
    //       updateDoc(doc(db, 'clients', clientUID, 'socialMediaContents', contentData.id), {
    //         logs: arrayUnion({
    //           displayName: user.displayName,
    //           uid: user.uid,
    //           photoURL: user?.photoURL,
    //           action: actionValue,
    //           oldValue: contentData.status,
    //           value: event.target.value,
    //           createdAt: Timestamp.now(),
    //         }),
    //       })
    //     })
    //     .catch((error) => {
    //       console.log(error.message)
    //     })
    // }
    const docRefProject = doc(
      db,
      'clients',
      clientUID,
      'projects',
      projectUID,
      'socialMediaContents',
      contentData.id
    )
    setDoc(
      docRefProject,
      {
        status: event.target.value,
      },
      {merge: true}
    )
      .then(async () => {
        let actionValue =
          'I changed the content status from ' +
          contentData.status +
          ' to ' +
          event.target.value +
          '.'
        updateDoc(docRefProject, {
          logs: arrayUnion({
            displayName: user.displayName,
            uid: user.uid,
            photoURL: user?.photoURL,
            action: actionValue,
            oldValue: contentData.status,
            value: event.target.value,
            createdAt: Timestamp.now(),
          }),
        })
      })
      .catch((error) => {
        console.log(error.message)
      })
  }

  return (
    <select
      className='form-select form-select-sm  fs-8'
      data-kt-select2='true'
      data-placeholder='Select option'
      data-allow-clear='true'
      defaultValue={contentData.status}
      onChange={handleSelect}
    >
      {SocialMediaContentStatusModels.map((status: any, i) => (
        <option key={i} value={status.name}>
          {status.name}
        </option>
      ))}
    </select>
    // <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
    //   <div className='px-7 py-5'>
    //     <div className='mb-7'>
    //       <label className='form-label fw-bold'>Content Status</label>
    //       <div>
    //         <select
    //           className='form-select form-select-solid'
    //           data-kt-select2='true'
    //           data-placeholder='Select option'
    //           data-allow-clear='true'
    //           defaultValue={contentData.status}
    //           onChange={handleSelect}

    //         >
    //           {SocialMediaContentStatusModels.map((status: any, i) => (
    //             <option key={i} value={status.name}>
    //               {status.name}
    //             </option>
    //           ))}
    //         </select>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  )
}

export {DropdownSocialMediaContentStatus}
