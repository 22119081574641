import { React, useState } from 'react'

import { auth, db ,storage} from '../../../firebase/config'
import { signInWithEmailAndPassword } from 'firebase/auth'
import {doc, setDoc, getDoc } from "firebase/firestore"; 
import {ref, uploadBytesResumable, getDownloadURL} from 'firebase/storage'
import {v4 as uuidv4} from 'uuid'


export const useFilesUpload = () => {
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(false)
  const [uploadProgress, setUploadProgress] = useState({})
  const [uploaded, setUploaded] = useState({})

  const fileUpload = async (selectedFiles, storagePath, firestorePath) => {
    setError(null)
    let uploadedFiles=[]

    for (const file of selectedFiles) {
      let fileName = `${uuidv4()}_${file.name}`
      let filePathWithName = `${storagePath}/${fileName}`
      const fileRef = ref(storage, filePathWithName)
      const uploadTask = uploadBytesResumable(fileRef, file)
      console.log(file)
      uploadTask.on('state_changed', (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        setUploadProgress((prevProgress) => ({
          ...prevProgress,
          [file.name]: progress,
        }))
      })

      await uploadTask

      const downloadURL = await getDownloadURL(fileRef)
      uploadedFiles.push({fileName: fileName, fileSize: file?.size, downloadURL: downloadURL, fileType: file.type})
    }

    
    if (uploadedFiles)  {
      getDoc(firestorePath)
      .then((data) => {
        let oldMedias=[] 
        oldMedias = data.data()?.files ? data.data()?.files : []

        var c = [...oldMedias, ...uploadedFiles]
        console.log(c)
        setDoc(firestorePath, {files: c}, {merge: true})
      .then(() => {
        setSuccess(true)
      })
      .catch((error) => {
        return {error}
      })
      })
      
    }

    if (!error) {
      return(uploadedFiles)
    }
    else {
      return(error)
    }
    
    

					
  }

  return { fileUpload, uploaded, error }
}