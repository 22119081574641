import React, {useEffect, useState} from 'react'
import {Navigate, Route, Routes, Outlet, useParams} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../../_metronic/layout/core'
import {ProjectHeader} from './ProjectHeader'
import {Overview} from './pages/Overview'
import {useAuthContext} from '../../../auth'
import {doc, getDoc, onSnapshot} from 'firebase/firestore'
import {db} from '../../../../../firebase/config'
import {TimeActivity} from './pages/TimeActivity'
import {Budgets} from './pages/Budgets'
import {Settings} from './pages/Settings'
import {SocialMedia} from './services/social-media/SocialMedia'

const projectBreadCrumbs: Array<PageLink> = [
  {
    title: 'Project',
    path: '/project/overview',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ProjectPage: React.FC = () => {
  const {id, projectID} = useParams()
  const {user, userData} = useAuthContext()

  const [projectDetail, setProjectDetail] = useState<any | null>(null)

  useEffect(() => {
    let docRef = doc(db, 'clients/' + id + '/projects/' + projectID)
    getDoc(docRef)
      .then((data) => {
        setProjectDetail(data.data())
      })
      .catch((error) => {
        console.log(error.message)
      })
  }, [projectID])

  return (
    <Routes>
      <Route
        element={
          <>
            <ProjectHeader projectData={projectDetail} />
            <Outlet />
          </>
        }
      >
        <Route
          path={`overview`}
          element={
            <>
              <PageTitle breadcrumbs={projectBreadCrumbs}>Overview</PageTitle>
              <Overview />
            </>
          }
        />

        {userData?.model.isManager === true && (
          <>
            <Route
              path={`timeActivity`}
              element={
                <>
                  <PageTitle breadcrumbs={projectBreadCrumbs}>Time Activity</PageTitle>
                  <TimeActivity />
                </>
              }
            />
            <Route
              path={`budgets`}
              element={
                <>
                  <PageTitle breadcrumbs={projectBreadCrumbs}>Overview</PageTitle>
                  <Budgets projectData={projectDetail} />
                </>
              }
            />
            <Route
              path={`settings`}
              element={
                <>
                  <PageTitle breadcrumbs={projectBreadCrumbs}>Overview</PageTitle>
                  <Settings />
                </>
              }
            />
          </>
        )}

        {projectDetail?.services?.socialMedia?.isActive === true && (
          <Route
            path={projectDetail?.services?.socialMedia.slug}
            element={
              <>
                <PageTitle breadcrumbs={projectBreadCrumbs}>
                  {projectDetail?.services?.socialMedia.name}
                </PageTitle>
                <SocialMedia />
              </>
            }
          />
        )}
        <Route index element={<Navigate to={`overview`} />} />
      </Route>
    </Routes>
  )
}

export default ProjectPage
