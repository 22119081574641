import { useState, useEffect } from "react"; 
import {useCollection} from '../../../../app/hooks/useCollection'
import {useAuthContext} from '../../../../app/modules/auth'
import {Timestamp} from 'firebase/firestore'

export const useGetWorks = (userUID) => {
  const {documents: projects} = useCollection('projects')
  const {documents: clients} = useCollection('clients')
  const [worksDocuments, setWorksworksDocuments] = useState([])
  const [pinWorksDocuments, setPinWorksDocuments] = useState([])

  useEffect(() => {
    let userWorks = []
    if (projects) {
      const filterProjects = projects.filter(
        (p) =>
          p?.works?.map(
            (w) => w?.authorities?.filter((u) => u.uid === userUID).length > 0
          ).length > 0 && p.isActive === true
      )
      filterProjects.map((p) =>
        p?.works.map(
          (w) =>
            w?.authorities?.filter((u) => u.uid === userUID).length > 0 &&
            (!w?.isDone || w?.isDone === false) &&
            clients.map(
              (c) =>
                p.clientUID === c.id &&
                userWorks.push({
                  clientUID: c.id,
                  clientName: c?.brandName,
                  clientLogo: c?.logo,
                  projectName: p?.name,
                  services: p?.services,
                  projectUID: p?.id,
                  working: p?.working ? p?.working : [],
                  projectDeadline: p?.endDate,
                  workName: w?.name,
                  workUID: w?.id,
                  workDeadline: w?.deadline ? w?.deadline : Timestamp.now(),
                  projectLink: p?.services?.socialMedia?.isActive === true ? 'social-media' : '',
                  repeat: w?.repeat,
                  isOrderActive: p?.isOrderActive,
                  pins: w?.pins ? w?.pins : [],
                })
            )
        )
      )
      

      const userPinWorks = userWorks
        .sort((a, b) => (a.workDeadline < b.workDeadline ? -1 : 1))

        .filter((w) => w?.pins.filter((p) => p?.uid === userUID).length > 0)
        .sort((a, b) => {
          const apin = a?.pins.find((p) => p?.uid === userUID)
          const bpin = b?.pins.find((p) => p?.uid === userUID)
          if (apin.createdAt > bpin.createdAt) {
            return 1
          } else {
            return -1
          }
        })
        .sort((a, b) => {
          const apin = a?.pins.find((p) => p?.uid === userUID)
          const bpin = b?.pins.find((p) => p?.uid === userUID)
          if (apin?.priority === bpin?.priority) return 0
          if (apin?.priority) return -1
          return 1
        })
      const userWorksWithDateGroup = userWorks
        .sort((a, b) => (a.workDeadline < b.workDeadline ? -1 : 1))
        .filter((w) => !w?.pins || w?.pins.filter((p) => p?.uid === userUID).length < 1)
        .reduce((acc, item) => {
          const dateInSeconds = item?.workDeadline.seconds
          const dateKey = new Date(dateInSeconds * 1000).toDateString()

          if (!acc[dateKey]) {
            acc[dateKey] = []
          }

          acc[dateKey].push(item)
          return acc
        }, {})

      console.log(Object.entries(userWorksWithDateGroup))
      setPinWorksDocuments(userPinWorks)
      setWorksworksDocuments(Object.entries(userWorksWithDateGroup))
      
    }
  }, [projects])
  return {worksDocuments, pinWorksDocuments}
}
