import {Key, useContext, useEffect, useState} from 'react'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {Avatar} from '../../../../../../_metronic/partials'
import {useCollection} from '../../../../../hooks/useCollection'
import {Tooltip} from 'react-tooltip'
import moment from 'moment'
import {useAuthContext} from '../../../../auth'
import {deleteDoc, doc, getDoc} from 'firebase/firestore'
import {db} from '../../../../../../firebase/config'

export function TimeActivity() {
  const {id, projectID} = useParams()
  const {documents: users} = useCollection('users')
  const {user, userData} = useAuthContext()
  const [clientDetail, setClientDetail] = useState<any | null>(null)

  const {documents: timeTrackers} = useCollection('clients/' + id + '/timeTracker')

  const {documents: clients} = useCollection('clients')

  const [header, setHeader] = useState('Time Activity')

  const [filteredUsers, setFilteredUsers] = useState<any | null>(users)
  const [trackerData, setTrackerData] = useState<any | null>(null)

  const navigate = useNavigate()

  useEffect(() => {
    if (users) {
      setFilteredUsers(users)
    }
  }, [users])

  useEffect(() => {
    let docRef = doc(db, 'clients/' + id)
    getDoc(docRef)
      .then((data) => {
        setClientDetail(data.data())
      })
      .catch((error) => {
        console.log(error.message)
      })
  }, [id])

  useEffect(() => {
    if (timeTrackers.length > 0) {
      const groupedData = timeTrackers
        .filter((t: any) => t.workUID === projectID)
        .sort((a: any, b: any) => (a.date < b.date ? 1 : -1))
        .reduce((acc, item: any) => {
          const dateInSeconds = item?.date.seconds
          const dateKey = new Date(dateInSeconds * 1000).toDateString()

          if (!acc[dateKey]) {
            acc[dateKey] = []
          }

          acc[dateKey].push(item)
          return acc
        }, {})
      setTrackerData(Object.entries(groupedData))
    }
  }, [timeTrackers])

  const search = (event) => {
    event.target.value !== null &&
      setFilteredUsers(
        users.filter(
          (u: any) => u?.displayName.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1
        )
      )
  }

  return (
    <div className='row'>
      <div className='d-flex flex-wrap flex-stack my-4'>
        <h3 className='fw-bolder my-3 ps-1'>Time Tracker</h3>
      </div>{' '}
      <div className='p-4'>
        <div className=' g-6 g-xl-9'>
          {trackerData &&
            trackerData.map((item) => (
              <div className='mb-8 mt-2'>
                <div className='d-flex align-items-center'>
                  <h3 className='flex-fill fs-6 text-primary'>{item[0]}</h3>

                  <span className='badge badge-sm badge-success fs-8 p-2 py-3 me-1'>
                    Toplam {item[1].reduce((a, v: any) => (a = a + v.timeValue), 0)} dk
                  </span>
                </div>
                <div className='separator my-2'></div>
                <div className='d-flex flex-wrap'>
                  {item[1]
                    ?.sort((a: any, b: any) => (a.date < b.date ? 1 : -1))
                    .map((value: any) => (
                      <div className='d-flex align-items-sm-center bg-white p-3 rounded-2 mt-4 me-4'>
                        {users &&
                          users.map(
                            (u: any) =>
                              u.id === value.userUID && (
                                <>
                                  <Avatar
                                    className={'symbol symbol-40px me-2'}
                                    name={u?.displayName}
                                    avatarURL={u?.photoURL}
                                  />

                                  <div className='d-flex flex-column flex-fill me-6'>
                                    <div className='fw-bolder d-flex align-items-center fs-7'>
                                      {u?.displayName}
                                    </div>
                                    <span className='fw-semibold text-muted fs-7'>
                                      {clientDetail?.authorities
                                        .filter((u: any) => u.uid === value.userUID)
                                        .map((user: any) => u.title)}
                                    </span>
                                  </div>
                                </>
                              )
                          )}

                        <div className='d-flex align-items-center '>
                          {value.note && (
                            <button
                              className='btn btn-icon btn-sm bg-hover-light me-1'
                              data-tooltip-id={`tooltip-${value.id}`}
                              data-tooltip-content={value.note}
                            >
                              <i className='bi bi-card-text fs-4'></i>
                              <Tooltip id={`tooltip-${value.id}`} />
                            </button>
                          )}

                          <span className='badge badge-sm badge-success fs-8 p-2 py-3'>
                            {value.timeValue} dk
                          </span>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            ))}
        </div>
      </div>
      {/* <div className='p-4'>
        <div className=' g-6 g-xl-9'>
          {myTimeTracker &&
            myTimeTracker
              ?.sort((a: any, b: any) => (a.date < b.date ? 1 : -1))
              .map((value: any, i, arr) => {
                const prevWork: any = arr[i - 1]
                return (
                  <div className='mb-8 mt-2'>
                    {!moment(moment(prevWork?.date.toDate())).isSame(
                      moment(value.date.toDate()),
                      'day'
                    ) && (
                      <>
                        <div className='d-flex align-items-center'>
                          <h3 className='flex-fill'>
                            {moment(value.date.toDate()).format('DD MMMM dddd')}
                          </h3>

                          <span className='badge badge-sm badge-success fs-8 p-2 py-3 me-1'>
                            Toplam{' '}
                            {myTimeTracker
                              .filter((tracker: any) =>
                                moment(moment(value.date.toDate())).isSame(
                                  moment(tracker.date.toDate()),
                                  'day'
                                )
                              )
                              .reduce((a, v: any) => (a = a + v.timeValue), 0)}{' '}
                            dk
                          </span>
                        </div>
                        <div className='separator my-2'></div>
                        <div className='d-flex flex-wrap'>
                          {myTimeTracker
                            .filter((tracker: any) =>
                              moment(moment(value.date.toDate())).isSame(
                                moment(tracker.date.toDate()),
                                'day'
                              )
                            )
                            .map((value: any, i, arr) => (
                              <div className='d-flex align-items-sm-center bg-white p-3 rounded-2 mt-4 me-4'>
                                {clients &&
                                  clients.map(
                                    (client: any) =>
                                      client.id === value.clientUID && (
                                        <>
                                          <Avatar
                                            className={'symbol symbol-40px me-2'}
                                            name={client?.brandName}
                                            avatarURL={client?.logo}
                                          />

                                          <div className='d-flex flex-column flex-fill me-6'>
                                            <div className='fw-bolder d-flex align-items-center fs-7'>
                                              {client?.brandName}
                                            </div>
                                            <span className='fw-semibold text-muted fs-7'>
                                              {value?.workTitle}
                                            </span>
                                          </div>
                                        </>
                                      )
                                  )}

                                <div className='d-flex align-items-center '>
                                  {value.note && (
                                    <button
                                      className='btn btn-icon btn-sm bg-hover-light me-1'
                                      data-tooltip-id={`tooltip-${value.id}`}
                                      data-tooltip-content={value.note}
                                    >
                                      <i className='bi bi-card-text fs-4'></i>
                                      <Tooltip id={`tooltip-${value.id}`} />
                                    </button>
                                  )}

                                  <span className='badge badge-sm badge-success fs-8 p-2 py-3 me-1'>
                                    {value.timeValue} dk
                                  </span>
                                  <button
                                    className='btn btn-icon btn-sm bg-hover-light'
                                    onClick={() => deleteTimeTracker(value.clientUID, value.id)}
                                  >
                                    <i className='bi bi-trash fs-4'></i>
                                  </button>
                                </div>
                              </div>
                            ))}
                        </div>
                      </>
                    )}

                    {}
                  </div>
                )
              })}
        </div>
      </div> */}
    </div>
  )
}
