import { useState, useEffect } from "react"; 
import { db } from "../../firebase/config";

// firebase imports
import {
  collection, 
  onSnapshot, 
  query,
  orderBy,
  limit} from 'firebase/firestore'

export const useCollectionWithParam = (c, collectionOrderBy, collectionLimit) => {
  const [documents, setDocuments] = useState([])

  useEffect(() => {
    const q = query(
      collection(db, c),
      orderBy(collectionOrderBy, "desc"),
      limit(collectionLimit)
    );

    const unsub = onSnapshot(q, (snapshot) => {
      let results = []
      snapshot.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      })
      setDocuments(results.reverse())
    })

    return () => unsub()
  }, [c,collectionOrderBy,collectionLimit])
  return {documents}
}
