import React, {useState, useEffect} from 'react'
import toast, {Toaster} from 'react-hot-toast'
import {onMessageListener} from '../firebase/config'
import {KTSVG} from '../_metronic/helpers'
import {Avatar} from '../_metronic/partials'
import {useSound} from 'use-sound'

const Notification = () => {
  const [notification, setNotification] = useState<any | null>(null)
  // Notification
  const soundUrl = '/media/sounds/notification.mp3'
  const [play, {stop}] = useSound(soundUrl, {volume: 0.5})

  const notify = () =>
    toast.custom((t) => (
      <div
        className={`${
          t.visible ? 'animate-enter' : 'animate-leave'
        } max-w-md w-full bg-white shadow rounded-4 pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
      >
        <div className='alert alert-dismissible bg-light-primary w-350px p-6 pe-2 mb-0 d-flex flex-column flex-sm-row'>
          <Avatar
            className={'symbol symbol-35px symbol-md-40px me-4'}
            name={notification['notification'].title}
            avatarURL={notification['notification'].icon}
          />
          <div className='d-flex flex-column text-primary pe-0 pe-sm-10'>
            <h5 className='mb-1'>{notification['notification'].title}</h5>
            <span>{notification['notification'].body}</span>
          </div>

          <button
            type='button'
            className='position-absolute position-sm-relative m-1 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto'
            data-bs-dismiss='alert'
          >
            <KTSVG path='/media/icons/close.svg' className={'svg-icon svg-icon-1'} />
          </button>
        </div>
      </div>
    ))

  useEffect(() => {
    if (notification) {
      play()
      const rightSidebar = document.body.getAttribute('data-right-sidebar')
      const rightSidebarContent = document.body.getAttribute('data-right-sidebar-content')

      if (rightSidebar !== 'on' && rightSidebarContent !== 'chat') {
        notify()
      }
    }
  }, [notification])

  // requestForToken()

  onMessageListener()
    .then((payload) => {
      console.log('notification payload')
      console.log(payload)
      setNotification(payload)
    })
    .catch((err) => console.log('failed: ', err))

  return <Toaster position='bottom-right' reverseOrder={false} />
}

export default Notification
