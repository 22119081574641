/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import {useState} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {useLayout} from '../../core'
import {Header} from './Header'
import {Navbar} from './Navbar'
import {MenuModal} from '../../../partials'
import {MenuInner} from '../../components/header/header-menus'
import {useAuthContext} from '../../../../app/modules/auth'

export function HeaderWrapper() {
  const {config, classes} = useLayout()
  const {user, userData} = useAuthContext()

  const [showMegaMenuModal, setShowMegaMenuModal] = useState(false)

  if (!config.app?.header?.display) {
    return null
  }

  if (config.app?.header?.default?.fixed?.desktop) {
    document.body.setAttribute('data-kt-app-header-fixed', 'true')
  }

  if (config.app?.header?.default?.fixed?.mobile) {
    document.body.setAttribute('data-kt-app-header-fixed-mobile', 'true')
  }

  if (config.app?.header?.default?.stacked) {
    document.body.setAttribute('data-kt-app-header-stacked', 'true')
  }

  return (
    <div id='kt_app_header' className='app-header'>
      <div
        id='kt_app_header_container'
        className={clsx(
          'app-container flex-lg-grow-1',
          classes.headerContainer.join(' '),
          config.app?.header?.default?.containerClass
        )}
      >
        <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0 me-lg-10'>
          {/* <button
						className="btn btn-icon btn-light-primary w-35px h-35px w-md-40px h-md-40px me-3 me-lg-6"
						id="kt_mega_menu_toggle"
						onClick={() => setShowMegaMenuModal(true)}
					>
						<KTSVG
							className="svg-icon-1"
							path={toAbsoluteUrl('/media/icons/menu.svg')}
						/>
					</button> */}

          <Link to='/dashboard'>
            {config.layoutType !== 'dark-header' ? (
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/fok-logo.svg')}
                className='w-35px h-35px w-md-40px h-md-40px rounded-2'
              />
            ) : (
              <>
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('/media/logos/fok-logo.svg')}
                  className='w-35px h-35px w-md-40px h-md-40px rounded-2'
                />
              </>
            )}
          </Link>
          {userData?.model.isAdmin && (
            <Link to='/agency' className='pt-1 ms-1 text-hover-primary'>
              <i className='bi bi-three-dots-vertical  fs-3'></i>
            </Link>
          )}
        </div>
        <div
          id='kt_app_header_wrapper'
          className='d-flex align-items-stretch justify-content-between flex-lg-grow-1'
        >
          {config.app.header.default?.content === 'menu' &&
            config.app.header.default.menu?.display && (
              <div
                className='app-header-menu app-header-mobile-drawer align-items-stretch'
                data-kt-drawer='true'
                data-kt-drawer-name='app-header-menu'
                data-kt-drawer-activate='{default: true, lg: false}'
                data-kt-drawer-overlay='true'
                data-kt-drawer-width='225px'
                data-kt-drawer-direction='end'
                data-kt-drawer-toggle='#kt_app_header_menu_toggle'
                data-kt-swapper='true'
                data-kt-swapper-mode="{default: 'append', lg: 'prepend'}"
                data-kt-swapper-parent="{default: '#kt_app_body', lg: '#kt_app_header_wrapper'}"
              >
                {/*<Header />*/}
              </div>
            )}
          <Navbar />
        </div>
        <MenuModal show={showMegaMenuModal} handleClose={() => setShowMegaMenuModal(false)}>
          <MenuInner />
        </MenuModal>
      </div>
    </div>
  )
}
