/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {SocialMediaChannelModels} from '../models/SocialMediaModels'

import {Timestamp, arrayUnion, doc, setDoc, updateDoc} from 'firebase/firestore'
import {useParams} from 'react-router-dom'
import {db} from '../../../../../../../../../firebase/config'
import {useAuthContext} from '../../../../../../../auth'

type Props = {
  socialMediaSettings: any
  contentChannel: any
  contentID: string
}

const DropdownSocialMediaChannelType: FC<Props> = ({
  contentID,
  socialMediaSettings,
  contentChannel,
}) => {
  const {id, projectID} = useParams()
  const clientUID = id ? id : ''
  const projectUID = projectID ? projectID : ''

  const {user} = useAuthContext()

  const [channels, setChannels] = useState<any | null>(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<any | null>(null)
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    if (contentChannel) {
      let channelArr: {name: string; type: string}[] = contentChannel
      setChannels([...channelArr])
    } else {
      setChannels([])
    }
  }, [contentChannel])

  const handleChannelType = (event) => {
    let channelArr: {name: string; type: string}[] = []

    if (channels) {
      channelArr = channels
    }

    if (event.target.checked === true) {
      channelArr.push({name: event.target.name, type: event.target.value})
    } else {
      channelArr = channelArr.filter(
        (c: any) => c.name !== event.target.name || c.type !== event.target.value
      )
    }

    setChannels([...channelArr])
  }

  const handleSubmit = async () => {
    setError(null)
    setLoading(true)
    setSuccess(false)
    const docRef = doc(
      db,
      'clients',
      clientUID,
      'projects',
      projectUID,
      'socialMediaContents',
      contentID
    )
    setDoc(
      docRef,
      {
        channels: channels,
      },
      {merge: true}
    )
      .then(() => {
        updateDoc(docRef, {
          logs: arrayUnion({
            displayName: user.displayName,
            uid: user.uid,
            photoURL: user?.photoURL,
            action: 'I changed Social Media Channels.',
            createdAt: Timestamp.now(),
            value: channels,
          }),
        })
        setSuccess(true)
        setTimeout(() => {
          setSuccess(false)
        }, 500)
        setLoading(false)
      })

      .catch((error) => {
        setError(error.message)
        console.log(error.message)
      })
  }

  return (
    <div className='menu menu-sub menu-sub-dropdown w-300px w-md-300px' data-kt-menu='true'>
      {socialMediaSettings ? (
        <div className='px-7 py-5'>
          <div className='mb-7'>
            <label className='form-label fw-bold'>Social Media Channels</label>
            <div>
              {channels &&
                SocialMediaChannelModels.map(
                  (channel: any, i) =>
                    socialMediaSettings?.settings?.channels?.filter(
                      (c: any, i) => c?.name === channel?.name
                    ).length > 0 && (
                      <div key={i}>
                        <span className='fw-bold'>{channel.name}</span>

                        <div className='row mx-1 my-1 mb-2'>
                          {channel.types.map((type: any, i) => (
                            <div
                              className='col-6 my-1 form-check form-check-custom form-check-solid form-check-sm'
                              key={i}
                            >
                              <input
                                className='form-check-input'
                                type='checkbox'
                                value={type}
                                defaultChecked={
                                  channels?.filter(
                                    (c: any) => c.name === channel.name && c.type === type
                                  ).length > 0
                                    ? true
                                    : false
                                }
                                id={contentID + channel?.name?.toLowerCase() + type.toLowerCase()}
                                name={channel?.name}
                                onChange={handleChannelType}
                              />
                              <label
                                className='form-check-label'
                                htmlFor={
                                  contentID + channel?.name?.toLowerCase() + type.toLowerCase()
                                }
                              >
                                {type}
                              </label>
                            </div>
                          ))}
                        </div>
                        <div className='separator separator-dashed mb-1'></div>
                      </div>
                    )
                )}
            </div>
          </div>

          <div className='d-flex justify-content-end'>
            <button
              type='submit'
              onClick={handleSubmit}
              className='btn btn-sm btn-primary'
              data-kt-menu-dismiss='true'
            >
              Apply
            </button>
          </div>
        </div>
      ) : (
        <div className='p-4'>
          Please select Social media channels used from <strong>Settings</strong> /{' '}
          <strong>Services</strong>/ <strong>Social Media tab</strong>.
        </div>
      )}
    </div>
  )
}

export {DropdownSocialMediaChannelType}
