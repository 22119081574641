/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {Card2} from '../../../../../../_metronic/partials/content/cards/Card2'
import {IconUserModel} from '../../../../profile/ProfileModels'

import {useParams} from 'react-router-dom'
import {useCollection} from '../../../../../hooks/useCollection'
import {BudgetCard} from '../components/BudgetCard'
import moment, {Moment} from 'moment'
import {db} from '../../../../../../firebase/config'
import {doc, getDoc} from 'firebase/firestore'
type Props = {
  projectData: any
}

const Budgets: React.FC<Props> = ({projectData}) => {
  const {id, projectID} = useParams()
  const [projectDetail, setProjectDetail] = useState<any | null>(null)
  const {documents: timeTrackers} = useCollection('clients/' + id + '/timeTracker')
  const {documents: users} = useCollection('users')
  const {documents: userCosts} = useCollection('agency/fok/userCosts')
  const [clientDetail, setClientDetail] = useState<any | null>(null)
  const [timeTrackerM, setTimeTrackerM] = useState<any | null>(null)

  useEffect(() => {
    let docRef = doc(db, 'clients/' + id)
    getDoc(docRef)
      .then((data) => {
        setClientDetail(data.data())
      })
      .catch((error) => {
        console.log(error.message)
      })
  }, [id])

  useEffect(() => {
    let docRef = doc(db, 'clients/' + id + '/projects/' + projectID)
    getDoc(docRef)
      .then((data) => {
        setProjectDetail(data.data())
      })
      .catch((error) => {
        console.log(error.message)
      })
  }, [projectID])

  const [expenseBudgets, setExpenseBudgets] = useState(0)

  const startDate: Moment = moment(projectDetail?.startDate)
  const endDate: Moment = moment()

  const getMonthsBetween = (start, end) => {
    let currentMonth = moment(start)
    const months: string[] = []

    while (currentMonth.isSameOrBefore(end, 'month')) {
      currentMonth && months.push(currentMonth.format('MM-DD-YYYY'))
      currentMonth.add(1, 'month')
    }

    return months.reverse()
  }

  const monthsArray = getMonthsBetween(startDate, endDate)
  monthsArray.reverse()
  console.log(monthsArray)

  return (
    <>
      <div className='d-flex flex-wrap flex-stack my-3'>
        <h3 className='fw-bolder my-2 ps-1'>Budgets</h3>
        <div className='d-flex flex-wrap my-2'>
          <div className='align-self-center'>
            <select
              name='status'
              data-control='select2'
              data-hide-search='true'
              className='form-select form-select-sm form-select-white w-125px'
              defaultValue='Active'
            >
              <option value='Active'>Active</option>
              <option value='Approved'>In Progress</option>
              <option value='Declined'>To Do</option>
              <option value='In Progress'>Completed</option>
            </select>
          </div>
        </div>
      </div>
      {/* <div className='card card-flush my-5'>
        <div className='card-header'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder text-primary m-0'>
              Budgets
              <span className='fs-6 text-gray-400 fw-bold ms-1'>Active</span>
            </h3>
          </div>

          <div className='d-flex flex-wrap my-2'></div>
        </div>
      </div> */}

      {projectDetail &&
        projectDetail?.period === 'monthly' &&
        monthsArray.reverse().map((value: any) => (
          <div className='row g-6 g-xl-9'>
            <div className='col-md-4'>
              <BudgetCard
                className='card-xl-stretch mb-xl-4'
                title='Incomes'
                date={value}
                color='primary'
                type='income'
                budget={projectDetail?.budget}
                period={projectDetail?.period}
              />
            </div>
            <div className='col-md-4'>
              <BudgetCard
                className='card-xl-stretch mb-xl-4'
                title='Expenses'
                date={`${moment(value).format('MMMM YYYY')}`}
                color='danger'
                type='expense'
                timeTracker={
                  timeTrackers
                    ? timeTrackers.filter(
                        (t: any) =>
                          moment(t.date.toDate()).isSame(
                            moment(value).format('MM-DD-YYYY'),
                            'month'
                          ) && t.projectUID === projectID
                      )
                    : []
                }
                users={users}
                clientDetail={clientDetail}
                userCosts={userCosts}
              />
            </div>
            <div className='col-md-4'>
              <BudgetCard
                className='card-xl-stretch mb-xl-4'
                title='Kar / Zarar'
                date={`${moment(value).format('MMMM YYYY')}`}
                color='success'
                type='profit'
                budget={projectDetail?.budget}
                timeTracker={
                  timeTrackers
                    ? timeTrackers.filter(
                        (t: any) =>
                          moment(t.date.toDate()).isSame(
                            moment(value).format('MM-DD-YYYY'),
                            'month'
                          ) && t.projectUID === projectID
                      )
                    : []
                }
                userCosts={userCosts}
              />
            </div>
          </div>
        ))}

      {(projectDetail?.period === 'oneTime' || projectDetail?.period === 'yearly') && (
        <div className='row g-6 g-xl-9'>
          <div className='col-md-4'>
            <BudgetCard
              className='card-xl-stretch mb-xl-4'
              title='Incomes'
              date={projectDetail.startDate}
              color='primary'
              type='income'
              budget={projectDetail?.budget}
              period={projectDetail?.period}
            />
          </div>
          <div className='col-md-4'>
            <BudgetCard
              className='card-xl-stretch mb-xl-4'
              title='Expenses'
              date={projectDetail.startDate}
              color='danger'
              type='expense'
              timeTracker={
                timeTrackers ? timeTrackers.filter((t: any) => t.projectUID === projectID) : []
              }
              users={users}
              clientDetail={clientDetail}
              userCosts={userCosts}
            />
          </div>
          <div className='col-md-4'>
            <BudgetCard
              className='card-xl-stretch mb-xl-4'
              title='Kar / Zarar'
              date={projectDetail.startDate}
              color='success'
              type='profit'
              budget={projectDetail?.budget}
              timeTracker={
                timeTrackers ? timeTrackers.filter((t: any) => t.projectUID === projectID) : []
              }
              userCosts={userCosts}
            />
          </div>
        </div>
      )}
    </>
  )
}

export {Budgets}
