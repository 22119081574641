/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import {IUpdatePassword, IUpdateEmail, updatePassword, updateEmail} from '../SettingsModel'
import {useCollection} from '../../../../../hooks/useCollection'
import {Avatar} from '../../../../../../_metronic/partials'
import {useAuthContext} from '../../../../auth'
import {db} from '../../../../../../firebase/config'
import {arrayRemove, arrayUnion, doc, getDoc, setDoc, updateDoc} from 'firebase/firestore'
import {useParams} from 'react-router-dom'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {getElementIndex} from '../../../../../../_metronic/assets/ts/_utils'

type Props = {
  clientData: any
}

const UserRoles: React.FC<Props> = (clientData: any) => {
  const [clientDetail, setClientDetail] = useState<any | null>(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<any | null>(null)
  const [success, setSuccess] = useState(false)
  const [selectedUser, setSelectedUser] = useState('')
  const [userRole, setUserRole] = useState('')

  const {documents: users} = useCollection('users')
  const currentClient = clientData?.clientData
  const {user, userData} = useAuthContext()

  const {id} = useParams()
  const clientUID = id ? id : ''
  console.log(clientDetail)

  useEffect(() => {
    getClientData()
  }, [clientUID])

  function getClientData() {
    let docRef = doc(db, 'clients', clientUID)
    getDoc(docRef)
      .then((data) => {
        setClientDetail(data.data())
      })
      .catch((error) => {})
  }

  const DeleteRole = (val, role) => {
    setSelectedUser(val)
    console.log('del')
    setLoading(true)
    let docRef = doc(db, 'clients', clientUID)
    getDoc(docRef)
      .then((data) => {
        const teams = data.data()?.authorities
        teams &&
          teams
            ?.filter((u: any) => u.uid === val)
            .map((user: any) =>
              updateDoc(docRef, {
                authorities: arrayRemove({uid: val, title: user.title, role: user.role}),
              })
                .then(async () => {
                  if (user.role === 'team') {
                    const docChatRef = doc(db, 'chat', clientUID)
                    updateDoc(docChatRef, {
                      hasRole: arrayRemove(val),
                    })
                  }
                  setSuccess(true)

                  setTimeout(() => {
                    setSuccess(false)
                  }, 3000)
                  setLoading(false)
                })
                .catch((error) => {
                  setError(error.message)
                  console.log(error.message)
                  setLoading(false)
                })
            )
        getClientData()
      })
      .catch((error) => {
        console.log(error)
      })
  }
  const handleChange = (event) => {
    setSelectedUser(event.target.id)
    setLoading(true)
    setSuccess(false)
    const docRef = doc(db, 'clients', clientUID)
    let userRolee
    if (
      users.filter((u: any) => u.id === event.target.id && u?.model.isTeammate === true).length > 0
    ) {
      setUserRole('team')
      userRolee = 'team'
    }
    if (
      users.filter((u: any) => u.id === event.target.id && u?.model.isClient === true).length > 0
    ) {
      setUserRole('client')
      userRolee = 'client'
    }
    if (
      users.filter((u: any) => u.id === event.target.id && u?.model.isFreelancer === true).length >
      0
    ) {
      setUserRole('freelancer')
      userRolee = 'freelancer'
    }

    if (userData.model.isManager === true) {
      updateDoc(docRef, {
        authorities: arrayUnion({
          uid: event.target.id,
          title: event.target.value,
          role: userRolee,
        }),
      })
        .then(async () => {
          getClientData()
          if (userRolee === 'team') {
            const docChatRef = doc(db, 'chat', clientUID)
            updateDoc(docChatRef, {
              hasRole: arrayUnion(event.target.id),
            })
              .then(() => {
                getClientData()
              })
              .catch((error) => {
                console.log(error.message)
                setLoading(false)
              })
          }
          setSuccess(true)
          setTimeout(() => {
            setSuccess(false)
          }, 3000)
          setLoading(false)
        })
        .catch((error) => {
          setError(error.message)
          console.log(error.message)
          setLoading(false)
        })
      console.log(event.target.id, 'id li kullanıcı ' + event.target.value)
    } else {
      setError('Yetkiniz bulunmamaktadır.')
    }
  }

  return (
    <div className='card mb-5'>
      <div
        className='card-header border-0 cursor-pointer align-items-center'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_signin_method'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder text-primary m-0'>Brand Manager & Client User</h3>
        </div>
        <div className='float-end'>
          {clientDetail?.authorities?.filter((u: any) => u?.role === 'team').length > 0 && (
            <span className='badge badge-light p-3 me-2'>
              {clientDetail?.authorities?.filter((u: any) => u?.role === 'team').length} Manager
            </span>
          )}
          {clientDetail?.authorities?.filter((u: any) => u?.role === 'client').length > 0 && (
            <span className='badge badge-light  p-3'>
              {clientDetail?.authorities?.filter((u: any) => u?.role === 'client').length} Client
            </span>
          )}
        </div>
      </div>

      <div id='kt_account_signin_method' className='collapse'>
        <div className='card-body border-top card-scroll h-400px p-9 py-5 mb-6'>
          {users
            .sort(
              (a: any, b: any) =>
                a.displayName.toLowerCase().charCodeAt(0) -
                b.displayName.toLowerCase().charCodeAt(0)
            )

            .filter(
              (u: any) =>
                u?.model.isTeammate === true ||
                u?.model.isClient === true ||
                u?.model.isManager === true
            )

            .map((user: any, i) => {
              return (
                <div
                  className='d-flex flex-stack w-100 py-4 border-bottom border-gray-300 border-bottom-dashed'
                  key={i}
                >
                  <div className='d-flex flex-fill align-items-center'>
                    <Avatar
                      className={'symbol symbol-40px'}
                      name={user.displayName}
                      avatarURL={user?.photoURL}
                    />

                    <div className='ms-5'>
                      <a href='#' className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-2'>
                        {user.displayName}
                      </a>
                      <div className='fw-bold text-muted'>{user.email}</div>
                    </div>
                  </div>
                  <div className='me-4'>
                    {loading && selectedUser === user.id && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        <span className='spinner-border spinner-border-sm  me-4'></span>
                      </span>
                    )}
                    {success && selectedUser === user.id && (
                      <>
                        <span className='text-success'>
                          <i className='bi bi-check text-success fs-2x me-2 mt-2'></i>
                        </span>
                      </>
                    )}
                    {error && selectedUser === user.id && (
                      <>
                        <span className='text-danger pt-1'>
                          {error}
                          <i className='bi bi-exclamation-circle text-danger fs-2 me-2'></i>
                        </span>
                      </>
                    )}
                  </div>
                  <div className='me-4 '>
                    {user.model.isTeammate === true && (
                      <span className='badge badge-success p-3'>Teammate</span>
                    )}
                    {user.model.isClient === true && (
                      <span className='badge badge-warning p-3'>Client</span>
                    )}
                  </div>
                  {clientDetail?.authorities
                    ?.filter((u: any) => u.uid === user.id)
                    .map((user: any) => (
                      <div className='me-4'>
                        <span className='badge badge-primary p-3'>{user?.title}</span>
                      </div>
                    ))}

                  {clientDetail?.authorities?.filter((u: any) => u.uid === user.id).length < 1 && (
                    <>
                      <div className='me-4'>
                        <select
                          id={user.id}
                          name={user.id}
                          className='form-select form-select-solid form-select-sm'
                          onChange={handleChange}
                        >
                          <option selected value='remove'>
                            No Role
                          </option>

                          {user.model.isTeammate === true && (
                            <>
                              <optgroup label='Kreatif Departman'>
                                <option>Metin Yazarı</option>
                                <option>Jr. Metin Yazarı</option>
                                <option>Stratejist</option>
                                <option>Art Direktör</option>
                                <option>Jr. Art Direktör</option>
                                <option>Motion Designer</option>
                                <option>Video Editörü</option>
                                <option>Prodüksiyon</option>
                              </optgroup>

                              <optgroup label='Dijital Medya Departmanı'>
                                <option>Sosyal Medya Uzmanı</option>
                                <option>Dijital Pazarlama Uzmanı </option>
                                <option>CRM Sorumlusu</option>
                              </optgroup>

                              <optgroup label='Marka ve Proje Yönetimi Departmanı'>
                                <option>Müşteri Temsilcisi</option>
                                <option>Marka Yöneticisi</option>
                                <option>Proje Yöneticisi</option>
                              </optgroup>

                              <optgroup label='Web ve Yazılım Departmanı'>
                                <option>Web Proje Yöneticisi</option>
                                <option>Arayüz Tasarımcısı</option>
                                <option>UI/UX Tasarımcısı</option>
                                <option>Front-End Geliştirici</option>
                                <option>Back-End Geliştirici</option>
                                <option>Full-Stack Geliştirici</option>
                                <option>Mobil Uygulama Geliştirici</option>
                                <option>Web İçerik Yöneticisi</option>
                                <option>SEO/SEM Uzmanı</option>
                                <option>Hosting / Domain Yöneticisi</option>
                              </optgroup>

                              <optgroup label='Gastronomi Departmanı'>
                                <option>Şef</option>
                              </optgroup>

                              <optgroup label='Diğer'>
                                <option>İnsan Kaynakları Uzmanı</option>
                                <option>Listede yok.</option>
                              </optgroup>
                            </>
                          )}
                          {user.model.isClient === true && (
                            <option
                              value='client'
                              selected={
                                currentClient?.authorities?.filter((u: any) => u.uid === user.id)
                                  .length > 0
                              }
                            >
                              Client
                            </option>
                          )}
                        </select>
                      </div>
                    </>
                  )}
                  {clientDetail?.authorities?.filter((u: any) => u.uid === user.id).length > 0 && (
                    <button
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm '
                      onClick={() => DeleteRole(user.id, 'teams')}
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen027.svg'
                        className='svg-icon-3'
                      />
                    </button>
                  )}
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}

export {UserRoles}
