import { useState } from 'react'
import { useAuthContext } from './useAuthContext';

import {auth, db} from './../../../../firebase/config'
import {createUserWithEmailAndPassword, updateProfile, sendEmailVerification, } from "firebase/auth";
import {doc, setDoc, getDoc } from "firebase/firestore"; 


export const useSignup = () => {
  const [error, setError] = useState(null)
	const [role, setRole] = useState(null)
	const {dispatch} = useAuthContext()

  const signup = (email, password, displayName, acceptTerms) => {
    setError(null)		
		createUserWithEmailAndPassword(auth, email, password)
		.then((res) => {
			// Signed in 
			console.log(res.user)

			updateProfile(auth.currentUser, {
				displayName: displayName
			}).then(() => {
				console.log('update displayName')
				const docRef = doc(db, "users", res.user.uid);
				const data = {
					displayName, 
					email,
					model: {
						isAdmin: false,
						isClient: false,
						isManager: false,
						isTeammate: false,
						viewAgency: false,
						viewBrand: false,
						isPending: true,
					}
				}
				setDoc(docRef,data)
				.then(async () => {
					dispatch({ type: 'LOGIN', payload: res.user, userData:data})
				})
				.catch((error) => {console.log(error.message)});

			}).catch((error) => {
				console.log(error.message)
			});
			
			// sendEmailVerification(auth.currentUser)
			// .then(() => {
			// 	console.log('success sendEmailVerification')
			// }).catch((error) => {
			// 	console.log(error.message)
			// });

			
		})
		.catch((error) => {
			setError(error.message)
		});

  }

  return { signup, error }
}