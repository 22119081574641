/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {useLocation} from 'react-router'
import {useAuthContext} from '../auth'
import {Avatar} from '../../../_metronic/partials/content/user/Avatar'
import {doc, getDoc, onSnapshot} from 'firebase/firestore'
import {db} from '../../../firebase/config'
import {Breadcrumb} from 'react-bootstrap'
import moment from 'moment'

export const ClientHeader: React.FC = () => {
  const {id, projectID} = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [clientDetail, setClientDetail] = useState<any | null>(null)
  const [project, setProject] = useState<any | null>(null)
  const {user, userData} = useAuthContext()

  const handleChange = (event) => {
    navigate(event.target.value)
  }

  const [clientButtonText, setclientButtonText] = useState('C')
  const [projectButtonText, setProjectButtonText] = useState('P')

  useEffect(() => {
    let docRef = doc(db, 'clients/' + id)
    getDoc(docRef)
      .then((data) => {
        setClientDetail(data.data())
      })
      .catch((error) => {
        console.log(error.message)
      })
  }, [id])
  useEffect(() => {
    if (projectID) {
      let docRef = doc(db, 'clients/' + id + '/projects/' + projectID)
      getDoc(docRef)
        .then((data) => {
          setProject(data.data())
        })
        .catch((error) => {
          console.log(error.message)
        })
    } else {
      setProject(null)
    }
  }, [projectID])

  return (
    <div className='pb-4'>
      <div className='d-flex flex-stack'>
        <Link to={'../../agency/clients'}>
          <button
            className='btn btn-sm px-4 h-40px btn-light fw-bold me-2 border-2 border'
            onMouseOver={() => setclientButtonText('Clients')}
            onMouseOut={() => setclientButtonText('C')}
          >
            {clientButtonText}
          </button>
        </Link>
        {clientDetail && (
          <Link to={'overview'}>
            <Avatar
              className='symbol symbol-40px symbol-fixed me-2 position-relative'
              name={clientDetail?.brandName}
              avatarURL={clientDetail?.logo}
            />
          </Link>
        )}
        {location.pathname.startsWith('/client/' + id + '/projects/') && (
          <Link to={'projects'}>
            <button
              className='btn btn-sm px-4 h-40px btn-light fw-bold me-2 border-2 border'
              onMouseOver={() => setProjectButtonText('Projects')}
              onMouseOut={() => setProjectButtonText('P')}
              onClick={() => setProjectButtonText('P')}
            >
              {projectButtonText}
            </button>
          </Link>
        )}
        <div className='page-title d-flex flex-fill flex-wrap align-items-center me-3  '>
          <div className='d-flex flex-column justify-content-center'>
            <h1 className='page-heading d-flex text-primary fw-bold mt-1 fs-5 my-0 flex-column'>
              {project ? project?.name : clientDetail?.brandName}
            </h1>

            <span className='fw-semibold fs-7 text-muted'>
              {project
                ? project?.period && (
                    <>
                      {project?.period === 'monthly' && (
                        <span className='d-flex align-items-center'>
                          {project?.period === 'monthly' && (
                            <span className='badge badge-success fs-9 '>Fee</span>
                          )}
                          <span className='text-muted fw-semibold ms-1'>
                            {moment(project?.startDate).format('DD.MM.YYYY')} -{' '}
                            {moment(project?.endDate).format('DD.MM.YYYY')}
                          </span>
                        </span>
                      )}
                      {project?.period === 'oneTime' && (
                        <span className='d-flex align-items-center'>
                          <span className='badge badge-warning fs-9 '>Project</span>
                          <span className='text-muted fw-semibold ms-1'>
                            Deadline {moment(project?.endDate).format('DD.MM.YYYY')}
                          </span>
                        </span>
                      )}
                      {project?.period === 'yearly' && (
                        <span className='d-flex align-items-center'>
                          <span className='badge badge-primary fs-9 '>Yearly</span>
                          <span className='text-muted fw-semibold ms-1'>
                            End {moment(project?.endDate).format('DD.MM.YYYY')}
                          </span>
                        </span>
                      )}
                    </>
                  )
                : clientDetail?.companyName}
            </span>
          </div>
        </div>
        {!location.pathname.startsWith('/client/' + id + '/projects/') && (
          <>
            <div className='d-flex align-items-center d-none d-sm-none d-md-block gap-2 gap-lg-3 '>
              <Link
                className={
                  'btn btn-sm fw-bold btn-light-primary me-3 ' +
                  (location.pathname === '/client/' + id + '/overview' && 'active')
                }
                to={`/client/${id}/overview`}
              >
                Overview
              </Link>
              {/* {clientDetail && clientDetail?.services?.socialMedia?.isActive === true && (
              <Link
                className={
                  'btn btn-sm fw-bold btn-light-primary me-3 ' +
                  (location.pathname ===
                    '/client/' + id + '/' + clientDetail?.services?.socialMedia?.slug && 'active')
                }
                to={`/client/${id}/${clientDetail?.services?.socialMedia?.slug}`}
              >
                {clientDetail?.services?.socialMedia?.name}
              </Link>
            )} */}

              <Link
                className={
                  'btn btn-sm fw-bold btn-light-primary me-3 ' +
                  (location.pathname.includes('projects') && 'active')
                }
                to={`/client/${id}/projects`}
              >
                Projects
              </Link>
              {/* <Link
              className={
                'btn btn-sm fw-bold btn-light-primary me-3 ' +
                (location.pathname === '/agency/documents' && 'active')
              }
              to='/agency/reports'
            >
              Web
            </Link>
            <Link
              className={
                'btn btn-sm fw-bold btn-light-primary me-3 ' +
                (location.pathname === '/agency/connections' && 'active')
              }
              to='/agency/library'
            >
              Library
            </Link> */}
              {userData?.model.isManager === true && (
                <>
                  <Link
                    className={
                      'btn btn-sm fw-bold btn-light-primary me-3 ' +
                      (location.pathname.includes('profit') && 'active')
                    }
                    to={`/client/${id}/profit`}
                  >
                    Profit
                  </Link>
                  <Link
                    className={
                      'btn btn-sm fw-bold btn-light-primary me-0 ' +
                      (location.pathname === '/client/' + id + '/settings' && 'active')
                    }
                    to={`/client/${id}/settings`}
                  >
                    Settings
                  </Link>
                </>
              )}
            </div>
            <div className='d-flex align-items-center gap-2 gap-lg-3 d-xs-block d-sm-block d-md-none'>
              <select
                className='form-select-sm form-select form-select-solid btn-light-primary'
                aria-label='Page Select'
                onChange={handleChange}
                value={location.pathname}
              >
                <option key={`/client/${id}/overview`} value={`/client/${id}/overview`}>
                  Overview
                </option>
                <option key={`/client/${id}/projects`} value={`/client/${id}/projects`}>
                  Projects
                </option>
                <option key={`/client/${id}/profit`} value={`/client/${id}/profit`}>
                  Profit
                </option>
                <option key={`/client/${id}/settings`} value={`/client/${id}/settings`}>
                  Settings
                </option>
              </select>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
