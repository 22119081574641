import { useCollection } from "./useCollection"


export 
function sendNotification(to, notification) {
  
  console.log('to: ')
  console.log({to})
  console.log('notification: ')
  console.log({notification})
  let key =
    'AAAA-GkBm0w:APA91bGDUc-t3RsQtdZuG1uK-o1CYK6i4hyk2mnkAuOQhv5k2q8Q-jmUEX7VE8BZlmC81WD8XceMdVpIO8xLkOwLZ6rTPpx_hZDTZSfvutk3LAd0jr6WjT_-KzKZka_nLXtbezwEEqOP'
  

  fetch('https://fcm.googleapis.com/fcm/send', {
    method: 'POST',
    headers: {
      Authorization: 'key=' + key,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      notification: notification,
      registration_ids: to,
    }),
  })
    .then(function (response) {
      console.log(response)
    })
    .catch(function (error) {
      console.log(error)
    })
}