/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {SocialMediaContentStatusModels} from '../../models/SocialMediaModels'

import {
  Timestamp,
  addDoc,
  arrayUnion,
  collection,
  doc,
  serverTimestamp,
  setDoc,
  updateDoc,
} from 'firebase/firestore'
import {useParams} from 'react-router-dom'
import {TagsInput} from 'react-tag-input-component'

import {useAuthContext} from '../../../../../../../../auth'
import {db} from '../../../../../../../../../../firebase/config'

import {Editor} from 'primereact/editor'

type Props = {
  contentData: any
}

const BriefContent: FC<Props> = ({contentData}) => {
  const [content, setContent] = useState(contentData?.contentText)
  const [tags, setTags] = useState<any | null>(contentData?.contentTags)

  const [contentBrief, setContentBrief] = useState(contentData?.brief ? contentData?.brief : '')

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState('')
  const {id, projectID} = useParams()
  const clientUID = id ? id : ''
  const projectUID = projectID ? projectID : ''

  const {user} = useAuthContext()

  const renderHeader = () => {
    return (
      <>
        <span className='ql-formats'>
          <button className='ql-bold' aria-label='Bold'></button>
          <button className='ql-italic' aria-label='Italic'></button>
          <button className='ql-underline' aria-label='Underline'></button>
        </span>
        <span className='ql-formats'>
          {/* <button className='ql-align' aria-label='Align'></button> */}
          <button className='ql-list' value='ordered' aria-label='List (ordered)'></button>
          <button className='ql-list' value='bullet' aria-label='List (bullet)'></button>
        </span>
        <span className='ql-formats'>
          <select className='ql-color ql-picker ql-color-picker ql-expanded'>
            <option value='rgb(0, 0, 0)' />
            <option value='rgb(230, 0, 0)' />
            <option value='rgb(255, 153, 0)' />
            <option value='rgb(255, 255, 0)' />
            <option value='rgb(0, 138, 0)' />
            <option value='rgb(0, 102, 204)' />
            <option value='rgb(153, 51, 255)' />
          </select>
          <select className='ql-background ql-picker ql-color-picker ql-expanded'>
            <option value='rgb(0, 0, 0)' />
            <option value='rgb(230, 0, 0)' />
            <option value='rgb(255, 153, 0)' />
            <option value='rgb(255, 255, 0)' />
            <option value='rgb(0, 138, 0)' />
            <option value='rgb(0, 102, 204)' />
            <option value='rgb(153, 51, 255)' />
          </select>
        </span>
        <span className='ql-formats'>
          <button className='ql-link' aria-label='Link'></button>
        </span>
      </>
    )
  }

  const header = renderHeader()

  const handleContent = (event) => {
    setContent(event.target.value)
  }
  const handleClick = async () => {
    setLoading(true)
    const docRef = doc(
      db,
      'clients',
      clientUID,
      'projects',
      projectUID,
      'socialMediaContents',
      contentData.id
    )
    setDoc(
      docRef,
      {
        brief: contentBrief,
      },
      {merge: true}
    )
      .then(async () => {
        updateDoc(docRef, {
          logs: arrayUnion({
            displayName: user.displayName,
            uid: user.uid,
            photoURL: user?.photoURL,
            action: 'I added the brief.',
            createdAt: Timestamp.now(),
            value: contentBrief,
          }),
        })

        setLoading(false)
        setSuccess(true)
        setTimeout(() => {
          setSuccess(false)
        }, 1000)
      })
      .catch((error) => {
        setLoading(false)
        console.log(error.message)
      })
  }

  return (
    <>
      <label className='fw-row col-form-label pt-1 text-primary fw-bold fs-6 mb-1'>
        Content Brief
      </label>

      <div className='mb-4'>
        <div className=' fv-row'>
          <div className='card'>
            <Editor
              value={contentBrief}
              onTextChange={(e) => setContentBrief(e.htmlValue ? e.htmlValue : '')}
              headerTemplate={header}
              style={{height: '240px'}}
              placeholder='Write content brief...'
            />
          </div>
        </div>
      </div>

      <div className='d-flex justify-content-end'>
        {/* <button
          type='reset'
          className='btn btn-sm btn-light btn-active-light-primary me-2'
          data-kt-menu-dismiss='true'
        >
          Cancel
        </button> */}

        <button
          type='submit'
          className={`btn btn-sm btn-primary ${success && 'btn-success'}`}
          disabled={loading}
          onClick={handleClick}
        >
          {success && (
            <>
              <span className='badge badge-success me-5 px-6'></span>
              <span className='' style={{display: 'block'}}>
                <i className='bi bi-check fs-2'></i>
                Success
              </span>
            </>
          )}
          {!success && (
            <>
              {!loading && 'Save'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </>
          )}
        </button>
      </div>
    </>
  )
}

export {BriefContent}
