/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'

import {Avatar} from '../../../../../../../../../../_metronic/partials'
import moment from 'moment'
import {SocialMediaChannelModels} from '../../models/SocialMediaModels'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {DropdownSocialMediaChannelType} from '../../dropdown/DropdownSocialMediaChannelType'
import {KTSVG} from '../../../../../../../../../../_metronic/helpers'
import clsx from 'clsx'

const btnClass = 'btn btn-sm btn-icon btn-light-primary h-31px w-31px'

type Props = {
  contentData: any
  socialMediaSettings: any
}

const Channels: FC<Props> = ({contentData, socialMediaSettings}) => {
  const [channels, setChannels] = useState<any | null>(null)

  useEffect(() => {
    if (contentData?.channels) {
      setChannels(
        contentData?.channels.reduce((group, channel) => {
          const {name} = channel
          group[name] = group[name] ?? []
          group[name].push(channel)
          return group
        }, {})
      )
    }
  }, [contentData])

  return (
    <div className='d-flex flex-column'>
      {channels &&
        SocialMediaChannelModels.map(
          (c: any, i) =>
            channels[c.name] && (
              <OverlayTrigger
                key={`${i}-${c.name + '-' + c.type}`}
                placement='right'
                overlay={
                  <Tooltip id='tooltip-contentType-name'>
                    {c.name + ' ' + channels[c.name]?.map((type: any) => ' ' + type.type)}
                  </Tooltip>
                }
              >
                <div
                  className='badge badge-square badge-light hover-primary w-30px h-30px me-1'
                  data-bs-toggle='tooltip'
                  key={`cw7-item-${i}`}
                >
                  <i className={`${c?.icon} fs-6`}></i>
                </div>
              </OverlayTrigger>
            )
        )}

      <div className='m-0'>
        <a
          className={clsx('position-relative w-30px h-30px', btnClass)}
          data-kt-menu-trigger='hover'
          data-kt-menu-placement='right'
        >
          <KTSVG path='/media/icons/add.svg' className={'svg-icon-4'} />
        </a>
        <DropdownSocialMediaChannelType
          socialMediaSettings={socialMediaSettings}
          contentChannel={contentData?.channels}
          contentID={contentData.id}
        />
      </div>
    </div>
  )
}

export {Channels}
