import {Route, Routes} from 'react-router-dom'
import {ApplicationForm} from './components/ApplicationForm'
import {HrLayout} from './HrLayout'

const HrPage = () => (
  <Routes>
    <Route element={<HrLayout />}>
      <Route path='start' element={<ApplicationForm />} />
    </Route>
  </Routes>
)

export {HrPage}
