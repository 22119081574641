/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'

import {PageTitle, useLayout} from '../../../_metronic/layout/core'
import {
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  MixedWidget8,
  TeamsWidget,
  CardsWidget17,
  CardsWidget20,
  ListsWidget26,
  EngageWidget10,
  UsersWidget,
  PendingUsersWidget,
} from '../../../_metronic/partials/widgets'
import {ProjectsWidget} from '../../modules/components/widgets/ProjectsWidget'
import {useAuthContext} from '../../modules/auth'

type Props = {
  user: any
}

const DashboardPage: FC<Props> = ({user}) => {
  return (
    <>
      {/* begin::Header */}
      <div className=''>
        <div className='d-flex flex-stack'>
          <div className='page-title d-flex flex-wrap me-3 flex-column justify-content-center'>
            <h1 className='page-heading d-flex text-primary fw-bold fs-3 my-0 flex-column justify-content-center'>
              Dashboard
            </h1>
            <span className='fw-semibold text-muted'>My widgets</span>
          </div>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Row */}
      <div className='row gy-5 gx-xl-8'>
        {/* <div className='col-lg-2'>
          <div
            className='card card-custom card-stretch  card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end h-md-50 mb-5 mb-xl-10'
            style={{
              backgroundColor: 'rgb(255, 218, 68)',
              backgroundImage: 'url("media/patterns/pattern-2.png")',
            }}
          >
            <div className='card-header pt-5'>
              <div className='card-title d-flex flex-column'>
                <KTSVG path='/media/icons/fish.svg' className='svg-icon-3x svg-icon-dark' />
                <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>69</span>
                <span className='text-dark opacity-75 pt-1 fw-semibold fs-6'>Active Projects</span>
              </div>
            </div>
            <div className='card-body d-flex align-items-end pt-0'>
              <div className='d-flex align-items-center flex-column mt-3 w-100'>
                <div className='d-flex justify-content-between fw-bold fs-6 text-dark opacity-75 w-100 mt-auto mb-2'>
                  <span>43 Pending</span>
                  <span>72%</span>
                </div>
                <div className='h-8px mx-3 w-100 bg-white bg-opacity-50 rounded'>
                  <div
                    className='bg-dark rounded h-8px'
                    role='progressbar'
                    style={{width: '72%'}}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className='col-lg-4 col-md-6'>
          <ProjectsWidget className={'mt-4 card-custom card-stretch '} userUID={user.uid} />
        </div> */}

        {/* <div className='col-xxl-6'>
          <UsersWidget className='card-xxl-stretch mb-5 mb-xl-8' />
        </div>

        <PendingUsersWidget className='col-xxl-4 card-xxl-stretch mb-5 mb-xl-8' />
        <div className='col-xxl-6'></div> */}
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      {/* <div className='row gy-5 gx-xl-8'>
        <div className='col-xxl-4'>
          <ListsWidget3 className='card-xxl-stretch mb-xl-3' />
        </div>
        <div className='col-xl-8'>
          <TablesWidget10 className='card-xxl-stretch mb-5 mb-xl-8' />
        </div>
      </div> */}
      {/* end::Row */}

      {/* begin::Row */}
      {/* <div className='row gy-5 g-xl-8'>
        <div className='col-xl-4'>
          <ListsWidget2 className='card-xl-stretch mb-xl-8' />
        </div>
        <div className='col-xl-4'>
          <ListsWidget6 className='card-xl-stretch mb-xl-8' />
        </div>
        <div className='col-xl-4'>
          <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-8' items={5} />
        </div>
      </div> */}

      {/* <div className='row g-5 gx-xxl-8'>
        <div className='col-xxl-4'>
          <MixedWidget8
            className='card-xxl-stretch mb-xl-3'
            chartColor='success'
            chartHeight='150px'
          />
        </div>
        <div className='col-xxl-8'>
          <TablesWidget5 className='card-xxl-stretch mb-5 mb-xxl-8' />
        </div>
      </div> */}
    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  const {user} = useAuthContext()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage user={user} />
    </>
  )
}

export {DashboardWrapper}
