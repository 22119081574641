/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import moment from 'moment'
import {ProgressBar} from 'react-bootstrap'

import {doc, getDoc, serverTimestamp, setDoc} from 'firebase/firestore'
import {useCollection} from '../../../hooks/useCollection'
import {db} from '../../../../firebase/config'
import {Avatar} from '../../../../_metronic/partials'

type Props = {
  usersData: any
  smybolGroupStyle?: string
  symbolStyle?: string
  maxLeght: number
}

const UsersAvatars: FC<Props> = ({usersData, smybolGroupStyle, symbolStyle, maxLeght}) => {
  const {documents: users} = useCollection('users')

  return (
    <div className={`d-flex symbol-group symbol-hover ${smybolGroupStyle}`}>
      {usersData
        .sort((a) =>
          a.title === 'Proje Yöneticisi' ||
          a.title === 'Marka Yöneticisi' ||
          a.title === 'Müşteri Temsilcisi'
            ? -1
            : 1
        )
        .map(
          (a: any, index) =>
            index < maxLeght &&
            users.map(
              (u: any) =>
                a.uid === u.id && (
                  <div key={index}>
                    <Link to={`/profile/${u.id}/overview`}>
                      <Avatar
                        className={`symbol ${
                          (a.title === 'Proje Yöneticisi' ||
                            a.title === 'Marka Yöneticisi' ||
                            a.title === 'Müşteri Temsilcisi') &&
                          'border border-danger border-2 '
                        } ${symbolStyle}`}
                        name={u?.displayName}
                        avatarURL={u?.photoURL}
                      />
                    </Link>
                  </div>
                )
            )
        )}
      {usersData.length > maxLeght && (
        <>
          <a href='#' className='symbol opacity-75 opacity-100-hover symbol-30px symbol-circle'>
            <span className='symbol-label bg-dark text-gray-300 fs-8 fw-bold'>
              {'+'}
              {usersData.length - maxLeght}
            </span>
          </a>
        </>
      )}

      {users.filter(
        (u: any, i) =>
          u.model.isTeammate === true &&
          usersData?.filter((user: any) => user.uid === u.id).length > 0
      ).length === 0 && <span className='ms-n3 fs-7 text-muted'>No User.</span>}
    </div>
  )
}

export {UsersAvatars}
