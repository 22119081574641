import {doc, setDoc} from 'firebase/firestore'
import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {db} from '../../../../../../firebase/config'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {SocialMediaChannelModels} from '../../../services/social-media/components/models/SocialMediaModels'

const socialMediaSchema = Yup.object().shape({
  monthlyContent: Yup.number().required('Monthly content is required'),
})

type Props = {
  projectData: any
}

const Services: React.FC<Props> = (projectData: any) => {
  const currentProject = projectData?.projectData
  console.log('currentProject')
  console.log(currentProject)
  const {id, projectID} = useParams()
  const clientUID = id ? id : ''
  const projectUID = projectID ? projectID : ''

  const [checked, setChecked] = React.useState(false)
  const [monthlyContent, setMonthlyContent] = React.useState(null)

  const [channels, setChannels] = useState<any | null>(null)

  useEffect(() => {
    if (currentProject?.services?.socialMedia?.isActive === true) {
      setChecked(true)
      setMonthlyContent(currentProject?.services?.socialMedia?.settings?.monthlyContent)
      if (currentProject?.services?.socialMedia?.settings?.channels) {
        let channelArr: {name: string; content?: number; URL?: string}[] =
          currentProject?.services?.socialMedia?.settings?.channels
        setChannels([...channelArr])
      }
      console.log(currentProject?.services?.socialMedia?.settings?.monthlyContent)
    }
  }, [currentProject])

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<any | null>(null)
  const [success, setSuccess] = useState(false)

  const [formLoading, setFormLoading] = useState(false)
  const [formError, setFormError] = useState<any | null>(null)
  const [formSuccess, setFormSuccess] = useState(false)

  const handleChannel = (val: string) => {
    let channelArr: {name: string; content?: number; URL?: string}[] = []
    if (channels) {
      channelArr = channels
    }

    if (channelArr && channelArr.filter((c: any) => c.name === val).length > 0) {
      channelArr = channelArr.filter((c: any) => c.name !== val)

      console.log('silindi')
    } else {
      channelArr.push({name: val})
    }
    console.log(val)
    console.log(channelArr)
    setChannels([...channelArr])
  }

  const handleChannelContent = (event) => {
    console.log(event.target.name + ' ' + event.target.id)
    if (channels) {
      let channelArr: {name: string; content?: number; URL?: string}[] = channels

      let itemIndex = channelArr.findIndex((item) => item.name === event.target.name)
      if (event.target.id === 'content') channelArr[itemIndex].content = event.target.value
      if (event.target.id === 'URL') channelArr[itemIndex].URL = event.target.value
      console.log(channelArr)
      setChannels([...channelArr])
    }
  }

  const handleChangeSocialMedia = async () => {
    setError(null)
    setLoading(true)
    setSuccess(false)

    const docRef = doc(db, 'clients', clientUID, 'projects', projectUID)
    const docRefProjects = doc(db, 'projects', projectUID)
    setDoc(
      docRef,
      {
        services: {socialMedia: {name: 'Social Media', slug: 'social-media', isActive: !checked}},
      },
      {merge: true}
    )
      .then(async () => {
        setDoc(
          docRefProjects,
          {
            services: {
              socialMedia: {name: 'Social Media', slug: 'social-media', isActive: !checked},
            },
          },
          {merge: true}
        )
      })
      .then(async () => {
        setChecked(!checked)
        setSuccess(true)
        setTimeout(() => {
          setSuccess(false)
        }, 3000)
        setLoading(false)
      })
      .catch((error) => {
        setError(error.message)
        console.log(error.message)
      })
  }

  const formik = useFormik({
    initialValues: {
      monthlyContent: monthlyContent,
    },
    enableReinitialize: true,
    validationSchema: socialMediaSchema,
    onSubmit: async (values) => {
      setFormError(null)
      setFormLoading(true)
      setFormSuccess(false)

      const docRef = doc(db, 'clients', clientUID, 'projects', projectUID)
      const docRefProjects = doc(db, 'clients', clientUID, 'projects', projectUID)
      setDoc(
        docRef,
        {
          services: {
            socialMedia: {
              settings: {
                monthlyContent: values.monthlyContent,
                channels: channels,
              },
            },
          },
        },
        {merge: true}
      )
        .then(async () => {
          setDoc(
            docRefProjects,
            {
              services: {
                socialMedia: {
                  settings: {
                    monthlyContent: values.monthlyContent,
                    channels: channels,
                  },
                },
              },
            },
            {merge: true}
          )
        })
        .then(async () => {
          setFormSuccess(true)
          setTimeout(() => {
            setFormSuccess(false)
          }, 3000)
          setFormLoading(false)
        })
        .catch((error) => {
          setFormError(error.message)
          console.log(error.message)
        })
    },
  })

  return (
    currentProject && (
      <div className='card mb-5'>
        <div
          className='card-header border-0 cursor-pointer align-items-center'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_connected_accounts'
          aria-expanded='true'
          aria-controls='kt_account_connected_accounts'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Services</h3>
          </div>
          <div className='float-end'>
            {checked && <span className='badge badge-success'>Social Media</span>}
          </div>
        </div>

        <div id='kt_account_connected_accounts' className='collapse'>
          <div className='card-body border-top p-9'>
            <div className='py-2'>
              <div className='d-flex flex-stack'>
                <div className='d-flex'>
                  <div className='d-flex flex-column'>
                    <span className='fs-5 text-dark fw-bolder'>Social Media</span>
                    <div className='fs-6 fw-bold text-gray-400'>Content Managements</div>
                  </div>
                </div>
                <div className='d-flex justify-content-end'>
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      <span className='spinner-border spinner-border-sm align-middle me-4 mt-2'></span>
                    </span>
                  )}
                  {success && (
                    <>
                      <span className='text-success'>
                        <i className='bi bi-check text-success fs-2x me-2 mt-2'></i>
                      </span>
                    </>
                  )}
                  {error && (
                    <>
                      <span className='text-danger pt-1'>
                        {error}
                        <i className='bi bi-exclamation-circle text-danger fs-2 me-2 pt-4'></i>
                      </span>
                    </>
                  )}
                  <div className='form-check form-switch'>
                    <input
                      className='form-check-input w-45px h-30px'
                      type='checkbox'
                      value='on'
                      defaultChecked={
                        currentProject?.services?.socialMedia?.isActive === true && true
                      }
                      onChange={handleChangeSocialMedia}
                    />
                  </div>
                </div>
              </div>
              {checked && (
                <>
                  <form onSubmit={formik.handleSubmit} className='form'>
                    <div className='separator separator-dashed my-5'></div>

                    <div className='row mb-6'>
                      <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                        Monthly Content
                      </label>
                      <div className='col-lg-8 fv-row'>
                        <input
                          type='number'
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          placeholder='Aylık İçerik Sayısı'
                          {...formik.getFieldProps('monthlyContent')}
                        />
                        {formik.touched.monthlyContent && formik.errors.monthlyContent && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              {formik.errors.monthlyContent.toString()}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='row mb-6'>
                      <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                        Social Media Channel Content
                        {channels && channels.map((c) => c.name)}
                      </label>
                      <div className='col-lg-8 fv-row'>
                        {SocialMediaChannelModels.map((channel: any) => (
                          <span
                            className={`btn btn-sm btn-icon btn-${channel.name.toLowerCase()} me-3 ${
                              channels?.filter((c: any) => c.name === channel.name).length > 0
                                ? 'opacity-100'
                                : 'opacity-25'
                            }`}
                            onClick={() => handleChannel(channel.name)}
                          >
                            <i className={`${channel.icon} fs-4`}></i>
                          </span>
                        ))}
                      </div>
                    </div>

                    {channels?.map((c: any, i) => (
                      <div className='row mb-6' key={i}>
                        <label className='col-lg-4 col-form-label fw-bold fs-6'>{c.name}</label>

                        <div className='col-lg-8'>
                          <div className='row'>
                            <div className='col-lg-4 fv-row'>
                              <input
                                type='number'
                                name={c.name}
                                id='content'
                                value={c?.content}
                                className='form-control  form-control-solid mb-3 mb-lg-0'
                                placeholder='İçerik Sayısı'
                                onChange={handleChannelContent}
                              />
                            </div>

                            <div className='col-lg-8 fv-row'>
                              <input
                                type='text'
                                name={c.name}
                                value={c?.URL}
                                id='URL'
                                className='form-control form-control-solid mb-3 mb-lg-0'
                                placeholder={`${c.name} Page URL`}
                                onChange={handleChannelContent}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}

                    <div className='row'>
                      <label className='col-lg-6 col-form-label'></label>
                      <div className='col-lg-6 text-end'>
                        {formError && <span className='badge badge-danger me-10'>{formError}</span>}

                        <button
                          type='submit'
                          className={`btn btn-primary ${formSuccess && 'btn-success'}`}
                          disabled={formLoading || formSuccess}
                        >
                          {formSuccess && (
                            <>
                              <span className='badge badge-success me-5 px-6'></span>
                              <span className='' style={{display: 'block'}}>
                                <i className='bi bi-check fs-2'></i>
                                Success
                              </span>
                            </>
                          )}
                          {!formSuccess && (
                            <>
                              {!formLoading && 'Save Changes'}
                              {formLoading && (
                                <span className='indicator-progress' style={{display: 'block'}}>
                                  Please wait...{' '}
                                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                              )}
                            </>
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  )
}

export {Services}
