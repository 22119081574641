/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'

import {Avatar} from '../../../../../../../../../../_metronic/partials'
import moment from 'moment'

type Props = {
  contentData: any
}

const Logs: FC<Props> = ({contentData}) => {
  return (
    <div className='card-scroll scroll-y h-375px'>
      {contentData?.logs
        .sort((a: any, b: any) => (a.createdAt < b.createdAt ? 1 : -1))
        .map((log: any, index) => (
          <div key={index}>
            <div className='d-flex me-2'>
              <Avatar
                className={'symbol symbol-35px me-2'}
                name={log?.displayName}
                avatarURL={log?.photoURL}
                uid={log?.uid}
              />
              <div className='d-flex flex-row-fluid flex-column'>
                <div className='d-flex flex-row-fluid flex-wrap'>
                  <span className='text-primary flex-fill  fs-7 fw-bold'>{log?.displayName}</span>
                  <span className='text-muted fw-semibold  fs-8'>
                    {log?.createdAt
                      ? moment
                          .utc(new Date(log?.createdAt?.toDate()))
                          .local()
                          .startOf('s')
                          .fromNow(true)
                      : moment
                          .utc(new Date(contentData?.createdAt?.toDate()))
                          .local()
                          .startOf('s')
                          .fromNow(true)}
                  </span>
                </div>
                <span className='text-muted fw-semibold d-block fs-7'>{log.action}</span>
              </div>
            </div>
            {/* <div className='mt-3' dangerouslySetInnerHTML={{__html: log?.value}} /> */}

            <div className='separator separator-dashed my-2'></div>
          </div>
        ))}
    </div>
  )
}

export {Logs}
