import {useEffect, useState} from 'react'
import {useCollection} from '../../../../app/hooks/useCollection'
import DatePicker from 'react-datepicker'
import {Avatar} from '../user/Avatar'
import {Timestamp, doc, getDoc, setDoc} from 'firebase/firestore'
import {useParams} from 'react-router-dom'
import {db} from '../../../../firebase/config'

type Props = {
  projectData: any
  messageUID: string
}

/* eslint-disable jsx-a11y/anchor-is-valid */
const DropdownCreateTask: React.FC<Props> = ({messageUID, projectData}) => {
  const {id, projectID} = useParams()
  const clientUID = id ? id : ''
  const projectUID = projectID ? projectID : ''

  const [selectedWork, setSelectedWork] = useState('')
  const [deadline, setDeadline] = useState(new Date())
  const [selectedUsers, setSelectedUsers] = useState<any | null>([])
  const [allowedTeams, setAllowedTeams] = useState<any | null>(null)
  const {documents: users} = useCollection('users')

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<any | null>(null)
  const [success, setSuccess] = useState(false)

  const {documents: serviceWorks} = useCollection('agency/data/serviceWorks')

  const toogleWorkUser = (user, type) => {
    type === 'add' && setSelectedUsers([...selectedUsers, {uid: user.id}])
    type === 'remove' && setSelectedUsers(selectedUsers.filter((u: any) => u.uid !== user.id))
  }
  useEffect(() => {
    setSelectedUsers([])
    serviceWorks
      .filter((w: any) => selectedWork === w.id)
      .map((work: any) => setAllowedTeams(work?.teams))
  }, [selectedWork])

  const handleCreate = () => {
    setLoading(true)
    const workData: any = serviceWorks.find((s: any) => s.id === selectedWork)
    workData.authorities = selectedUsers
    workData.deadline = Timestamp.fromDate(deadline)
    workData.messageUID = messageUID
    console.log(workData)

    let docRef = doc(db, 'clients/' + clientUID + '/projects/' + projectUID)
    getDoc(docRef)
      .then((data) => {
        const projectData: any = data.data()
        if (projectData?.works) {
          projectData.works.push(workData)
        } else {
          projectData.works = [workData]
        }

        setDoc(doc(db, 'clients', clientUID, 'projects', projectUID), projectData, {merge: true})
          .then(async (data) => {
            setDoc(doc(db, 'projects', projectUID), projectData, {
              merge: true,
            }).then(() => {
              setLoading(false)
              setSuccess(true)
              setTimeout(() => {
                setSuccess(false)
              }, 1500)
              setLoading(false)
            })
          })
          .catch((err) => {
            setError(err.message)
            console.log(err.message)
            setLoading(false)
          })
      })
      .catch((error) => {})
  }
  return (
    <div className='menu menu-sub menu-sub-dropdown w-250px' data-kt-menu='true'>
      <div className='p-5'>
        <select
          className='form-select form-select-sm form-select-solid mb-4'
          data-kt-select2='true'
          data-placeholder='Select Work'
          data-allow-clear='true'
          onChange={(e) => setSelectedWork(e.target.value)}
          value={selectedWork}
        >
          <option selected disabled value=''>
            Select Work
          </option>
          {serviceWorks &&
            serviceWorks.map(
              (work: any) =>
                work?.services.filter((s: any) => s.id === projectData?.serviceUID).length > 0 && (
                  <option value={work.id}>{work?.name}</option>
                )
            )}
        </select>
        <DatePicker
          className='form-control form-control-sm form-control-solid mb-4'
          selected={deadline}
          onChange={(date, event) => {
            event.stopPropagation() // Bu, menünün kapanmasını engelleyecektir
            setDeadline(date)
          }}
          timeInputLabel=''
          dateFormat='eeee - dd.MM.yyyy'
          calendarStartDay={1}
        ></DatePicker>

        {selectedWork && (
          <div className='mb-6 ms-1'>
            <label className='form-label fw-bold text-primary fs-7'>User to Assign</label>

            <div className='symbol-group symbol-hover ms-0 pe-4 ps-4 py-0'>
              {users
                ?.filter(
                  (u: any) =>
                    u?.teams?.filter(
                      (ut: any) => allowedTeams?.filter((at: any) => at.id === ut.uid).length > 0
                    ).length > 0
                )
                .map((user: any) => (
                  <div className='overlay'>
                    <div className='overlay-wrapper'>
                      <Avatar
                        className={`symbol symbol-30px ${
                          selectedUsers?.filter((u: any) => u?.uid === user.id).length > 0
                            ? ''
                            : 'opacity-25 opacity-75-hover'
                        } symbol-circle`}
                        name={user.displayName}
                        avatarURL={user?.photoURL}
                      />
                    </div>
                    {selectedUsers?.filter((u: any) => u.uid === user.id).length > 0 ? (
                      <div className='symbol symbol-30px overlay-layer symbol-circle bg-danger bg-opacity-75'>
                        <button
                          className='btn btn-link'
                          type='button'
                          onClick={() => toogleWorkUser(user, 'remove')}
                        >
                          <i className='bi bi-x fs-2 text-white pe-0'></i>
                        </button>
                      </div>
                    ) : (
                      <div className='symbol symbol-30px overlay-layer symbol-circle bg-success bg-opacity-75'>
                        <button
                          className='btn btn-link'
                          type='button'
                          onClick={() => toogleWorkUser(user, 'add')}
                        >
                          <i className='bi bi-check fs-2 text-white pe-0'></i>
                        </button>
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </div>
        )}

        <div className='d-flex justify-content-end'>
          <button
            type='submit'
            onClick={() => handleCreate()}
            className={`btn btn-sm btn-primary ${success && 'btn-success'}`}
            data-kt-menu-dismiss='true'
            disabled={!selectedWork || selectedUsers.length < 1}
          >
            {success && (
              <>
                <span className='badge badge-success me-5  px-6'></span>
                <span className='' style={{display: 'block'}}>
                  <i className='bi bi-check fs-2'></i>
                  Success
                </span>
              </>
            )}
            {!success && (
              <>
                {!loading && 'Create Task'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  )
}

export default DropdownCreateTask
