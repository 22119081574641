/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, AuthPending} from '../modules/auth'
import {HrLayout} from '../modules/hr/'
import {useAuthContext} from '../modules/auth'

import {App} from '../App'
import {HrPage} from '../modules/hr/HrPage'

const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const {user, authIsReady, userData} = useAuthContext()
  return (
    <>
      {authIsReady && (
        <BrowserRouter basename={PUBLIC_URL}>
          <Routes>
            <Route element={<App />}>
              <Route path='error/*' element={<ErrorsPage />} />
              <Route path='logout' element={<Logout />} />
              <Route path='application/*' element={<HrPage />} />
              {user && (
                <>
                  {userData?.model?.isPending && (
                    <>
                      <Route path='*' element={<AuthPending />} />
                    </>
                  )}
                  {userData?.model?.isTeammate && (
                    <>
                      <Route path='/*' element={<PrivateRoutes />} />
                      <Route index element={<Navigate to='/dashboard' />} />
                    </>
                  )}
                  {!userData?.model?.isTeammate && (
                    <>
                      <Route path='*' element={<AuthPending />} />
                    </>
                  )}
                  {!userData?.model?.isClient && (
                    <>
                      <Route path='*' element={<AuthPending />} />
                    </>
                  )}
                </>
              )}
              {!user && (
                <>
                  <Route path='auth/*' element={<AuthPage />} />
                  <Route path='*' element={<Navigate to='/auth' />} />
                </>
              )}
            </Route>
          </Routes>
        </BrowserRouter>
      )}
    </>
  )
}
export {AppRoutes}
